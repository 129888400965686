import React from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography, useTheme } from '@mui/material';
import { Dropdown } from '../../../shared/components/Dropdown';
import { FilterButton } from '../../../shared/components/FilterButton';
import { AdminListFilters } from '../admin-types';
import { useQuery, useQueryActive } from '../../../shared/utils/route';
import { DB_STATUS_VALUES } from '../admin-utils';

type AdminFilterProps = {
  filters: AdminListFilters;
};

export const AdminFilters: React.FC<AdminFilterProps> = ({ filters }) => {
  const theme = useTheme();
  const { searchQuery, query, resetQuery } = useQuery();
  const isActive = useQueryActive(query);
  const onChangeField =
    (field: string) =>
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): void => {
      searchQuery({
        page: 1, // when change a filter we should reset the page
        [field]: event.target.value,
      });
    };

  const onChangeSelect =
    (field: string) =>
    (event: SelectChangeEvent<string | number | unknown>): void => {
      searchQuery({
        page: 1, // when change a filter we should reset the page
        [field]: event?.target?.value as string | number,
      });
    };
  return (
    <Dropdown
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      button={({ toggle }) => (
        <Stack justifyContent="center">
          <FilterButton isActive={isActive} onClick={toggle} />
        </Stack>
      )}
    >
      <Stack paddingTop='10px' paddingBottom='10px' direction="column" spacing={1} >
        <Stack direction="column" spacing={1}>
          <Typography variant="subtitle2">Creation Date</Typography>
          <Stack spacing={1} direction="row">
            <TextField
              label="From"
              type="date"
              value={filters.from}
              onChange={onChangeField('from')}
              fullWidth
            />
            <TextField
              label="To"
              type="date"
              value={filters.to}
              onChange={onChangeField('to')}
              fullWidth
            />
          </Stack>
        </Stack>
        <Stack direction="column" spacing={1}>
          <Typography variant="subtitle2">DB Status</Typography>
          <FormControl>
            <InputLabel>DB Status</InputLabel>
            <Select
              label='DB Status'
              value={filters.dbStatus}
              onChange={onChangeSelect('dbStatus')}
            >
              {DB_STATUS_VALUES.map((item) => (
                <MenuItem key={item.value} value={item.value} >
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          spacing={1}
          sx={{
            display: 'none',
            [theme.breakpoints.down('md')]: {
              display: 'block',
            },
          }}
        >
          <Typography variant="subtitle2">Rows</Typography>
          <FormControl fullWidth>
            <Select
              value={filters.rows}
              fullWidth
              onChange={onChangeSelect('rows')}
            >
              <MenuItem value={10}>10 Rows(s)</MenuItem>
              <MenuItem value={20}>20 Rows(s)</MenuItem>
              <MenuItem value={30}>30 Rows(s)</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Stack paddingTop={2}>
          <Button onClick={resetQuery} variant="contained" size="medium">
            Reset filters
          </Button>
        </Stack>
      </Stack>
    </Dropdown>
  );
};
