import React, { useState } from 'react';
import Edit from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import CircleIcon from '@mui/icons-material/Circle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { styled, useTheme } from '@mui/material/styles';
import {
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  Button,
  Typography,
  Box,
  Chip,
  SpeedDial,
  SpeedDialAction,
  useMediaQuery,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useLocation, useHistory } from 'react-router-dom';
import { snackbar } from '../../shared/components/Snackbar';
import { TableSkeleton } from '../../shared/components/Skeletons';
import { AssignedJobs } from './components/AssignedJobs';
import { archiveCustomerVariables, archiveCustomerVariablesV2 } from './customer-utils';
import { RowResponsive } from '../../shared/components/RowResponsive';
import {
  useCustomerQuery,
  useUpdateCustomer2Mutation,
  useUpdateCustomerMutation,
} from '../../shared/types/generated';
import {
  humanizeDate,
  clearQuerysFromCache,
} from '../../shared/utils';
import { UseValidateFeatureFlag } from '../auth/auth-hooks';
import { ViewBackButton } from '../../shared/components/ViewBackButton';
import { DisplayPhoneNumber } from '../../shared/components/DisplayPhoneNumber';
import { FeatureFlags } from '../../shared/constants/featureFlag';

const MdPaper = styled(Box)(({ theme }) => ({
  position: 'relative',
  minWidth: '65%',
  maxWidth: '65%',
  height: 'auto',
  backgroundColor: '#fff',
  margin: '5% auto 0 auto',
  borderRadius: '10px',
  boxShadow: '0px 3px 0px #EDEDF6',
  padding: '35px 20px 35px 25px',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    margin: '0',
    height: 'auto',
    marginTop: '25px',
  },
}));

/**
 * @returns {JSX.Element} - Customer view.
 */
export function DetailCustomerView(): JSX.Element {
  const history = useHistory();
  const featureFlag = UseValidateFeatureFlag(FeatureFlags.DOCUMENTS);
  const featureFlagBlockUser = UseValidateFeatureFlag(FeatureFlags.BLOCK_CUSTOMERS);
  const [openModalWarning, setOpenModalWarning] = useState<boolean>(false);
  const [openModalStatus, setOpenModalStatus] = useState<boolean>(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const id = params.get('id');

  const { data } = useCustomerQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: typeof id === 'string' ? id : '',
    },
  });

  const [archiveCustomer, { loading: loadingArchive, error: errorArchive }] =
    useUpdateCustomerMutation({
      /**
       * @returns {void} - Result.
       */
      onCompleted: () => {
        if (errorArchive === undefined) {
          snackbar.success('Customer archived');
          setOpenModalWarning(false);
          history.goBack();
        } else {
          setOpenModalStatus(true);
        }
      },

      /**
       * @param cache - Apollo chache.
       */
      update: clearQuerysFromCache(['customersList', 'customer']),
    });

  /**
   * @returns {boolean} - Remove Address.
   */
  const isArchive = (): boolean => {
    let retorno = true;
    if (data !== undefined && data.customer?.archived === null) {
      retorno = false;
    }
    return retorno;
  };

  const [archiveCustomerV2, { loading: loadingArchiveV2, error: errorArchiveV2}] =
    useUpdateCustomer2Mutation({
      onCompleted: () => {
        if (errorArchiveV2 === undefined) {
          snackbar.success(
              data?.customer?.archived ? 'Customer Activated' : 'Customer Archived',
          );
          setOpenModalWarning(false);
        } else {
          setOpenModalStatus(true);
        }
      },
      update: clearQuerysFromCache(['customersList', 'customer']),
    });


  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="left"
        >
          {data ? (
            <Typography variant="h4">{data.customer?.name}</Typography>
          ) : (
            <Skeleton animation="wave" />
          )}
          <ViewBackButton />
        </Box>
        <Box
          sx={{
            zIndex: 50,
            display: ['flex', 'none'],
            position: 'fixed',
            transform: 'translateZ(0px)',
            flexGrow: 1,
            height: '100%',
            top: '0',
            right: '20px',
            bottom: '30px',
          }}
        >
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: 'absolute', bottom: 16, right: 16 }}
            icon={<MoreVertIcon />}
          >
            {isArchive() === false && (
              <SpeedDialAction
                icon={<Edit />}
                onClick={() => history.push(`/customers/edit?id=${id}`)}
                tooltipTitle="Edit"
              />
            )}
            <SpeedDialAction
              icon={<ArchiveIcon />}
              tooltipTitle={
                data?.customer.archived === null ? 'Archive' : 'Active'
              }
              onClick={() => {
                setOpenModalWarning(true);
              }}
            />
          </SpeedDial>
        </Box>

        <Box
          justifyContent={
            data?.customer.archived === null ? 'space-between' : 'flex-end'
          }
          alignItems="center"
          sx={{ gap: 5, display: ['none', 'flex'] }}
        >
          <Button
            variant="contained"
            size="large"
            color={data?.customer.archived === null ? 'error' : 'primary'}
            onClick={() => {
              setOpenModalWarning(true);
            }}
          >
            {data?.customer.archived === null
              ? 'Archive customer'
              : 'Active customer'}
          </Button>
          {isArchive() === false && (
            <>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Edit />}
                href={`/customers/edit?id=${id}`}
              >
                Edit customer
              </Button>
            </>
          )}
        </Box>
      </Box>
      <MdPaper sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="h5">Information</Typography>
          </Grid>
          <Grid item sm={12} md={6} lg={6}>
            <Typography variant="h5">Full Name</Typography>
            {data ? (
              <Typography variant="body1">{data.customer?.name}</Typography>
            ) : (
              <Skeleton animation="wave" />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography variant="h5">Email</Typography>
            {data ? (
              <Typography variant="body1">{data.customer?.email}</Typography>
            ) : (
              <Skeleton animation="wave" />
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography variant="h5">Phone Number</Typography>
            {data ? (
              <>
                <DisplayPhoneNumber phoneNumber={data.customer?.phoneNumber} />
                {data.customer.customerPhoneNumberRelation.items.map(
                  (value) => (
                    <DisplayPhoneNumber key={value.id} phoneNumber={value.phoneNumber} />
                  ),
                )}
              </>
            ) : (
              <Skeleton animation="wave" />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography variant="h5">Email Notifications</Typography>
            {data ? (
              <>
                {data.customer.customerCustomerEmailRelation.items.map(
                  ({ email }) => (
                    <Typography variant="body1">{email}</Typography>
                  ),
                )}
              </>
            ) : (
              <Skeleton animation="wave" />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography variant="h5">Customer Since</Typography>
            {data ? (
              <Typography variant="body1">
                {humanizeDate(data.customer?.createdAt)}
              </Typography>
            ) : (
              <Skeleton animation="wave" />
            )}
          </Grid>
          <Grid item sm={6} md={6} lg={6}>
            <Typography variant="h5">Status</Typography>
            {data ? (
              <Chip
                icon={<CircleIcon />}
                label={data?.customer?.archived ? 'Archived' : 'Active'}
                variant="outlined"
                color={data?.customer?.archived ? 'error' : 'secondary'}
              />
            ) : (
              <Skeleton animation="wave" />
            )}
          </Grid>
        </Grid>
      </MdPaper>
      {featureFlag && (
        <MdPaper sx={{ flexGrow: 1, marginTop: '50px' }}>
          <Grid container>
            <Grid item sm={12} md={12} lg={12}>
              <Typography variant="h5">Documents</Typography>
            </Grid>
            <Grid item sm={12} md={12} lg={12} sx={{ width: '100%' }}>
              {matches ? (
                <>
                  {data &&
                    data.customer.documents &&
                    data.customer.documents.items.map((document, index) => (
                      <Box sx={{ marginTop: '10px' }} key={document.id}>
                        <RowResponsive
                          type="document"
                          status="active"
                          code={`${index + 1}`}
                          key={document.id}
                          name={document.filename}
                          downloadUrl={document.downloadUrl}
                        />
                      </Box>
                    ))}
                </>
              ) : (
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>File Name</TableCell>
                      <TableCell align="center">Download</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ height: '80%' }}>
                    {data !== undefined ? (
                      data.customer.documents &&
                      data.customer.documents.items.map((document, index) => (
                        <React.Fragment key={document.id}>
                          <TableRow>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{document.filename}</TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                maxWidth: '200px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              <a
                                href={document.downloadUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <SaveAltIcon />
                              </a>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))
                    ) : (
                      <TableSkeleton rows={1} columns={8} loading />
                    )}
                  </TableBody>
                </Table>
              )}
            </Grid>
          </Grid>
        </MdPaper>
      )}
      <MdPaper sx={{ flexGrow: 1, marginTop: '50px' }}>
        <Grid container>
          <Grid item sm={12} md={12} lg={12}>
            <Typography variant="h5">Addresses</Typography>
          </Grid>
          <Grid item sm={12} md={12} lg={12} sx={{ width: '100%' }}>
            {matches ? (
              <>
                {data &&
                  data.customer.customerAddressRelation.items.map(
                    (item, index) => (
                      <Box sx={{ marginTop: '10px' }} key={item.id}>
                        <RowResponsive
                          type="address"
                          status="active"
                          key={item.zipCode}
                          name={item.name}
                          code={index + 1}
                          email={item.state}
                          number={item.city}
                          street={item.streetAddress}
                          zip={item.zipCode}
                        />
                      </Box>
                    ),
                  )}
              </>
            ) : (
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Contact Name</TableCell>
                    <TableCell>Contact Phone</TableCell>
                    <TableCell>State</TableCell>
                    <TableCell>City</TableCell>
                    <TableCell>Street</TableCell>
                    <TableCell>ZIP</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ height: '80%' }}>
                  {data !== undefined ? (
                    data.customer.customerAddressRelation.items.map(
                      (item, index) => (
                        <React.Fragment key={item.id}>
                          <TableRow>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{item.contactName}</TableCell>
                            <TableCell>
                              <DisplayPhoneNumber variant="caption" phoneNumber={item.contactPhone} />
                            </TableCell>
                            <TableCell>{item.state}</TableCell>
                            <TableCell>{item.city}</TableCell>
                            <TableCell
                              sx={{
                                maxWidth: '200px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              <a
                                href={`https://www.google.com/maps/place/${item.streetAddress}+${item.city}+${item.zipCode}+${item.state}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {item.streetAddress}
                              </a>
                            </TableCell>
                            <TableCell>{item.zipCode}</TableCell>
                          </TableRow>
                        </React.Fragment>
                      ),
                    )
                  ) : (
                    <TableSkeleton rows={1} columns={8} loading />
                  )}
                </TableBody>
              </Table>
            )}
          </Grid>
        </Grid>
      </MdPaper>
      <AssignedJobs list={data} />
      <Dialog open={openModalWarning}>
        <DialogTitle id="warningTitle">Are you sure?</DialogTitle>
        <DialogContent>
          {data?.customer.archived === null
            ? 'By archiving this customer, they will lose access to the platform. Proceed with archiving?'
            : 'This Customer will be activated'}
        </DialogContent>
        <DialogActions>
          <Button
            variant="default"
            onClick={() => {
              setOpenModalWarning(false);
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant={data?.customer.archived === null ? 'delete' : 'contained'}
            loading={loadingArchive || loadingArchiveV2}
            onClick={() => {
              const con = !data?.customer.archived;

              if (featureFlagBlockUser) {
                archiveCustomerV2({
                  variables: archiveCustomerVariablesV2(
                    typeof id === 'string' ? id : '',
                    con,
                  ),
                });
              }else { 
                archiveCustomer({
                  variables: archiveCustomerVariables(
                    typeof id === 'string' ? id : '',
                    con,
                  ),
                }); 
              }
            }}
          >
            {data?.customer.archived === null ? 'archive' : 'active'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog open={openModalStatus}>
        {errorArchive !== undefined || errorArchiveV2 !== undefined && (
          <>
            <DialogTitle id="warningTitle">Something went wrong</DialogTitle>
            <DialogActions>
              <Button
                variant="default"
                autoFocus
                onClick={() => {
                  setOpenModalStatus(false);
                }}
              >
                close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}
