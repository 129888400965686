import { ReactElement } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export const TableTooltip: React.FC<{
  title?: string;
  children: ReactElement;
}> = ({ children, title }) => {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up('xl'));
  const shouldDisabledListener = (title?.length || 0) <= 30 && match;
  return (
    <Tooltip disableHoverListener={shouldDisabledListener} title={title || 'N/A'}>
      {children}
    </Tooltip>
  );
};
