import { useState, useMemo, forwardRef, useImperativeHandle } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import debounce from 'lodash.debounce';
import TextField, { TextFieldProps } from '@mui/material/TextField';


export type SearchInputRef = {
  clearInput: () => void;
}

type SearchInputProps = TextFieldProps & {
  onChangeText: (value: string) => void;
};

export const SearchInput = forwardRef<SearchInputRef, SearchInputProps>((props, ref) => {
  const [state, setState] = useState<string>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeText = useMemo(() => debounce(props.onChangeText, 1000), []);

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    setState(event.target.value);
    onChangeText(event.target.value?.trim());
  };

  const clearInput = (): void => {
    setState('');
  };

  useImperativeHandle(ref, () =>({
    clearInput,
  }));

  return (
    <TextField  
      {...props}
      variant="outlined" 
      fullWidth label={props?.label || 'Search'}  
      value={state}
      onChange={onChange} 
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Search />
          </InputAdornment>
        )
     }}
    />
  );
});
