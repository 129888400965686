import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material';

import { JobsListQuery } from '../../../shared/types/generated';
import { TableTooltip } from '../../../shared/components/TableTooltip';

const Text = styled('span')(({ theme }) => ({
  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: 200,
  [theme.breakpoints.down('lg')]: {
    width: 100,
  },
}));

type JobFieldWorkerTableCellListProps = {
  data: JobsListQuery['jobsList']['items'][0];
};

export const JobFieldWorkerTableCellList: React.FC<JobFieldWorkerTableCellListProps> =
  ({ data }) => {
    const theme = useTheme();
    const match = useMediaQuery(theme.breakpoints.down('lg'));
    const fwList = data.jobJobFieldWorkerRelation.items
      .map((fw) => fw.fieldworker?.fullName || 'N/A')
      .join(',  ');
    const size = match ? 30 : 50;
    const fwText = fwList.slice(0, size);
    return (
      <TableTooltip title={fwList} >
        <Text>{fwText}</Text>
      </TableTooltip>
    );
  };
