import {
  BoxProps,
  Box,
  Typography,
  useMediaQuery,
  Pagination as MuiPagination,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
// import { Box } from "@mui/system";

/**
 * @param root0 - Props.
 * @param root0.page - Page.
 * @param root0.pageSize - PageSize.
 * @param root0.totalCount - Total count of records.
 * @param root0.onChange - OnChange handler.
 * @returns Component.
 */
export function Pagination({
  page,
  pageSize,
  totalCount,
  onChange,
  ...props
}: Omit<BoxProps, 'onChange'> & {
  page: number;
  pageSize: number;
  totalCount: number;
  onChange: (page: number) => void;
}): JSX.Element {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const showing = Math.min(pageSize, totalCount ?? 0);

  return (
    <>
      {matches ? (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ height: '100px', width: '100%', margin: 0 }}
              {...props}
            >
              <MuiPagination
                count={Math.ceil(totalCount / pageSize)}
                shape="rounded"
                size="medium"
                page={page}
                boundaryCount={1}
                siblingCount={0}
                showFirstButton
                showLastButton
                onChange={(event: unknown, changedPage: number) => {
                  onChange(changedPage);
                }}
              />
            </Box>
          </Box>
        </>
      ) : (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
          {...props}
        >
          <Typography variant="body2">
            Showing {showing} of {totalCount}
          </Typography>
          <MuiPagination
            count={Math.ceil(totalCount / pageSize)}
            shape="rounded"
            page={page}
            showFirstButton
            showLastButton
            onChange={(event: unknown, changedPage: number) => {
              onChange(changedPage);
            }}
          />
        </Box>
      )}
    </>
  );
}

export const getPagination = (
  page: number,
  pageSize: number,
): {
  skip: number;
  first: number;
} => ({
  skip: page * pageSize,
  first: pageSize * (page + 1),
});