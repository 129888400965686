import { styled } from '@mui/material';
import { useLocation, Link } from 'react-router-dom';
import MuiDrawer from '@mui/material/Drawer';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import MuiListItem from '@mui/material/ListItem';
import MuiList from '@mui/material/List';
import Box from '@mui/material/Box';
import { red, common } from '@mui/material/colors';

import { Icon, IconNames } from '../icons/Icon';
import { LAYOUT_SIZE } from '../../css/theme';
import CoolriteLogo from '../../assets/Coolrite-logo.png';
import { useUserRoutes } from '../../../modules/session/session-hooks';

const ACTIVE_ICON_COLOR = red['900'];

const Drawer = styled(MuiDrawer)(({ theme }) => ({
  gridArea: 'sidebar',
  overflowX: 'hidden',
  height: '100%',
  backgroundColor: theme.palette.primary.main,
  position: 'relative',
  boxSizing: 'border-box',
}));

const List = styled(MuiList)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));

const ListItem = styled(MuiListItem, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>(({ theme, isActive }) => ({
  padding: 0,
  borderRadius: 10,
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
  transition: theme.transitions.create(['backgroundColor', 'color'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  '&:hover': {
    backgroundColor: theme.palette.common.white,
  },
  '&:hover svg': { color: ACTIVE_ICON_COLOR },
  ...(isActive && {
    backgroundColor: theme.palette.common.white,
  }),
}));

export function WebNavMenu(): JSX.Element {
  const location = useLocation();
  const routes = useUserRoutes();
  return (
    <Drawer
      variant="permanent"
      PaperProps={{
        sx: {
          position: 'relative',
          bgcolor: 'transparent',
          overflow: 'hidden',
          width: '100%',
          borderRight: 'none',
        },
        elevation: 0,
      }}
    >
      <Box
        bgcolor="secondary.main"
        height={`${LAYOUT_SIZE}px`}
        minHeight={`${LAYOUT_SIZE}px`}
        width={`${LAYOUT_SIZE}px`}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <img height="32" width="32" src={CoolriteLogo} alt="logo" />
      </Box>
      <List>
        {routes
          .filter((_item) => _item.icon)
          .map((route) => (
            <ListItem
              isActive={location.pathname.indexOf(route.path) >= 0}
              key={route.path}
            >
              <ListItemButton
                to={route.path}
                component={Link}
              >
                <MuiListItemIcon sx={{ display: 'inline-flex', justifyContent: 'center',  minWidth: 'auto'}}>
                  <Icon
                    name={route.icon as IconNames}
                    sx={(theme) => ({
                      color:
                        location.pathname.indexOf(route.path) >= 0
                          ? ACTIVE_ICON_COLOR
                          : common.white,
                      transition: theme.transitions.create(
                        ['backgroundColor', 'color'],
                        {
                          easing: theme.transitions.easing.sharp,
                          duration: theme.transitions.duration.enteringScreen,
                        },
                      ),
                    })}
                  />
                </MuiListItemIcon>
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </Drawer>
  );
}
