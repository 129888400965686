/* eslint-disable jsdoc/require-jsdoc */
import React from 'react';
import dayjs from 'dayjs';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import {
  Box,
  Button,
  Link,
  Dialog,
  Typography,
  useMediaQuery,
  Grid,
} from '@mui/material';
import {
  GRAY_DARK,
  GRAY_LIGHT,
  PRIMARY,
  theme,
} from '../../../shared/css/theme';
import { Job, Fieldworker } from '../../../shared/types/types';
import { CalendarType, JobColor } from '../calendar-types';
import { InfoCardPopover } from './InfoCardPopover';
import { InfoCardMinPreview } from './InfoCardMinPreview';
// import { CustomDialog } from '../../../shared/components/CustomDialog';
// import { DialogScheduleCalendar } from '../../../shared/components/DialogScheduleCalendar';

/**
 * @param {string} props - Today date.
 * @param {string} props.job - Today date.
 * @returns {JSX.Element} - Calendar View.
 */
export function InfoCard({
  job,
  fieldworker,
  gridRowStart,
  gridColumnStart,
  backgroundColor,
  count,
  type,
}: {
  job: Job;
  fieldworker?: Fieldworker;
  gridColumnStart: number | string;
  gridRowStart: number | string;
  backgroundColor: string;
  count: number;
  type: CalendarType;
}): JSX.Element {
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        onClick={handleClick}
        sx={{
          borderBottom: [
            type === CalendarType.DAY || type === CalendarType.AGENDA
              ? 'none'
              : `1px solid ${GRAY_LIGHT}`,
            `1px solid ${GRAY_LIGHT}`,
          ],
          borderRight: [
            type === CalendarType.DAY || type === CalendarType.AGENDA
              ? 'none'
              : `1px solid ${GRAY_LIGHT}`,
            `1px solid ${GRAY_LIGHT}`,
          ],
          height: [
            type === CalendarType.DAY || type === CalendarType.AGENDA
              ? '80%'
              : '100%',
            '100%',
          ],
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          width: count <= 2 ? '100%' : 'auto',
          marginBottom: '10px',
          padding: '0',
          gridColumnStart,
          gridRowStart,
          backgroundColor,
        }}
      >
        <Box
          sx={{
            display: [
              type === CalendarType.DAY || type === CalendarType.AGENDA
                ? 'grid'
                : 'block',
              'block',
            ],
            gridTemplateColumns: 'repeat(3, 33%)',
            padding: [
              type === CalendarType.DAY || type === CalendarType.AGENDA
                ? '10px'
                : '0 5px 5px 5px',
              '0 5px 5px 5px',
            ],
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Link
            noWrap
            href={`/jobs/${job.id}`}
            color="grayDark"
            underline="none"
            sx={{
              fontWeight: 'bold',
              fontSize: '13px',
              width: '100%',
            }}
          >
            #{job?.code}
          </Link>
          {!matchesSm && type === CalendarType.AGENDA ? (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid md={8}>
                <Typography
                  noWrap
                  color={GRAY_DARK}
                  variant="caption"
                  component="div"
                >
                  {dayjs(job?.startDateTime).format('HH:mm A')}
                </Typography>
                <Box display="flex" flexDirection="row" alignContent="center">
                  <Typography
                    fontWeight="bold"
                    textOverflow="ellipsis"
                    noWrap
                    color={GRAY_DARK}
                    variant="caption"
                    component="div"
                  >
                    Customer:
                  </Typography>
                  <Typography
                    style={{ marginLeft: 5 }}
                    noWrap
                    color={GRAY_DARK}
                    variant="caption"
                    component="div"
                    textOverflow="ellipsis"
                  >
                    {job?.address?.customer?.name || ''}
                  </Typography>
                  <Typography
                    style={{ marginLeft: 5 }}
                    noWrap
                    fontWeight="bold"
                    color={GRAY_DARK}
                    variant="caption"
                    component="div"
                  >
                    Fieldworker:
                  </Typography>
                  <Typography
                    style={{ marginLeft: 5 }}
                    noWrap
                    color={GRAY_DARK}
                    variant="caption"
                    component="div"
                    textOverflow="ellipsis"
                  >
                    {fieldworker?.fullName ||
                      job?.jobJobFieldWorkerRelation?.items[0].fieldworker
                        ?.fullName ||
                      ''}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="row" alignContent="center">
                  <Typography
                    noWrap
                    color={GRAY_DARK}
                    variant="caption"
                    component="div"
                    fontWeight="bold"
                    sx={{
                      gridColumnStart: '3',
                    }}
                  >
                    Address:{' '}
                  </Typography>
                  <Typography
                    style={{ marginLeft: 5 }}
                    noWrap
                    color={GRAY_DARK}
                    variant="caption"
                    component="div"
                  >
                    <FmdGoodIcon
                      sx={{
                        color: GRAY_DARK,
                        fontSize: '10px',
                      }}
                    />
                    {job?.address?.state}, {job?.address?.city},{' '}
                    {job?.address?.streetAddress}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <>
              {type === CalendarType.AGENDA && (
                <>
                  <Typography
                    noWrap
                    color={GRAY_DARK}
                    variant="caption"
                    component="div"
                  >
                    {dayjs(job?.startDateTime).format('HH:mm A')}
                  </Typography>

                  <Typography
                    noWrap
                    color={GRAY_DARK}
                    variant="caption"
                    component="div"
                  >
                    {type === CalendarType.AGENDA
                      ? `${fieldworker?.fullName || ''}`
                      : `${
                          fieldworker?.fullName?.slice(0, 29).trim() || ''
                        }...`}
                  </Typography>
                </>
              )}
              <Typography
                fontWeight="bold"
                textOverflow="ellipsis"
                noWrap
                color={GRAY_DARK}
                variant="caption"
                component="div"
              >
                Customer:
              </Typography>
              <Typography
                noWrap
                color={GRAY_DARK}
                variant="caption"
                component="div"
              >
                {type === CalendarType.AGENDA
                  ? `${job?.address?.customer?.name || ''}`
                  : `${
                      job?.address?.customer?.name?.slice(0, 29).trim() || ''
                    }...`}
              </Typography>
              <Typography
                noWrap
                color={GRAY_DARK}
                variant="caption"
                component="div"
                sx={{
                  gridColumnStart: '3',
                }}
              >
                <FmdGoodIcon
                  sx={{
                    color: GRAY_DARK,
                    fontSize: '10px',
                  }}
                />
                {job?.address?.state}, {job?.address?.city},{' '}
                {job?.address?.streetAddress}
              </Typography>
            </>
          )}
        </Box>
      </Box>
      <InfoCardPopover
        fieldWorkerName={
          fieldworker?.fullName ||
          job?.jobJobFieldWorkerRelation?.items[0].fieldworker?.fullName
        }
        job={job as Job}
        popoverProps={{
          id: `calendar-card-popover-${job.id}`,
          open,
          anchorEl,
          onClose: handleClose,
        }}
      />
    </>
  );
}
/**
 * @param {string} props - Today date.
 * @param {string} props.job - Today date.
 * @returns {JSX.Element} - Calendar View.
 */
export function InfoCards({
  jobs,
  fieldworker,
  gridRowStart,
  gridColumnStart,
  type,
}: {
  jobs: JobColor[];
  fieldworker?: Fieldworker;
  gridColumnStart: number | string;
  gridRowStart: number | string;
  type: CalendarType;
}): JSX.Element {
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      {matchesSm && type === CalendarType.DAY ? (
        <>
          {jobs.map((job) => (
            <InfoCard
              type={CalendarType.DAY}
              fieldworker={job.fieldWorker || fieldworker}
              key={job.id}
              backgroundColor={job.color}
              count={jobs.length}
              gridColumnStart="auto"
              gridRowStart="auto"
              job={job}
            />
          ))}
        </>
      ) : (
        <Box
          sx={{
            borderBottom: `1px solid ${GRAY_LIGHT}`,
            borderRight: `1px solid ${GRAY_LIGHT}`,
            height: '100%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            gridColumnStart,
            gridRowStart,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              flexWrap: 'wrap',
              padding: '0',
            }}
          >
            {jobs
              .slice(0, jobs.length > 6 ? 5 : jobs.length)
              .map((job, index) => (
                <InfoCardMinPreview
                  key={job.id}
                  job={job}
                  index={index}
                  fieldWorkerName={
                    job.fieldWorker?.fullName || fieldworker?.fullName ||
                    job?.jobJobFieldWorkerRelation?.items[0].fieldworker
                      ?.fullName
                  }
                />
              ))}
            {jobs.length > 6 && <JobsModal jobs={jobs} />}
          </Box>
        </Box>
      )}
    </>
  );
}
/**
 * @param {string} props - Today date.
 * @param {string} props.job - Today date.
 * @returns {JSX.Element} - Calendar View.
 */
export function JobsModal({ jobs }: { jobs: JobColor[] }): JSX.Element {
  const [open, setOpen] = React.useState(false);
  /**
   * @returns {void} - Open/Close modal.
   */
  const handleOpen = (): void => {
    setOpen(!open);
  };

  return (
    <Box
      sx={{
        width: '50%',
        height: '25px',
        backgroundColor: jobs[6].color,
      }}
    >
      <Button
        sx={{
          width: '100%',
          height: '25px',
          minWidth: 'unset',
          textTransform: 'none',
          color: GRAY_DARK,
          '&:hover': {
            color: PRIMARY,
          },
        }}
        variant="text"
        size="small"
        disableRipple
        onClick={handleOpen}
      >
        More
      </Button>
      <Dialog open={open} onClose={handleOpen}>
        <Box sx={{ width: '100%', padding: '20px' }}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '50% 50%',
            }}
          >
            {jobs.map((job) => (
              <InfoCard
                backgroundColor={job.color}
                gridColumnStart="auto"
                gridRowStart="auto"
                job={job}
                count={jobs.length}
                type={CalendarType.WEEK}
              />
            ))}
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
}
