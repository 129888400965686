import { SvgIconTypeMap } from '@mui/material';
import {
  SettingsOutlined,
  HomeOutlined,
  GroupsOutlined,
  ExitToAppOutlined,
  NotificationsNoneOutlined,
  GroupOutlined,
  CalendarToday,
  MapOutlined,
  AdminPanelSettingsOutlined,
} from '@mui/icons-material';
// import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
// import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
// import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
// import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

export type IconNames =
  | 'gear'
  | 'house'
  | 'group'
  | 'exit'
  | 'admin'
  | 'notification'
  | 'groups'
  | 'calendar'
  | 'map';

/**
 * @param root0 - Props.
 * @param root0.name - Name of the icon.
 * @returns {JSX.Element} - Icon component.
 */
export function Icon({
  name,
  ...rest
}: {
  name: IconNames;
} & SvgIconTypeMap['props']): JSX.Element {
  let icon;
  switch (name) {
    case 'gear':
      icon = <SettingsOutlined {...rest} />;
      break;
    case 'house':
      icon = <HomeOutlined {...rest} />;
      break;
    case 'groups':
      icon = <GroupsOutlined {...rest} />;
      break;
    case 'group':
      icon = <GroupOutlined {...rest} />;
      break;
    case 'exit':
      icon = <ExitToAppOutlined {...rest} />;
      break;
    case 'notification':
      icon = <NotificationsNoneOutlined {...rest} />;
      break;
    case 'calendar':
      icon = <CalendarToday {...rest} />;
      break;
    case 'admin':
      icon = <AdminPanelSettingsOutlined {...rest} />;
      break;
    case 'map':
      icon = <MapOutlined {...rest} />;
      break;
    default:
      break;
  }
  if (!icon) {
    throw new Error('Not icon found');
  }
  return icon;
}
