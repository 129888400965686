import { useEffect, useState } from 'react';
import { Badge, BadgeProps, Divider, IconButton, Menu, Pagination, Stack, styled } from '@mui/material';
import { Box } from '@mui/system';
import { Notifications } from '@mui/icons-material';
// import { useHistory } from 'react-router-dom';
import { PanelHeader } from './components/PanelHeader';
import { getPagination } from '../../shared/components/Pagination';
import { NotificationsList } from './components/NotificationList';
import { MutationType, Notification, NotificationOrderBy, useMarkAllAsReadMutation, useMarkAsReadMutation, useNotificationSubscription, useUserNotificationsCountQuery, useUserNotificationsQuery, useUserSessionQuery } from '../../shared/types/generated';
import { snackbar } from '../../shared/components/Snackbar';

const NotificationDrawer = styled(Menu)(({ theme }) => ({
  top: '15px',
  overflow: 'hidden',
  '& .MuiBackdrop-root': {
    background: 'black',
    opacity: '0.6 !important',
    transition: 'all 1s',
  },
  '& .MuiPaper-root': {
    background: 'white',
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    }
  },
}));
const Container = styled(Box)(({ theme }) => ({
  minHeight: '100px',
  padding: '0 15px',
  width: '350px',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 0,
    top: 0,
    border: `1px solid ${theme.palette.background.paper}`,
  },
}));

export const NotificationPannel = (): JSX.Element => {
  const navLimit = 8;
  const { data: userSession } = useUserSessionQuery();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loadClearAll, setLoadClearAll] = useState<boolean>(false);
  const [markedAsReadItem, setMarkedAsReadItem] = useState<string | undefined>(undefined);
  const [pageOffset, setPageOffset] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showAll, setShowAll] = useState<boolean>(false);
  const { data: countReadData, refetch: refreshReadCount } = useUserNotificationsCountQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: {
        userId: { equals: userSession?.user.id },
        read: { equals: false }
      },
    }});
  const { data: countData, refetch: refreshCount } = useUserNotificationsCountQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: {
        userId: { equals: userSession?.user.id },
        ...( !showAll && {read: { equals: false }})
      },
    }});
  const { data, loading, refetch } = useUserNotificationsQuery ({
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: {
        userId: { equals: userSession?.user.id },
        ...( !showAll && {read: { equals: false }})
      },
      first: navLimit,
      skip: pageOffset,
      orderBy: NotificationOrderBy.CreatedAtDesc
    },
    onCompleted: () => {
      setMarkedAsReadItem(undefined);
    },
  });
  // const history = useHistory();
  const [markNotificationAsRead] = useMarkAsReadMutation({
    fetchPolicy: 'network-only',
    onCompleted: async () => {
      refreshCount();
      await refreshReadCount();
      await refetch();
      setMarkedAsReadItem(undefined);
    }
  });
  const [markAllNotificationsAsRead] = useMarkAllAsReadMutation({
    fetchPolicy: 'network-only',
    onCompleted: async () => {
      refreshCount();
      await refreshReadCount();
      await refetch();
      setLoadClearAll(false);
      snackbar.success('All notifications marked as read.');
    }
  });
  const totalNotif = countData?.notificationsList?.count || 0;
  const totalUnreadNotif = countReadData?.notificationsList?.count || 0;
  const paginationSize = Math.ceil(Number(totalNotif) / navLimit);
  useNotificationSubscription({
    variables: {
      filter: {
        node: {
          user: { id: { equals: userSession?.user?.id } }
        },
        mutation_in: [MutationType.Create, MutationType.Update],
      },
    },
    onSubscriptionData: async () => {
      refreshCount();
      await refreshReadCount();
      if (currentPage === 1) {
        await refetch();
      }
    },
  });
  const handlePage = (_e: React.ChangeEvent<unknown>, val: number): void => {
    setPageOffset(getPagination(val, navLimit).skip - navLimit);
    setCurrentPage(val);
  };
  
  const handleOpenNotificationsMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
    setCurrentPage(1);
    setPageOffset(0);
  };
  const handleCloseNotificationsMenu = (): void => {
    setCurrentPage(1);
    setPageOffset(0);
    setAnchorEl(null);
  };
  const clearNotifications = async (): Promise<void> => {
    setLoadClearAll(true);
    await markAllNotificationsAsRead({
      fetchPolicy: 'network-only',
      variables: {
        data: {
          read: [ { set: true } ]
        },
        filter: {
          read: { equals: false },
          userId: { equals: userSession?.user?.id }
        }
      }
    });
    handleCloseNotificationsMenu();
  };

  const showAllNotifications = (): void => {
    setShowAll((state) => !state);
    setCurrentPage(1);
    setPageOffset(0);
  };

  const markAsRead = async (id?: string): Promise<void> => {
    setMarkedAsReadItem(id);
    handleCloseNotificationsMenu();
    await markNotificationAsRead({
      variables: {
        data: {
          read: true
        },
        filter: {
          id
        },
      }
    });
  };

  useEffect(() => {
    if (!data?.notificationsList?.items?.length && totalNotif > 0 && currentPage !== 1 && !loading) {
      setPageOffset(getPagination(currentPage - 1, navLimit).skip - navLimit);
      setCurrentPage((state) => state -1); 
    }
  }, [data, totalNotif, currentPage, loading]);

  return (
    <>
      <IconButton onClick={handleOpenNotificationsMenu} color="inherit" sx={{
        margin: '0 10px'
      }} disabled={loading && loadClearAll}>
        <StyledBadge badgeContent={totalUnreadNotif} color='error'>
          <Notifications color={totalUnreadNotif > 0 ? 'secondary' : undefined} />
        </StyledBadge>
      </IconButton>
      <NotificationDrawer
        open={Boolean(anchorEl)}
        onClose={handleCloseNotificationsMenu}
        anchorEl={anchorEl}
      >
      <Container>
        <PanelHeader 
          closePanel={handleCloseNotificationsMenu} 
          clearNotifications={clearNotifications}
          showAllNotifications={showAllNotifications}
          showAll={showAll}
          showClear={Boolean(totalUnreadNotif)}
        />
        <Divider />
          <NotificationsList
            items={data?.notificationsList?.items as Notification[]}
            isLoading={loading || loadClearAll}
            markAsRead={markAsRead}
            markedAsReadItem={markedAsReadItem}
            totalNotif={totalNotif}
          />
        <Stack alignItems='center' bottom='0' pt={1}>
          <Pagination
            count={paginationSize}
            page={currentPage}
            onChange={handlePage}
            size='small'
          />
        </Stack>
      </Container>
    </NotificationDrawer>
  </>
  );
};
