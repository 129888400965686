
// feature flags Names
export enum FeatureFlags {
  ON_ROUTE_FLAG = 'ON_ROUTE_FLAG',
  DOCUMENTS = 'DOCUMENTS',
  MAP = 'MAP',
  CALENDAR = 'CALENDAR',
  INPUTS = 'INPUTS',
  NOTIFICATIONS = 'NOTIFICATIONS',
  SUPER_ADMIN = 'SUPER_ADMIN',
  USER_DISPLAYED_INFO = 'USER_DISPLAYED_INFO',
  JOB_EDIT_V2 = 'JOB_EDIT_V2',
  BLOCK_CUSTOMERS = 'BLOCK_CUSTOMERS',
  JOB_FILTER_FIELDWORKER = 'JOB_FILTER_FIELDWORKER',
  MAP_ACTIVE_FIELDWORKER = 'MAP_ACTIVE_FIELDWORKER',
  PDF_IMAGE_ROWS = 'PDF_IMAGE_ROWS',
  ARCHIVE_FIELDWORKERS = 'ARCHIVE_FIELDWORKERS',
  CUSTOMER_DOCS = 'CUSTOMER_DOCS',
  ADMIN_EMAIL_NOTIFICATIONS = 'ADMIN_EMAIL_NOTIFICATIONS',
  FIELDWORKER_DOCS = 'FIELDWORKER_DOCS',
  CALENDAR_ACTIVE_FIELDWORKER = 'CALENDAR_ACTIVE_FIELDWORKER',
}
