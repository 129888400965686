import React from 'react';
import { error as errorLogger } from '@cobuildlab/pure-logger';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useResetPasswordMutation } from '../../../shared/types/generated';
import { snackbar } from '../../../shared/components/Snackbar';

export const ResetPassword: React.FC<{
  open: boolean;
  close: () => void;
  email: string;
}> = ({
  open,
  close,
  email,
}) => {
  const [resetPassword, { loading }] = useResetPasswordMutation({
    onCompleted: () => {
      snackbar.success('Request password sended');
      close();
    },
    onError: (err) => {
      errorLogger(err);
      snackbar.error(err.message);
    },
  });

  const onConfirm = (): void => {
    resetPassword({
      variables: {
        email,
      },
    });
  };
  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="responsive-dialog-title"
      fullWidth
    >
      <DialogTitle id="responsive-dialog-title">Reset Password</DialogTitle>
      <DialogContent>
        Are you sure you want to reset the password? We will send a reset link
        to{' '}
        <Typography fontWeight="normal" component="b" color='secondary'>
          {email}.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="default" onClick={close}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={onConfirm}
          color="error"
          autoFocus
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
