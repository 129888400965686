import { string } from 'yup';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { PHONE_NUMBER_REGREX, PHONE_REGION_CODE } from '../constants/common';

export const getNationalPhoneNumber = (
  _phoneNumber: string | undefined | null,
): { value: string; isValid: boolean } => {
  if (!_phoneNumber) {
    return {
      value: '',
      isValid: false,
    };
  }

  const phoneNumber = parsePhoneNumber(_phoneNumber, {
    regionCode: PHONE_REGION_CODE,
  });

  if (!phoneNumber.valid) {
    return {
      value: _phoneNumber,
      isValid: false,
    };
  }

  return {
    value: phoneNumber.number.national,
    isValid: true,
  };
};

export const getPhoneNumberFomartToSaveInDB = (
  _phoneNumber: string | undefined | null,
): string => {
  if (!_phoneNumber) {
    return '';
  }

  const phoneNumber = parsePhoneNumber(_phoneNumber, {
    regionCode: PHONE_REGION_CODE,
  });

  return phoneNumber?.number?.e164 || '';
};

export const getPhoneNumberWithOutRegionCode = (
  _phoneNumber: string | undefined,
): string => {
  if (!_phoneNumber) {
    return '';
  }

  const phoneNumber = parsePhoneNumber(_phoneNumber, {
    regionCode: PHONE_REGION_CODE,
  });

  if (!phoneNumber.valid) {
    return _phoneNumber;
  }

  return phoneNumber.number.significant;
};

export const phoneNumberSchemaValidation = string().test(
  'phoneNumberSchema',
  'Invalid phone number',
  (value) => {
    if(!value){
      return true;
    }

    if(!PHONE_NUMBER_REGREX.test(value)){
      return false;
    }
    const phoneNumber = parsePhoneNumber(value, {
      regionCode: PHONE_REGION_CODE,
    });
    return phoneNumber.valid;
  },
);
