import {
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  Grid,
  Typography,
  styled,
  Box,
  useMediaQuery,
  Link,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CustomerQuery } from '../../../shared/types/generated';
import { JobStatusPill } from '../../jobs/components/StatusPills';
import { RowResponsive } from '../../../shared/components/RowResponsive';
import { humanizeDate } from '../../../shared/utils';

const MdPaper = styled(Box)(({ theme }) => ({
  position: 'relative',
  minWidth: '65%',
  maxWidth: '65%',
  height: 'auto',
  backgroundColor: '#fff',
  margin: '5% auto 0 auto',
  borderRadius: '10px',
  boxShadow: '0px 3px 0px #EDEDF6',
  padding: '35px 20px 35px 25px',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    margin: '0',
    height: 'auto',
    marginTop: '25px',
    maxHeight: 'none',
  },
}));
/**
 * @param root0 - Props.
 * @param root0.list - Prost text.
 * @returns {JSX.Element} - Main loader component.
 */
export function AssignedJobs({
  list,
}: {
  list: CustomerQuery | undefined;
}): JSX.Element {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <MdPaper sx={{ flexGrow: 1, marginTop: '50px' }}>
      <Grid container>
        <Grid item sm={12} md={12} lg={12}>
          <Typography variant="h5">Assigned Jobs</Typography>
        </Grid>
        <Grid item sm={12} md={12} lg={12} sx={{ width: '100%' }}>
          {matches ? (
            <>
              {list &&
                list?.customer?.customerAddressRelation?.items.map((item) => (
                  <>
                    {item.addressJobRelation.items.map((job) => (
                      <Box sx={{ marginTop: '10px' }} key={job.id}>
                        <RowResponsive
                          type="job"
                          key={job.id}
                          id={job.id}
                          name={list.customer.name}
                          street={item.name}
                          date={humanizeDate(job?.startDateTime)}
                          code={job.code}
                          status={job.status}
                        />
                      </Box>
                    ))}
                  </>
                ))}
            </>
          ) : (
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Code</TableCell>

                  <TableCell align="center">Start Date</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Address</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ height: '80%' }}>
                {list &&
                  list?.customer?.customerAddressRelation?.items.map((item) => (
                    <>
                      {item.addressJobRelation.items.map((job) => (
                        <TableRow key={job.id}>
                          <TableCell align="left">
                            <Link
                              href={`/jobs/${job.id}`}
                              color="inherit"
                              underline="none"
                            >
                              {job.code}
                            </Link>
                          </TableCell>
                          <TableCell align="center">
                            {humanizeDate(job?.startDateTime)}
                          </TableCell>
                          <TableCell align="center">
                            <JobStatusPill status={job?.status} />
                          </TableCell>
                          <TableCell align="center">
                            {`${item.streetAddress}, ${item.city}, ${item.state}`}
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ))}
              </TableBody>
            </Table>
          )}
        </Grid>
      </Grid>
    </MdPaper>
  );
}
