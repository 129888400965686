import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

dayjs.extend(relativeTime);
dayjs.extend(utc);

export const notificationDateFormat = (date: string): string =>
  dayjs(date)
    .toNow(true)
    .replace('minutes', 'Min')
    .replace('hours', 'Hrs')
    .replace('days', 'D');