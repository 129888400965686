import { ROUTES } from '../../routes/routes-model';
import { FeatureFlags } from '../../shared/constants/featureFlag';
import { useUserSessionQuery } from '../../shared/types/generated';
import { UseValidateFeatureFlag } from '../auth/auth-hooks';
import { RolesType, USER_ROLES } from './session-model';

/**
 * @param roleToCheck - Roles.
 * @param userRoles - User roles.
 * @returns Result.
 */
function check(
  roleToCheck: RolesType,
  userRoles: {
    __typename?: 'Role' | undefined;
    name: string;
  }[],
): boolean {
  const role = userRoles.find((item) => item.name === USER_ROLES[roleToCheck]);

  return Boolean(role);
}
/**
 * @param roles - Input roles.
 * @returns Booleas if the user has the roles.
 */
export function useUserHasRole(roles: RolesType | RolesType[]): boolean {
  const sessionState = useUserSessionQuery();

  if (!sessionState?.data) {
    return false;
  }

  const userRoles = sessionState?.data?.user?.roles?.items;

  return typeof roles === 'string'
    ? check(roles, userRoles)
    : roles.some((roleItem) => check(roleItem, userRoles));
}

/**
 * @returns Booleas if the user has the roles.
 */
export function useUserRoutes(): typeof ROUTES[keyof typeof ROUTES][] {
  const sessionState = useUserSessionQuery();
  const featureFlagMap = UseValidateFeatureFlag(FeatureFlags.MAP);
  const featureFlagCalendar = UseValidateFeatureFlag(FeatureFlags.CALENDAR);
  const featureFlagSuperAdmin = UseValidateFeatureFlag(FeatureFlags.SUPER_ADMIN);

  if (!sessionState?.data) {
    return [];
  }

  const userRoles = sessionState.data.user.roles.items;
  // Mutate array of routes with feature flag
  let routes = Object.values(ROUTES);
  if (!featureFlagMap) {
    routes = routes.filter((route) => route.path !== '/map');
  }
  if (!featureFlagCalendar) {
    routes = routes.filter((route) => route.path !== '/calendar');
  }
  if (!featureFlagSuperAdmin) {
    routes = routes.filter((route) => route.path !== '/admin');
  }
  return routes.filter((route) =>
    route.rolePermission
      ? route.rolePermission.some((role) => check(role, userRoles))
      : true,
  );
}
