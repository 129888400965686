/* eslint-disable jsdoc/require-jsdoc */
import { PropsWithChildren } from 'react';
import { UseValidateFeatureFlag } from '../../modules/auth/auth-hooks';

export const FeatureFlagWrapper: React.FC<PropsWithChildren<{ flag: string }>> =
  ({ flag, children }) => {
    const featureFlag = UseValidateFeatureFlag(flag);

    if (!featureFlag) {
      return null;
    }

    return <>{children}</>;
  };
