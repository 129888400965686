import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Box } from '@mui/system';
import SignatureCanvas from 'react-signature-canvas';

/**
 * @param root0 - Props.
 * @param root0.open - Props text.
 * @param root0.close - Props text.
 * @param root0.onChange - Props text.
 * @returns {} Component.
 */
export function SignatureMenu({
  open,
  close,
  onChange,
}: {
  open: boolean;
  close: () => void;
  onChange: (data: string) => void;
}): JSX.Element {
  const valueRef = React.useRef<HTMLDivElement>(null);
  const [sigCanvas, setSigCanvas] = React.useState<SignatureCanvas>();
  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="responsive-dialog-title"
      fullWidth
    >
      <DialogTitle id="responsive-dialog-title">Signature</DialogTitle>
      <DialogContent>
        <Box
          ref={valueRef}
          sx={{
            width: '100%',
            alignSelf: 'start',
          }}
        >
          <SignatureCanvas
            canvasProps={{
              width: valueRef.current?.clientWidth,
              height: '250px',
            }}
            ref={(ref) => {
              if (ref) {
                setSigCanvas(ref);
              }
            }}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{
              width: '100%',
            }}
          >
            <Button
              onClick={() => {
                sigCanvas?.clear();
              }}
            >
              Clear
            </Button>
            <Button
              onClick={() => {
                onChange(
                  sigCanvas?.getTrimmedCanvas().toDataURL('image/png') ?? '',
                );
                close();
              }}
            >
              Send
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
