import React from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Typography,
  Box,
  styled,
  Chip,
  Skeleton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useMediaQuery,
  Link,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { useFieldworkerDetailQuery } from '../../shared/types/generated';
import { TableSkeleton } from '../../shared/components/Skeletons';
import { RowResponsive } from '../../shared/components/RowResponsive';
import { FieldworkerHeader } from './FieldworkerHeader';
import { humanizeDate } from '../../shared/utils';
import { JobStatusPill } from '../jobs/components/StatusPills';
import { JobsStatuses } from '../jobs/jobs-types';
import { DisplayPhoneNumber } from '../../shared/components/DisplayPhoneNumber';

const CardContend = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '80%',
  maxHeight: '45vh',
  backgroundColor: '#fff',
  margin: '5% auto 0 auto',
  borderRadius: '10px',
  boxShadow: '0px 3px 0px #EDEDF6',
  padding: '35px 0 35px 25px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    margin: '0',
    marginTop: '20px',
    paddingTop: '20px',
    minHeight: 'auto',
    maxHeight: 'none',
  },
}));
const CardContendAssigned = styled(Box)(({ theme }) => ({
  position: 'relative',
  minWidth: '80%',
  backgroundColor: '#fff',
  margin: '5% auto 0 auto',
  borderRadius: '10px',
  boxShadow: '0px 3px 0px #EDEDF6',
  padding: '35px 0 35px 25px',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    margin: '0',
    marginTop: '20px',
    paddingTop: '20px',
    minHeight: 'auto',
    maxHeight: 'none',
  },
}));
/**
 * @returns {JSX.Element} - Detail Fieldworker.
 */
export function Fieldworker(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { data } = useFieldworkerDetailQuery({
    variables: {
      id: id ?? '',
      jobFilter: {
        job: {
          archived: {
            is_empty: true,
          },
          status: {
            not_equals: JobsStatuses.CLOSED,
          },
        },
      },
    },
  });
  if (data) {
    return (
      <>
        <FieldworkerHeader
          title={data.fieldworker.fullName}
          subtitle={data.fieldworker.archived ? 'archived' : 'active'}
          email={data.fieldworker.email}
        />
        <Box>
          <CardContend sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography variant="h5" component="h2" color="white">
                  Information
                </Typography>
                <Chip
                  icon={<CircleIcon />}
                  label={data.fieldworker.archived ? 'Archived' : 'Active'}
                  variant="outlined"
                  color={data.fieldworker.archived ? 'error' : 'secondary'}
                  sx={{
                    position: 'absolute',
                    top: ['20px', '40px'],
                    right: ['25px', '50px'],
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography gutterBottom variant="h5" component="h2">
                  Full Name
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {data.fieldworker.fullName}
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography gutterBottom variant="h5" component="h2">
                  Email
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {data.fieldworker.email}
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography gutterBottom variant="h5" component="h2">
                  Phone Number
                </Typography>
                <DisplayPhoneNumber variant="body2" phoneNumber={data.fieldworker.phoneNumber} />
              </Grid>
            </Grid>
          </CardContend>
        </Box>
        <CardContendAssigned>
          <Grid container>
            <Grid item sm={12} md={12} lg={12}>
              <Typography variant="h5">Assigned Jobs</Typography>
            </Grid>
            <Grid item sm={12} md={12} lg={12} sx={{ width: '100%' }}>
              {matches ? (
                <>
                  {data &&
                    data.fieldworker.fieldworkerJobFieldworkerRelation.items.map(
                      (item) => (
                        <Box sx={{ marginTop: '10px' }} key={item.job.id}>
                          <RowResponsive
                            type="job"
                            id={item.job.id}
                            name={item?.job?.address?.customer?.name ?? ''}
                            email={item.job?.address?.customer?.name || ''}
                            date={humanizeDate(item.job?.startDateTime)}
                            code={item.job.code}
                            status={item.job.status}
                          />
                        </Box>
                      ),
                    )}
                </>
              ) : (
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Code</TableCell>
                      <TableCell align="center">Name</TableCell>
                      <TableCell align="center">Start Date</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">Job type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ height: '80%' }}>
                    {data !== undefined ? (
                      data.fieldworker.fieldworkerJobFieldworkerRelation.items.map(
                        (item) => (
                          <>
                            <TableRow>
                              <TableCell align="left">
                                {item.job.code}
                              </TableCell>
                              <TableCell align="center">
                                <Link
                                  href={`/jobs/${item.job.id}`}
                                  color="inherit"
                                  underline="none"
                                >
                                  {item.job.address?.customer?.name}
                                </Link>
                              </TableCell>
                              <TableCell align="center">
                                {humanizeDate(item.job?.startDateTime)}
                              </TableCell>
                              <TableCell align="center">
                                <JobStatusPill status={item.job.status} />
                              </TableCell>
                              <TableCell align="center">
                                {item.job?.type?.name || ''}
                              </TableCell>
                            </TableRow>
                          </>
                        ),
                      )
                    ) : (
                      <TableSkeleton rows={10} columns={7} loading />
                    )}
                  </TableBody>
                </Table>
              )}
            </Grid>
          </Grid>
        </CardContendAssigned>
      </>
    );
  }
  return (
    <>
      <Box>
        <Box sx={{ height: '15%' }}> </Box>
        <CardContend>
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography variant="h5" component="h2" color="white">
                Information
              </Typography>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography gutterBottom variant="h5" component="h2">
                Full Name
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <Skeleton animation="wave" />
              </Typography>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography gutterBottom variant="h5" component="h2">
                Email
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <Skeleton animation="wave" />
              </Typography>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography gutterBottom variant="h5" component="h2">
                Phone Number
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <Skeleton animation="wave" />
              </Typography>
            </Grid>
          </Grid>
        </CardContend>
      </Box>
    </>
  );
}
