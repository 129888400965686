import React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

import { DB_STATUS } from '../../../shared/constants/common';
import { useQuery, useQueryActive } from '../../../shared/utils/route';
import { Dropdown } from '../../../shared/components/Dropdown';
import { CustomerFiltersType } from '../customer-types';
import { FilterButton } from '../../../shared/components/FilterButton';

const DB_STATUS_VALUES = Object.values(DB_STATUS).map((item) => ({
  value: item,
  label: item,
}));

type CustomerFiltersProps = {
  filters: CustomerFiltersType;
};

export const CustomerFilters: React.FC<CustomerFiltersProps> = ({
  filters,
}) => {
  const theme = useTheme();
  const { searchQuery, query, resetQuery } = useQuery();
  const isActive = useQueryActive(query);

  const onChangeField =
    (field: string) =>
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): void => {
      searchQuery({
        page: 1, // when change a filter we should reset the page
        [field]: event.target.value,
      });
    };

  const onChangeSelect =
    (field: string) =>
    (event: SelectChangeEvent<string | number>): void => {
      searchQuery({
        page: 1, // when change a filter we should reset the page
        [field]: event.target.value,
      });
    };

  return (
    <Dropdown
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      button={({ toggle }) => (
        <Stack justifyContent="center">
          <FilterButton isActive={isActive} onClick={toggle} />
        </Stack>
      )}
    >
      <Stack paddingTop={2} paddingBottom={2} direction="column" spacing={2}>
        <Stack direction="column" spacing={1}>
          <Typography variant="subtitle2">Creation Date</Typography>
          <Stack spacing={1} direction="row">
            <TextField
              label="From"
              type="date"
              value={filters.from}
              fullWidth
              onChange={onChangeField('from')}
            />
            <TextField
              label="To"
              type="date"
              value={filters.to}
              fullWidth
              onChange={onChangeField('to')}
            />
          </Stack>
        </Stack>
        <Stack direction="column" spacing={1}>
          <Typography variant="subtitle2">DB Status</Typography>
          <FormControl>
            <Select value={filters.status} onChange={onChangeSelect('status')}>
              {DB_STATUS_VALUES.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          spacing={1}
          sx={{
            display: 'none',
            [theme.breakpoints.down('md')]: {
              display: 'block',
            },
          }}
        >
          <Typography variant="subtitle2">Rows</Typography>
          <FormControl fullWidth>
            <Select
              value={filters.rows}
              fullWidth
              onChange={onChangeSelect('rows')}
            >
              <MenuItem value={10}>10 Rows(s)</MenuItem>
              <MenuItem value={20}>20 Rows(s)</MenuItem>
              <MenuItem value={30}>30 Rows(s)</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Stack paddingTop={2}>
          <Button onClick={resetQuery} variant="contained" size="large">
            Reset filters
          </Button>
        </Stack>
      </Stack>
    </Dropdown>
  );
};
