import { TableCell, TableRow, Skeleton } from '@mui/material';

/**
 * @param root0 - Props.
 * @param root0.rows - Number of rows.
 * @param root0.columns - Number of columns.
 * @returns Component.
 */
export function TableSkeleton({
  columns,
  rows,
}: {
  rows: number;
  columns: number;
}): JSX.Element {
  return (
    <>
      {Array(rows)
        .fill(0)
        .map((item, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableRow key={i}>
            {Array(columns)
              .fill(0)
              .map((itemCell, iCell) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableCell sx={{ height: '50px' }} key={iCell}>
                  <Skeleton animation="wave" />
                </TableCell>
              ))}
          </TableRow>
        ))}
    </>
  );
}
