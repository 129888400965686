import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Link,
  Typography,
  SpeedDial,
  SpeedDialAction,
} from '@mui/material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import ArchiveIcon from '@mui/icons-material/Archive';
import { ResetPassword } from './components/ResetPassword';
import { AlertFieldworker } from './AlertFieldworker';
import { ViewBackButton } from '../../shared/components/ViewBackButton';

type HeaderProps = {
  title: string | undefined;
  subtitle: string;
  email: string;
};

/**
 * @param {object} props - Props.
 * @param {string} props.title - Title.
 * @param {string} props.subtitle - Subtitle.
 * @returns {JSX.Element} - Fieldworker's view header.
 */
export function FieldworkerHeader(props: HeaderProps): JSX.Element {
  const { title, subtitle, email } = props;
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [alert, setAlert] = useState<{ status: string | null; open: boolean }>({
    status: null,
    open: false,
  });
  const [open, setOpen] = useState(false);
  if (subtitle === 'archived') {
    return (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="left"
        >
          <Typography variant="h4">{title}</Typography>
          <ViewBackButton />
        </Box>
        <Button
          onClick={() => setAlert({ status: 'archived', open: true })}
          sx={{ display: ['none', 'flex'] }}
        >
          Active Fieldworker
        </Button>
        <Box
          sx={{
            zIndex: 50,
            display: ['flex', 'none'],
            position: 'fixed',
            transform: 'translateZ(0px)',
            flexGrow: 1,
            height: '100%',
            top: '0',
            right: '20px',
            bottom: '30px',
          }}
        >
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: 'absolute', bottom: 16, right: 16 }}
            icon={<MoreVertIcon />}
          >
            <SpeedDialAction
              icon={<DriveFolderUploadIcon />}
              tooltipTitle="Active"
              onClick={() => setAlert({ status: 'archived', open: true })}
            />
          </SpeedDial>
        </Box>
        <AlertFieldworker
          archived={alert.status}
          open={alert.open}
          id={id}
          back
        />
      </Box>
    );
  }
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <ResetPassword open={open} close={() => setOpen(false)} email={email} />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="left"
      >
        <Typography variant="h4">{title}</Typography>
        <Link
          href="/fieldworker"
          color="gray"
          underline="hover"
          variant="subtitle2"
        >
          <ViewBackButton />
        </Link>
      </Box>
      <Box
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: 'auto', display: ['none', 'flex'] }}
      >
        <Button
          disableElevation
          variant="contained"
          color="error"
          onClick={() => setAlert({ ...alert, open: true })}
          sx={{ marginLeft: '15px' }}
        >
          Archive
        </Button>
        <Button
          disableElevation
          variant="contained"
          sx={{ marginLeft: '15px' }}
          onClick={() => setOpen(true)}
        >
          Reset Password
        </Button>
        <Button
          href={`/fieldworker/edit/${id}`}
          sx={{ marginLeft: '15px' }}
          startIcon={<EditIcon />}
        >
          Edit fieldworker
        </Button>

        <AlertFieldworker
          archived={alert.status}
          open={alert.open}
          id={id}
          back
        />
      </Box>
      <Box
        sx={{
          zIndex: 50,
          display: ['flex', 'none'],
          position: 'fixed',
          transform: 'translateZ(0px)',
          flexGrow: 1,
          height: '100%',
          top: '0',
          right: '20px',
          bottom: '30px',
        }}
      >
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{ position: 'absolute', bottom: 16, right: 16 }}
          icon={<MoreVertIcon />}
        >
          <SpeedDialAction
            icon={<VpnKeyIcon />}
            onClick={() => setOpen(true)}
            tooltipTitle="Reset Password"
          />
          <SpeedDialAction
            icon={<ArchiveIcon />}
            tooltipTitle="Archived"
            onClick={() => setAlert({ ...alert, open: true })}
          />
          <SpeedDialAction
            icon={<EditIcon />}
            onClick={() => history.push(`/fieldworker/edit/${id}`)}
            tooltipTitle="Edit"
          />
        </SpeedDial>
      </Box>
    </Box>
  );
}
