import * as filestack from 'filestack-js';
import {
  CustomersListQueryVariables,
  CreateCustomerMutationVariables,
  UpdateCustomerMutationVariables,
  UpdateCustomer2MutationVariables,
  CustomerEQuery,
  Customer_AddressCreateInput,
  Customer_AddressUpdateInput,
  CustomerCustomerAddressRelationUpdateRelationInput,
  CustomerCustomerCustomerEmailRelationUpdateRelationInput,
  /*
  Customer_AddressCreateInput,
     ,UpdateCustomerMutationVariables , */
} from '../../shared/types/generated';
import {
  CustomerEmailKeyFilter,
  Customer_CustomerEmailCreateInput,
  Customer_CustomerEmailUpdateInput,
  Customer_CustomerPhoneCreateInput,
  CustomerPhoneKeyFilter,
  CustomerCustomerPhoneNumberRelationUpdateRelationInput,
  Customer_CustomerPhoneUpdateInput,
} from '../../shared/types/types';
import {
  Address,
  CreateCustomerFormType,
  CustomerFormType,
  EditCustomerFormType,
  EmailType,
  InvalidFieldType,
  PhoneType,
} from './customer-types';
import { DB_STATUS } from '../../shared/constants/common';
import { getPhoneNumberFomartToSaveInDB } from '../../shared/utils/phone';

/**
 * @returns {CustomersListQueryVariables} - Variables.
 * @param {string} search - Search string.
 * @param {number} skip - Skip.
 * @param {number} first - First.
 * @param {string} startD - Start Date.
 * @param {string} endD - End Date.
 * @param {string} status - Archive Value.
 */
export const createVariablesCustomerList = (
  search: string,
  skip: number,
  first: number,
  startD: string | undefined,
  endD: string | undefined,
  status: string,
): CustomersListQueryVariables => {
  let obj: CustomersListQueryVariables = {};
  switch (status) {
    case DB_STATUS.Active:
      obj = {
        skip,
        first,
        filter: {
          AND: [
            {
              archived: { is_empty: true },
            },

            {
              createdAt: startD ? { gte: startD } : undefined,
            },
            {
              createdAt: endD ? { lte: endD } : undefined,
            },
            {
              OR: [
                {
                  email: {
                    contains: search,
                  },
                },
                {
                  name: {
                    contains: search,
                  },
                },
              ],
            },
          ],
        },
      };
      break;
    case DB_STATUS.Archived:
      obj = {
        skip,
        first,
        filter: {
          AND: [
            {
              archived: { is_empty: false },
            },

            {
              createdAt: startD ? { gte: startD } : undefined,
            },
            {
              createdAt: endD ? { lte: endD } : undefined,
            },
            {
              OR: [
                {
                  email: {
                    contains: search,
                  },
                },
                {
                  name: {
                    contains: search,
                  },
                },
              ],
            },
          ],
        },
      };
      break;

    default:
      obj = {
        skip,
        first,
        filter: {
          AND: [
            {
              createdAt: startD ? { gte: startD } : undefined,
            },
            {
              createdAt: endD ? { lte: endD } : undefined,
            },
            {
              OR: [
                {
                  email: {
                    contains: search,
                  },
                },
                {
                  name: {
                    contains: search,
                  },
                },
              ],
            },
          ],
        },
      };
      break;
  }

  return obj;
};

/**
 * @returns {CustomersListQueryVariables} - Variables.
 * @param {string} simpleData - Data without relations.
 * @param {string} simpleData.name - CustomerName.
 * @param {string} simpleData.email - Customer Email.
 * @param {string} simpleData.phoneNumber - Customer Phone Number.
 * @param {boolean} simpleData.willLogin - Create session.
 * @param {Array<Address>} addresses - Addresses list.
 * @param {Array<PhoneType>} phones - Phones list.
 * @param {Array} emails - Emails.
 * @param {Array<PhoneType>} documentFile - Phones list.
 */
export const createVariablesNewCustomer = (
  simpleData: {
    name: string;
    email: string;
    phoneNumber: string;
    willLogin: boolean;
  },
  addresses: Array<Address> | undefined,
  phones: Array<PhoneType> | undefined,
  emails:
    | {
        email: string;
      }[]
    | undefined,
  documentFile: Array<filestack.PickerFileMetadata> | undefined,
): CreateCustomerMutationVariables => {
  let data: CreateCustomerMutationVariables['data'] = {
    name: simpleData.name,
    email: simpleData.email,
    phoneNumber: simpleData.phoneNumber,
    willLogin: simpleData.willLogin,
  };
  if (addresses) {
    data.customerAddressRelation = {
      create: addresses.map((item) => ({
        ...item, 
        contactPhone: getPhoneNumberFomartToSaveInDB(item.contactPhone)
      })),
    };
  }
  if (phones) {
    data.customerPhoneNumberRelation = {
      create: phones.map((item) => ({ phoneNumber: getPhoneNumberFomartToSaveInDB(item.phoneNumber)})),
    };
  }
  if (emails) {
    data.customerCustomerEmailRelation = {
      create: emails,
    };
  }
  if (documentFile && documentFile.length > 0) {
    data = {
      ...data,
      documents: {
        create: documentFile.map((document) => ({
          fileId: document.handle,
          filename: document.filename,
        })),
      },
    };
  }

  return { data };
};

/**
 * @returns {CustomersListQueryVariables} - Variables.
 * @param {string} phoneNumber - Phone number.
 * @param {string} email - Email.
 * @param {string} name - Name.
 * @param {string} id - ID.
 */
export const createVariablesUpdateUser = (
  phoneNumber: string,
  email: string,
  name: string,
  id: string,
): UpdateCustomerMutationVariables => {
  const obj = {
    data: {
      phoneNumber: [
        {
          set: phoneNumber,
        },
      ],
      email: [
        {
          set: email,
        },
      ],
      name: [
        {
          set: name,
        },
      ],
    },
    filter: {
      id: {
        equals: id,
      },
    },
  };

  return obj;
};

/**
 * @returns {CustomersListQueryVariables} - Variables.
 * @param {string} id - Id to archive.
 * @param {boolean} archive - Archive Boolean.
 */
export const archiveCustomerVariables = (
  id: string,
  archive: boolean,
): UpdateCustomerMutationVariables => {
  const date = new Date().toISOString();
  const obj = {
    data: {
      archived: [
        {
          set: archive ? date : (null as unknown as string),
        },
      ],
    },
    filter: {
      id: {
        equals: id,
      },
    },
  };

  return obj;
};
export const archiveCustomerVariablesV2 = (
  id: string,
  archive: boolean,
): UpdateCustomer2MutationVariables => {
  const date = new Date().toISOString();
  const obj: UpdateCustomer2MutationVariables = {
    data: {
      archived: archive ? date : (null as unknown as string),
    },
    filter: {
      id,
    },
  };

  return obj;
};

/**
 * @returns {Array<Customer_AddressCreateInput>} - Variables.
 * @param {EditCustomerFormType} form - New Data.
 */
const createAddressUpdateCostumer = (
  form: EditCustomerFormType,
): Array<Customer_AddressCreateInput> => {
  const data: Array<Customer_AddressCreateInput> = [];
  form.customerAddressRelation.forEach((item, index) => {
    if (item.id === '' || item.id === undefined) {
      const obj = {
        ...form.customerAddressRelation[index],
      };
      data.push(obj);
    }
  });

  return data;
};

/**
 * @returns {Array<string>} - Variables.
 * @param {CustomerEQuery} oldData - Old Data.
 * @param {EditCustomerFormType} newData - New Data.
 */
const deletedElements = (
  oldData: CustomerEQuery,
  newData: EditCustomerFormType,
): Array<string> => {
  const arr: Array<string> = [];
  oldData.customer.customerAddressRelation.items.forEach((item) => {
    let deleted = true;
    for (
      let index = 0;
      index < newData.customerAddressRelation.length;
      index += 1
    ) {
      if (item.id === newData.customerAddressRelation[index].id) {
        deleted = false;
        break;
      }
    }
    if (deleted === true) {
      arr.push(item.id);
    }
  });

  return arr;
};

/**
 * @returns {Array<Customer_AddressUpdateInput> | boolean} - Variables.
 * @param {CustomerEQuery} oldData - Old Data.
 * @param {EditCustomerFormType} newData - New Data.
 */
const updateAddressUpdateCustomer = (
  oldData: CustomerEQuery,
  newData: EditCustomerFormType,
): Array<Customer_AddressUpdateInput> => {
  const update: Array<Customer_AddressUpdateInput> = [];
  newData.customerAddressRelation.forEach((item, index) => {
    if (item.id !== '' && item.id !== undefined) {
      const obj = {
        filter: {
          id: newData.customerAddressRelation[index].id,
        },
        data: {
          city: newData.customerAddressRelation[index].city,
          name: newData.customerAddressRelation[index].name,
          state: newData.customerAddressRelation[index].state,
          streetAddress: newData.customerAddressRelation[index].streetAddress,
          zipCode: newData.customerAddressRelation[index].zipCode,
          contactName: newData.customerAddressRelation[index].contactName,
          contactPhone: newData.customerAddressRelation[index].contactPhone,
        },
      };
      update.push(obj);
    }
  });

  const deleted = deletedElements(oldData, newData);
  if (deleted.length > 0) {
    deleted.forEach((element) => {
      const obj: Customer_AddressUpdateInput = {
        filter: {
          id: element,
        },
        data: {
          archived: new Date().toISOString(),
        },
      };
      update.push(obj);
    });
  }

  return update;
};
/**
 * @param {CustomerFormType} customerForm - Customer forms.
 * @returns {Array<Customer_CustomerEmailCreateInput>} - Create the create part object.
 */
const createEmail = (
  customerForm: CustomerFormType,
): Array<Customer_CustomerEmailCreateInput> => {
  const create: Array<Customer_CustomerEmailCreateInput> = [];
  customerForm.customerCustomerEmailRelation
    ?.filter((item) => item.id === undefined)
    .forEach((item) => create.push(item));
  return create;
};
/**
 * @returns {Array<Customer_CustomerEmailUpdateInput>} -Create Update Part object.
 * @param {CustomerFormType} customerForm - Customer form.
 */
const updateEmail = (
  customerForm: CustomerFormType,
): Array<Customer_CustomerEmailUpdateInput> => {
  const update: Array<Customer_CustomerEmailUpdateInput> = [];

  customerForm.customerCustomerEmailRelation
    ?.filter((item) => item.id !== undefined)
    .map((item) => ({
      filter: {
        id: item.id,
      },
      data: {
        email: item.email,
      },
    }))
    .forEach((item) => update.push(item));

  return update;
};
/**
 * @param {CustomerFormType} customerForm - Customer form.
 * @param {EmailType} originalEmails - Input data.
 * @returns {Array<CustomerEmailKeyFilter>} - Create disconnect part object.
 */
const disconnectEmail = (
  customerForm: CustomerFormType,
  originalEmails: EmailType[],
): Array<CustomerEmailKeyFilter> => {
  const disconnect: Array<CustomerEmailKeyFilter> = [];
  originalEmails
    .filter(
      (item) =>
        !customerForm.customerCustomerEmailRelation?.find(
          ({ id }) => id === item.id,
        ),
    )
    .map((item) => ({ id: item.id }))
    .forEach((item) => disconnect.push(item));
  return disconnect;
};

/**
 * @param {CustomerFormType} customerForm - Customer forms.
 * @returns {Array<Customer_CustomerPhoneCreateInput>} - Create the create part object.
 */
const createPhones = (
  customerForm: CustomerFormType,
): Array<Customer_CustomerPhoneCreateInput> => customerForm?.customerPhoneNumberRelation
  ?.filter((item) => item.id === undefined)
  .map((item) => ({ ...item, phoneNumber: getPhoneNumberFomartToSaveInDB(item.phoneNumber)})) || [];

/**
 * @returns {Array<Customer_CustomerPhoneUpdateInput>} -Create Update Part object.
 * @param {CustomerFormType} customerForm - Customer form.
 */
const updatePhones = (
  customerForm: CustomerFormType,
): Array<Customer_CustomerPhoneUpdateInput> => {
  const update: Array<Customer_CustomerPhoneUpdateInput> = [];

  customerForm.customerPhoneNumberRelation
    ?.filter((item) => item.id !== undefined)
    .map((item) => ({
      filter: {
        id: item.id,
      },
      data: {
        phoneNumber: getPhoneNumberFomartToSaveInDB(item.phoneNumber),
      },
    }))
    .forEach((item) => update.push(item));

  return update;
};

/**
 * @param {CustomerFormType} customerForm - Customer form.
 * @param {PhoneType} originalPhones - Input data.
 * @returns {Array<CustomerPhoneKeyFilter>} - Create disconnect part object.
 */
const disconnectPhones = (
  customerForm: CustomerFormType,
  originalPhones: PhoneType[],
): Array<CustomerPhoneKeyFilter> => {
  const disconnect: Array<CustomerPhoneKeyFilter> = [];
  originalPhones
    .filter(
      (item) =>
        !customerForm.customerPhoneNumberRelation?.find(
          ({ id }) => id === item.id,
        ),
    )
    .map((item) => ({ id: item.id }))
    .forEach((item) => disconnect.push(item));
  return disconnect;
};

/**
 * @param {CustomerFormType} customerForm - Customer form.
 * @param {EmailType[]} originalEmails - Input data.
 * @returns {CustomerCustomerCustomerEmailRelationUpdateRelationInput} - Create Parts Object.
 */
export const createEmailsObject = (
  customerForm: CustomerFormType,
  originalEmails: EmailType[],
): CustomerCustomerCustomerEmailRelationUpdateRelationInput => {
  const parts: CustomerCustomerCustomerEmailRelationUpdateRelationInput = {
    create: createEmail(customerForm),
    update: updateEmail(customerForm),
    disconnect: disconnectEmail(customerForm, originalEmails),
  };
  return parts;
};

/**
 * @param {CustomerFormType} customerForm - Customer form.
 * @param {PhoneType[]} originalPhones - Input data.
 * @returns {CustomerCustomerPhoneNumberRelationUpdateRelationInput} - Create Parts Object.
 */
export const createPhonesObject = (
  customerForm: CustomerFormType,
  originalPhones: PhoneType[],
): CustomerCustomerPhoneNumberRelationUpdateRelationInput => {
  const parts: CustomerCustomerPhoneNumberRelationUpdateRelationInput = {
    create: createPhones(customerForm),
    update: updatePhones(customerForm),
    disconnect: disconnectPhones(customerForm, originalPhones),
  };
  return parts;
};

/**
 * @returns {UpdateCustomer2MutationVariables} - Variables.
 * @param {string} id - Id.
 * @param {CustomerEQuery} oldData - Old Data.
 * @param {EditCustomerFormType} newData - New Data.
 * @param {{filename: string, fileId: string, id: string }} documentUri - Document.
 * @param {string} documentUri.filename - Pdf.
 * @param {string} documentUri.fileId - Pdf.
 * @param {string} documentUri.id - Pdf.
 */
export const updateCustomer2MutationVariables = (
  id: string,
  oldData: CustomerEQuery,
  newData: EditCustomerFormType | CustomerFormType,
  documentUri: { filename: string; fileId: string; id: string }[] | undefined,
): UpdateCustomer2MutationVariables => {
  const address: CustomerCustomerAddressRelationUpdateRelationInput = {};
  const create = createAddressUpdateCostumer(newData as EditCustomerFormType);
  const update = updateAddressUpdateCustomer(
    oldData,
    newData as EditCustomerFormType,
  );
  const newFiles: { filename: string; fileId: string; id: string }[] = [];
  const disconectFiles = oldData.customer.documents.items.filter(
    (document) => !documentUri?.includes(document),
  );
  documentUri?.forEach((document) => {
    if (document.id === '') {
      newFiles.push(document);
    }
  });
  const disconect =
    disconectFiles.length > 0
      ? {
          disconnect: disconectFiles.map((document) => ({
            fileId: document.fileId,
          })),
        }
      : {};

  if (create.length > 0) {
    address.create = create.map((item) => ({ 
      ...item, 
      contactPhone: getPhoneNumberFomartToSaveInDB(item.contactPhone)
    }));
  }

  if (update.length > 0) {
    address.update = update.map((item) => ({ 
      ...item, 
      data:{
        ...item.data,
        contactPhone: getPhoneNumberFomartToSaveInDB(item.data.contactPhone)
      }
    }));
  }

  const variables: UpdateCustomer2MutationVariables = {
    filter: {
      id,
    },
    data: {
      email: newData.email,
      name: newData.name,
      phoneNumber: getPhoneNumberFomartToSaveInDB(newData.phoneNumber),
      customerAddressRelation: address,
      willLogin: newData.willLogin,
      customerCustomerEmailRelation: createEmailsObject(
        newData as CustomerFormType,
        oldData.customer.customerCustomerEmailRelation.items,
      ),
      customerPhoneNumberRelation: createPhonesObject(
        newData as CustomerFormType,
        oldData.customer.customerPhoneNumberRelation.items,
      ),
      documents:
        documentUri !== undefined
          ? {
              ...disconect,
              create: newFiles.map((document) => ({
                fileId: document.fileId,
                filename: document.filename,
              })),
            }
          : {
              ...disconect,
            },
    },
  };

  /* if (oldData.customer.user !== null) {
    variables.data.user = {
      update: {
        email: newData.email,
        firstName: newData.name,
        phone: newData.phoneNumber,
      },
    };
  } */

  return variables;
};
type FieldUtils = {
  fields: CreateCustomerFormType;
  invalidField: InvalidFieldType;
  setInvalid: React.Dispatch<React.SetStateAction<InvalidFieldType>>;
};
/**
 * @param props -
 * @param props.fields -
 * @param props.invalidField -
 * @param props.setInvalid -
 * @returns {void} - Remove Address.
 */
export const addressValidation = ({
  fields,
  invalidField,
  setInvalid,
}: FieldUtils): void => {
  fields.customerAddressRelation.forEach((element, index) => {
    const add = invalidField.addresses;
    add[index] = {
      name: !element.name,
      street: !element.streetAddress,
      nameReplicated: false,
    };
    setInvalid({
      ...invalidField,
      addresses: add,
    });
  });
};

/**
 * Function to check if the address name isn't replicated.
 *
 * @param props -
 * @param props.fields -
 * @param props.invalidField -
 * @param props.setInvalid -
 * @returns {boolean} -
 */
export const isAddressNamesReplicated = ({
  fields,
  invalidField,
  setInvalid,
}: FieldUtils): boolean =>
  fields.customerAddressRelation
    .map(({ name }, i, addresses) => {
      const addressNamesMatches = addresses.filter(
        (address) => address.name === name,
      );
      if (addressNamesMatches.length !== 1) {
        // eslint-disable-next-line no-param-reassign
        invalidField.addresses[i].nameReplicated = true;
        const { addresses: invalidFieldAddresses } = invalidField;
        setInvalid({
          ...invalidField,
          addresses: [...invalidFieldAddresses],
        });
        return true;
      }
      return false;
    })
    .reduce((pv, cv) => !!(pv || cv));
