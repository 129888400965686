import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useState, useEffect, useMemo } from 'react';
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
  Box,
  Skeleton,
} from '@mui/material';
import * as filestack from 'filestack-js';
import ClearIcon from '@mui/icons-material/Clear';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DateAdapter from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { useHistory } from 'react-router-dom';
import { DatePicker, LoadingButton, TimePicker } from '@mui/lab';

import {
  FormImageContainer,
  FormViewConatiner,
} from '../../shared/components/Containers';
import logo from '../../shared/assets/bro.svg';
import { JOB_STATUSES_OPTIONS, PartType } from './jobs-types';
import {
  SortOrder,
  useAddressesSelectListQuery,
  useAdminsListQuery,
  useCustomerSelectListQuery,
  useFieldworkerSelectListQuery,
  useGetApiKeyQuery,
  useJobCreateMutation,
  useJobTypeListForSelectsQuery,
} from '../../shared/types/generated';
import { useJobForm } from './jobs-hooks';
import { mapJobCreateFormToInput, mapJobCreateFormToInputV2 } from './jobs-utils';
import { snackbar } from '../../shared/components/Snackbar';
import { clearQuerysFromCache } from '../../shared/utils';
import { PartsInputPortal } from '../../shared/components/PartModal';
import { CalendarModal } from './components/CalendarModal';
import { UseValidateFeatureFlag } from '../auth/auth-hooks';
import { FeatureFlags } from '../../shared/constants/featureFlag';
import { USER_ROLES } from '../session/session-model';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Los_Angeles');
/**
 * @returns React component.
 */
export function JobCreateView(): JSX.Element {
  const { register, setValue, formState, handleSubmit, getValues, watch, } =
    useJobForm();
  const featureFlag = UseValidateFeatureFlag(FeatureFlags.INPUTS);
  const featureFlagJobEditV2 = UseValidateFeatureFlag(FeatureFlags.JOB_EDIT_V2);
  const fieldworkerDocsFeatureFlag = UseValidateFeatureFlag(FeatureFlags.FIELDWORKER_DOCS);
  const { data: JobsTypesData } = useJobTypeListForSelectsQuery();
  const [open, setOpen] = useState<boolean>(false);
  const [clientFileStack, setClientFileStack] = useState<filestack.Client>();
  const [fieldworkerFIles, setFieldworkerFIles] = useState<filestack.PickerFileMetadata[]>([]);
  const [calendar, setCalendar] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<string>('');
  const history = useHistory();
  const { data: dataFileStack } = useGetApiKeyQuery();
  const [path, setPath] = useState<string>('');

  const { data: addressesData, loading: addressLoading } =
    useAddressesSelectListQuery({
      variables: {
        id: selectedCustomer,
      },
    });
  const { data: customersData } = useCustomerSelectListQuery();
  const { data: fieldworkersData, loading: loadingFieldworkers } =
    useFieldworkerSelectListQuery();

  const { data: adminList } = useAdminsListQuery({
    fetchPolicy: 'cache-and-network',
    skip: !featureFlagJobEditV2,
    variables: {
      filter: {
        archived: {
          is_empty: true
        },
        roles: {
          some: {
            name: { equals: USER_ROLES.ADMIN }
          }
        }
      },
      sort: [{
        firstName: SortOrder.Asc,
      }]
    }
  });
  const [submitCreateJob, { loading, data: newData }] = useJobCreateMutation({
    onCompleted: (result) => {
      snackbar.success(`Job ${result.jobCreate.name} created`);
      history.goBack();
    },
    update: clearQuerysFromCache(['jobsList', 'fieldworker', 'customer']),
  });

  const [date, setDate] = useState<Date | null>(new Date());
  const addressList = addressesData?.addressesList.items ?? [];
  const fieldworkersList = fieldworkersData?.fieldworkersList.items ?? [];
  const customersList = customersData?.customersList.items ?? [];

  useEffect(() => {
    if(formState.errors){
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errros = formState.errors as Record<any, any>;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errorListKey = Object.keys(formState.errors) as Array<any>;
      errorListKey.forEach((key) => {
        snackbar.error(errros[key].message || 'Field error');
      });
    }
  }, [formState.errors]);
  useEffect(() => {
    if (dataFileStack?.fileUploadInfo.apiKey) {
      setClientFileStack(
        filestack.init(dataFileStack?.fileUploadInfo.apiKey, {
          security: {
            signature: dataFileStack?.fileUploadInfo.signature as string,
            policy: dataFileStack?.fileUploadInfo.policy as string,
          },
        }),
      );
      setPath(dataFileStack?.fileUploadInfo.path as string);
    }
  }, [dataFileStack]);

  const ADMINOPTIONS = useMemo(() => {
    const returnArr = adminList?.usersList?.items?.map((item) => {
      const name = item?.firstName ? `${item?.firstName} ${item?.lastName || ''}` : item?.email;
      return {
        label: name,
        value: name,
      };
    });
    return featureFlagJobEditV2 ? returnArr : null;
  }, [adminList, featureFlagJobEditV2]);

  const handleAttachFile = (): void => {
    if (!clientFileStack) return;
    const options = {
      fromSources: ['local_file_system'],
      accept: ['.pdf', '.docx'],
      maxFiles: 5 - ((fieldworkerFIles?.length || 0)),
      /**
       * @param files - Filestack files client.
       */
      onUploadDone: (files: filestack.PickerResponse): void => {
        if (files.filesUploaded.length > 0) {
          const newSourcesArr = files.filesUploaded.map((newSrc) => newSrc);
          setFieldworkerFIles((state) => [
            ...state,
            ...newSourcesArr,
          ]);
        }
      },
      storeTo: { path },
    };
    clientFileStack.picker(options).open();
  };

  const deleteFieldworkerDoc = (uploadId: string): void => {
    const filteredItems = fieldworkerFIles.filter((item) => item.uploadId !== uploadId);
    setFieldworkerFIles(filteredItems);
  };

  return (
    <FormViewConatiner title="Create Job" subtitle="Add new job">
      <Typography
        variant="h5"
        sx={{ display: [null, 'none'], gridColumn: 'span 2' }}
      >
        Job details
      </Typography>
      <CalendarModal
        open={calendar}
        date={date}
        onClose={() => setCalendar(() => !calendar)}
      />
      <PartsInputPortal
        actionAdd={(obj) => {
          setOpen(!open);
          const newParts: PartType[] = getValues('parts') ?? [];
          newParts.push({
            name: obj.name,
            unit: obj.unit,
            partNumber: obj.partNumber,
          });
          const json = JSON.stringify(newParts);
          const newPartsFormat = JSON.parse(json);
          setValue('parts', newPartsFormat);
        }}
        label="Part Name"
        title="Add Part Information"
        errorMessage="Max. 100 characters"
        onClose={() => {
          setOpen(!open);
        }}
        open={open}
        extra
      />
      <FormImageContainer
        imagePath={logo}
        onSubmit={handleSubmit((data): void =>  {
          if (featureFlagJobEditV2) {
            if (fieldworkerDocsFeatureFlag) {
              const dispatchedBy = ADMINOPTIONS?.find((value) => value.value === data?.dispatchedBy)?.value ? data.dispatchedBy : ''; 
              submitCreateJob({
                variables: {
                  data: mapJobCreateFormToInputV2({
                    ...data,
                    fieldworkerDocs: fieldworkerFIles,
                    dispatchedBy: dispatchedBy || '',
                    startDate: date?.toISOString(),
                  }),
                },
              });
            } else {
              const dispatchedBy = ADMINOPTIONS?.find((value) => value.value === data?.dispatchedBy)?.value ? data.dispatchedBy : ''; 
              submitCreateJob({
                variables: {
                  data: mapJobCreateFormToInput({
                    ...data,
                    dispatchedBy: dispatchedBy || '',
                    startDate: date?.toISOString(),
                  }),
                },
              });
            }
          } else {
            submitCreateJob({
              variables: {
                data: mapJobCreateFormToInput({
                  ...data,
                  startDate: date?.toISOString(),
                }),
              },
            });
          }
        })}
      >
        <Typography
          variant="h5"
          sx={{ display: ['none', 'block'], gridColumn: 'span 2' }}
        >
          Job details
        </Typography>
        <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
          <Autocomplete
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            options={customersList}
            filterOptions={(options, { inputValue }) =>
              options.filter(
                (item) =>
                  item.name.toLowerCase().indexOf(inputValue.toLowerCase()) >=
                  0,
              )
            }
            onChange={(_event, value) => {
              setValue('name', value?.name ?? '');
              setSelectedCustomer(value?.id ?? '');
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Customer"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingFieldworkers ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
          <InputLabel>Address</InputLabel>
          <Select
            label="Address"
            disabled={!selectedCustomer || addressLoading}
            {...register('address')}
            error={Boolean(formState.errors?.address?.message)}
          >
            {!addressLoading ? (
              addressList?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.city}, {item.state}, {item.streetAddress}
                </MenuItem>
              ))
            ) : (
              <CircularProgress
                sx={{
                  alignContent: 'center',
                }}
                color="inherit"
                size={20}
              />
            )}
          </Select>
          {formState.errors?.address?.message && (
            <FormHelperText error>
              {formState.errors?.address?.message}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
          <TextField
            label="Description"
            placeholder="Type the job description"
            multiline
            minRows={3}
            error={Boolean(formState.errors?.description?.message)}
            {...register('description')}
          />
        </FormControl>
        {featureFlag && (
          <>
            <FormControl
              variant="outlined"
              fullWidth
              sx={{ gridColumn: 'span 2' }}
            >
              <TextField
                label="Caller Name"
                {...register('callerName')}
                helperText={formState.errors?.callerName?.message}
                error={Boolean(formState.errors?.callerName?.message)}
              />
            </FormControl>
            <FormControl
              variant="outlined"
              fullWidth
              sx={{ gridColumn: 'span 2' }}
            >
              <TextField
                {...register('callerCell')}
                label="Caller Cell"
                helperText={formState?.errors?.callerCell?.message}
                error={Boolean(formState?.errors?.callerCell?.message)}
              />
            </FormControl>
          </>
        )}
        {formState.errors?.description?.message && (
          <FormHelperText error>
            {formState.errors?.description?.message}
          </FormHelperText>
        )}
        <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
          <TextField
            label="Admin Notes"
            multiline
            error={Boolean(formState.errors?.adminNote?.message)}
            minRows={3}
            {...register('adminNote')}
          />
        </FormControl>
        {formState.errors?.adminNote?.message && (
          <FormHelperText error>
            {formState.errors?.adminNote?.message}
          </FormHelperText>
        )}
        <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
          <InputLabel>Priority</InputLabel>
          <Select
            label="Priority"
            defaultValue="NORMAL"
            {...register('priority')}
          >
            <MenuItem value="LOW">Low</MenuItem>
            <MenuItem value="NORMAL">Normal</MenuItem>
            <MenuItem value="HIGH">High</MenuItem>
          </Select>
        </FormControl>
        {JobsTypesData?.jobTypesList?.items[2]?.id && (
          <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
              label="Type"
              defaultValue={JobsTypesData?.jobTypesList?.items[2]?.id}
              {...register('type')}
            >
              {JobsTypesData?.jobTypesList.items.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <FormControl fullWidth>
          <InputLabel>Status</InputLabel>
          <Select label="Status" defaultValue="NEW" {...register('status')}>
            {JOB_STATUSES_OPTIONS.map((item) => (
              <MenuItem key={item} value={item}>
                {item.replaceAll('_', ' ')}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DatePicker
              onChange={(fecha) => {
                setDate(fecha);
              }}
              value={date}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <TimePicker
              value={date}
              onChange={(fecha) => {
                setDate(fecha);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </FormControl>
        {!featureFlagJobEditV2 ? (featureFlag && (
          <FormControl
          variant="outlined"
          fullWidth
          sx={{ gridColumn: 'span 2' }}
        >
          <TextField
            label="Dispatched by"
            {...register('dispatchedBy')}
            helperText={formState.errors?.dispatchedBy?.message}
            error={Boolean(formState.errors?.dispatchedBy?.message)}
          />
        </FormControl>
        )) : <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
          {ADMINOPTIONS && <InputLabel>Dispatched by</InputLabel>}
          {ADMINOPTIONS ? (
            <Select
              label="Dispatched by"
              {...register('dispatchedBy')}
              placeholder='Dispatched by'
            >
              {ADMINOPTIONS?.map((adminItem) => (
                <MenuItem value={adminItem?.value} key={adminItem?.value}>
                {adminItem?.label}
              </MenuItem>
              ))}
            </Select>) : (
            <Skeleton animation="wave" />
          )}
        </FormControl>
        }
        <Typography variant="h5" sx={{ gridColumn: 'span 2' }}>
          Fieldworkers
        </Typography>

        <FormControl
          fullWidth
          sx={{
            gridColumn: 'span 2',
            display: 'grid',
            gridTemplateColumns: '90% 10%',
          }}
        >
          <Autocomplete
            multiple
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.fullName}
            options={fieldworkersList}
            filterOptions={(options, { inputValue }) =>
              options.filter(
                (item) =>
                  item.fullName
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) >= 0,
              )
            }
            onChange={(_event, newValue) => {
              setValue(
                'fieldworker',
                newValue.map((item) => item.id),
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Fieldworker"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingFieldworkers ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
          <Box ml={1}>
            <IconButton
              color="primary"
              onClick={() => setCalendar(() => !calendar)}
              size='small'
            >
              <CalendarTodayIcon />
            </IconButton>
          </Box>
        </FormControl>
        <FormControl
          variant="outlined"
          fullWidth
          sx={{ gridColumn: 'span 2', marginTop: '10px' }}
        >
          <TextField
            label="Unit Serviced"
            {...register('unitServiced')}
            helperText={formState.errors?.unitServiced?.message}
            error={Boolean(formState.errors?.unitServiced?.message)}
          />
        </FormControl>
        <FormControl
          variant="outlined"
          fullWidth
          sx={{ gridColumn: 'span 2', marginTop: '10px' }}
        >
          <TextField
            label="Model"
            {...register('model')}
            helperText={formState.errors?.model?.message}
            error={Boolean(formState.errors?.model?.message)}
          />
        </FormControl>
        <FormControl
          variant="outlined"
          fullWidth
          sx={{ gridColumn: 'span 2', marginTop: '10px' }}
        >
          <TextField
            label="Serial"
            {...register('serial')}
            helperText={formState.errors?.serial?.message}
            error={Boolean(formState.errors?.serial?.message)}
          />
        </FormControl>
        <FormControl
          variant="outlined"
          fullWidth
          sx={{ gridColumn: 'span 2', marginTop: '10px' }}
        >
          <TextField
            label="Work Performed"
            {...register('workPerformed')}
            multiline
            minRows={3}
            helperText={formState.errors?.workPerformed?.message}
            error={Boolean(formState.errors?.workPerformed?.message)}
          />
        </FormControl>
        <FormControl
          variant="outlined"
          fullWidth
          sx={{ gridColumn: 'span 2', marginTop: '10px' }}
        >
          <TextField
            label="Purchase Order"
            {...register('purchaseOrder')}
            helperText={formState.errors?.purchaseOrder?.message}
            error={Boolean(formState.errors?.purchaseOrder?.message)}
          />
        </FormControl>
        <FormControl
          variant="outlined"
          fullWidth
          sx={{ gridColumn: 'span 2', marginTop: '10px' }}
        >
          <TextField
            label="Work Order"
            {...register('workOrder')}
            helperText={formState.errors?.workOrder?.message}
            error={Boolean(formState.errors?.workOrder?.message)}
          />
        </FormControl>
        <FormControl
          variant="outlined"
          fullWidth
          sx={{ gridColumn: 'span 2', marginTop: '10px' }}
        >
          <TextField
            label="Call ID"
            {...register('callID')}
            helperText={formState.errors?.callID?.message}
            error={Boolean(formState.errors?.callID?.message)}
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth sx={{ gridColumn: 'span 2' }}>
          <Button
            variant="outlined"
            onClick={() => {
              setOpen(!open);
            }}
          >
            Add Part
          </Button>
        </FormControl>
        <List sx={{ gridColumn: 'span 2' }}>
          {watch('parts') &&
            watch('parts')?.map((item, index) => (
              <ListItem
                key={item.name}
                sx={{ 
                  border: '1px solid #e0e0e0',
                  borderRadius: '10px', 
                  marginBottom: '5px', 
                  '&:nth-last-child(1)': {
                    marginBottom: '0'
                  } 
                }}
                secondaryAction={
                  <IconButton
                    edge="end"
                    onClick={() => {
                      const newParts = getValues('parts');
                      if (newParts) {
                        newParts.splice(index, 1);
                        setValue('parts', newParts);
                      }
                    }}
                  >
                    <ClearIcon color="error" />
                  </IconButton>
                }
                disablePadding
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  paddingLeft={1}
                  paddingTop={1}
                  paddingBottom={1}
                >
                  <Typography variant="body1">
                    Part Name: {item.name}
                  </Typography>
                  {item.partNumber !== '' && item.unit !== null && (
                    <Typography variant="body1">
                      Part Number: {item.partNumber}
                    </Typography>
                  )}
                  {item.unit !== '' && item.unit !== null && (
                    <Typography variant="body1">Unit: {item.unit}</Typography>
                  )}
                </Box>
              </ListItem>
            ))}
        </List>
        {fieldworkerDocsFeatureFlag && <FormControl variant="outlined" fullWidth sx={{ gridColumn: 'span 2' }}>
          <Button
            variant="outlined"
            onClick={() => handleAttachFile()}
            disabled={fieldworkerFIles?.length >= 5}
          >
            Add Fieldworker Docs
          </Button>
        </FormControl>}
        {fieldworkerDocsFeatureFlag && fieldworkerFIles && <List sx={{ gridColumn: 'span 2' }}>
          {fieldworkerFIles?.length > 0 &&
            fieldworkerFIles?.map((item, index) => (
              <ListItem
                key={item.uploadId}
                sx={{ 
                  border: '1px solid #e0e0e0', 
                  borderRadius: '10px', 
                  marginBottom: '5px', 
                  '&:nth-last-child(1)': {
                    marginBottom: '0'
                  } 
                }}
                secondaryAction={
                  <IconButton
                    edge="end"
                    onClick={() => deleteFieldworkerDoc(item.uploadId)}
                  >
                    <ClearIcon color="error" />
                  </IconButton>
                }
                disablePadding
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  padding='8px 40px 8px 8px'
                  textOverflow='ellipsis'
                  overflow='hidden'
                >
                  <Typography variant="body1" noWrap>
                    {index + 1}.- {item.filename}
                  </Typography>
                </Box>
              </ListItem>
            ))}
        </List>}
        <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
          <FormControlLabel
            control={<Checkbox {...register('notification')} defaultChecked />}
            label="Send Notification"
          />
        </FormControl>
        <Box
          display="flex"
          alignItems="center"
          sx={{
            gridColumn: 'span 2',
            marginBottom: '20px',
            justifyContent: ['space-between', 'flex-end'],
            '&>*': {
              /**
               * @param theme - Theme.
               * @returns Margin.
               */
              marginLeft: (theme) => `${theme.spacing(2)} !important`,
            },
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              history.goBack();
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={loading || Boolean(newData?.jobCreate)}
          >
            Create
          </LoadingButton>
        </Box>
      </FormImageContainer>
    </FormViewConatiner>
  );
}
