import React, { useState } from 'react';
import { error as errorLogger } from '@cobuildlab/pure-logger';
import { Box, Button, FormControl, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormImageContainer, FormViewConatiner } from '../../shared/components/Containers';
import logo from '../../shared/assets/fieldworker.svg';
import { UserCreateInput, useCreateAdminResolverMutation } from '../../shared/types/generated';
import { snackbar } from '../../shared/components/Snackbar';
import { getPhoneNumberFomartToSaveInDB } from '../../shared/utils/phone';
import { EMAIL_REGREX } from '../../shared/constants/common';
import { ADMIN_FORM_VALIDATOR_SCHEMA, CreateAdminType } from './admin-types';
import { defaultAdminFormValues } from './admin-utils';

export const AdminCreateView: React.FC = () => {
  const history = useHistory();
  const [err, setErr] = useState(false);
  const { control, handleSubmit, formState: { errors } } = useForm<CreateAdminType>({
    defaultValues: defaultAdminFormValues,
    resolver: yupResolver(ADMIN_FORM_VALIDATOR_SCHEMA, {})
  });
  const [createAdmin, { loading }] = useCreateAdminResolverMutation({
    onCompleted: () => {
      snackbar.success('Admin created');
      history.push('/admin');
    },
    onError: (error) => {
      console.log(error.message);
      errorLogger(error);
      setErr(true);
    },
  });
  const hasFormErrors = Boolean(Object.keys(errors)?.length);

  const handlerSubmitForm  = handleSubmit<UserCreateInput>((data) => {
    const phoneNumberFormatToSaveInDataBD = getPhoneNumberFomartToSaveInDB(data?.phone as string);
    createAdmin({
      variables: {
        firstName: data?.firstName as string,
        lastName: data?.lastName as string,
        email: data?.email?.toLowerCase() as string,
        phone: phoneNumberFormatToSaveInDataBD  as string,
      },
    });
  });

  return (
  <FormViewConatiner
  title="Create Admin"
  subtitle="Add new admin"
>
  <Typography
    variant="h5"
    sx={{ display: [null, 'none'], gridColumn: 'span 2' }}
  >
    Information
  </Typography>
  <FormImageContainer
    imagePath={logo}
    onSubmit={handlerSubmitForm}
  >
    <Typography
      variant="h5"
      sx={{ display: ['none', 'block'], gridColumn: 'span 2' }}
    >
      Information
    </Typography>
    <FormControl
      variant="outlined"
      fullWidth
      sx={{ gridColumn: 'span 2', marginTop: '10px' }}
    >
      <Controller 
        control={control}
        name="firstName"
        rules={{
          required: {
            value: true,
            message: 'Full name is required'
          }
        }}
        render={({ field }) => (
          <TextField
          {...field}
          label="First Name"
          helperText={errors?.firstName?.message}
          error={Boolean(errors?.firstName?.message)}
        />
        )}
      />
    </FormControl>
    <FormControl
      variant="outlined"
      fullWidth
      sx={{ gridColumn: 'span 2', marginTop: '10px' }}
    >
      <Controller 
        control={control}
        name="lastName"
        rules={{
          required: {
            value: true,
            message: 'Full name is required'
          }
        }}
        render={({ field }) => (
          <TextField
          {...field}
          label="Last Name"
          helperText={errors?.lastName?.message}
          error={Boolean(errors?.lastName?.message)}
        />
        )}
      />
    </FormControl>
    <FormControl variant="outlined" fullWidth sx={{ gridColumn: 'span 2' }}>
      <Controller
        control={control}
        name="email"
        rules={{
          required: {
            value: true,
            message: 'Email is required',
          },
          maxLength: {
            value: 100,
            message: 'Email must be at most 100 characters',
          },
          pattern: {
            value: EMAIL_REGREX,
            message: 'Invalid email address',
          },
        }}
        render={({ field }) => (
          <TextField
            {...field}
            label="Email"
            helperText={errors?.email?.message}
            error={Boolean(errors?.email?.message)}
          />
        )}
      />
    </FormControl>
    <FormControl variant="outlined" fullWidth sx={{ gridColumn: 'span 2' }}>
      <Controller
        control={control}
        name="phone"
        render={({ field }) => (
          <TextField
            {...field}
            label="Phone Number"
            helperText={errors?.phone?.message}
            error={Boolean(errors?.phone?.message)}
          />
        )}
      />
    </FormControl>
    {err && (
      <Typography
        variant="body1"
        sx={{
          gridColumn: 'span 2',
          marginLeft: '5px',
        }}
        color="error"
      >
        Phone Number or Email already exists
      </Typography>
    )}
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        gridColumn: 'span 2',
        position: ['static', 'absolute'],
        bottom: '40px',
        right: '100px',
        width: ['100%', '220px'],
        marginTop: ['20px', '0'],
      }}
    >
      <Button
        variant="outlined"
        onClick={() => {
          history.goBack();
        }}
      >
        Cancel
      </Button>
      <LoadingButton disabled={hasFormErrors} variant="contained" loading={loading} type="submit">
        Create
      </LoadingButton>
    </Box>
  </FormImageContainer>
</FormViewConatiner>
  );
};