import React, { useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { Box, Typography } from '@mui/material';
// import { CalendarListQuery } from '../../../shared/types/generated';
import {
  hours,
  FieldworkerColor,
  days,
  getDays,
  CalendarType,
} from '../calendar-types';
import { WhiteBoards } from './WhiteBoards';
import { InfoCard, InfoCards } from './InfoCard';
import { GRAY_LIGHT } from '../../../shared/css/theme';
import { filterWeek } from '../calendar-utils';

/**
 * @param {JSX.Element} props - Calendar View.
 * @param {JSX.Element} props.fieldworkers - Calendar View.
 * @returns {JSX.Element} - Calendar View.
 */
export function TableWeek({
  fieldworkers,
  day,
}: {
  fieldworkers: FieldworkerColor[];
  day: string;
}): JSX.Element {
  const validHours = fieldworkers.filter(
    (item) => item.fieldworkerJobFieldworkerRelation.items.length > 0,
  );
  const formatData = filterWeek(validHours);
  const today = Number(dayjs().format('DD'));
  const daysList = getDays(day);
  const currentHour = Number(dayjs().format('H'));
  const valueRef = useRef<HTMLDivElement>(null);
  const columnWith = '14%';
  useEffect(() => {
    // move scroll to current hour
    valueRef?.current?.scrollTo(0, currentHour * 90);
  }, [currentHour]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          paddingRight: '0.6rem',
        }}
        mt={['40px', 0]}
      >
        <Box
          sx={{
            borderRight: `1px solid ${GRAY_LIGHT}`,
            borderBottom: `1px solid ${GRAY_LIGHT}`,
            borderTop: `1px solid ${GRAY_LIGHT}`,
            minWidth: '47px',
          }}
          display="flex"
          alignItems="center"
        >
          <Typography variant="subtitle2">1h</Typography>
        </Box>
        <Box display="flex" width="100%">
          {days.map((dayWeek, index) => (
            <Box
              sx={{
                borderRight: `1px solid ${GRAY_LIGHT}`,
                borderBottom: `1px solid ${GRAY_LIGHT}`,
                borderTop: `1px solid ${GRAY_LIGHT}`,
                minWidth: '100px',
                width: `${columnWith}`,
              }}
              key={dayWeek.id}
            >
              {Number(daysList[index]) === today ? (
                <Box
                  sx={{
                    backgroundColor: 'primary.main',
                    borderRadius: '8px',
                    width: '70%',
                    margin: '5px auto',
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    textAlign="center"
                    color="#fff"
                    sx={{
                      fontSize: '12px',
                    }}
                  >
                    {dayWeek.id}
                  </Typography>
                  <Typography variant="h6" textAlign="center" color="#fff">
                    {daysList[index]}
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ margin: '5px auto' }}>
                  <Typography
                    variant="subtitle2"
                    textAlign="center"
                    sx={{
                      fontSize: '12px',
                    }}
                  >
                    {dayWeek.id}
                  </Typography>
                  <Typography variant="h6" textAlign="center">
                    {daysList[index]}
                  </Typography>
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: ['max-content', '100%'],
          height: '63vh',
          overflowY: 'scroll',
          overflowX: 'hidden',
          '&::-webkit-scrollbar': {
            backgroundColor: '#fafafa',
            width: '0.6rem',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#bcbcbd',
            borderRadius: '10px',
          },
        }}
        ref={valueRef}
      >
        <Box
          sx={{
            height: '100%',
            minWidth: '47px',
          }}
        >
          {hours.map((hour, index) => (
            <Box
              key={hour.id}
              sx={{
                borderRight: `1px solid ${GRAY_LIGHT}`,
                height: '90px',
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  position: 'relative',
                  top: '-10px',
                  display: index === 0 ? 'none' : 'block',
                }}
              >
                {hour.id}
              </Typography>
            </Box>
          ))}
        </Box>

        <Box
          display="grid"
          gridTemplateRows="repeat(24, 90px)"
          gridTemplateColumns={`repeat(7, minmax(100px, ${columnWith}))`}
          width="calc(100% - 47px)"
          sx={{
            gridGap: '0',
          }}
        >
          {formatData.map((item) => (
            <>
              {item.jobs.length > 1 ? (
                <InfoCards
                  type={CalendarType.WEEK}
                  key={item.id}
                  gridRowStart={Number(item.id.slice(0, -2))}
                  gridColumnStart={Number(item.id.slice(-1))}
                  fieldworker={{ fullName: item.fullName }}
                  jobs={item.jobs}
                />
              ) : (
                <InfoCard
                  type={CalendarType.WEEK}
                  key={item.id}
                  count={validHours.length}
                  backgroundColor={item.jobs[0].color}
                  job={item.jobs[0]}
                  fieldworker={{ fullName: item.fullName }}
                  gridRowStart={Number(item.id.slice(0, -2))}
                  gridColumnStart={Number(item.id.slice(-1))}
                />
              )}
            </>
          ))}
          {validHours.length === 0 ? (
            <WhiteBoards rows={hours.length * 7} />
          ) : (
            <WhiteBoards rows={hours.length * 7 - formatData.length} />
          )}
        </Box>
      </Box>
    </>
  );
}
