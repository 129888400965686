import { styled } from '@mui/material/styles';
import { Chip } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { JobsStatuses, PAUSED_STATE } from '../jobs-types';

const StyledChip = styled(Chip)(({ theme }) => ({
  position: 'absolute',
  top: '30px',
  right: '50px',
  [theme.breakpoints.down('md')]: {
    top: '15px',
    right: '10px',
  },
}));

/**
 * @param status - Props.
 * @returns Status in CamelCase.
 */
function camelCase(status: string): string {
  return status
    .toLowerCase()
    .replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase());
}
/**
 * @param props - Props.
 * @param props.status - Status.
 * @returns Jobs Status pill.
 */
export function JobStatusPoint(props: { status: string }): JSX.Element {
  if (props.status === JobsStatuses.NEW) {
    return (
      <StyledChip
        icon={<CircleIcon />}
        variant="outlined"
        color="secondary"
        label={camelCase(props.status)}
      />
    );
  }
  if (props.status === JobsStatuses.DISPATCHED) {
    return (
      <StyledChip
        icon={<CircleIcon />}
        variant="outlined"
        color="error"
        label={camelCase(props.status)}
      />
    );
  }
  if (props.status === JobsStatuses.CLOSED) {
    return (
      <StyledChip
        icon={<CircleIcon />}
        variant="outlined"
        color="primary"
        label={camelCase(props.status)}
      />
    );
  }
  if (props.status === JobsStatuses.ON_ROUTE) {
    return (
      <StyledChip
        icon={<CircleIcon sx={{ color: '#FF4D00 !important' }} />}
        variant="outlined"
        sx={{
          color: '#FF4D00',
        }}
        label={camelCase(props.status.replace('_', ' '))}
      />
    );
  }
  if (props.status === JobsStatuses.ON_SITE) {
    return (
      <StyledChip
        icon={<CircleIcon sx={{ color: '#2BA668 !important' }} />}
        variant="outlined"
        sx={{
          color: '#2BA668',
        }}
        label={camelCase(props.status.replace('_', ' '))}
      />
    );
  }
  if (props.status === PAUSED_STATE.PAUSED) {
    return (
      <StyledChip
        icon={<CircleIcon sx={{ color: '#3e67b0 !important' }} />}
        variant="outlined"
        sx={{
          color: '#3e67b0',
        }}
        label={camelCase(props.status.replace('_', ' '))}
      />
    );
  }
  if (props.status === JobsStatuses.QUOTE) {
    return (
      <StyledChip
        icon={<CircleIcon sx={{ color: '#9558e2 !important' }} />}
        variant="outlined"
        sx={{
          color: '#9558e2',
        }}
        label={camelCase(props.status.replace('_', ' '))}
      />
    );
  }
  if (props.status === JobsStatuses.Q_SUBMITTED) {
    return (
      <StyledChip
        icon={<CircleIcon sx={{ color: '#DD5476 !important' }} />}
        variant="outlined"
        sx={{
          color: '#DD5476',
        }}
        label={camelCase(props.status.replace('_', ' '))}
      />
    );
  }
  if (props.status === JobsStatuses.READY_FOR_RETURN) {
    return (
      <StyledChip
        icon={<CircleIcon sx={{ color: '#3A543B !important' }} />}
        variant="outlined"
        sx={{
          color: '#3A543B',
        }}
        label={camelCase(props.status.replaceAll('_', ' '))}
      />
    );
  }
  return (
    <StyledChip
      icon={<CircleIcon />}
      variant="outlined"
      color="warning"
      label={camelCase(props.status.replace('_', ' '))}
    />
  );
}
