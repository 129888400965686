/* eslint-disable jsdoc/require-jsdoc */
import React from 'react';
import Popover, { PopoverProps } from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';

import { Job } from '../../../shared/types/types';

type InfoCardPopoverProps = {
  fieldWorkerName?: string;
  popoverProps: PopoverProps;
  job?: Job;
};

export const InfoCardPopover: React.FC<InfoCardPopoverProps> = ({
  fieldWorkerName,
  popoverProps,
  job,
}) => {
  const title = `#${job?.code || 'N/A'}`;
  const address = `${job?.address?.state}, ${job?.address?.city}, ${job?.address?.streetAddress}`;
  const customer = job?.address?.customer?.name || 'N/A';
  const time = dayjs(job?.startDateTime).format('HH:mm A');
  return (
    <Popover
      {...popoverProps}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
    >
      <Box padding={1}>
        <Typography variant="subtitle1">{title}</Typography>
        <Typography variant="subtitle2">Time: {time}</Typography>
        {fieldWorkerName && (
          <Typography variant="subtitle2">
            FieldWorker: {fieldWorkerName || 'N/A'}
          </Typography>
        )}
        <Typography variant="subtitle2">Customer: {customer}</Typography>
        <Typography variant="subtitle2">Address: {address}</Typography>
      </Box>
    </Popover>
  );
};
