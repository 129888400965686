import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Theme,
  Card,
  styled,
  Button,
  FormControl,
  Box,
  Paper,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  IconButton,
  ListItemText,
  useMediaQuery,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Grid from '@mui/material/Grid';
import { useHistory } from 'react-router-dom';
import * as filestack from 'filestack-js';
import { Controller } from 'react-hook-form';

import { FormImageContainer } from '../../shared/components/Containers';
import { snackbar } from '../../shared/components/Snackbar';
import image from '../../shared/assets/bro.svg';
import {
  useCreateCustomerMutation,
  useGetApiKeyQuery,
} from '../../shared/types/generated';

import { createVariablesNewCustomer } from './customer-utils';
import { clearQuerysFromCache } from '../../shared/utils';
import { useCustomerForm } from './customer-hooks';
import { PartsInputPortal } from '../../shared/components/PartModal';
import { Address, PhoneType } from './customer-types';
import { theme as cssTheme } from '../../shared/css/theme';
import { UseValidateFeatureFlag } from '../auth/auth-hooks';
import { PhoneNumberInputModal } from '../../shared/components/PhoneNumberInputModal';
import { getPhoneNumberFomartToSaveInDB } from '../../shared/utils/phone';
// import { useFormState } from 'react-hook-form';

const DeleteButton = styled(Button)(() => ({
  color: '#EF5350',
}));
// const theme = createMuiTheme();
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    Width: '75%',
    borderRadius: '15px',
  },
  contend: {
    width: '95%',
  },
  card: {
    border: '0.5px solid #969CBA',
    boxSizing: 'border-box',
    boxShadow: '0px 3px 0px #EDEDF6',
    borderRadius: '10',
    padding: theme.spacing(2),
  },
  photo: {
    width: '90%',
    display: 'block',
    alignSelf: 'center',
  },
  sep: {
    marginTop: theme.spacing(4),
  },
  actions: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'right',
  },
}));

const BigPaper = styled(Paper)(({ theme }) => ({
  height: 'auto',
  paddingLeft: theme.spacing(10),
  paddingRight: theme.spacing(10),
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    maxHeight: 'none',
    overflowY: 'initial',
  },
}));

/**
 * @returns {JSX.Element} - Form create new Fieldworker.
 */
export function CreateCustomerForm(): JSX.Element {
  const featureFlag = UseValidateFeatureFlag('DOCUMENTS');
  const matchesSm = useMediaQuery(cssTheme.breakpoints.down('sm'));
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
    watch,
    getValues,
    control,
  } = useCustomerForm();
  const history = useHistory();
  const [path, setPath] = useState<string>('');
  const [clientFileStack, setClientFileStack] = useState<filestack.Client>();
  const [documentUri, setDocumentUri] = useState<
    filestack.PickerFileMetadata[]
  >([]);
  const [modal, setModal] = useState<{ emails: boolean; phones: boolean }>({
    emails: false,
    phones: false,
  });
  const [open, setOpen] = useState<boolean>(false);
  const [createCustomer, { loading, error, data: newData }] =
    useCreateCustomerMutation({
      /**
       * @returns {void} - Result.
       */
      onCompleted: () => {
        if (error === undefined) {
          snackbar.success('Customer created');
          history.push('/customers');
        } else {
          setOpen(true);
        }
      },

      /**
       * @param cache - Cache.
       * @returns Void.
       */
      update: (cache) =>
        clearQuerysFromCache([
          'customersList',
          'customer',
          'address',
          'addressesList',
        ])(cache),
    });
  const { data: dataFileStack } = useGetApiKeyQuery();

  useEffect(() => {
    if(errors){
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const erros = errors as Record<any, any>;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errorListKey = Object.keys(erros) as Array<any>;
      errorListKey.forEach((key) => {
        snackbar.error(erros[key].message || 'Field error');
      });
    }
  }, [errors]);

  useEffect(() => {
    if (dataFileStack?.fileUploadInfo.apiKey) {
      setClientFileStack(
        filestack.init(dataFileStack?.fileUploadInfo.apiKey, {
          security: {
            signature: dataFileStack?.fileUploadInfo.signature as string,
            policy: dataFileStack?.fileUploadInfo.policy as string,
          },
        }),
      );
      setPath(dataFileStack?.fileUploadInfo.path as string);
    }
  }, [dataFileStack]);
  /**
   * - Handle upload files.
   */
  const handleAttachFile = (): void => {
    if (!clientFileStack) return;
    const options = {
      fromSources: ['local_file_system'],
      accept: ['.pdf'],
      maxFiles: 10,
      /**
       * @param files - Filestack files client.
       */
      onUploadDone: (files: filestack.PickerResponse): void => {
        if (files.filesUploaded.length > 0) {
          const newSourcesArr = files.filesUploaded.map((newSrc) => newSrc);
          const oldSourcesArr = [...documentUri];
          newSourcesArr.forEach((source) => oldSourcesArr.push(source));
          setDocumentUri(oldSourcesArr);
        }
      },
      storeTo: { path },
    };
    clientFileStack.picker(options).open();
  };
  /**
   * @returns {void} - Push Address.
   */
  const pushAddres = (): void => {
    const addresses = watch('customerAddressRelation') ?? [];

    addresses.push({
      contactName: '',
      contactPhone: '',
      city: '',
      zipCode: '',
      state: '',
      name: '',
      streetAddress: '',
    });
    setValue('customerAddressRelation', addresses);
  };

  /**
   * @returns {void} - Remove Address.
   * @param {number} index - Array position to delete.
   */
  const removeAddress = (index: number): void => {
    const addresses = watch('customerAddressRelation') ?? [];

    addresses.splice(index, 1);

    setValue('customerAddressRelation', addresses);
  };

  const classes = useStyles();

  return (
    <>
      <BigPaper variant="card">
        <Typography
          variant="h5"
          sx={{ display: [null, 'none'], gridColumn: 'span 2' }}
        >
          Customer Details
        </Typography>
        <FormImageContainer
          onSubmit={handleSubmit(async (data) => {
            await createCustomer({
              variables: createVariablesNewCustomer(
                {
                  name: data.name,
                  email: data.email?.toLowerCase() ?? '',
                  phoneNumber: data.phoneNumber ? getPhoneNumberFomartToSaveInDB(data.phoneNumber) : '',
                  willLogin: data.willLogin,
                },
                data.customerAddressRelation as Array<Address>,
                data.customerPhoneNumberRelation as Array<PhoneType>,
                data.customerCustomerEmailRelation,
                documentUri,
              ),
            });
          })}
          imagePath={image}
        >
          <Typography
            variant="h5"
            component="h2"
            sx={{ display: ['none', 'block'], gridColumn: 'span 2' }}
          >
            Customer Details
          </Typography>
          <PartsInputPortal
            actionAdd={(obj) => {
              setModal({ ...modal, emails: !modal.emails });
              const newEmails =
                getValues('customerCustomerEmailRelation') ?? [];
              newEmails.push({
                email: obj.name,
              });
              setValue('customerCustomerEmailRelation', newEmails);
            }}
            errorMessage="Invalid email"
            label="Email"
            title="Add Email For Notifications"
            onClose={() => {
              setModal({ ...modal, emails: !modal.emails });
            }}
            open={modal.emails}
          />
          <PhoneNumberInputModal
            isVisible={modal.phones}
            onComfirm={(value) => {
              setModal({ ...modal, phones: !modal.phones });
              const newPhones = getValues('customerPhoneNumberRelation') ?? [];
              newPhones.push({
                phoneNumber: value.phoneNumber,
              });
              setValue('customerPhoneNumberRelation', newPhones);
            }}
            onCancel={() => {
              setModal({ ...modal, phones: !modal.phones });
            }}

          /> 
          <FormControl
            fullWidth
            sx={{ gridColumn: 'span 2', marginTop: '10px' }}
          >
            <TextField
              required
              id="outlined-basic"
              label="Full Name"
              variant="outlined"
              {...register('name')}
              helperText={errors?.name?.message}
              error={Boolean(errors?.name?.message)}
            />
          </FormControl>
          <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
            <TextField
              id="outlined-basic"
              label="Email"
              helperText={errors.email?.message}
              error={Boolean(errors.email)}
              variant="outlined"
              {...register('email')}
            />
          </FormControl>
          <List sx={{ gridColumn: 'span 2', padding: 0 }}>
            {watch('customerCustomerEmailRelation')?.map((item, index) => (
                <ListItem
                  key={item.email}
                  sx={{
                    border: '1px solid #e0e0e0',
                    borderRadius: '5px',
                    marginBottom: '15px',
                  }}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      onClick={() => {
                        const newParts = getValues(
                          'customerCustomerEmailRelation',
                        );
                        if (newParts) {
                          newParts.splice(index, 1);
                          setValue('customerCustomerEmailRelation', newParts);
                        }
                      }}
                    >
                      <ClearIcon color="error" />
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemText
                    sx={{
                      margin: '7px 0 7px 15px',
                    }}
                    primary={item.email}
                  />
                </ListItem>
              ))}
            <FormControl
              variant="outlined"
              fullWidth
              sx={{ gridColumn: 'span 2' }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setModal({ ...modal, emails: !modal.emails });
                }}
              >
                Add Email Notifications
              </Button>
            </FormControl>
          </List>
          <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field }) => (
              <TextField
                {...field}
                label="Phone"
                helperText={errors?.phoneNumber?.message}
                error={Boolean(errors?.phoneNumber?.message)}
              />
            )}
          />
          </FormControl>
          <List sx={{ gridColumn: 'span 2', padding: 0 }}>
            {watch('customerPhoneNumberRelation') &&
              watch('customerPhoneNumberRelation')?.map((item, index) => (
                <ListItem
                  key={item.phoneNumber}
                  sx={{
                    border: '1px solid #e0e0e0',
                    borderRadius: '5px',
                    marginBottom: '15px',
                  }}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      onClick={() => {
                        const newPhones = getValues(
                          'customerPhoneNumberRelation',
                        );
                        if (newPhones) {
                          newPhones.splice(index, 1);
                          setValue('customerPhoneNumberRelation', newPhones);
                        }
                      }}
                    >
                      <ClearIcon color="error" />
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemText
                    sx={{
                      margin: '7px 0 7px 15px',
                    }}
                    primary={item.phoneNumber}
                  />
                </ListItem>
              ))}
            <FormControl
              variant="outlined"
              fullWidth
              sx={{ gridColumn: 'span 2' }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setModal({ ...modal, phones: !modal.phones });
                }}
              >
                Add Phone Number
              </Button>
            </FormControl>
          </List>

          <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems=""
              mt={2}
              sx={{ gridColumn: 'span 2' }}
            >
              <Typography variant="h5" sx={{ gridColumn: 'span 2' }}>
                Address
              </Typography>
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  pushAddres();
                }}
              >
                Add Address
              </Button>
            </Box>
          </FormControl>
          {getValues('customerAddressRelation').map((_, index) => {
            const customerAddressRelationError =
              typeof errors?.customerAddressRelation !== 'undefined' &&
              typeof errors?.customerAddressRelation[index] !== 'undefined'
                ? errors?.customerAddressRelation[index]
                : {};
            return (
              <>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  mt={2}
                  sx={{ gridColumn: 'span 2', padding: 0.5, marginTop: 0 }}
                >
                  <Card className={classes.card} elevation={5}>
                    <Grid container md={12} lg={12} sm={12} spacing={1}>
                      {index > 0 ? (
                        <>
                          <Grid container item md={6} lg={6} sm={6} spacing={5}>
                            <Grid item>
                              <Typography>{`Address ${index + 1}`}</Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            item
                            justifyContent="flex-end"
                            md={6}
                            lg={6}
                            sm={6}
                            spacing={10}
                          >
                            <Grid item>
                              <DeleteButton
                                variant="default"
                                onClick={() => {
                                  removeAddress(index);
                                }}
                              >
                                Remove
                              </DeleteButton>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <Grid
                          container
                          item
                          md={12}
                          lg={12}
                          sm={12}
                          spacing={5}
                        >
                          <Grid item>
                            <Typography>{`Address ${index + 1}`}</Typography>
                          </Grid>
                        </Grid>
                      )}

                      <Grid item container md={12} lg={12} sm={12}>
                        <TextField
                          fullWidth
                          label="Contact Name"
                          variant="outlined"
                          {...register(
                            `customerAddressRelation.${index}.contactName`,
                          )}
                        />
                      </Grid>
                      <Grid item container md={12} lg={12} sm={12}>
                        <TextField
                          {...register(`customerAddressRelation.${index}.contactPhone`)}
                          label="Phone Number"
                          fullWidth
                          helperText={errors?.customerAddressRelation?.[index]?.contactPhone?.message}
                          error={Boolean(errors?.customerAddressRelation?.[index]?.contactPhone?.message)}
                        />
                      </Grid>
                      <Grid item container md={12} lg={12} sm={12}>
                        <TextField
                          required
                          label="Street Address"
                          fullWidth
                          variant="outlined"
                          {...register(
                            `customerAddressRelation.${index}.streetAddress`,
                          )}
                          helperText={
                            customerAddressRelationError.streetAddress?.message
                          }
                          error={Boolean(
                            customerAddressRelationError.streetAddress,
                          )}
                        />
                      </Grid>

                      <Grid container item md={4} lg={4} sm={12}>
                        <TextField
                          required
                          fullWidth
                          label="City"
                          variant="outlined"
                          {...register(`customerAddressRelation.${index}.city`)}
                          helperText={
                            customerAddressRelationError.city?.message
                          }
                          error={Boolean(customerAddressRelationError.city)}
                        />
                      </Grid>

                      <Grid item container md={4} lg={4} sm={12}>
                        <TextField
                          required
                          fullWidth
                          label="State"
                          variant="outlined"
                          {...register(
                            `customerAddressRelation.${index}.state`,
                          )}
                          helperText={
                            customerAddressRelationError.state?.message
                          }
                          error={Boolean(customerAddressRelationError.state)}
                        />
                      </Grid>

                      <Grid item container md={4} lg={4} sm={12}>
                        <TextField
                          fullWidth
                          label="Zip"
                          variant="outlined"
                          {...register(
                            `customerAddressRelation.${index}.zipCode`,
                          )}
                          helperText={
                            customerAddressRelationError.zipCode?.message
                          }
                          error={Boolean(customerAddressRelationError.zipCode)}
                        />
                      </Grid>
                    </Grid>
                  </Card>
                </Box>
              </>
            );
          })}
          {!matchesSm ? (
            <>
              <FormControl fullWidth>
                <FormControlLabel
                  control={<Checkbox {...register('willLogin')} />}
                  label="Able Customer Login"
                />
              </FormControl>
              {featureFlag && (
                <FormControl fullWidth>
                  <Button
                    type="button"
                    variant="outlined"
                    startIcon={<AttachFileIcon />}
                    onClick={handleAttachFile}
                  >
                    Upload files
                  </Button>
                </FormControl>
              )}
            </>
          ) : (
            <>
              <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
                <FormControlLabel
                  control={<Checkbox {...register('willLogin')} />}
                  label="Able Customer Login"
                />
              </FormControl>
              {featureFlag && (
                <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
                  <Button
                    type="button"
                    variant="outlined"
                    startIcon={<AttachFileIcon />}
                    onClick={handleAttachFile}
                  >
                    Upload files
                  </Button>
                </FormControl>
              )}
            </>
          )}
          {featureFlag && documentUri && documentUri.length > 0 && (
            <List sx={{ gridColumn: 'span 2', padding: 0 }}>
              {documentUri &&
                documentUri?.map((item) => (
                  <ListItem
                    key={item.uploadId}
                    sx={{
                      border: '1px solid #e0e0e0',
                      borderRadius: '5px',
                      marginBottom: '15px',
                    }}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        onClick={() => {
                          setDocumentUri([]);
                        }}
                      >
                        <ClearIcon color="error" />
                      </IconButton>
                    }
                    disablePadding
                  >
                    <ListItemText
                      sx={{
                        margin: '7px 0 7px 15px',
                      }}
                      primary={item.filename}
                    />
                  </ListItem>
                ))}
            </List>
          )}
          <Box
            display="flex"
            alignItems="center"
            sx={{
              justifyContent: ['space-between', 'flex-end'],
              gridColumn: 'span 2',
              marginBottom: '20px',
              '&>*': {
                marginLeft: 2,
              },
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              loading={loading || Boolean(newData?.customerCreate)}
              style={{ marginLeft: 24 }}
              type="submit"
            >
              Create
            </LoadingButton>
          </Box>
        </FormImageContainer>
      </BigPaper>

      <Dialog open={open}>
        {error !== undefined && (
          <>
            <DialogTitle id="warningTitle">Something went wrong</DialogTitle>
            <DialogActions>
              <Button
                color="primary"
                autoFocus
                onClick={() => {
                  setOpen(false);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}
