import { Typography, Box, IconButton, Link } from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Pill, PillsTypeProp } from './Pills';

const jobStates = {
  NEW: 'NEW',
  QUOTE: 'QUOTE',
  Q_SUBMITTED: 'Q SUBMITTED',
  PENDING: 'PENDING',
  DISPATCHED: 'DISPATCHED',
  READY_FOR_RETURN: 'READY_FOR_RETURN',
  ON_ROUTE: 'ON_ROUTE',
  ON_SITE: 'ON_SITE',
  CLOSED: 'CLOSED',
  PAUSED: 'PAUSED',
} as const;
const states = ['active', 'yellow', 'archived'] as const;

/**
 * @param root0 - Props.
 * @param root0.type - Prost text.
 * @param root0.name - Prost text.
 * @param root0.description - Prost text.
 * @param root0.id - Prost text.
 * @param root0.email - Prost text.
 * @param root0.status - Prost text.
 * @param root0.number - Prost text.
 * @param root0.action - Prost text.
 * @param root0.code - Prost text.
 * @param root0.zip - Prost text.
 * @param root0.street - Prost text.
 * @param root0.time - Prost text.
 * @param root0.fieldworker - Prost text.
 * @param root0.typeClock - Prost text.
 * @param root0.date - Prost text.
 * @param root0.price - Prost text.
 * @param root0.location - Prost text.
 * @param root0.location.coordinates - Prost text.
 * @param root0.partNumber - Props text.
 * @param root0.unit - Props text.
 * @param root0.downloadUrl - Props text.
 * @returns {JSX.Element} - Main loader component.
 */
export function RowResponsive({
  type,
  name,
  description,
  id,
  email,
  status,
  number,
  action,
  code,
  zip,
  street,
  typeClock,
  fieldworker,
  time,
  date,
  location,
  price,
  partNumber,
  unit,
  downloadUrl,
}: {
  type?:
    | 'fieldworker'
    | 'job'
    | 'customer'
    | 'address'
    | 'clocks'
    | 'jobHistory'
    | 'parts'
    | 'document';
  name?: string;
  description?: string;
  id?: string;
  email?: string;
  status?: string;
  number?: string;
  code?: number | string;
  zip?: number | string;
  street?: number | string;
  date?: number | string;
  action?: React.MouseEventHandler<HTMLButtonElement>;
  typeClock?: string;
  fieldworker?: string;
  time?: string;
  price?: number | string;
  location?: {
    coordinates: string[];
  };
  partNumber?: string;
  unit?: string;
  downloadUrl?: string;
}): JSX.Element {
  const keys = Object.keys(jobStates);
  const index = status !== undefined ? keys.indexOf(status) : '';
  if (type === 'address') {
    return (
      <Box
        sx={{
          padding: '15px 20px',
          backgroundColor: '#fff',
          border: '0.3px solid #8392AB',
          boxSizing: 'border-box',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '10px',
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6" color="secondary">
            {name}
          </Typography>

          <Typography variant="body1" gutterBottom>
            <b>#{code}</b>
          </Typography>
        </Box>
        <Typography variant="body1" gutterBottom>
          <b>State:</b> {email}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>City:</b> {number}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>Street:</b> {street}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>ZIP:</b> {zip}
        </Typography>
      </Box>
    );
  }

  if (type === 'clocks') {
    return (
      <Box
        sx={{
          padding: '15px 20px',
          backgroundColor: '#fff',
          border: '0.3px solid #8392AB',
          boxSizing: 'border-box',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '10px',
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body1" gutterBottom>
            <b>#{code}</b>
          </Typography>
        </Box>
        <Typography variant="body1" gutterBottom>
          <b>Type Clock:</b> {typeClock}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>Fieldworker:</b> {fieldworker}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>Time:</b> {time}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>Location: </b>
          {location ? (
            <a
              href={`https://www.google.com/maps/place/${location.coordinates[0]},${location.coordinates[1]}`}
              rel="noreferrer"
              target="_blank"
            >
              Map Link
            </a>
          ) : (
            ''
          )}
        </Typography>
      </Box>
    );
  }

  if (type === 'parts') {
    return (
      <Box
        sx={{
          padding: '15px 20px',
          backgroundColor: '#fff',
          border: '0.3px solid #8392AB',
          boxSizing: 'border-box',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '10px',
          marginTop: 1,
        }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" flexDirection="column">
          <Typography variant="body1">Part Name: {name}</Typography>
          <Typography variant="body1">Part Number: {partNumber}</Typography>
          <Typography variant="body1">Unit: {unit}</Typography>
          {price && (
            <Typography variant="body1" gutterBottom>
              <b>Price:</b> {price}
            </Typography>
          )}
        </Box>
      </Box>
    );
  }
  if (type === 'job') {
    return (
      <Box
        sx={{
          padding: '15px 20px',
          backgroundColor: '#fff',
          border: '0.3px solid #8392AB',
          boxSizing: 'border-box',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '10px',
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Link href={`/jobs/${id}`} underline="none" overflow='hidden'>
            <Typography variant="h6" color="secondary" textOverflow='ellipsis' noWrap>
              {name}
            </Typography>
          </Link>

          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{ alignItems: 'center' }}
          >
            <Typography variant="body1" gutterBottom>
              <b>#{code}</b>
            </Typography>
            {action && (
              <IconButton onClick={action}>
                <MoreVertIcon />
              </IconButton>
            )}
          </Box>
        </Box>
        {email ? (
          <Typography
            variant="body1"
            gutterBottom
            sx={{ wordWrap: 'break-word' }}
          >
            <b>Customer Name:</b> {email}
          </Typography>
        ) : (
          <Typography variant="body1" gutterBottom>
            <b>Address:</b> {street}
          </Typography>
        )}
        <Typography variant="body1" gutterBottom>
          <b>Fieldworker:</b> {fieldworker}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>Start Date:</b> {date}
        </Typography>

        <Box display="flex" justifyContent="flex-end">
          <Pill
            text={status?.replaceAll('_', ' ') ?? ''}
            type={index as PillsTypeProp}
          />
        </Box>
      </Box>
    );
  }

  if (type === 'jobHistory') {
    return (
      <Box
        sx={{
          padding: '15px 20px',
          backgroundColor: '#fff',
          border: '0.3px solid #8392AB',
          boxSizing: 'border-box',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '10px',
          marginTop: 2,
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Link href={`/jobs/${id}`} underline="none">
            <Typography color="secondary">{name}</Typography>
          </Link>

          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{ alignItems: 'center' }}
          >
            <Typography variant="body1" gutterBottom>
              <b>#{code}</b>
            </Typography>
          </Box>
        </Box>

        <Typography variant="body1" gutterBottom>
          <b>Description:</b> {description}
        </Typography>

        <Typography variant="body1" gutterBottom>
          <b>Date:</b> {date}
        </Typography>

        <Box display="flex" justifyContent="flex-end">
          <Pill
            text={status === undefined ? '' : status}
            type={index as PillsTypeProp}
          />
        </Box>
      </Box>
    );
  }
  if (type === 'customer') {
    return (
      <Box
        sx={{
          padding: '15px 20px',
          backgroundColor: '#fff',
          border: '0.3px solid #8392AB',
          boxSizing: 'border-box',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '10px',
        }}
      >
        <Box>
          <Box display="flex" justifyContent="space-between">
            <Link href={`customers/details?id=${id}`} underline="none">
              <Typography variant="h6" color="secondary">
                {name}
              </Typography>
            </Link>
            <IconButton onClick={action}>
              <MoreVertIcon />
            </IconButton>
          </Box>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ wordWrap: 'break-word' }}
          >
            <b>Email:</b> {email}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>Customer Since:</b> {number}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Pill
            text={status === undefined ? '' : status}
            type={
              states.findIndex((state) => state === status) as PillsTypeProp
            }
          />
        </Box>
      </Box>
    );
  }
  if (type === 'document') {
    return (
      <Box
        sx={{
          padding: '15px 20px',
          backgroundColor: '#fff',
          border: '0.3px solid #8392AB',
          boxSizing: 'border-box',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '10px',
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6" color="secondary">
            {name?.slice(0, 15)}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <a href={downloadUrl} target="_blank" rel="noreferrer">
              <SaveAltIcon />
            </a>
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>#{code}</b>
          </Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        padding: '15px 20px',
        backgroundColor: '#fff',
        border: '0.3px solid #8392AB',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px',
      }}
    >
      <Box>
        <Box display="flex" justifyContent="space-between">
          <Link href={`fieldworker/detail/${id}`} underline="none">
            <Typography variant="h6" color="secondary">
              {name}
            </Typography>
          </Link>
          <IconButton onClick={action}>
            <MoreVertIcon />
          </IconButton>
        </Box>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ wordWrap: 'break-word' }}
        >
          <b>Email:</b> {email}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>Phone Number:</b> {number}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Pill
          text={status?.replace('_', ' ') ?? ''}
          type={states.findIndex((state) => state === status) as PillsTypeProp}
        />
      </Box>
    </Box>
  );
}
