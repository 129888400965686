/* eslint-disable no-restricted-syntax */
/* eslint-disable jsdoc/require-jsdoc */
import { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

type UseQueryReturnType<T extends Record<string, string | number>> = {
  query: T | undefined;
  searchQuery: (values: Partial<T>) => void;
  resetQuery: () => void;
};

export const useQuery = <
  T extends Record<string, string | number>,
>(): UseQueryReturnType<T> => {
  const history = useHistory();
  const location = useLocation();
  const params = useMemo<Record<string, string | number> | undefined>(() => {
    const searchParams = new URLSearchParams(location.search);
    let values: Record<string, string | number> | undefined;
    for (const [key, value] of searchParams.entries()) {
      values = {
        ...(values || {}),
        [key]: value,
      };
    }

    return values;
  }, [location]);

  const searchQuery = (values: Partial<T>): void => {
    const mergeParams = { ...(params || {}), ...values };
    const keys = Object.keys(mergeParams);
    let search = '';
    keys.forEach((key) => {
      if (mergeParams[key]) {
        search = `${search}${search.length ? '&' : ''}${key}=${
          mergeParams[key]
        }`;
      }
    });
    history.push({
      search: `?${search}`,
    });
  };

  const resetQuery = (): void => {
    history.push({
      search: '',
    });
  };

  return {
    query: params as T,
    searchQuery,
    resetQuery,
  };
};

export const useQueryActive = <T extends Record<string, string | number>>(
  query?: T,
): boolean => {
  const isActive = useMemo(() => {
    const queryValues = { ...(query || {}) };
    // add here the elements in the query that you dont want to active the button.
    delete queryValues.rows;
    delete queryValues.page;
    delete queryValues.search;
    const hasAlmostOneProperty = Object.keys(queryValues).length;
    return Boolean(hasAlmostOneProperty) && Boolean(queryValues);
  }, [query]);
  return isActive;
};
