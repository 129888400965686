import { Box, Divider, Skeleton } from '@mui/material';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import { IconWrapper, MessageWrapper, NotifBody } from './NotificationItem';

export const NotificationSkeleton = (): JSX.Element => (
  <Box>
    <>
      <Box sx={{
        opacity: 0.8,
      }}>
        <MessageWrapper>
          <IconWrapper>
            <AcUnitIcon fontSize='large' />
          </IconWrapper>
          <NotifBody>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" width='50%' />
          </NotifBody>
        </MessageWrapper>
      </Box>
      <Divider sx={{
        opacity: 0.8,
      }}/>
    </>
    <>
    <Box sx={{
        opacity: 0.7,
      }}>
        <MessageWrapper>
          <IconWrapper>
            <AcUnitIcon fontSize='large' />
          </IconWrapper>
          <NotifBody>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" width='50%' />
          </NotifBody>
        </MessageWrapper>
      </Box>
      <Divider sx={{
        opacity: 0.7,
      }}/>
    </>
    <>
    <Box sx={{
        opacity: 0.6,
      }}>
        <MessageWrapper>
          <IconWrapper>
            <AcUnitIcon fontSize='large' />
          </IconWrapper>
          <NotifBody>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" width='50%' />
          </NotifBody>
        </MessageWrapper>
      </Box>
      <Divider sx={{
        opacity: 0.6,
      }}/>
    </>
    <>
    <Box sx={{
        opacity: 0.5,
      }}>
        <MessageWrapper>
          <IconWrapper>
            <AcUnitIcon fontSize='large' />
          </IconWrapper>
          <NotifBody>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" width='50%' />
          </NotifBody>
        </MessageWrapper>
      </Box>
      <Divider sx={{
        opacity: 0.5,
      }}/>
    </>
    <>
    <Box sx={{
        opacity: 0.4,
      }}>
        <MessageWrapper>
          <IconWrapper>
            <AcUnitIcon fontSize='large' />
          </IconWrapper>
          <NotifBody>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" width='50%' />
          </NotifBody>
        </MessageWrapper>
      </Box>
      <Divider sx={{
        opacity: 0.4,
      }}/>
    </>
    <>
    <Box sx={{
        opacity: 0.3,
      }}>
        <MessageWrapper>
          <IconWrapper>
            <AcUnitIcon fontSize='large' />
          </IconWrapper>
          <NotifBody>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" width='50%' />
          </NotifBody>
        </MessageWrapper>
      </Box>
      <Divider sx={{
        opacity: 0.3,
      }}/>
    </>
    <>
    <Box sx={{
        opacity: 0.2,
      }}>
        <MessageWrapper>
          <IconWrapper>
            <AcUnitIcon fontSize='large' />
          </IconWrapper>
          <NotifBody>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" width='50%' />
          </NotifBody>
        </MessageWrapper>
      </Box>
      <Divider sx={{
        opacity: 0.2,
      }}/>
    </>
    <>
    <Box sx={{
        opacity: 0.1,
      }}>
        <MessageWrapper>
          <IconWrapper>
            <AcUnitIcon fontSize='large' />
          </IconWrapper>
          <NotifBody>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" width='50%' />
          </NotifBody>
        </MessageWrapper>
      </Box>
      <Divider sx={{
        opacity: 0.1,
      }}/>
    </>
  </Box>
  );
