import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {parsePhoneNumber} from 'awesome-phonenumber';
import { PHONE_REGION_CODE } from '../constants/common';

const ErrorMessage = 'Invalid phone number';

type PhoneInputModalFormType = {
  phoneNumber: string;
  id: string;
}

type PhoneNumberInputModalProps = {
  onComfirm: (values: PhoneInputModalFormType) => void;
  onCancel: () => void;
  isVisible: boolean;
}

export const PhoneNumberInputModal: React.FC<PhoneNumberInputModalProps> = ({
  onComfirm,
  onCancel,
  isVisible
}) => {
  const [value, setValue] = useState<string>('');
  const [error, setError] = useState(false);


  const handlerSubmitInput = (): void => {
    if(!value || error){
      return;
    }
    onComfirm({
      phoneNumber: value,
      id: `${new Date().getTime()}-${value}`  
    });
    setValue('');
  };

  const onCloseModal = (): void =>{ 
    onCancel();
    setValue('');
    setError(false);
  };

  return (
    <Dialog
      open={isVisible}
      onClose={onCloseModal}
    >
      <DialogTitle id="responsive-dialog-title">Add Additional Phone Number</DialogTitle>
      <DialogContent>
        <FormControl variant="outlined" fullWidth sx={{ marginTop: '20px' }}>
        <TextField
          label="Phone Number"
          helperText={error && ErrorMessage}
          error={error}
          value={value}
          onChange={(_value) => {
            if(_value.target.value){
              const formatedValue = parsePhoneNumber(_value.target.value, {
                regionCode: PHONE_REGION_CODE,
              });
              if (
                !formatedValue.valid &&
                !error
              ) {
                setError(true);
              }
              if (
                formatedValue.valid &&
                error
              ) {
                setError(false);
              }
            }
            setValue(_value.target.value);
          }}
        />
        </FormControl>
        <Stack mt={3} direction="row" justifyContent="flex-end">
        <Button
            variant="default"
            onClick={onCloseModal}
          >
            Cancel
          </Button>
          <Button
            disabled={error || value.length <= 0}
            onClick={handlerSubmitInput}
            type="submit"
            color="primary"
          >
            Add
          </Button>
        </Stack>
        
      </DialogContent>
    </Dialog>
  );
};