import React from 'react';
import { CustomerHeader } from './CustomerHeader';
import { CreateCustomerForm } from './CreateCustomerForm';

/**
 * @returns {JSX.Element} - Customer view.
 */
export function CreateCustomerView(): JSX.Element {
  return (
    <>
      <CustomerHeader />
      <CreateCustomerForm />
    </>
  );
}
