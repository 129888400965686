import { Box } from '@mui/material';
import React from 'react';
import { GRAY_LIGHT } from '../../../shared/css/theme';

/**
 * @param {string} props - Today date.
 * @param {string} props.rows - Today date.
 * @returns {JSX.Element} - Calendar View.
 */
export function WhiteBoards({ rows }: { rows: number }): JSX.Element {
  return (
    <>
      {Array(rows)
        .fill(0)
        .map((_item, index) => (
          <Box
            sx={{
              borderBottom: `1px solid ${GRAY_LIGHT}`,
              borderRight: `1px solid ${GRAY_LIGHT}`,
              width: 'auto',
              minWidth: '100px',
              height: '100%',
            }}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            {' '}
          </Box>
        ))}
    </>
  );
}
