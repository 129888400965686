import { useEffect } from 'react';
import {
  useListFieldworkersQuery,
  ListFieldworkersQuery,
  ListFieldworkersQueryVariables,
  FieldworkerListSubscriptionDocument,
} from '../../shared/types/generated';

/**
 * @returns {ListFieldworkersQuery} - Hook query customer.
 * @param {ListFieldworkersQueryVariables} variables - Variables.
 */
export function useCustomerListSuscriptionHook(
  variables: ListFieldworkersQueryVariables,
): {
  loading: boolean;
  data: ListFieldworkersQuery | undefined;
} {
  const { loading, data, refetch, subscribeToMore } = useListFieldworkersQuery({
    variables,
  });

  useEffect(() => {
    subscribeToMore({
      document: FieldworkerListSubscriptionDocument,
      variables: {
        filter: variables.filter,
      },
      /**
       * @param listData - Data.
       * @returns Data.
       */
      updateQuery: (listData) => {
        refetch();

        return listData;
      },
    });
  }, [refetch, subscribeToMore, variables.filter]);

  return { loading, data };
}
