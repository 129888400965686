import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  Backdrop,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Modal,
  styled,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFieldworkerUpdateMutation } from '../../shared/types/generated';
import { snackbar } from '../../shared/components/Snackbar';

import { clearQuerysFromCache } from '../../shared/utils';

const ModalBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30%',
  background: '#FFFFFF',
  boxShadow: '0px 3px 0px #EDEDF6',
  borderRadius: '10px',
  [theme.breakpoints.down('md')]: {
    width: '90%',
  },
}));

/**
 * @param {Object} params - Variants of the modal.
 * @param {boolean} params.open - Open or Close modal.
 * @param {boolean} params.id - Id of fieldwoker.
 * @param {boolean} params.back - Back to table.
 * @param {boolean} params.action - Text in the modal.
 * @param {boolean} params.archived - Change status.
 * @returns {JSX.Element} - Modal for changee status fieldworker.
 */
export function AlertFieldworker(params: {
  open: boolean;
  back?: boolean;
  id?: string | undefined;
  action?: string;
  archived?: string | null;
}): JSX.Element {
  const { open, id, back, action, archived } = params;
  const [open1, setOpen] = React.useState(false);
  const history = useHistory();

  const [deleteFieldworker, { loading, error }] = useFieldworkerUpdateMutation({
    /**
     * @returns {void} - Result.
     */
    onCompleted: () => {
      if (error === undefined) {
        snackbar.success('Fieldworker archived');
        setOpen(false);
        if (back) history.goBack();
      }
    },

    /**
     * @param cache - Apollo chache.
     */
    update: clearQuerysFromCache(['fieldworkersList']),
  });
  useEffect(() => {
    setOpen(open);
  }, [open]);
  /**
   * @returns {void} - Handle the more button event close.
   */
  const handleClose = (): void => {
    setOpen(false);
  };
  /**
   * @param isArchived - Check if fw archived.
   * @returns {void} - Delete fieldworker.
   */
  const deleteFieldworkerById = (isArchived: boolean): void => {
    const today = dayjs(new Date()).toISOString();
    deleteFieldworker({
      variables: {
        data: {
          id,
          archived: !isArchived ? today : (null as unknown as string),
        },
      },
    });
  };
  if (action) {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open1}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open1}>
          <ModalBox>
            <DialogTitle color="#969CBA" id="alert-dialog-title">
              {`fieldworker ${action}`}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {`Fieldworker ${action} successful.`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => history.replace('/fieldworker')}
                color="primary"
                autoFocus
              >
                Close
              </Button>
            </DialogActions>
          </ModalBox>
        </Fade>
      </Modal>
    );
  }
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open1}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open1}>
        <ModalBox>
          <DialogTitle color="#969CBA" id="alert-dialog-title">
            Are you sure?
          </DialogTitle>
          <DialogContent sx={{ color: '#969CBA' }}>
            {archived ? 'This Customer will be activated' : 'By archiving this fieldworker, they will lose access to the platform. Proceed with archiving?'}
          </DialogContent>
          <DialogActions>
            <Button variant="default" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton
              variant={archived ? 'contained' : 'delete'}
              loading={loading}
              onClick={() => deleteFieldworkerById(Boolean(archived))}
              color="primary"
              autoFocus
            >
              {archived ? 'Active' : 'Archive'}
            </LoadingButton>
          </DialogActions>
        </ModalBox>
      </Fade>
    </Modal>
  );
}
