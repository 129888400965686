import React, { useEffect } from 'react';
import { Box, Button, CircularProgress, FormControl, TextField, Typography } from '@mui/material';
import { error as errorLogger } from '@cobuildlab/pure-logger';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useHistory, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormImageContainer, FormViewConatiner } from '../../shared/components/Containers';
import logo from '../../shared/assets/fieldworker.svg';
import { UserUpdateInput, useAdminDetailQuery, useAdminUpdateMutation } from '../../shared/types/generated';
import { snackbar } from '../../shared/components/Snackbar';
import { getPhoneNumberFomartToSaveInDB, getPhoneNumberWithOutRegionCode } from '../../shared/utils/phone';
import { ADMIN_FORM_VALIDATOR_SCHEMA, EditAdminType } from './admin-types';
import { defaultAdminFormValues } from './admin-utils';
import { EMAIL_REGREX } from '../../shared/constants/common';

export const AdminEditView: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { control, handleSubmit, formState: { errors }, setValue } = useForm<EditAdminType>({
    defaultValues: defaultAdminFormValues,
    resolver: yupResolver(ADMIN_FORM_VALIDATOR_SCHEMA, {})
  });
  const { data: adminData, loading: isFetchingData } = useAdminDetailQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: id as string,
    }
  });
  const hasFormErrors = Boolean(Object.keys(errors)?.length);

  const [editAdmin, { loading }] = useAdminUpdateMutation({
    onCompleted: () => {
      snackbar.success('Admin edited');
      history.push('/admin');
    },
    onError: (err: Error) => {
      console.log(JSON.stringify(err));
      errorLogger(err);
    },
  });

  useEffect(() => {
    if(adminData){
      setValue('firstName', adminData?.user?.firstName);
      setValue('lastName', adminData?.user?.lastName);
      setValue('email', adminData?.user?.email);
      setValue('phone', getPhoneNumberWithOutRegionCode(adminData?.user?.phone));
    }
  }, [adminData, setValue]);

  const handleSubmitForm = handleSubmit((data) : void => {
    const phoneNumberFormatToSaveInDataBD = getPhoneNumberFomartToSaveInDB(data.phone);
    const dataVariable: UserUpdateInput = { 
      ...data, 
      id,
      phone: phoneNumberFormatToSaveInDataBD 
    };
    editAdmin({
      variables: { 
        data:  {
          ...dataVariable
        },
      }
    });
  });
  const adminName = adminData?.user?.firstName && (`${adminData?.user?.firstName} ${adminData?.user?.lastName}`) || adminData?.user?.email;
  return (
  <FormViewConatiner
  title="Edit Admin"
  subtitle={adminName || ''}
>
  <Typography
    variant="h5"
    sx={{ display: [null, 'none'], gridColumn: 'span 2' }}
  >
    Information
  </Typography>
  <FormImageContainer imagePath={logo} onSubmit={handleSubmitForm}>
    <Typography
      variant="h5"
      sx={{ display: ['none', 'block'], gridColumn: 'span 2' }}
    >
      Information
    </Typography>
    <FormControl
      variant="outlined"
      fullWidth
      sx={{ gridColumn: 'span 2', marginTop: '10px', position: 'relative' }}
    >
      <Controller 
        control={control}
        name="firstName"
        rules={{
          required: {
            value: true,
            message: 'First name is required'
          }
        }}
        render={({ field }) => (
        <TextField
          {...field}
          InputProps={{ endAdornment: isFetchingData ? <CircularProgress size={20} /> : undefined }}
          label="First Name"
          helperText={errors?.firstName?.message}
          error={Boolean(errors?.firstName?.message)}
        />
        )}
      />
    </FormControl>
    <FormControl
      variant="outlined"
      fullWidth
      sx={{ gridColumn: 'span 2', marginTop: '10px', position: 'relative' }}
    >
      <Controller 
        control={control}
        name="lastName"
        rules={{
          required: {
            value: true,
            message: 'Last name is required'
          }
        }}
        render={({ field }) => (
        <TextField
          {...field}
          InputProps={{ endAdornment: isFetchingData ? <CircularProgress size={20} /> : undefined }}
          label="Last Name"
          helperText={errors?.lastName?.message}
          error={Boolean(errors?.lastName?.message)}
        />
        )}
      />
    </FormControl>
    <FormControl
      variant="outlined"
      fullWidth
      disabled
      sx={{ gridColumn: 'span 2', marginTop: '10px', position: 'relative' }}
    >
      <Controller
        control={control}
        name="email"
        rules={{
          required: {
            value: true,
            message: 'Email required',
          },
          maxLength: {
            value: 100,
            message: 'Email must be at most 100 characters',
          },
          pattern: {
            value: EMAIL_REGREX,
            message: 'Invalid email address',
          },
        }}
        render={({ field }) => (
          <TextField
            {...field}
            InputProps={{ endAdornment: isFetchingData ? <CircularProgress size={20} /> : undefined }}
            disabled
            label="Email"
            helperText={errors?.email?.message}
            error={Boolean(errors?.email?.message)}
          />
        )}
      />
    </FormControl>
    <FormControl
      variant="outlined"
      fullWidth
      sx={{ gridColumn: 'span 2', marginTop: '10px', position: 'relative' }}
    >
        <Controller
        control={control}
        name="phone"
        render={({ field }) => (
          <TextField
            {...field}
            InputProps={{ endAdornment: isFetchingData ? <CircularProgress size={20} /> : undefined }}
            label="Phone Number"
            helperText={errors?.phone?.message}
            error={Boolean(errors?.phone?.message)}
          />
        )}
      />
    </FormControl>
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        gridColumn: 'span 2',
        position: ['static', 'absolute'],
        bottom: '40px',
        right: '100px',
        width: ['100%', '210px'],
        marginTop: ['20px', '0'],
      }}
    >
      <Button
        variant="outlined"
        onClick={() => {
          history.goBack();
        }}
      >
        Cancel
      </Button>
      <LoadingButton disabled={hasFormErrors} variant="contained" loading={loading} type="submit">
        Save
      </LoadingButton>
    </Box>
  </FormImageContainer>
</FormViewConatiner>
  );
};
