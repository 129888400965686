import { useMemo } from 'react';
import {
  ListItemIcon,
  styled,
  ListItemText,
  ListItemButton,
  Typography,
  Box,
} from '@mui/material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useLocation, Link } from 'react-router-dom';
import MuiDrawer from '@mui/material/Drawer';
import MuiListItem from '@mui/material/ListItem';
import MuiList from '@mui/material/List';
import { red, common } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';

import CoolriteLogo from '../../assets/Coolrite-logo.png';
import { Icon, IconNames } from '../icons/Icon';
import { useUserRoutes } from '../../../modules/session/session-hooks';
import { User } from '../../types/types';
import { USER_ROLES } from '../../../modules/session/session-model';
import { UseValidateFeatureFlag } from '../../../modules/auth/auth-hooks';
import { FeatureFlags } from '../../constants/featureFlag';


const ACTIVE_ICON_COLOR = red['900'];

const PAPER_PROPS = {
  sx: {
    position: 'relative',
    bgcolor: 'transparent',
    overflow: 'hidden',
    borderRight: 'none',
  },
  elevation: 0,
};

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 0),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(1),
  position: 'sticky',
  top: 0,
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
}));

const Image = styled('img')({
  width: 50,
  height: 'auto',
  padding: 8,
  borderRadius: 12,
  backgroundColor: 'rgb(94, 178, 207)',
});

const Drawer = styled(MuiDrawer)(({ theme }) => ({
  width: '80%',
  backgroundColor: theme.palette.primary.main,
}));

const List = styled(MuiList)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  height: '100%',
  overflowY: 'auto',
}));

const BottomList = styled(MuiList)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  position: 'sticky',
  left: 0,
  bottom: 0,
  width: '100%',
  borderTop: `1px solid ${theme.palette.grey[700]}`
}));

const ListItem = styled(MuiListItem, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>(({ theme, isActive }) => ({
  borderRadius: 10,
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
  transition: theme.transitions.create(['backgroundColor', 'color'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  '&:hover': {
    backgroundColor: theme.palette.common.white,
  },
  '&:hover svg': { color: ACTIVE_ICON_COLOR },
  ...(isActive && {
    backgroundColor: theme.palette.common.white,
  }),
}));

export const MobileNavMenu: React.FC< {
  open: boolean;
  close: () => void;
  currentUser?: User;
}> = ({
  open,
  close,
  currentUser,
}) => {
  const featureFlagUserInfo = UseValidateFeatureFlag(FeatureFlags.USER_DISPLAYED_INFO);
  const location = useLocation();
  const routes = useUserRoutes();
  const userInfo = useMemo(() => {
    if (!featureFlagUserInfo) return null;
    const userRoles = currentUser?.roles?.items;
    let name: string;
    let role: string | undefined;
    if (currentUser?.fielworker) {
      name = currentUser?.fielworker?.fullName || '';
    } else if (currentUser?.firstName) {
      name = `${currentUser?.firstName} ${currentUser?.lastName}`;
    } else {
      name = currentUser?.email || '';
    }

    if (userRoles?.length === 1) {
      role = userRoles[0].name;
    } else if (userRoles?.find((item) => item.name === USER_ROLES.SUPER_ADMIN)){
      role = userRoles?.find((item) => item.name === USER_ROLES.SUPER_ADMIN)?.name;
    } else {
      role = userRoles?.find((item) => item.name === USER_ROLES.ADMIN)?.name; 
    }
    

    return {
      userName: name,
      userRole: role,
    };
  }, [currentUser, featureFlagUserInfo]);
  return (
    <Drawer anchor="left" open={open} onClose={close} PaperProps={PAPER_PROPS}>
      <DrawerHeader>
        <Stack direction="row" spacing={2} alignItems="center">
          <Image src={CoolriteLogo} alt="logo" />
          <Typography variant="h6" color="white">
            Coolrite
          </Typography>
        </Stack>
        <IconButton onClick={close}>
          <CloseIcon sx={{ color: 'GrayText' }} />
        </IconButton>
      </DrawerHeader>
      {featureFlagUserInfo && currentUser && <Box sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        height: '6rem !important',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(1),
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
      })}>
        <Typography variant='subtitle1' color='#ffff'>
          {userInfo?.userName}
        </Typography>
        {userInfo?.userRole && <Typography variant='subtitle2' >
          {userInfo.userRole}
        </Typography>}
      </Box>}
      <List >
        {routes.filter((item) => item.icon).map((route) => (
            <ListItem   key={route.path} isActive={location.pathname.indexOf(route.path) >= 0}>
              <ListItemButton onClick={close}  to={route.path}
                component={Link}>
                <ListItemIcon sx={{ marginRight: '4px' }}>
                  <Icon
                    name={route.icon as IconNames}
                    sx={(theme) => ({
                      fontSize: 24,
                      color:
                        location.pathname.indexOf(route.path) >= 0
                          ? ACTIVE_ICON_COLOR
                          : common.white,
                      transition: theme.transitions.create(
                        ['backgroundColor', 'color'],
                        {
                          easing: theme.transitions.easing.sharp,
                          duration: theme.transitions.duration.enteringScreen,
                        },
                      ),
                    })}
                  />
                </ListItemIcon>
                <ListItemText primary={route.text} />
              </ListItemButton>
            </ListItem>
        ))}
      </List>
      <BottomList>
      <MuiListItem>
              <ListItemButton component={Link}
to="/logout">
                <ListItemIcon sx={{ marginRight: '4px' }}>
                  <PowerSettingsNewIcon
                    sx={{
                      fontSize: 24,
                      color: common.white,
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </MuiListItem>
      </BottomList>
    </Drawer>
  );
};