import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { snackbar } from '../shared/components/Snackbar';

type CacheBusterReturn = {
  loading: boolean;
  newVersionAvailable: boolean;
  reload: () => void;
};

/**
 *
 */
const refreshCacheAndReload = async (): Promise<void> => {
  try {
    if (window?.caches) {
      const { caches } = window;
      const cacheNames = await caches.keys();

      cacheNames.forEach((cacheName) => {
        caches.delete(cacheName);
      });
      // log(isVerboseMode, 'The cache has been deleted.');
      window.location.reload();
    }
  } catch (error) {
    snackbar.warning(
      'Error Occured when trying to update the App please reload the page',
    );
  }
};

/**
 * @param currentHash - Current version set at build time for the app.
 * @returns Hook state.
 */
export function useCacheBuster(currentHash: string): CacheBusterReturn {
  const [cacheStatus, setCacheStatus] = useState({
    loading: false,
    newVersionAvailable: false,
  });

  const checkCacheStatus = useCallback(async (): Promise<void> => {
    try {
      const timestamp = dayjs().unix();
      const res = await fetch(`/meta.json?${timestamp}`);

      const { hash: newHash } = await res.json();

      const shouldForceRefresh = newHash !== currentHash;

      setCacheStatus({
        loading: false,
        newVersionAvailable: shouldForceRefresh,
      });
    } catch (error) {
      console.log(error);
    }
  }, [currentHash]);

  useEffect(() => {
    /**
     * On window blur handler.
     */
    const onBlur = (): void => {
      if (cacheStatus.newVersionAvailable) {
        refreshCacheAndReload();
      }
    };

    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('blur', onBlur);
    };
  }, [cacheStatus.newVersionAvailable]);

  useEffect(() => {
    checkCacheStatus();
  }, [checkCacheStatus]);

  return { ...cacheStatus, reload: refreshCacheAndReload };
}
