import * as yup from 'yup';
import { phoneNumberSchemaValidation } from '../../shared/utils/phone';

export const PASSWORD_FORM_VALIDATOR_SCHEMA = yup
  .object({
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
        'Invalid password',
      )
      .required('Password is required'),
    confirmPassword: yup
      .string()
      .test('passwords-match', 'Passwords must match', function match(value) {
        return this.parent.password === value;
      }),
  })
  .required();

export const FIELDWORKER_FORM_VALIDATOR_SCHEMA = yup
  .object({
    id: yup.string(),
    fullName: yup.string().required('Name is required'),
    email: yup.string().email().required('Email is required'),
    phoneNumber: yup
      .string()
      .test(
        'phoneNumberValidation',
        'Invalid phone number',
        (value) => phoneNumberSchemaValidation.isValid(value),
      )
      .required('Phone is required'),
    color: yup.string(),
  })
  .required();

export type FieldWorkerFiltersType = {
  from?: string;
  to?: string;
  status?: string;
  search?: string;
  rows: number;
  page: number;
};
