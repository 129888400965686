/* eslint-disable jsdoc/require-jsdoc */
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  InputLabel,
  Stack,
  FormControl,
  Select,
  Typography,
  Button,
  Menu,
  MenuItem,
  useMediaQuery,
  Fab,
  IconButton,
  Link,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { AlertFieldworker } from './AlertFieldworker';
import { Pill } from '../../shared/components/Pills';
import { ResetPassword } from './components/ResetPassword';
// funtions o hooks
import { useQuery } from '../../shared/utils/route';
import { searchFieldworker } from './Fieldworker-actions';
import { Pagination } from '../../shared/components/Pagination';
import { ListViewConatiner } from '../../shared/components/Containers';
import { RowResponsive } from '../../shared/components/RowResponsive';
import { SortOrder, ListFieldworkersQuery } from '../../shared/types/types';
import { useCustomerListSuscriptionHook } from './fieldworker-hooks';
import { validePhone } from '../../shared/utils';
import { Table, Column } from '../../shared/components/table/Table';

import { FieldWorkerFiltersType } from './fieldworker-types';
import { FieldWorkerFilters } from './components/FieldWorkerFilters';
import { DB_STATUS } from '../../shared/constants/common';
import { SearchInput } from '../../shared/components/SearchInput';
import { DisplayPhoneNumber } from '../../shared/components/DisplayPhoneNumber';

const DEFAULT_FILTERS: FieldWorkerFiltersType = {
  status: DB_STATUS.Active,
  rows: 10,
  page: 1,
};

/**
 * @returns {JSX.Element} - Fieldworker's view table.
 */
export function FieldworkerView(): JSX.Element {
  // States
  const [selected, setSelected] = useState<{
    id: string | undefined;
    archived: string | null;
    email: string;
  }>({ id: undefined, archived: null, email: '' });
  const [ancla, setAncla] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<{
    menu: boolean;
    modal: boolean;
    reset: boolean;
  }>({ menu: false, modal: false, reset: false });
  const { query, searchQuery } = useQuery<FieldWorkerFiltersType>();
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSm = useMediaQuery(theme.breakpoints.down('md'));
  const [filters, setFilters] =
    useState<FieldWorkerFiltersType>(DEFAULT_FILTERS);
  const { data, loading } = useCustomerListSuscriptionHook({
    first: filters.rows,
    skip: (filters.page - 1) * filters.rows,
    filter: searchFieldworker(filters),
    sort: [
      {
        createdAt: SortOrder.Desc,
      },
    ],
  });
  /**
   * @returns {void} - Handle the event click on the button more.
   * @param {React.MouseEvent} event - The component that generate the event.
   * @param {string} id - The fieldworker id.
   * @param {string} email - Fieldworker email.
   * @param {string} archived - The fieldworker status.
   */
  const handleMore = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement>,
      id: string | undefined,
      email: string,
      archived: string | null,
    ): void => {
      setSelected({ id, archived, email });
      setAncla(event.currentTarget);
      setOpen({ ...open, modal: false, menu: true });
    },
    [open],
  );

  const COLUMNS = useMemo<
    Column<ListFieldworkersQuery['fieldworkersList']['items'][0]>[]
  >(
    () => [
      {
        name: 'Full Name',
        key: 'fullName',
        render: (item) => (
          <Link
            href={`/fieldworker/detail/${item.id}`}
            color="inherit"
            underline="none"
            fontWeight='normal'
          >
            {item.fullName}
          </Link>
        ),
      },
      {
        name: 'Email Address',
        key: 'email',
      },
      {
        name: 'Status',
        key: 'archived',
        render: (item) => (
          <Pill
            text={item.archived ? 'Archived' : 'Active'}
            type={item.archived ? 2 : 0}
          />
        ),
      },
      {
        name: 'Phone Number   ',
        key: 'phoneNumber',
        render: (item) => (
          <DisplayPhoneNumber variant="body2" phoneNumber={item.phoneNumber} />
        ),
      },
      {
        name: 'Action',
        key: 'action',
        action: (item) => (
          <IconButton
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              if (item.id) {
                handleMore(event, item.id, item.email, item.archived);
              }
            }}
          >
            <MoreHoriz />
          </IconButton>
        ),
      },
    ],
    [handleMore],
  );

  const search = (value: string): void => {
    searchQuery({
      page: 1,
      search: value,
    });
  };

  /**
   * @returns {void} - Handle the event click on the button more.
   */
  const onFieldworkerEdit = (): void => {
    const to = `/fieldworker/edit/${selected.id}`;
    history.push(to);
  };

  /**
   * @returns {void} - Handle the modal event close.
   */
  const handleClose = (): void => {
    setOpen({ ...open, menu: false, modal: !open.modal });
  };

  /**
   * @returns {void} - Handle the more button event close.
   */
  const handleMoreClose = (): void => {
    setOpen({ ...open, menu: false });
  };

  const onChangePage = (value: number): void => {
    searchQuery({
      page: value,
    });
  };

  const onChangePageSize = (value: number): void => {
    searchQuery({
      page: 1,
      rows: value,
    });
  };

  useEffect(() => {
    if (query) {
      setFilters((state) => ({
        ...query,
        status: query.status ? query.status : state.status,
        rows: query.rows ? Number(query.rows) : state.rows,
        page: query.page ? Number(query.page) : state.page,
      }));
    } else {
      setFilters(DEFAULT_FILTERS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <ListViewConatiner>
      {matchesSm && (
        <Fab
          color="primary"
          aria-label="Create Job"
          href="fieldworker/create"
          sx={{
            position: 'fixed',
            right: '32px',
            bottom: '70px',
            zIndex: '3',
          }}
        >
          <Add />
        </Fab>
      )}
      {matches ? (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ alignItems: 'center' }}
          >
            <Typography variant="body2">
              {data?.fieldworkersList &&
              data?.fieldworkersList?.items.length < filters.rows
                ? Math.min(
                    filters.page * filters.rows -
                      (filters.rows - data?.fieldworkersList?.items.length),
                  )
                : Math.min(filters.page * filters.rows)}{' '}
              of {data?.fieldworkersList.count ?? 0}
            </Typography>
            <Stack direction="row" spacing={2}>
              <FieldWorkerFilters filters={filters} />
            </Stack>
          </Box>
          {!loading && data && (
            <>
              {data.fieldworkersList.items.map((fieldworker) => (
                <RowResponsive
                  type="fieldworker"
                  key={fieldworker.id}
                  id={fieldworker.id}
                  name={fieldworker.fullName}
                  email={fieldworker.email}
                  number={validePhone(fieldworker.phoneNumber || '')}
                  status={fieldworker.archived ? 'Archived' : 'Active'}
                  action={(event: React.MouseEvent<HTMLButtonElement>) => {
                    if (fieldworker.id) {
                      handleMore(
                        event,
                        fieldworker.id,
                        fieldworker.email,
                        fieldworker.archived,
                      );
                    }
                  }}
                />
              ))}
              <Pagination
                page={filters.page}
                pageSize={filters.rows}
                totalCount={data?.fieldworkersList.count ?? 0}
                onChange={onChangePage}
              />
            </>
          )}
        </>
      ) : (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">Fieldworkers</Typography>
            <Button variant="contained" size="large" href="fieldworker/create">
              <Add /> Create New Fieldworker
            </Button>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Stack direction="row" spacing={2}>
              <SearchInput onChangeText={search} />
              <FormControl
                sx={{
                  minWidth: 150,
                }}
              >
                <InputLabel>Rows</InputLabel>
                <Select
                  label="Rows"
                  value={filters.rows}
                  onChange={(e) => {
                    onChangePageSize(Number(e.target.value));
                  }}
                >
                  <MenuItem value={10}>10 Rows(s)</MenuItem>
                  <MenuItem value={20}>20 Rows(s)</MenuItem>
                  <MenuItem value={30}>30 Rows(s)</MenuItem>
                </Select>
              </FormControl>
              <FieldWorkerFilters filters={filters} />
            </Stack>
          </Box>
          <Table
            columns={COLUMNS}
            data={data?.fieldworkersList.items}
            pageSize={filters.rows}
            page={filters.page}
            total={data?.fieldworkersList.count ?? 0}
            onChangePage={onChangePage}
          />
        </>
      )}
      <Menu open={open.menu} onClose={handleMoreClose} anchorEl={ancla}>
        {selected.archived ? (
          <MenuItem onClick={handleClose}>Active</MenuItem>
        ) : (
          <>
            <MenuItem onClick={onFieldworkerEdit}>Edit</MenuItem>
            <MenuItem onClick={handleClose}>Archive</MenuItem>
            <MenuItem
              onClick={() => setOpen({ ...open, menu: false, reset: true })}
            >
              Reset
            </MenuItem>
          </>
        )}
      </Menu>
      <ResetPassword
        open={open.reset}
        close={() => setOpen({ ...open, reset: false })}
        email={selected.email}
      />
      <AlertFieldworker
        open={open.modal}
        id={selected.id}
        back={false}
        archived={selected.archived}
      />
    </ListViewConatiner>
  );
}
