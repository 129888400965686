import React from 'react';
import { EditCustomerForm } from './EditCustomerForm';

/**
 * @returns {JSX.Element} - Customer view.
 */
export function EditCustomerView(): JSX.Element {
  return (
    <>
      <EditCustomerForm />
    </>
  );
}
