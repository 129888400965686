import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  useFeatureFlagsListQuery,
  useUserSessionQuery,
} from '../../shared/types/generated';
import { useSetupAuth0Token } from '../auth/auth-hooks';
import { OnFeatureFlagEvent } from '../auth/auth-events';
import { MainLoader } from '../../shared/components/MainLoader';

type SessionProps = {
  children: React.ReactNode;
};

/**
 * @param {object} props - Props.
 * @param {JSX.Element}  props.children - Children.
 * @returns {JSX.Element} - Session component.
 */
export function Session({ children }: SessionProps): JSX.Element {
  const { isLoading } = useAuth0();
  const { isTokenReady } = useSetupAuth0Token();
  const [loading, setLoading] = useState<boolean>(true);

  const { loading: sessioStateLoad, error: sessionStateError } = useUserSessionQuery({
    skip: !isTokenReady || isLoading,
    fetchPolicy: 'cache-and-network',
  });

  const { data: featureFlagData, loading: featureFlagLoading, error: featureFlagError } =
  useFeatureFlagsListQuery({
    skip: !isTokenReady || sessioStateLoad,
    fetchPolicy: 'cache-and-network',
    onError: (err) => {
      console.log(err);
    },
  });

  useEffect(() => {
    if (!sessioStateLoad && !featureFlagLoading && isTokenReady && !isLoading) {
      setLoading(false);
    }
  }, [sessioStateLoad, featureFlagData, featureFlagLoading, isTokenReady, isLoading]);
  
  if (featureFlagData?.featureFlagsList?.items) OnFeatureFlagEvent.dispatch(featureFlagData?.featureFlagsList?.items);
  
  // prevents rendering the app if an error has occurred
  if (sessionStateError?.message || featureFlagError?.message) {
    setLoading(false);
    return <div>something is wrong...</div>;
  }

  return (
    loading ? <MainLoader /> : <>{children}</>
  );
}
