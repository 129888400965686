import React from 'react';
import { Grid, Box, Paper } from '@mui/material';
import { MapComponent } from './components/MapResponsiveComponent';
import { MapListQuery } from '../../shared/types/generated';

/**
 * @returns {JSX.Element} - Calendar View.
 * @param {JSX.Element} props - Calendar View.
 * @param {JSX.Element} props.fieldworkers - Calendar View.
 */
export function MapResponsive({
  formatData,
  children,
}: {
  formatData: MapListQuery | undefined;
  children: JSX.Element;
}): JSX.Element {
  return (
    <Grid item xs={12}>
      <Box display="flex" justifyContent={['flex-end', 'space-between']}>
        {children}
      </Box>
      <Paper
        variant="card"
        sx={{
          height: '82vh',
          position: 'relative',
          overflowY: 'hidden',
          padding: 0,
          backgroundColor: ['#00000000', '#ffff'],
          '&::-webkit-scrollbar': {
            backgroundColor: '#fafafa',
            width: '0.6rem',
            height: '0.6rem',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#bcbcbd',
            borderRadius: '10px',
          },
        }}
      >
        <MapComponent locations={formatData} />
      </Paper>
    </Grid>
  );
}
