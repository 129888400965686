import * as yup from 'yup';
import { phoneNumberSchemaValidation } from '../../shared/utils/phone';

export type AdminListFilters = {
  search?: string;
  type?: string;
  from?: string;
  to?: string;
  dbStatus?: string,
  page: number;
  rows: number;
};

export enum UserStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  INVITATION_PENDING = 'invitationPending',
}

export type EditAdminType = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export type CreateAdminType = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export const ADMIN_FORM_VALIDATOR_SCHEMA = yup
  .object({
    id: yup.string(),
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email().required('Email is required'),
    phone: yup
      .string()
      .test(
        'phoneNumberValidation',
        'Invalid phone number',
        (value) => phoneNumberSchemaValidation.isValid(value),
      )
      .required('Phone is required'),
  })
  .required();