/* eslint-disable @typescript-eslint/naming-convention */
export enum DB_STATUS {
  Archived = 'Archived',
  Active = 'Active',
  All = 'All',
}

export enum CLOCK {
  CLOCK_IN = 'CLOCK_IN',
  CLOCK_OUT = 'CLOCK_OUT',
  ON_ROUTE = 'ON_ROUTE',
  ON_ROUTE_STR = 'On Route',
  CLOCK_OUT_STR = 'Clock Out',
  CLOCK_IN_STR = 'Clock In',
}

export enum JOB_STATUS  {
  NEW = 'NEW',
  QUOTE = 'QUOTE',
  Q_SUBMITTED = 'Q_SUBMITTED',
  PENDING = 'PENDING',
  DISPATCHED = 'DISPATCHED',
  READY_FOR_RETURN = 'READY_FOR_RETURN',
  ON_ROUTE = 'ON_ROUTE',
  ON_SITE = 'ON_SITE',
  CLOSED = 'CLOSED',
  PAUSED = 'PAUSED'
}

export const PHONE_REGION_CODE = 'US';
export const US_PHONE_NUMBER_REGREX =
  /^(\+1)?\s?\(?([0-9]{3})\)?[-.]?([0-9]{3})[-.]?([0-9]{4})$/;
export const PHONE_NUMBER_REGREX = /^(\+?\d{1,3})?(\d{7,10})$/;
export const EMAIL_REGREX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const NUMBER_REGREX = /^\d+$/;