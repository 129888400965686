import React from 'react';
import dayjs from 'dayjs';
import { NotificationTypes } from '../notification-types';
import { NotifBold, NotifTextWrapper } from '../../../shared/components/Typografy';
import { Job, Notification } from '../../../shared/types/generated';

export const AdminNotification: React.FC<{notifiication: Notification}> = ({ notifiication }) => {
  const isRead = notifiication?.read;
  const jobSnapshot = Object(notifiication?.snapshot)?.job as Job;
  const jobStatus = jobSnapshot?.status;

  if (notifiication.type === NotificationTypes.ADMIN_JOB_STATUS) {
    const notifyBy = notifiication?.generatedBy?.fielworker?.fullName;
    return (
      <NotifTextWrapper isRead={isRead}>
        Fieldworker <NotifBold isRead={isRead}>{notifyBy}</NotifBold> has updated the job status to <b>{jobStatus.replaceAll('_', ' ')}</b>.
      </NotifTextWrapper>);
  }

  const notifyBy = jobSnapshot?.address?.customer?.name;
  return (
    <NotifTextWrapper isRead={isRead}>
      A new service request has been created by <NotifBold isRead={isRead}>{notifyBy}</NotifBold>.
    </NotifTextWrapper>);
};

export const FieldWorkerNotification: React.FC<{notifiication: Notification}> = ({ notifiication }) => {
  const isRead = notifiication.read;
  
  if (notifiication.type === NotificationTypes.FIELDWORKER_JOB_ASSIGNED) {
    return (
      <NotifTextWrapper isRead={isRead}>
        You have been <NotifBold isRead={isRead}>assigned</NotifBold> a new job.
      </NotifTextWrapper>);
  }

  if (notifiication.type === NotificationTypes.FIELDWORKER_JOB_UNASSIGNED) {
    return (
      <NotifTextWrapper isRead={isRead}>
        You have been <NotifBold isRead={isRead}>removed</NotifBold> from a job.
      </NotifTextWrapper>);
  }

  return (
    <NotifTextWrapper isRead={isRead}>
      The Job start date has been <NotifBold isRead={isRead}>updated.</NotifBold>
    </NotifTextWrapper>);
};

export const CustomerNotification: React.FC<{notifiication: Notification}> = ({ notifiication }) => {

  const isRead = notifiication.read;
  const job = Object(notifiication?.snapshot)?.job as Job;
  const fieldWorkers = job.jobJobFieldWorkerRelation.items.map((jobFw) => jobFw.fieldworker.fullName).join(', ');
  const client = job.address.customer.name;
  const jobStatus = job?.status;
  const date = dayjs(job.startDateTime).format('MM/DD/YYYY');
  const time = dayjs(job.startDateTime).format('h:mm a');

  if (notifiication.type === NotificationTypes.CUSTOMER_JOB_STATUS) {
    return (
      <NotifTextWrapper isRead={isRead}>
        <NotifBold isRead={isRead}>{client}</NotifBold> The status of your request has been updated to <NotifBold isRead={isRead}>{jobStatus}</NotifBold>.
      </NotifTextWrapper>
    );
  }

  if (notifiication.type === NotificationTypes.CUSTOMER_JOB_FW_DATE) {
    return (
      <NotifTextWrapper isRead={isRead}>
        <NotifBold isRead={isRead}>{client}</NotifBold> Good news: your appointment is confirmed for <NotifBold isRead={isRead}>{date}</NotifBold> at <NotifBold isRead={isRead}>{time}</NotifBold>, our technician <NotifBold isRead={isRead}>{fieldWorkers}</NotifBold> will visit you.
      </NotifTextWrapper>
    );
  }

  if (notifiication.type === NotificationTypes.CUSTOMER_JOB_NEW_START_DATE) {
    return (
      <NotifTextWrapper isRead={isRead}>
        <NotifBold isRead={isRead}>{client}</NotifBold>! Our technician will visit you on <NotifBold isRead={isRead}>{date}</NotifBold> at <NotifBold isRead={isRead}>{time}</NotifBold>.
      </NotifTextWrapper>
    );
  }

  return <NotifTextWrapper isRead={isRead}>
    Attention <NotifBold isRead={isRead}>{client}</NotifBold>, We have adjusted the date of the technician&apos;s visit to your location. It will now be on <NotifBold isRead={isRead}>{date}</NotifBold> at <NotifBold isRead={isRead}>{time}</NotifBold>.
    </NotifTextWrapper>;
};
