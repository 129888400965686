import dayjs from 'dayjs';
import { DB_STATUS } from '../../shared/constants/common';
import { SortOrder, AdminUpdateMutationVariables, UserFilter, UserSort } from '../../shared/types/generated';
import { USER_ROLES } from '../session/session-model';
import { AdminListFilters, EditAdminType, UserStatus } from './admin-types';

export const DEFAULT_FILTERS: AdminListFilters = {
  search: '',
  from: '',
  to: '',
  dbStatus: DB_STATUS.Active,
  page: 1,
  rows: 10,
};

export const UserStatusTexts = {
  [UserStatus.ACTIVE] : 'Active',
  [UserStatus.INACTIVE] : 'Inactive',
  [UserStatus.INVITATION_PENDING] : 'Pending',
};

export const defaultAdminFormValues: EditAdminType = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
};

export const DB_STATUS_VALUES = Object.values(DB_STATUS).map((item) => ({
  value: item,
  label: item,
}));

export const STATUS_OPTIONS = Object.values(UserStatus);

export const getAdminFilters = (filters: AdminListFilters): {
  AdminFilter: UserFilter,
  skip: number,
  first: number,
  sort: UserSort,
} => {
  const AND: UserFilter[] = [
    {
      roles: {
        some: { name: { equals: USER_ROLES.ADMIN } }
      }
    },
  ];
  const OR: UserFilter[] = [];

  if (filters.from) {
    AND.push({
      createdAt: { gte: dayjs(filters.from).startOf('day').toISOString() }
    });
  }
  if (filters.to) {
    AND.push({
      createdAt: { lte: dayjs(filters.to).endOf('day').toISOString() }
    });
  }
  if (filters.dbStatus === DB_STATUS.Active) {
    AND.push({
      archived: { is_empty: true }
    });
  }
  if (filters.dbStatus === DB_STATUS.Archived) {
    AND.push({
      archived: { is_empty: false }
    });
  }
  if (filters.search && filters.search !== '') {
    OR.push({
      firstName: { contains: filters.search }
    });
    OR.push({
      lastName: { contains: filters.search }
    });
    OR.push({
      email: { contains: filters.search }
    });
  }
  return {
    AdminFilter: {
      AND,
      OR
    },
    first: filters.rows,
    skip: (filters.page - 1) * filters.rows,
    sort: {
      createdAt: SortOrder.Desc
    }
  };
};

export const archiveAdminVariables = (
  id: string,
  archive: boolean,
): AdminUpdateMutationVariables => {
  const date = dayjs().toISOString();
  const obj: AdminUpdateMutationVariables = {
    data: {
      archived: archive ? date : (null as unknown as string),
      id,
    },
  };

  return obj;
};