import React, { useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { Box, Typography, useMediaQuery, Avatar } from '@mui/material';
import {
  FieldworkerColor,
  FieldworkerDayList,
  CalendarType,
} from '../calendar-types';
import { InfoCard } from './InfoCard';
import { theme, PRIMARY } from '../../../shared/css/theme';
import { filterDay, filterDayResponsive } from '../calendar-utils';
import { Job } from '../../../shared/types/generated';

// Set the column view
const numColumn = 10;

/**
 * @param {JSX.Element} props - Calendar View.
 * @param {JSX.Element} props.fieldworkers - Calendar View.
 * @returns {JSX.Element} - Calendar View.
 */
export function TableAgenda({
  fieldworkers,
  day,
}: {
  fieldworkers: FieldworkerColor[];
  day: string;
}): JSX.Element {
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [fieldworkersList, setFieldworkersList] =
    React.useState<FieldworkerDayList>([]);
  const [formatDataResponsive, setFormatDataResponsive] = React.useState<
    {
      job: Job;
      fieldworker: FieldworkerColor;
    }[]
  >([]);
  const [count] = React.useState<number>(numColumn);
  const currentHour = Number(dayjs().format('H'));
  const valueRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // move scroll to current hour
    if (!matchesSm) {
      valueRef?.current?.scrollTo(0, currentHour * 90);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const validHours = fieldworkers.filter(
      (item) => item.fieldworkerJobFieldworkerRelation.items.length > 0,
    );
    const formatData = filterDay(validHours);
    setFieldworkersList(formatData);
    setFormatDataResponsive(filterDayResponsive(validHours));
  }, [count, matchesSm, fieldworkers]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          minWidth: '100%',
          padding: 0,
          width: [
            '100%',
            fieldworkersList.length <= 2 ? '100%' : 'max-content',
          ],
          height: ['100%', '63vh'],
          overflowY: 'scroll',
          overflowX: 'hidden',
          '&::-webkit-scrollbar': {
            backgroundColor: '#fafafa',
            width: '0.6rem',
            height: '0.6rem',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#bcbcbd',
            borderRadius: '10px',
          },
        }}
        ref={valueRef}
      >
        <Box
          sx={{
            height: ['auto', '100%'],
            minWidth: ['55px', '47px'],
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            sx={{
              marginRight: ['10px', '0'],
            }}
          >
            <Typography variant="body2" color="primary">
              {dayjs(day).format('ddd')}
            </Typography>
            <Avatar
              sx={{
                backgroundColor: PRIMARY,
              }}
            >
              <Typography variant="h6">{dayjs(day).format('DD')}</Typography>
            </Avatar>
          </Box>
        </Box>
        <Box
          display="grid"
          gridTemplateRows={[
            `repeat(${fieldworkersList.length}, 80px)`,
            'repeat(24, 90px)',
          ]}
          width="100%"
          sx={{
            gridGap: '0',
          }}
        >
          {formatDataResponsive.map((hour) => (
            <InfoCard
              type={CalendarType.AGENDA}
              count={fieldworkersList.length}
              backgroundColor={hour.fieldworker.color}
              job={hour.job}
              fieldworker={hour.fieldworker}
              gridRowStart="auto"
              gridColumnStart="auto"
            />
          ))}
          {matchesSm && fieldworkersList.length === 0 && (
            <Typography variant="body2" textAlign="center" mt="30px">
              No jobs assigned today
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
}
