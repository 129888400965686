import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import MenuIcon from '@mui/icons-material/Menu';
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';

const HeaderWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '17px',
  position: 'sticky',
  justifyContent: 'space-between',
  paddingBottom: '4px',
  width: '100%',
});

export const PanelHeader: React.FC<{ 
  closePanel: () => void, 
  clearNotifications: () => void,
  showAllNotifications: () => void,
  showAll: boolean,
  showClear: boolean,
}> = ({ closePanel, clearNotifications, showAllNotifications, showAll, showClear }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openNotificationMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <HeaderWrapper>
      <IconButton onClick={() => closePanel()} size='small'>
        <CloseIcon fontSize='small'/>
      </IconButton>
      <Typography fontWeight='normal' fontSize='18px'>Notifications</Typography>
      <IconButton onClick={openNotificationMenu} size='small'>
        <MenuIcon fontSize='small' color='secondary'/>
      </IconButton>
      <Menu 
        anchorEl={anchorEl} 
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {showClear && <MenuItem onClick={() => {
          setAnchorEl(null);
          clearNotifications();
          }}>
          <MarkEmailReadIcon fontSize='small' color='secondary' style={{ paddingRight: '5px' }}/>
          Clear all
        </MenuItem>}
        <MenuItem onClick={() => {
          setAnchorEl(null);
          showAllNotifications();
        }}
        sx={{
          minWidth: '155px',
        }}>
          <CalendarViewDayIcon fontSize='small' color='secondary' style={{ paddingRight: '5px' }}/>
          {showAll ? 'View unread' : 'View all'}
        </MenuItem>
      </ Menu>
    </HeaderWrapper>
  );
};