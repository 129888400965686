import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { MainLoader } from '../../shared/components/MainLoader';
import { useUserHasRole } from '../session/session-hooks';

// TODO add loading component
/**
 * @returns {JSX.Element} - Logout element.
 */
export function Logout(): JSX.Element {
  const { logout } = useAuth0();
  const isCustomer = useUserHasRole('CUSTOMER');
  useEffect(() => {
    let route: string = window.location.origin;
    if (isCustomer) {
      route = `${route}/customerSession`;
    }
    logout({
      returnTo: route,
    });
  }, [logout, isCustomer]);

  return <MainLoader text="Loging Out..." />;
}
