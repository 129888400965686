import React, { useState, useEffect } from 'react';
import { error as errorLogger } from '@cobuildlab/pure-logger';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import Grid from '@mui/material/Grid';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  styled,
  Card,
  FormControl,
  Box,
  Theme,
  Skeleton,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  IconButton,
  ListItemText,
  useMediaQuery,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as filestack from 'filestack-js';
import { useHistory,  } from 'react-router-dom';
import { Controller } from 'react-hook-form';

import {
  FormImageContainer,
  FormViewConatiner,
} from '../../shared/components/Containers';
import { snackbar } from '../../shared/components/Snackbar';
import { clearQuerysFromCache } from '../../shared/utils';
import {
  useCustomerEQuery,
  useGetApiKeyQuery,
  useUpdateCustomer2Mutation,
} from '../../shared/types/generated';
import {
  // addressValidation,
  // InvalidFieldType,
  // isAddressNamesReplicated,
  updateCustomer2MutationVariables,
} from './customer-utils';
import image from '../../shared/assets/bro.svg';
import { useQuery } from '../../shared/utils/route';
import { useCustomerForm } from './customer-hooks';
import { PartsInputPortal } from '../../shared/components/PartModal';
import { EditCustomerFormType } from './customer-types';
import { theme as cssTheme } from '../../shared/css/theme';
import { UseValidateFeatureFlag } from '../auth/auth-hooks';
import { PhoneNumberInputModal } from '../../shared/components/PhoneNumberInputModal';
import { getPhoneNumberWithOutRegionCode } from '../../shared/utils/phone';

const DeleteButton = styled(Button)(() => ({
  color: '#EF5350',
}));

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    Width: '75%',
    borderRadius: '15px',
  },
  contend: {
    width: '95%',
  },
  photo: {
    width: '90%',
    display: 'block',
    alignSelf: 'center',
  },
  card: {
    border: '0.5px solid #969CBA',
    boxSizing: 'border-box',
    boxShadow: '0px 3px 0px #EDEDF6',
    borderRadius: '10',
    width: '100%',
    padding: theme.spacing(2),
  },
  sep: {
    marginTop: theme.spacing(4),
  },
  green: {
    color: '#2BA668',
  },
  actions: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'right',
    '& button': {
      height: theme.spacing(6),
      width: theme.spacing(12),
      marginRight: theme.spacing(3),
    },
  },
}));

/**
 *
 * @returns {JSX.Element} -
 */
// eslint-disable-next-line arrow-body-style
const AddressFormSkeleton = (): JSX.Element => {
  // const classes = useStyles();
  return (
    <>
      <Grid style={{ gridColumn: '1/3' }}>
        <Skeleton width="inherit" animation="wave" />
        <Skeleton width="inherit" animation="wave" />
      </Grid>
      <Grid
        container
        width="max-content"
        justifyContent="space-evenly"
        spacing={2}
      >
        <Grid item>
          <Skeleton width="8rem" animation="wave" />
        </Grid>
        <Grid item>
          <Skeleton width="8rem" animation="wave" />
        </Grid>
        <Grid item>
          <Skeleton width="8rem" animation="wave" />
        </Grid>
      </Grid>
    </>
  );
};

export function EditCustomerForm(): JSX.Element {
  const { query } = useQuery<{ id: string }>();
  const id = query?.id || '';
  const { loading: customerLoading, data: customerData } = useCustomerEQuery({
    skip: !id,
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
  });
  const { data: dataFileStack } = useGetApiKeyQuery();
  const featureFlag = UseValidateFeatureFlag('DOCUMENTS');
  const matchesSm = useMediaQuery(cssTheme.breakpoints.down('sm'));
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    control
  } = useCustomerForm();
  const [once, setOnce] = useState<boolean>(false);
  const history = useHistory();
  const [path, setPath] = useState<string>('');
  const [clientFileStack, setClientFileStack] = useState<filestack.Client>();
  const [documentUri, setDocumentUri] =
    useState<{ filename: string; fileId: string; id: string }[]>();
  useEffect(() => {
    if (!customerLoading) {
      setDocumentUri(customerData?.customer.documents.items);
    }
  }, [customerData, customerLoading]);
  useEffect(() => {
    if (dataFileStack?.fileUploadInfo.apiKey) {
      setClientFileStack(
        filestack.init(dataFileStack?.fileUploadInfo.apiKey, {
          security: {
            signature: dataFileStack?.fileUploadInfo.signature as string,
            policy: dataFileStack?.fileUploadInfo.policy as string,
          },
        }),
      );
      setPath(dataFileStack?.fileUploadInfo.path as string);
    }
  }, [dataFileStack]);
  /**
   * - Handle upload files.
   */
  const handleAttachFile = (): void => {
    if (!clientFileStack) return;
    const options = {
      fromSources: ['local_file_system'],
      accept: ['.pdf'],
      maxFiles: 10,
      /**
       * @param files - Filestack files client.
       */
      onUploadDone: (files: filestack.PickerResponse): void => {
        if (files.filesUploaded.length > 0) {
          const newSourcesArr = files.filesUploaded.map((newSrc) => newSrc);
          const oldSourcesArr = [...(documentUri || [])];
          newSourcesArr.forEach((source) =>
            oldSourcesArr.push({
              filename: source.filename,
              fileId: source.handle,
              id: '',
            }),
          );
          setDocumentUri(oldSourcesArr);
        }
      },
      storeTo: { path },
    };
    clientFileStack.picker(options).open();
  };
  const [modal, setModal] = useState<{ emails: boolean; phones: boolean }>({
    emails: false,
    phones: false,
  });
  const [open, setOpen] = useState<boolean>(false);
  const [check, setCheck] = useState<boolean>(false);
  const [updateCustomer2Mutation, { data, loading, error }] =
    useUpdateCustomer2Mutation({
      /**
       * @returns {void} - Result.
       */
      onCompleted: () => {
        if (error === undefined) {
          snackbar.success('Customer edited');
          history.goBack();
        } else {
          setOpen(true);
        }
      },
      /**
       * @param {} err -Error.
       * @returns {void} - Result.
       */
      onError: (err) => {
        errorLogger(err);
      },
      /**
       * @param {object} cache - Array position to delete.
       * @returns Void.
       */
      update: (cache) => clearQuerysFromCache(['customer'])(cache),
    });

  const [openRelocate, setOpenRelocate] = useState<boolean>(false);

  useEffect(() => {
    if(errors){
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errros = errors as Record<any, any>;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errorListKey = Object.keys(errors) as Array<any>;
      errorListKey.forEach((key) => {
        snackbar.error(errros[key].message || 'Field error');
      });
    }
  }, [errors]);

  useEffect(() => {
    if (!customerLoading && customerData && once === false) {
      setOnce(true);
      setValue(
        'customerAddressRelation',
        customerData?.customer?.customerAddressRelation?.items?.map((item) =>({
            ...item,
            contactPhone: getPhoneNumberWithOutRegionCode(item.contactPhone),
          })) ?? [],
      );

      setValue(
        'customerPhoneNumberRelation',
        customerData?.customer?.customerPhoneNumberRelation?.items.map((item) => ({
            ...item,
            phoneNumber: getPhoneNumberWithOutRegionCode(item.phoneNumber),
          })) ?? [],
      );

      setCheck(customerData.customer.willLogin);
      setValue('name', customerData.customer.name);
      setValue('willLogin', customerData.customer.willLogin);
      setValue('phoneNumber', getPhoneNumberWithOutRegionCode(customerData.customer.phoneNumber,));
      setValue('email', customerData.customer.email);
      setValue(
        'customerCustomerEmailRelation',
        customerData.customer?.customerCustomerEmailRelation?.items?.map((item) => ( {...item} )) ?? [],
      );
    }

    if (
      !customerLoading &&
      customerData?.customer.name !== '' &&
      customerData?.customer.archived !== null
    ) {
      setOpenRelocate(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, customerData, customerLoading, once, setValue]);

  /**
   * @returns {void} - Push Address.
   */
  const pushAddres = (): void => {
    const addresses = watch('customerAddressRelation') ?? [];

    addresses.push({
      contactName: '',
      contactPhone: '',
      city: '',
      zipCode: '',
      state: '',
      name: '',
      streetAddress: '',
    });
    setValue('customerAddressRelation', addresses);
  };

  /**
   * @returns {void} - Remove Address.
   * @param {number} index - Array position to delete.
   */
  const removeAddress = (index: number): void => {
    const addresses = watch('customerAddressRelation') ?? [];

    addresses.splice(index, 1);
    setValue('customerAddressRelation', addresses);
  };

  const classes = useStyles();

  return (
    <>
      <FormViewConatiner
        title="Edit Customer"
        subtitle={customerData?.customer.name || ''}
      >
        <Typography
          variant="h5"
          sx={{ display: [null, 'none'], gridColumn: 'span 2' }}
        >
          Customer Details
        </Typography>
        <FormImageContainer
          onSubmit={handleSubmit((dataForm) => {
            if(!customerData){
              return;
            }
            updateCustomer2Mutation({
              variables: updateCustomer2MutationVariables(
                id ?? '',
                customerData,
                dataForm as EditCustomerFormType,
                documentUri || undefined,
              ),
            });
          })}
          imagePath={image}
        >
          <Typography
            variant="h5"
            component="h2"
            sx={{ display: ['none', 'block'], gridColumn: 'span 2' }}
          >
            Customer Details
          </Typography>
          <PartsInputPortal
            actionAdd={(obj) => {
              setModal({ ...modal, emails: !modal.emails });
              const newEmails =
                getValues('customerCustomerEmailRelation') ?? [];
              newEmails.push({
                email: obj.name,
              });
              setValue('customerCustomerEmailRelation', newEmails);
            }}
            errorMessage="Invalid email"
            label="Email"
            title="Add Email For Notifications"
            onClose={() => {
              setModal({ ...modal, emails: !modal.emails });
            }}
            open={modal.emails}
          />
          <PhoneNumberInputModal 
            isVisible={modal.phones}
            onComfirm={(value) => {
              setModal({ ...modal, phones: !modal.phones });
              const newPhones = getValues('customerPhoneNumberRelation') ?? [];
              newPhones.push({
                phoneNumber: value.phoneNumber,
              });
              setValue('customerPhoneNumberRelation', newPhones);
            }}
            onCancel={() => {
              setModal({ ...modal, phones: !modal.phones });
            }}
          />
          {!customerLoading && customerData?.customer?.name ? (
            <>
              <FormControl
                fullWidth
                sx={{ gridColumn: 'span 2', marginTop: '10px' }}
              >
                <TextField
                  required
                  id="outlined-basic"
                  label="Full Name"
                  variant="outlined"
                  {...register('name')}
                  helperText={errors?.name?.message}
                  error={Boolean(errors?.name?.message)}
                />
              </FormControl>
              <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
                <TextField
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  helperText={errors.email?.message}
                  error={Boolean(errors.email)}
                  {...register('email')}
                />
              </FormControl>
              <List sx={{ gridColumn: 'span 2', padding: 0 }}>
                {watch('customerCustomerEmailRelation') &&
                  watch('customerCustomerEmailRelation')?.map((item, index) => (
                    <ListItem
                      key={item.email}
                      sx={{
                        border: '1px solid #e0e0e0',
                        borderRadius: '5px',
                        marginBottom: '15px',
                      }}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          onClick={() => {
                            const newParts = getValues(
                              'customerCustomerEmailRelation',
                            );
                            if (newParts) {
                              newParts.splice(index, 1);
                              setValue(
                                'customerCustomerEmailRelation',
                                newParts,
                              );
                            }
                          }}
                        >
                          <ClearIcon color="error" />
                        </IconButton>
                      }
                      disablePadding
                    >
                      <ListItemText
                        sx={{
                          margin: '7px 0 7px 15px',
                        }}
                        primary={item.email}
                      />
                    </ListItem>
                  ))}
                <FormControl
                  variant="outlined"
                  fullWidth
                  sx={{ gridColumn: 'span 2' }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setModal({ ...modal, emails: !modal.emails });
                    }}
                  >
                    Add Email Notifications
                  </Button>
                </FormControl>
              </List>
              <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
              <Controller
                control={control}
                name="phoneNumber"
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Phone"
                    helperText={errors?.phoneNumber?.message}
                    error={Boolean(errors?.phoneNumber?.message)}
                  />
                )}
              />
            </FormControl>
            </>
          ) : (
            <Grid container style={{ gridColumn: '1/3' }}>
              <Grid item xs={12}>
                <Skeleton animation="wave" />
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" />
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" />
              </Grid>
            </Grid>
          )}
          <List sx={{ gridColumn: 'span 2', padding: 0 }}>
            {watch('customerPhoneNumberRelation')?.map((item, index) => (
                <ListItem
                  key={item.phoneNumber}
                  sx={{
                    border: `1px solid ${errors?.customerPhoneNumberRelation?.[index]?.phoneNumber ? 'red' : '#e0e0e0' }`,
                    borderRadius: '5px',
                    marginBottom: '15px',
                  }}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      onClick={() => {
                        const newPhones = getValues(
                          'customerPhoneNumberRelation',
                        );
                        if (newPhones) {
                          newPhones.splice(index, 1);
                          setValue('customerPhoneNumberRelation', newPhones);
                        }
                      }}
                    >
                      <ClearIcon color="error" />
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemText
                    sx={{
                      margin: '7px 0 7px 15px',
                    }}
                    primary={item.phoneNumber}
                    secondary={errors?.customerPhoneNumberRelation?.[index]?.phoneNumber  ? 'Invalid phone number' : undefined}
                    secondaryTypographyProps={{
                      color: errors?.customerPhoneNumberRelation?.[index]?.phoneNumber ? 'red' : undefined,
                    }}
                  />
                </ListItem>
              ))}
            <FormControl
              variant="outlined"
              fullWidth
              sx={{ gridColumn: 'span 2' }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setModal({ ...modal, phones: !modal.phones });
                }}
              >
                Add Phone Number
              </Button>
            </FormControl>
          </List>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems=""
            mt={2}
            sx={{ gridColumn: 'span 2' }}
          >
            <Typography variant="h5" sx={{ gridColumn: 'span 2' }}>
              Address
            </Typography>
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                pushAddres();
              }}
            >
              Add Address
            </Button>
          </Box>
          {!customerLoading && customerData?.customer?.name ? (
            watch('customerAddressRelation')?.map((item, index) => {
              const customerAddressRelationError = errors?.customerAddressRelation?.[index];
              return (
                <>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems=""
                    mt={2}
                    sx={{ gridColumn: 'span 2', padding: 0.5 }}
                  >
                    <Card className={classes.card} elevation={5}>
                      <Grid container md={12} lg={12} sm={12} spacing={1}>
                        {index > 0 ? (
                          <>
                            <Grid
                              container
                              item
                              md={6}
                              lg={6}
                              sm={6}
                              spacing={5}
                            >
                              <Grid item>
                                <Typography>{`Address ${
                                  index + 1
                                }`}</Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              item
                              justifyContent="flex-end"
                              md={6}
                              lg={6}
                              sm={6}
                              spacing={10}
                            >
                              <Grid item>
                                <DeleteButton
                                  variant="default"
                                  onClick={() => {
                                    removeAddress(index);
                                  }}
                                >
                                  Remove
                                </DeleteButton>
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <Grid
                            container
                            item
                            md={12}
                            lg={12}
                            sm={12}
                            spacing={5}
                          >
                            <Grid item>
                              <Typography>{`Address ${index + 1}`}</Typography>
                            </Grid>
                          </Grid>
                        )}

                        <Grid item container md={12} lg={12} sm={12}>
                          <TextField
                            fullWidth
                            label="Contact Name"
                            variant="outlined"
                            {...register(
                              `customerAddressRelation.${index}.contactName`,
                            )}
                          />
                        </Grid>
                        <Grid item container md={12} lg={12} sm={12}>
                        <TextField
                            fullWidth
                          label="Contact Phone"
                          variant="outlined"
                          {...register(
                            `customerAddressRelation.${index}.contactPhone`,
                          )}
                          helperText={
                            customerAddressRelationError?.contactPhone?.message
                          }
                          error={Boolean(
                            customerAddressRelationError?.contactPhone,
                          )}
                        />
                        </Grid>
                        <Grid item container md={12} lg={12} sm={12}>
                          <TextField
                            required
                            label="Street Address"
                            fullWidth
                            variant="outlined"
                            {...register(
                              `customerAddressRelation.${index}.streetAddress`,
                            )}
                            helperText={
                              customerAddressRelationError?.streetAddress
                                ?.message
                            }
                            error={Boolean(
                              customerAddressRelationError?.streetAddress,
                            )}
                          />
                        </Grid>

                        <Grid container item md={4} lg={4} sm={12}>
                          <TextField
                            required
                            fullWidth
                            label="City"
                            variant="outlined"
                            {...register(
                              `customerAddressRelation.${index}.city`,
                            )}
                            helperText={
                              customerAddressRelationError?.city?.message
                            }
                            error={Boolean(customerAddressRelationError?.city)}
                          />
                        </Grid>

                        <Grid item container md={4} lg={4} sm={12}>
                          <TextField
                            required
                            fullWidth
                            label="State"
                            variant="outlined"
                            {...register(
                              `customerAddressRelation.${index}.state`,
                            )}
                            helperText={
                              customerAddressRelationError?.state?.message
                            }
                            error={Boolean(customerAddressRelationError?.state)}
                          />
                        </Grid>

                        <Grid item container md={4} lg={4} sm={12}>
                          <TextField
                            fullWidth
                            label="Zip"
                            variant="outlined"
                            {...register(
                              `customerAddressRelation.${index}.zipCode`,
                            )}
                            helperText={
                              customerAddressRelationError?.zipCode?.message
                            }
                            error={Boolean(
                              customerAddressRelationError?.zipCode,
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Card>
                  </Box>
                </>
              );
            })
          ) : (
            <AddressFormSkeleton />
          )}
          {!matchesSm ? (
            <>
              {!customerLoading && (
                <FormControl fullWidth>
                  <FormControlLabel
                    disabled={customerData?.customer?.willLogin}
                    control={
                      <Checkbox
                        checked={check}
                        onChange={() => {
                          setValue('willLogin', !check);
                          setCheck(!check);
                        }}
                      />
                    }
                    label="Able Customer Login"
                  />
                </FormControl>
              )}
              {featureFlag && (
                <FormControl fullWidth>
                  <Button
                    type="button"
                    variant="outlined"
                    startIcon={<AttachFileIcon />}
                    onClick={handleAttachFile}
                  >
                    Upload files
                  </Button>
                </FormControl>
              )}
            </>
          ) : (
            <>
              {!customerLoading && (
                <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={check}
                        onChange={() => {
                          setValue('willLogin', !check);
                          setCheck(!check);
                        }}
                      />
                    }
                    label="Able Customer Login"
                  />
                </FormControl>
              )}
              {featureFlag && (
                <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
                  <Button
                    type="button"
                    variant="outlined"
                    startIcon={<AttachFileIcon />}
                    onClick={handleAttachFile}
                  >
                    Upload files
                  </Button>
                </FormControl>
              )}
            </>
          )}
          {featureFlag &&
            documentUri &&
            documentUri.map((document) => (
              <List sx={{ gridColumn: 'span 2', padding: 0 }}>
                {documentUri && (
                  <ListItem
                    key={document.fileId}
                    sx={{
                      border: '1px solid #e0e0e0',
                      borderRadius: '5px',
                      marginBottom: '15px',
                    }}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        onClick={() => {
                          setDocumentUri(
                            documentUri.filter(
                              (item) => item.fileId !== document.fileId,
                            ),
                          );
                        }}
                      >
                        <ClearIcon color="error" />
                      </IconButton>
                    }
                    disablePadding
                  >
                    <ListItemText
                      sx={{
                        margin: '7px 0 7px 15px',
                      }}
                      primary={document.filename}
                    />
                  </ListItem>
                )}
              </List>
            ))}
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            sx={{
              gridColumn: 'span 2',
              marginBottom: '20px',
              '&>*': {
                marginLeft: 2,
              },
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              loading={loading}
              style={{ marginLeft: 24 }}
              type="submit"
            >
              Save
            </LoadingButton>
          </Box>
        </FormImageContainer>
      </FormViewConatiner>
      <Dialog open={open}>
        {error !== undefined && (
          <>
            <DialogTitle id="warningTitle">Something went wrong</DialogTitle>
            <DialogActions>
              <Button
                color="primary"
                autoFocus
                onClick={() => {
                  setOpen(false);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <Dialog open={openRelocate}>
        <DialogTitle id="warningTitle">Archived Customer</DialogTitle>

        <DialogActions>
          <Button
            color="primary"
            autoFocus
            onClick={() => {
              setOpenRelocate(false);
              setTimeout(() => {
                history.push('/customers');
              }, 1000);
            }}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
