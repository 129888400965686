import React, { SyntheticEvent, useMemo } from 'react';
import Stack from '@mui/material/Stack';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { Autocomplete, InputLabel, MenuItem, TextField, Typography} from '@mui/material';

import { FilterButton } from '../../../shared/components/FilterButton';
import { useQuery, useQueryActive } from '../../../shared/utils/route';
import { useJobTypeListForSelectsQuery, useListFieldworkersQuery, useUserSessionQuery } from '../../../shared/types/generated';
import { Dropdown } from '../../../shared/components/Dropdown';
import { DB_STATUS } from '../../../shared/constants/common';
import { JobsListFilters, JOB_STATUSES_OPTIONS } from '../jobs-types';
import { allFilter } from '../jobs-utils';
import { SortOrder } from '../../../shared/types/types';
import { UseValidateFeatureFlag } from '../../auth/auth-hooks';
import { FeatureFlags } from '../../../shared/constants/featureFlag';
import { USER_ROLES } from '../../session/session-model';

const DB_STATUS_VALUES = Object.values(DB_STATUS).map((item) => ({
  value: item,
  label: item,
}));

const STATUS_OPTIONS = [
  ...JOB_STATUSES_OPTIONS
];

type JobFilterProps = {
  filters: JobsListFilters;
};

export const JobFilter: React.FC<JobFilterProps> = ({ filters }) => {
  const theme = useTheme();
  const featureFlagJobFieldWorkerFilter = UseValidateFeatureFlag(FeatureFlags.JOB_FILTER_FIELDWORKER);
  const currentSession = useUserSessionQuery();
  const userRole = currentSession?.data?.user?.roles?.items?.find((roles) => roles.name === USER_ROLES.ADMIN)?.name;
  const { data: jobsTypesData } = useJobTypeListForSelectsQuery();
  const { data: fieldWorkerList } = useListFieldworkersQuery({
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        archived: {
          is_empty: true
        }
      },
      sort: {
        fullName: SortOrder.Asc
      }
    }
  });
  const { searchQuery, query, resetQuery } = useQuery();
  const isActive = useQueryActive(query);

  const onChangeField =
    (field: string) =>
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): void => {
      searchQuery({
        page: 1, // when change a filter we should reset the page
        [field]: event.target.value,
      });
    };

  const onChangeSelect =
    (field: string) =>
    (event: SelectChangeEvent<string | number | unknown>): void => {
      searchQuery({
        page: 1, // when change a filter we should reset the page
        [field]: event?.target?.value as string | number,
      });
    };

  const onChangeAutoFill = (field: string) => (event: SyntheticEvent<Element, Event>, value: {
    label: string,
    value: string
  } | null): void => {
    searchQuery({
      page: 1, // when change a filter we should reset the page
      [field]: value?.value as string,
    });
  };

  const FIELDWORKERSOPTIONS = useMemo(() => {
    const returnArr = fieldWorkerList?.fieldworkersList?.items?.map((item) => ({
      label: item.fullName || item.email || '',
      value: item.id,
    }));
    return [
      allFilter,
      ...returnArr || [],
    ];
  }, [fieldWorkerList]);
  return (
    <Dropdown
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      button={({ toggle }) => (
        <Stack justifyContent="center">
          <FilterButton isActive={isActive} onClick={toggle} />
        </Stack>
      )}
    >
      <Stack paddingTop='10px' paddingBottom='10px' direction="column" spacing={1} maxHeight='70vh' overflow='auto'>
        <Stack direction="column" spacing={1}>
          <Typography variant="subtitle2">Creation Date</Typography>
          <Stack spacing={1} direction="row">
            <TextField
              label="From"
              type="date"
              value={filters.from}
              onChange={onChangeField('from')}
              fullWidth
            />
            <TextField
              label="To"
              type="date"
              value={filters.to}
              onChange={onChangeField('to')}
              fullWidth
            />
          </Stack>
        </Stack>
        <Stack direction="column" spacing={1}>
          <Typography variant="subtitle2">Start Date</Typography>
          <Stack spacing={1} direction="row">
            <TextField
              label="From"
              type="date"
              value={filters.fromStart}
              onChange={onChangeField('fromStart')}
              fullWidth
            />
            <TextField
              label="To"
              type="date"
              value={filters.toStart}
              onChange={onChangeField('toStart')}
              fullWidth
            />
          </Stack>
        </Stack>
        <Stack direction="column" spacing={1}>
          <Typography variant="subtitle2">Job Type</Typography>
          <FormControl>
            <InputLabel>Select Type</InputLabel>
            <Select
              label="Select Type"
              value={filters.type}
              onChange={onChangeSelect('type')}
            >
              <MenuItem value="" >All</MenuItem>
              {jobsTypesData?.jobTypesList.items.map((item) => (
                <MenuItem key={item.id} value={item.id} >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack direction="column" spacing={1}>
          <Typography variant="subtitle2">Status</Typography>
          <FormControl>
            <InputLabel>Select Status</InputLabel>
            <Select
              label="Select Status"
              value={filters.status}
              onChange={onChangeSelect('status')}
            >
              <MenuItem value="" >ALL</MenuItem>
              {STATUS_OPTIONS.map((item) => (
                <MenuItem key={item} value={item} >
                  {item.replaceAll('_', ' ')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        {featureFlagJobFieldWorkerFilter && userRole && (
          <Stack direction="column" spacing={1}>
            <Typography variant="subtitle2">Fieldworker</Typography>
            <FormControl>
              <Autocomplete
                options={FIELDWORKERSOPTIONS}
                autoHighlight
                getOptionLabel={(option) => option.value ? option.label : ''}
                onChange={onChangeAutoFill('fieldWorker')}
                value={FIELDWORKERSOPTIONS.find((item) => item.value === filters?.fieldWorker)}
                renderOption={(props, option) => (
                  <MenuItem {...props} value={option?.value} key={option?.value}>
                    {option?.label}
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Fieldworker"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </FormControl>
          </Stack>)
        }
        <Stack direction="column" spacing={1}>
          <Typography variant="subtitle2">DB Status</Typography>
          <FormControl>
            <InputLabel>DB Status</InputLabel>
            <Select
              label='DB Status'
              value={filters.dbStatus}
              onChange={onChangeSelect('dbStatus')}
            >
              {DB_STATUS_VALUES.map((item) => (
                <MenuItem key={item.value} value={item.value} >
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          spacing={1}
          sx={{
            display: 'none',
            [theme.breakpoints.down('md')]: {
              display: 'block',
            },
          }}
        >
          <Typography variant="subtitle2">Rows</Typography>
          <FormControl fullWidth>
            <Select
              value={filters.rows}
              fullWidth
              onChange={onChangeSelect('rows')}
            >
              <MenuItem value={10}>10 Rows(s)</MenuItem>
              <MenuItem value={20}>20 Rows(s)</MenuItem>
              <MenuItem value={30}>30 Rows(s)</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Stack paddingTop={2}>
          <Button onClick={resetQuery} variant="contained" size="medium">
            Reset filters
          </Button>
        </Stack>
      </Stack>
    </Dropdown>
  );
};
