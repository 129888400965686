import IconButton from '@mui/material/IconButton';
import FilterList from '@mui/icons-material/FilterList';
import Tooltip from '@mui/material/Tooltip';

type FilterButtonProps = {
  isActive?: boolean;
  onClick?: (currentTarget: EventTarget & HTMLElement) => void;
};

export const FilterButton: React.FC<FilterButtonProps> = ({
  isActive,
  onClick,
}) => (
  <Tooltip title="Apply filters">
    <IconButton
      aria-label="filters"
      component="button"
      color={isActive ? 'primary' : undefined}
      size="small"
      sx={(_theme) => ({
        transition: 'color .3s ease-out',
        backgroundColor: isActive ? _theme.palette.primary.main : 'none',
        '&:hover': {
          backgroundColor: _theme.palette.primary.main,
        },
        '&:hover svg': {
          color: _theme.palette.common.white,
        },
      })}
      onClick={({ currentTarget }: React.MouseEvent<HTMLElement>) => {
        onClick?.(currentTarget);
      }}
    >
      <FilterList
        sx={(_theme) => ({
          transition: 'color .3s ease-out',
          color: isActive ? _theme.palette.common.white : 'none',
        })}
      />
    </IconButton>
  </Tooltip>
);
