import React, { useState, useRef, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Grid,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  Typography,
  Box,
  Button,
  Link,
  styled,
  Modal,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  SpeedDial,
  SpeedDialAction,
  useTheme,
  useMediaQuery,
  Tooltip,
  IconButton,
} from '@mui/material';
import * as filestack from 'filestack-js';
import { LoadingButton } from '@mui/lab';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import Download from '@mui/icons-material/Download';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ClockIcon from '@mui/icons-material/AccessAlarm';
import EditIcon from '@mui/icons-material/Edit';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArchiveIcon from '@mui/icons-material/Archive';
import Call from '@mui/icons-material/Call';
import Email from '@mui/icons-material/Email';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { RowResponsive } from '../../shared/components/RowResponsive';
import { snackbar } from '../../shared/components/Snackbar';
import { MainLoader } from '../../shared/components/MainLoader';
import { archiveJobVariables, jobHistoryFilter } from './jobs-utils';
import {
  useJobDetailQuery,
  useJobUpdateMutation,
  useClockListQuery,
  useUserSessionQuery,
  useCreateClockMutationMutation,
  SortOrder,
  useGetApiKeyQuery,
  Job_CustomerDocs_FileCreateInput,
  JobCustomerDocsUpdateRelationInput,
} from '../../shared/types/generated';
import { JobStatusPoint } from './components/StatusPoint';
import { humanizeDate, clearQuerysFromCache } from '../../shared/utils';
import { useJobsListSuscription } from './jobs-hooks';
import { useUserHasRole } from '../session/session-hooks';
import { PdfComponent } from './components/PdfComponent';
import { UseValidateFeatureFlag } from '../auth/auth-hooks';
import { ViewBackButton } from '../../shared/components/ViewBackButton';
import { DisplayPhoneNumber } from '../../shared/components/DisplayPhoneNumber';
import { FeatureFlags } from '../../shared/constants/featureFlag';

const CardContend = styled(Box)(({ theme }) => ({
  position: 'relative',
  maxWidth: '80%',
  backgroundColor: '#fff',
  margin: '5% auto 0 auto',
  borderRadius: '10px',
  boxShadow: '0px 3px 0px #EDEDF6',
  padding: '35px 0 35px 25px',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    margin: '0',
    marginTop: '20px',
    height: 'auto',
    maxHeight: 'none',
    padding: '25px 35px',
  },
}));

const MdPaper = styled(Box)(({ theme }) => ({
  position: 'relative',
  minWidth: '80%',
  maxWidth: '80%',
  height: 'auto',
  backgroundColor: '#fff',
  margin: '50px auto 0 auto',
  borderRadius: '10px',
  boxShadow: '0px 3px 0px #EDEDF6',
  padding: '35px 20px 35px 25px',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    margin: '0',
    height: 'auto',
    marginTop: '25px',
  },
}));

/**
 * @returns {JSX.Element} - Detail job.
 */
export function JobDetailView(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const featureFlag = UseValidateFeatureFlag(FeatureFlags.INPUTS);
  const [clientFileStack, setClientFileStack] = useState<filestack.Client>();
  const [loadingUploadButton, setLoadingUploadButton] = useState<boolean>(false);
  const [openDelDoc, setOpenDelDoc] = useState<boolean>(false);
  const [docToDelete, setDocToDelete] = useState<string>('');
  const [openDelFwDoc, setOpenDelFwDoc] = useState<boolean>(false);
  const [fwDocToDelete, setFwDocToDelete] = useState<string>('');
  const customerDocsFeatureFlag = UseValidateFeatureFlag(FeatureFlags.CUSTOMER_DOCS);
  const fieldworkerDocsFeatureFlag = UseValidateFeatureFlag(FeatureFlags.FIELDWORKER_DOCS);
  const pdfImagesRowsFeatureFlag = UseValidateFeatureFlag(FeatureFlags.PDF_IMAGE_ROWS);
  const inputRef = useRef<HTMLButtonElement>(null);
  const [openModalWarning, setOpenModalWarning] = useState<boolean>(false);
  const [speedDialDownloadButton, setSpeedDialDownloadButton] =
    useState<boolean>(false);
  const theme = useTheme();
  const [path, setPath] = useState<string>('');
  const userSession = useUserSessionQuery();
  const idUser = userSession?.data?.user.id;
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const history = useHistory();
  const IS_FIELDWORKER = useUserHasRole('FIELDWORKER');
  const IS_ADMIN = useUserHasRole('ADMIN');
  const [modalImage, setModalImage] = useState<boolean>(false);
  const [urlImageModal, setUrlImageModal] = useState<string>('');
  const { data: dataFileStack } = useGetApiKeyQuery();
  /**
   * @returns {void} - Handle open.
   */
  const handleOpen = (): void => setModalImage(true);

  /**
   * @returns {void} - Handle close.
   */
  const handleClose = (): void => setModalImage(false);
  useEffect(() => {
    if (dataFileStack?.fileUploadInfo.apiKey) {
      setClientFileStack(
        filestack.init(dataFileStack?.fileUploadInfo.apiKey, {
          security: {
            signature: dataFileStack?.fileUploadInfo.signature as string,
            policy: dataFileStack?.fileUploadInfo.policy as string,
          },
        }),
      );
      setPath(dataFileStack?.fileUploadInfo.path as string);
    }
  }, [dataFileStack]);
  const { data } = useJobDetailQuery({
    fetchPolicy: 'cache-and-network', 
    variables: {
      id: id ?? '',
    },
  });

  const { data: dataHistory } = useJobsListSuscription({
    filter: jobHistoryFilter(data?.job?.address?.id ?? '', id ?? ''),
    sort: [
      {
        createdAt: SortOrder.Desc,
      },
    ],
  });

  const { data: clockData, refetch } = useClockListQuery({
    variables: { filter: { job: { id: { equals: id } } } },
  });

  /**
   * @returns {void} - Result.
   */
  const pdfMobile = (): void => {
    if (inputRef.current !== null) {
      inputRef.current.click();
    }
  };

  const [createClockMutationMutation, { loading: loadingClockMutation }] =
    useCreateClockMutationMutation({
      /**
       * @param ob - Apollo response.
       * @returns {void} - Result.
       */
      onCompleted: (ob) => {
        snackbar.success(
          ob.clockInOutCreate.type === 'CLOCK_IN'
            ? 'Clock In success'
            : 'Clock Out success',
        );
        refetch();
      },

      /**
       * @param {object} cache - Array position to delete.
       * @returns {void}.
       */
      update: (cache) => clearQuerysFromCache(['clockInOutsList'])(cache),
    });

  const [jobUpdateMutation, { loading }] = useJobUpdateMutation({
    onError:(error) => {
      console.log(error);
      setLoadingUploadButton(false);
    },
    /**
     * @param cache - Apollo chache.
     */
    update: clearQuerysFromCache([
      'jobsList',
      'job',
      'fieldworker',
      'fieldworkersList',
    ]),
  });

  if (data) {
    const relationJobData = data.job.jobJobFieldWorkerRelation.items.find(
      (element) => element?.fieldworker?.user?.id === idUser,
    );

    const filterClocks = clockData?.clockInOutsList?.items.filter(
      (item) => item?.fieldworker?.user?.id === idUser,
    );

    const findUser = data?.job.jobJobFieldWorkerRelation?.items.filter(
      (item) => item?.fieldworker?.user?.id === idUser,
    );

    let clock = 'Clock In';

    if (
      filterClocks !== undefined &&
      filterClocks.length >= 1 &&
      filterClocks[0].type === 'CLOCK_IN'
    ) {
      clock = 'Clock Out';
    }

    const handleAttachFile = (): void => {
      if (!clientFileStack || data?.job?.customerDocs?.items?.length >= 5) {
        return;
      }
      const uploadedFilesNumb = data?.job?.customerDocs?.items?.length || 0;
      const options = {
        fromSources: ['local_file_system'],
        accept: ['.pdf', '.docx'],
        maxFiles: 5 - uploadedFilesNumb,
        /**
         * @param files - Filestack files client.
         */
        onUploadDone: async (files: filestack.PickerResponse): Promise<void> => {
          setLoadingUploadButton(true);
          const uploadedFiles = files.filesUploaded;
          const customerDocs: JobCustomerDocsUpdateRelationInput = {
            create: [...uploadedFiles?.map((item) => ({
                fileId: item.handle,
                filename: item.filename,
                public: true,
              })) as Job_CustomerDocs_FileCreateInput[],
            ]
          };
          await jobUpdateMutation({
            variables: {
              id: id ?? '',
              data: {
                customerDocs,
              }
            }
          });
          snackbar.success('Document uploaded');
          setLoadingUploadButton(false);
        },
        storeTo: { path },
      };
      clientFileStack.picker(options).open();
    };

    const handleDownloadFiles = async (url: string, fileName: string): Promise<void> => {
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    const handleShowdocs = (fileId: string): void => {
      if(clientFileStack) {
        clientFileStack?.preview(fileId);
      }
    };

    const handleCloseDelMod = (): void => {
      setDocToDelete('');
      setOpenDelDoc(false);
    };
    const handleCloseDelFwMod = (): void => {
      setFwDocToDelete('');
      setOpenDelFwDoc(false);
    };

    return (
      <>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent={
              data.job.archived === null ? 'space-between' : 'flex-end'
            }
            alignItems="left"
          >
            <Typography variant="h4">Jobs Information</Typography>
            <ViewBackButton />
          </Box>
          {IS_FIELDWORKER ? (
            <Box
              justifyContent={
                data?.job.archived === null ? 'space-between' : 'flex-end'
              }
              alignItems="center"
              sx={{ width: 'auto', display: ['none', 'flex'], paddingRight: ['0', '0', '3%', '5%', '7%'] }}
            >
              {findUser.length > 0 && (
                <>
                  {data.job.status !== 'CLOSED' && (
                  <Tooltip title={clock}>
                    <LoadingButton
                      loading={loadingClockMutation}
                      variant="outlined"
                      sx={{ margin: 1 }}
                      size='large'
                      onClick={() => {
                        if (data.job.status !== 'CLOSED') {
                          createClockMutationMutation({
                            variables: {
                              clock:
                                clock === 'Clock In' ? 'CLOCK_IN' : 'CLOCK_OUT',
                              idJob: id !== undefined ? id : '',
                              idFieldworker:
                                relationJobData?.fieldworker.id === undefined
                                  ? ''
                                  : relationJobData.fieldworker.id,
                              // relationJobData?.id !== undefined
                              //   ? relationJobData.id
                              //   : '',
                            },
                          });
                        }
                      }}
                    >
                      <ClockIcon />
                    </LoadingButton>
                  </Tooltip>
                  )}
                  {data.job.archived === null && findUser.length > 0 && (
                    <Tooltip title='Edit job'>
                      <Button
                        variant="contained"
                        sx={{ margin: 1 }}
                        size='large'
                        href={`/jobs/edit/${id}`}
                      >
                        <EditIcon />
                      </Button>
                    </Tooltip>
                  )}
                  {(() => data !== undefined)() && findUser.length > 0 && (
                    <PDFDownloadLink
                      document={
                        <PdfComponent data={data} clockData={clockData} featureFlag={pdfImagesRowsFeatureFlag}/>
                      }
                      fileName={`Report-Job-${id}-${new Date()}.pdf`}
                    >
                      {(obj) =>
                        !obj.blob || obj.loading ? (
                          <Tooltip title='Generating report..'>
                            <Button
                              variant="contained"
                              sx={{ margin: 1 }}
                              disabled
                              size='large'
                              >
                              <Download />
                            </Button>
                          </Tooltip>
                        ) : (
                          <Tooltip title='Download report'>
                            <Button
                              variant="contained"
                              sx={{ margin: 1 }}
                              size='large'
                            >
                              <Download />
                            </Button>
                          </Tooltip>
                        )
                      }
                    </PDFDownloadLink>
                  )}
                </>
              )}
            </Box>
          ) : (
            <Box
              justifyContent={
                data?.job.archived === null ? 'space-between' : 'flex-end'
              }
              alignItems="center"
              sx={{ width: 'auto', display: ['none', 'flex'], 
              paddingRight: ['0', '0', '3%', '5%', '7%']
            }}
            >
              <Tooltip title={data.job.archived === null ? 'Archive Job' : 'Active Job'}>
                <Button
                  variant="contained"
                  sx={{ margin: 1 }}
                  size='large'
                  color={data.job.archived === null ? 'error' : 'primary'}
                  onClick={() => {
                    setOpenModalWarning(true);
                  }}
                >
                  {data.job.archived === null ? <ArchiveIcon /> :  <DriveFolderUploadIcon />}
                </Button>
              </Tooltip >
              {data.job.archived === null && customerDocsFeatureFlag && (
                <Tooltip title='Upload customer documents'>
                  <Button
                    variant="contained"
                    color='secondary'
                    sx={{ margin: 1 }}
                    size='large'
                    onClick={handleAttachFile}
                    disabled={loadingUploadButton || data?.job?.customerDocs?.items?.length >= 5}
                    >
                    <FileUploadIcon sx={{ color: '#ffff' }}/>
                  </Button>
                </Tooltip >
              )}
              {data.job.archived === null && (
                <Tooltip title='Edit job'>
                  <Button
                    variant="contained"
                    sx={{ margin: 1 }}
                    size='large'
                    href={`/jobs/edit/${id}`}
                  >
                    <EditIcon />
                  </Button>
                </Tooltip >
              )}
              {data.job && (
                <PDFDownloadLink
                  document={<PdfComponent data={data} clockData={clockData} featureFlag={pdfImagesRowsFeatureFlag}/>}
                  fileName={`Report-Job-${id}-${new Date()}.pdf`}
                >
                  {(obj) =>
                    !obj.blob || obj.loading ? (
                      <Tooltip title='Generating report..'>
                        <Button
                          variant="contained"
                          sx={{ margin: 1 }}
                          disabled
                          size='large'
                          >
                          <Download />
                        </Button>
                      </Tooltip>
                    ) : (
                      <Tooltip title='Download report'>
                        <Button
                          variant="contained"
                          sx={{ margin: 1 }}
                          size='large'
                          >
                          <Download />
                        </Button>
                      </Tooltip>
                    )
                  }
                </PDFDownloadLink>
              )}
            </Box>
          )}
        </Box>

        <Box
          sx={{
            zIndex: 50,
            display: ['flex', 'none'],
            position: 'fixed',
            transform: 'translateZ(0px)',
            flexGrow: 1,
            height: '100%',
            top: '0',
            right: '20px',
            bottom: '30px',
          }}
        >
          {IS_FIELDWORKER ? (
            <>
              {findUser.length > 0 && (
                <>
                  {(() => data !== undefined)() && (
                    <PDFDownloadLink
                      document={
                        <PdfComponent data={data} clockData={clockData} featureFlag={pdfImagesRowsFeatureFlag}/>
                      }
                      fileName={`Report-Job-${id}-${new Date()}.pdf`}
                    >
                      {(obj) => {
                        setSpeedDialDownloadButton(obj.loading);
                        return (
                          <Tooltip title='Download report'>
                            <Button
                              variant="contained"
                              sx={{ margin: 1, display: 'none' }}
                              ref={inputRef}
                              id="download"
                              size='large'
                            >
                              <Download />
                            </Button>
                          </Tooltip>
                        );
                      }}
                    </PDFDownloadLink>
                  )}
                  {data.job.archived === null && (
                    <SpeedDial
                      ariaLabel="SpeedDial basic example"
                      sx={{
                        position: 'absolute',
                        bottom: 16,
                        right: 16,
                        zIndex: '3',
                      }}
                      icon={<MoreVertIcon />}
                    >
                      <SpeedDialAction
                        icon={<EditIcon />}
                        onClick={() => history.push(`/jobs/edit/${id}`)}
                        tooltipTitle="Edit"
                      />

                      {data.job.status !== 'CLOSED' && (
                        <SpeedDialAction
                          icon={<ClockIcon />}
                          tooltipTitle={clock}
                          onClick={() => {
                            createClockMutationMutation({
                              variables: {
                                clock:
                                  clock === 'Clock In'
                                    ? 'CLOCK_IN'
                                    : 'CLOCK_OUT',
                                idJob: id !== undefined ? id : '',
                                idFieldworker:
                                  relationJobData?.fieldworker.id === undefined
                                    ? ''
                                    : relationJobData.fieldworker.id,
                                // relationJobData?.id !== undefined
                                //   ? relationJobData.id
                                //   : '',
                              },
                            });
                          }}
                        />
                      )}
                      {speedDialDownloadButton === false && (
                        <SpeedDialAction
                          icon={<Download />}
                          open={false}
                          onClick={() => pdfMobile()}
                          tooltipTitle="Download PDF Report"
                        />
                      )}
                    </SpeedDial>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {(() => data !== undefined)() && (
                <PDFDownloadLink
                  document={<PdfComponent data={data} clockData={clockData} featureFlag={pdfImagesRowsFeatureFlag}/>}
                  fileName={`Report-Job-${id}-${new Date()}.pdf`}
                >
                  {(obj) => {
                    setSpeedDialDownloadButton(obj.loading);
                    return (
                      <Tooltip title='Download report'>
                        <Button
                          variant="contained"
                          sx={{ margin: 1, display: 'none' }}
                          ref={inputRef}
                          id="download"
                          size='large'
                        >
                          <Download />
                        </Button>
                      </Tooltip>
                    );
                  }}
                </PDFDownloadLink>
              )}
              <SpeedDial
                ariaLabel="SpeedDial basic
                example"
                sx={{
                  position: 'absolute',
                  bottom: 16,
                  right: 16,
                  zIndex: '3',
                }}
                icon={<MoreVertIcon />}
              >
                {data.job.archived === null && (
                  <SpeedDialAction
                    icon={<EditIcon />}
                    onClick={() => history.push(`/jobs/edit/${id}`)}
                    tooltipTitle="Edit"
                  />
                )}
                {data.job.archived === null && customerDocsFeatureFlag && (
                  <SpeedDialAction
                    icon={<FileUploadIcon />}
                    onClick={handleAttachFile}
                    tooltipTitle='Upload customer documents'
                  />
                )}
                <SpeedDialAction
                  icon={
                    data.job.archived === null ? (
                      <ArchiveIcon />
                    ) : (
                      <DriveFolderUploadIcon />
                    )
                  }
                  tooltipTitle={
                    data.job.archived === null ? 'Archive' : 'Active'
                  }
                  onClick={() => {
                    setOpenModalWarning(true);
                  }}
                />

                {speedDialDownloadButton === false && (
                  <SpeedDialAction
                    icon={<Download />}
                    open={false}
                    onClick={() => pdfMobile()}
                    tooltipTitle="Download PDF Report"
                  />
                )}
              </SpeedDial>
            </>
          )}
        </Box>
        <CardContend sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={matches ? 2 : 4}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <Typography variant="h5" component="h2" color="white">
                Information
              </Typography>
              <JobStatusPoint status={data.job.status} />
            </Grid>
            <Grid item sm={4} xs={12} alignItems="flex-start">
              <Typography gutterBottom variant="h6" component="h2">
                Customer Name
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {data.job.address.customer.name}
              </Typography>
            </Grid>
            <Grid item sm={4} xs={12} alignItems="flex-start">
              <Typography gutterBottom variant="h6" component="h2">
                Job Code
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                #{data.job.code}
              </Typography>
            </Grid>
            <Grid item container sm={4} xs={12} alignItems="flex-start">
              <Typography gutterBottom variant="h6" component="h2">
                Contact
              </Typography>
              <Grid item container alignItems="center" direction="row">
                <Email color="primary" style={{ marginRight: 5 }} />
                <Box width="90%" sx={{ wordBreak: 'break-all' }}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {`${data.job.address.customer.email}`}
                  </Typography>
                </Box>
              </Grid>
              <Grid item container alignItems="center" direction="row">
                <DisplayPhoneNumber leftIcon={<Call color="primary" style={{ marginRight: 5 }} />} variant="body2" phoneNumber={data.job.address.customer.phoneNumber} />
              </Grid>
              {data.job.address.customer.customerPhoneNumberRelation.items.map(
                (value) => (
                  <Grid key={value.id} item container alignItems="center" direction="row">
                    <DisplayPhoneNumber leftIcon={<Call color="primary" style={{ marginRight: 5 }} />} variant="body2" phoneNumber={value.phoneNumber} />
                  </Grid>
                ),
              )}
            </Grid>
            <Grid item sm={4} xs={12} alignItems="flex-start">
              <Typography gutterBottom variant="h6" component="h2">
                Address
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <a
                  href={`https://www.google.com/maps/place/${data.job.address.streetAddress}+${data.job.address.city}+${data.job.address.state}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  {`${data.job.address.streetAddress}, ${data.job.address.city}, ${data.job.address.state}`}
                </a>
              </Typography>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography gutterBottom variant="h6" component="h2">
                Type
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {data.job.type?.name}
              </Typography>
            </Grid>

            <Grid item sm={4} xs={12}>
              <Typography gutterBottom variant="h6" component="h2">
                Priority
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {data.job.priority}
              </Typography>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography gutterBottom variant="h6" component="h2">
                Fieldworker
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {data.job.jobJobFieldWorkerRelation.items
                  .map((item) => item?.fieldworker?.fullName)
                  .join(', ')}
              </Typography>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography gutterBottom variant="h6" component="h2">
                Creation Date
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {humanizeDate(data.job.createdAt)}
              </Typography>
            </Grid>

            <Grid item sm={4} xs={12}>
              <Typography gutterBottom variant="h6" component="h2">
                Start Date
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {humanizeDate(data.job.startDateTime) === '-'
                  ? 'Unasigned'
                  : humanizeDate(data.job.startDateTime)}
              </Typography>
            </Grid>
            {featureFlag && (
              <>
                <Grid item sm={4} xs={12}>
                  <Typography gutterBottom variant="h6" component="h2">
                    Dispatched By
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {data.job.jobJobInfoRelation?.dispatchedBy || ''}
                  </Typography>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Typography gutterBottom variant="h6" component="h2">
                    Caller Name
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {data.job.jobJobInfoRelation?.callerName || ''}
                  </Typography>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Typography gutterBottom variant="h6" component="h2">
                    Caller Cell
                  </Typography>
                  {data.job.jobJobInfoRelation?.callerCell && (<DisplayPhoneNumber variant="body2" phoneNumber={data.job.jobJobInfoRelation?.callerCell} />)}
                </Grid>
              </>
            )}

            <Grid item sm={12} xs={12} >
              <Typography gutterBottom variant="h6" component="h2">
                Description
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                style={{ overflowWrap: 'break-word' }}
              >
                {data.job.description}
              </Typography>
            </Grid>
            {IS_ADMIN && (
              <Grid item sm={12} xs={12} >
                <Typography gutterBottom variant="h6" component="h2">
                  Admin Notes
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  style={{ overflowWrap: 'break-word' }}
                >
                  {data.job?.adminNote?.note ?? ''}
                </Typography>
              </Grid>
            )}
            <Grid item sm={12} xs={12} >
              <Typography gutterBottom variant="h6" component="h2">
                Fieldworker Notes
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                style={{ overflowWrap: 'break-word' }}
              >
                {data.job.fieldworkerNotes}
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12} >
              <Typography gutterBottom variant="h6" component="h2">
                Office Notes
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                style={{ overflowWrap: 'break-word' }}
              >
                {data.job?.officeNoteJobRelation?.items[0]?.officeNotes ?? ''}
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12} >
              <Typography gutterBottom variant="h6" component="h2">
                Work Performed
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                style={{ overflowWrap: 'break-word' }}
              >
                {data.job.workPerformed}
              </Typography>
            </Grid>
            <Grid item sm={4} xs={12} >
              <Typography gutterBottom variant="h6" component="h2">
                Unit Serviced
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                style={{ overflowWrap: 'break-word' }}
              >
                {data.job.unitServiced}
              </Typography>
            </Grid>
            <Grid item sm={4} xs={12} >
              <Typography gutterBottom variant="h6" component="h2">
                Model
              </Typography>
              {data?.job?.model && (<Typography
                variant="body2"
                color="textSecondary"
                component="p"
                style={{ overflowWrap: 'break-word' }}
              >
                {`#${data?.job?.model || ''}`}
              </Typography>)}
            </Grid>
            <Grid item sm={4} xs={12} >
              <Typography gutterBottom variant="h6" component="h2">
                Serial
              </Typography>
              {data?.job?.serial && (<Typography
                variant="body2"
                color="textSecondary"
                component="p"
                style={{ overflowWrap: 'break-word' }}
              >
                {`#${data?.job?.serial || ''}`}
              </Typography>)}
            </Grid>

            <Grid item sm={4} xs={12} >
              <Typography gutterBottom variant="h6" component="h2">
                Purchase Order
              </Typography>
              {data?.job?.purchaseOrder && (<Typography
                variant="body2"
                color="textSecondary"
                component="p"
                style={{ overflowWrap: 'break-word' }}
              >
                {`#${data?.job?.purchaseOrder || ''}`}
              </Typography>)}
            </Grid>
            <Grid item sm={4} xs={12} >
              <Typography gutterBottom variant="h6" component="h2">
                Work Order
              </Typography>
              {data?.job?.workOrder && (<Typography
                variant="body2"
                color="textSecondary"
                component="p"
                style={{ overflowWrap: 'break-word' }}
              >
                {`#${data?.job?.workOrder || ''}`}
              </Typography>)}
            </Grid>
            <Grid item sm={4} xs={12} >
              <Typography gutterBottom variant="h6" component="h2">
                Call ID
              </Typography>
              {data?.job?.callID && (<Typography
                variant="body2"
                color="textSecondary"
                component="p"
                style={{ overflowWrap: 'break-word' }}
              >
                {`#${data?.job?.callID || ''}`}
              </Typography>)}
            </Grid>
            <Grid item sm={4} xs={12} >
              <Typography gutterBottom variant="h6" component="h2">
                Signature
              </Typography>
              {data?.job?.signature !== null &&
                data?.job?.signature.length > 0 && (
                  <img
                    src={data?.job?.signature}
                    alt="signature"
                    width="100%"
                  />
                )}
            </Grid>
            <Grid item sm={4} xs={12} >
              <Typography gutterBottom variant="h6" component="h2">
                Signature Name
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {data?.job.signatureName ?? ''}
              </Typography>
            </Grid>
            <Grid item sm={4} xs={12} >
              <Typography gutterBottom variant="h6" component="h2">
                Signature Title
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {data?.job.signatureTitle ?? ''}
              </Typography>
            </Grid>
            <Grid item lg={12} sm={12} xs={12} >
              <Typography gutterBottom variant="h6" component="h2">
                Images
              </Typography>
              <Box display="flex" flexDirection="row" flexWrap="wrap">
                {data?.job?.images.items.length > 0 &&
                  data?.job.images.items.map(
                    (item, index) =>
                      index <= 9 && (
                        <a
                          href="#"
                          onClick={() => {
                            handleOpen();
                            setUrlImageModal(
                              `https://cdn.filestackcontent.com/resize=width:1080${
                                item.downloadUrl.split('.com')[1]
                              }`,
                            );
                          }}
                        >
                          <img
                            src={`https://cdn.filestackcontent.com/resize=width:1080${
                              item.downloadUrl.split('.com')[1]
                            }`}
                            alt="signature"
                            width={150}
                            style={{ margin: 5 }}
                          />
                        </a>
                      ),
                  )}
              </Box>
            </Grid>
          </Grid>
        </CardContend>
        <Modal
          open={modalImage}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              bgcolor: 'background.paper',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <img
              src={urlImageModal}
              alt="signature"
              width={200}
              style={{ margin: 5 }}
            />
          </Box>
        </Modal>
        {fieldworkerDocsFeatureFlag && data?.job.fieldworkerDocs?.items?.length > 0 && 
        <MdPaper sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5">Fieldworker Documents</Typography>
            </Grid>
            <Grid item xs={12}>
              {data?.job?.fieldworkerDocs?.items?.map((item, index) => (
                  <Grid 
                    container 
                    xs={12} 
                    flexWrap='nowrap' 
                    justifyContent='space-between'
                  >
                    <Grid item sx={{
                      display: 'flex',
                      alignItems: 'center',
                      overflow: 'hidden',
                    }}>
                      <Link 
                        onClick={() => handleDownloadFiles(item.downloadUrl, item.filename)} color='secondary' 
                        textOverflow='ellipsis' 
                        noWrap
                        sx={{
                          textDecoration: 'none',
                        }}
                      >
                        {index + 1}.- {item.filename}
                      </Link>
                    </Grid>
                    <Grid item width='120px' display='flex' flexWrap='nowrap'>
                      <Tooltip title='Download'>
                        <IconButton color='primary' onClick={() => handleDownloadFiles(item.downloadUrl, item.filename)} sx={(themeP) => ({
                          transition: 'all .5s',
                          '&:hover': {
                            color: themeP.palette.secondary.light,
                            scale: '1.15'
                          },
                          })}>
                          <FileDownloadIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='View'>
                        <IconButton sx={{ 
                          color: 'primary',
                          transition: 'all .5s',
                          '&:hover': {
                            color:'#2BA668',
                            scale: '1.15'
                          },
                          }} onClick={() => handleShowdocs(item.fileId)}>
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                      {IS_ADMIN && <Tooltip title='Delete'>
                        <IconButton sx={{ 
                          color: 'primary',
                          transition: 'all .5s',
                          '&:hover': {
                            color:'#fa4d56',
                            scale: '1.15'
                          },
                          }} 
                          onClick={() => {
                            setFwDocToDelete(item.id);
                            setOpenDelFwDoc(true);
                          }}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </Tooltip>}
                    </Grid>
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
          <Dialog open={openDelFwDoc}>
            <DialogTitle id="warningTitle">Are you sure?</DialogTitle>
            <DialogContent>
              You confirm that you want to delete this document? <br />
              This document cannot be recovered after this action.
            </DialogContent>
            <DialogActions>
              <Button
                variant="default"
                onClick={handleCloseDelFwMod}
              >
                Cancel
              </Button>
              <LoadingButton
                variant='delete'
                loading={loading}
                onClick={async () => {
                  await jobUpdateMutation({
                    variables: {
                      id,
                      data: {
                        fieldworkerDocs: { 
                          disconnect: [{
                            id: fwDocToDelete,
                          }] 
                        }
                      }
                    },
                  });
                  setFwDocToDelete('');
                  setOpenDelFwDoc(false);
                }}
              >
                Delete
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </MdPaper>}
        {customerDocsFeatureFlag && !IS_FIELDWORKER && data?.job?.customerDocs?.items?.length > 0 && 
        <MdPaper sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5">Customer Documents</Typography>
            </Grid>
            <Grid item xs={12}>
              {data?.job?.customerDocs?.items?.map((item, index) => (
                  <Grid 
                    container 
                    xs={12} 
                    flexWrap='nowrap' 
                    justifyContent='space-between'
                  >
                    <Grid item sx={{
                      display: 'flex',
                      alignItems: 'center',
                      overflow: 'hidden',
                    }}>
                      <Link 
                        onClick={() => handleDownloadFiles(item.downloadUrl, item.filename)} color='secondary' 
                        textOverflow='ellipsis' 
                        noWrap
                        sx={{
                          textDecoration: 'none',
                        }}
                      >
                        {index + 1}.- {item.filename}
                      </Link>
                    </Grid>
                    <Grid item width='120px' display='flex' flexWrap='nowrap'>
                      <Tooltip title='Download'>
                        <IconButton color='primary' onClick={() => handleDownloadFiles(item.downloadUrl, item.filename)} sx={(themeP) => ({
                          transition: 'all .5s',
                          '&:hover': {
                            color: themeP.palette.secondary.light,
                            scale: '1.15'
                          },
                          })}>
                          <FileDownloadIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='View'>
                        <IconButton sx={{ 
                          color: 'primary',
                          transition: 'all .5s',
                          '&:hover': {
                            color:'#2BA668',
                            scale: '1.15'
                          },
                          }} onClick={() => handleShowdocs(item.fileId)}>
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                      {IS_ADMIN && <Tooltip title='Delete'>
                        <IconButton sx={{ 
                          color: 'primary',
                          transition: 'all .5s',
                          '&:hover': {
                            color:'#fa4d56',
                            scale: '1.15'
                          },
                          }} 
                          onClick={() => {
                            setDocToDelete(item.id);
                            setOpenDelDoc(true);
                          }}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </Tooltip>}
                    </Grid>
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
          <Dialog open={openDelDoc}>
            <DialogTitle id="warningTitle">Are you sure?</DialogTitle>
            <DialogContent>
              You confirm that you want to delete this document? <br />
              This document cannot be recovered after this action.
            </DialogContent>
            <DialogActions>
              <Button
                variant="default"
                onClick={handleCloseDelMod}
              >
                Cancel
              </Button>
              <LoadingButton
                variant='delete'
                loading={loading}
                onClick={async () => {
                  await jobUpdateMutation({
                    variables: {
                      id,
                      data: {
                        customerDocs: { 
                          disconnect: [{
                            id: docToDelete,
                          }] 
                        }
                      }
                    },
                  });
                  setDocToDelete('');
                  setOpenDelDoc(false);
                }}
              >
                Delete
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </MdPaper>}
        <MdPaper sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item sm={12} md={12} lg={12}>
              <Typography variant="h5">Parts Used</Typography>
            </Grid>
            <Grid item sm={12} md={12} lg={12} sx={{ width: '100%' }}>
              {matches ? (
                <>
                  {data !== undefined &&
                    data.job.jobJobPartsRelation.items.map((item, index) => (
                      <React.Fragment key={item.id}>
                        <RowResponsive
                          type="parts"
                          code={index + 1}
                          name={item.name}
                          time={humanizeDate(item?.createdAt)}
                          partNumber={item?.partNumber}
                          unit={item?.unit}
                        />
                      </React.Fragment>
                    ))}
                </>
              ) : (
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Code</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Part Number</TableCell>
                      <TableCell>Unit</TableCell>
                      <TableCell>Create At</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ height: '80%' }}>
                    {data !== undefined &&
                      data.job.jobJobPartsRelation.items.map((item, index) => (
                        <TableRow key={item.id}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            <Typography>{item.name}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{item?.partNumber || '-'}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{item?.unit || '-'}</Typography>
                          </TableCell>
                          <TableCell>{humanizeDate(item.createdAt)}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              )}
            </Grid>
          </Grid>
        </MdPaper>
        <MdPaper sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item sm={12} md={12} lg={12}>
              <Typography variant="h5">Parts Order</Typography>
            </Grid>
            <Grid item sm={12} md={12} lg={12} sx={{ width: '100%' }}>
              {matches ? (
                <>
                  {data !== undefined &&
                    data?.job?.jobJobPartOrderRelation?.items.map(
                      (item, index) => (
                        <React.Fragment key={item.id}>
                          <RowResponsive
                            type="parts"
                            code={index + 1}
                            name={item.name}
                            price={item.price}
                          />
                        </React.Fragment>
                      ),
                    )}
                </>
              ) : (
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Code</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ height: '80%' }}>
                    {data !== undefined &&
                      data.job.jobJobPartOrderRelation.items.map(
                        (item, index) => (
                          <React.Fragment key={item.id}>
                            <TableRow>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>
                                <Typography>{item.name}</Typography>
                              </TableCell>
                              <TableCell>{item.price}</TableCell>
                            </TableRow>
                          </React.Fragment>
                        ),
                      )}
                  </TableBody>
                </Table>
              )}
            </Grid>
          </Grid>
        </MdPaper>

        <MdPaper sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item sm={12} md={12} lg={12}>
              <Typography variant="h5">Clock In / Clock Out</Typography>
            </Grid>
            <Grid item sm={12} md={12} lg={12} sx={{ width: '100%' }}>
              {matches ? (
                <>
                  {clockData !== undefined &&
                    clockData.clockInOutsList.items.map((item, index) => {
                      if (item.type === 'ON_ROUTE') return null;
                      return (
                        <Box sx={{ marginTop: '10px' }} key={item.id}>
                          <RowResponsive
                            type="clocks"
                            code={index + 1}
                            typeClock={
                              item.type === 'CLOCK_IN'
                                ? 'Clock In'
                                : 'Clock Out'
                            }
                            location={item.location}
                            fieldworker={item?.fieldworker?.fullName}
                            time={humanizeDate(item.createdAt)}
                          />
                        </Box>
                      );
                    })}
                </>
              ) : (
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Type Clock</TableCell>
                      <TableCell>Fieldworker</TableCell>
                      <TableCell>Time</TableCell>
                      <TableCell>Location</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ height: '80%' }}>
                    {clockData !== undefined &&
                      clockData.clockInOutsList.items.map((item, index) => {
                        if (item.type === 'ON_ROUTE') return null;
                        return (
                          <React.Fragment key={item.id}>
                            <TableRow>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>
                                {item.type === 'CLOCK_IN'
                                  ? 'Clock In'
                                  : 'Clock Out'}
                              </TableCell>
                              <TableCell>
                                {item.fieldworker?.fullName}
                              </TableCell>
                              <TableCell>
                                {humanizeDate(item.createdAt)}
                              </TableCell>
                              <TableCell>
                                {item.location ? (
                                  <a
                                    href={`https://www.google.com/maps/place/${item.location.coordinates[0]},${item.location.coordinates[1]}`}
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    Map Link
                                  </a>
                                ) : (
                                  ''
                                )}
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        );
                      })}
                  </TableBody>
                </Table>
              )}
            </Grid>
          </Grid>
        </MdPaper>
        <MdPaper sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item sm={12} md={12} lg={12}>
              <Typography variant="h5">Address Job History</Typography>
            </Grid>
            <Grid item sm={12} md={12} lg={12} sx={{ width: '100%' }}>
              {matches ? (
                <>
                  {dataHistory !== undefined &&
                    dataHistory.jobsList.items.map((item) => (
                      <React.Fragment key={item.id}>
                        <RowResponsive
                          type="jobHistory"
                          key={item.id}
                          id={item.id}
                          code={item.code}
                          name={item.name}
                          date={humanizeDate(item?.startDateTime)}
                          description={item.description}
                          status={item.status}
                        />
                      </React.Fragment>
                    ))}
                </>
              ) : (
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Code</TableCell>

                      <TableCell>Description</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ height: '80%' }}>
                    {dataHistory !== undefined &&
                      dataHistory.jobsList.items.map((item) => (
                        <React.Fragment key={item.id}>
                          <TableRow>
                            <TableCell>
                              <Link
                                href={`/jobs/${item.id}`}
                                underline="none"
                                color="inherit"
                              >
                                {item.code}
                              </Link>
                            </TableCell>
                            <TableCell
                              sx={{ maxWidth: '400px', textAlign: 'justify' }}
                            >
                              {item.description}
                            </TableCell>
                            <TableCell>{item.status}</TableCell>
                            <TableCell>
                              {humanizeDate(item.createdAt)}
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                  </TableBody>
                </Table>
              )}
            </Grid>
          </Grid>
        </MdPaper>
        <Dialog open={openModalWarning}>
          <DialogTitle id="warningTitle">Are you sure?</DialogTitle>
          <DialogContent>
            {data.job.archived === null
              ? 'This job will be moved to the archived job list. Proceed with archiving?'
              : 'This Job will be activated'}
          </DialogContent>
          <DialogActions>
            <Button
              variant="default"
              onClick={() => {
                setOpenModalWarning(false);
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant={data.job.archived === null ? 'delete' : 'contained'}
              loading={loading}
              onClick={async () => {
                const con = !data.job.archived;
                await jobUpdateMutation({
                  variables: archiveJobVariables(id, con),
                });
                if (data !== undefined) {
                  snackbar.success(
                    data.job.archived === null ? 'Job Archived' : 'Job Actived',
                  );
          
                  setOpenModalWarning(false);
                  history.goBack();
                }
              }}
            >
              {data.job.archived === null ? 'archive' : 'active'}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  return <MainLoader />;
}
