import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Switch, Route } from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';
import { Auth } from '../modules/auth/Auth';
import { AuthCallback } from '../modules/auth/auth-callback/AuthCallback';

import { Logout } from '../modules/auth/Logout';
import { Layout } from '../shared/components/Layout';
import { CustomerView } from '../modules/customer/CustomerView';
import { CreateCustomerView } from '../modules/customer/CreateCustomerView';
import { DetailCustomerView } from '../modules/customer/DetailCustomerView';
import { EditCustomerView } from '../modules/customer/EditCustomerView';
import { Session } from '../modules/session/Session';
import { FieldworkerView } from '../modules/fieldworker/FieldworkerView';
import { CreateFieldworker } from '../modules/fieldworker/CreateFieldworker';
import { EditFieldworker } from '../modules/fieldworker/EditFieldworker';
import { Fieldworker } from '../modules/fieldworker/Fieldworker';
import { JobsListView } from '../modules/jobs/JobsListView';
import { JobEditView } from '../modules/jobs/JobEditView';
import { useDefaultRedirect } from '../modules/auth/auth-hooks';
import { ROUTES } from './routes-model';
import { JobCreateView } from '../modules/jobs/JobCreateView';
import { JobDetailView } from '../modules/jobs/JobDetailView';
import { RoleRoute } from './RoleRoute';
import { CustomerSession } from '../modules/session/CustomerSession';
import { Calendar } from '../modules/calendar/CalendarView';
import { Map } from '../modules/map/MapView';
import { AdminListView } from '../modules/adminList/AdminListView';
import { AdminCreateView } from '../modules/adminList/AdminCreateView';
import { AdminDetailView } from '../modules/adminList/AdminDetailView';
import { AdminEditView } from '../modules/adminList/AdminEditView';
import { MainLoader } from '../shared/components/MainLoader';
import { FeatureFlags } from '../shared/constants/featureFlag';

/*
WARNING: It is not necessary to add a protected route to each component within session,
an example of how to add the routes I will leave below

  <ProtectedRoute path="/">
    <Session>
     <Route path="/1" component={AuthCallback} />
     <Route path="/2/4" component={AuthCallback} />
     <Route path="/3/5" component={AuthCallback} />
    </Session>
  </ProtectedRoute>

*/

/**
 * @returns Routes.
 */
export const Routes: React.FC = () => {
  const { isLoading } = useAuth0();
  useDefaultRedirect('/jobs');

  return (
    isLoading ? <MainLoader /> : <>
      <Switch>
        <Route exact path="/auth" component={Auth} />
        <Route exact path="/auth/callback" component={AuthCallback} />
        <Route path="/logout" component={Logout} />
        <ProtectedRoute path="/">
          <Session>
            <Layout>
              <Switch>
                <Route path={ROUTES.JOBS.path} exact component={JobsListView} />
                <Route path="/customerSession" component={CustomerSession} />
                <Route
                  path={ROUTES.JOBS.internalPaths.JOB_CREATE.path}
                  exact
                  component={JobCreateView}
                />
                <Route
                  path={ROUTES.JOBS.internalPaths.JOB_DETAIL.path}
                  exact
                  component={JobDetailView}
                />
                <Route path="/jobs/edit/:id" component={JobEditView} exact />
                <Route path="/customers" component={CustomerView} exact />
                <Route
                  path="/customers/create"
                  component={CreateCustomerView}
                />
                <RoleRoute
                  exact
                  path="/fieldworker/create"
                  component={CreateFieldworker}
                  roles={['ADMIN']}
                />
                <RoleRoute
                  roles={['ADMIN']}
                  exact
                  path="/fieldworker/detail/:id"
                  component={Fieldworker}
                />
                <RoleRoute
                  roles={['ADMIN']}
                  exact
                  path="/fieldworker/detail/:id"
                  component={Fieldworker}
                />
                <RoleRoute
                  roles={['ADMIN']}
                  exact
                  path="/fieldworker/edit/:id"
                  component={EditFieldworker}
                />
                <RoleRoute
                  roles={['ADMIN']}
                  path="/customers/details"
                  component={DetailCustomerView}
                  exact
                />
                <RoleRoute
                  roles={['ADMIN']}
                  path="/customers/edit"
                  component={EditCustomerView}
                  exact
                />
                <RoleRoute
                  roles={['ADMIN']}
                  exact
                  path="/fieldworker"
                  component={FieldworkerView}
                />
                <RoleRoute
                  roles={['ADMIN']}
                  exact
                  path={ROUTES.CALENDAR.path}
                  component={Calendar}
                  flag={FeatureFlags.CALENDAR}
                />
                <RoleRoute 
                  roles={['ADMIN']}
                  path={ROUTES.MAP.path}
                  exact 
                  component={Map} 
                  flag={FeatureFlags.MAP}
                />
                {/* Admin table Routes */}
                <RoleRoute 
                  roles={['SUPER_ADMIN']}
                  path={ROUTES.ADMINS.path} 
                  exact 
                  component={AdminListView} 
                  flag={FeatureFlags.SUPER_ADMIN}
                />
                <RoleRoute 
                  roles={['SUPER_ADMIN']}
                  path={ROUTES.ADMINS.internalPaths.ADMIN_CREATE.path} 
                  exact 
                  component={AdminCreateView} 
                  flag={FeatureFlags.SUPER_ADMIN}
                />
                <RoleRoute 
                  roles={['SUPER_ADMIN']}
                  path={ROUTES.ADMINS.internalPaths.ADMIN_DETAIL.path} 
                  exact 
                  component={AdminDetailView} 
                  flag={FeatureFlags.SUPER_ADMIN}
                />
                <RoleRoute 
                  roles={['SUPER_ADMIN']}
                  path='/admin/edit/:id'
                  exact 
                  component={AdminEditView} 
                  flag={FeatureFlags.SUPER_ADMIN}
                />
              </Switch>
            </Layout>
          </Session>
        </ProtectedRoute>
      </Switch>
    </>
  );
};
