import { Box, Button, Typography } from '@mui/material';
import Add from '@mui/icons-material/Add';
import { useLocation } from 'react-router-dom';

type Item = { title: string; subtitle: string; path: `/${string}` };
const paths: Item[] = [
  {
    path: '/customers',
    subtitle: 'Manage your Clients',
    title: 'Customers',
  },
  {
    path: '/customers/create',
    title: 'Create Customer',
    subtitle: 'Add new client in your businness',
  },
  {
    path: '/customers/edit',
    title: 'Edit Customer',
    subtitle: '',
  },
];
/**
 * @returns {JSX.Element} - Customer's view header.
 */
export function CustomerHeader(): JSX.Element {
  const location = useLocation();
  // const params = new URLSearchParams(location.search);

  const { subtitle, title }: Item = paths.find(
    (item) => item.path === location.pathname,
  ) || {
    path: '/',
    title: '',
    subtitle: '',
  };

  return (
    <Box
      justifyContent="space-between"
      alignItems="center"
      sx={{ display: ['none', 'flex'] }}
    >
      <Box>
        <Typography variant="h4">{title}</Typography>
        <Typography variant="body1">{subtitle}</Typography>
      </Box>
      {location.pathname === '/customers' && (
        <Button startIcon={<Add />} href="/customers/create">
          Create New Customer
        </Button>
      )}
    </Box>
  );
}
