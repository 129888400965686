import React, { useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  Avatar,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  hours,
  FieldworkerColor,
  FieldworkerDayList,
  CalendarType,
} from '../calendar-types';
import { WhiteBoards } from './WhiteBoards';
import { InfoCard, InfoCards } from './InfoCard';
import { GRAY_LIGHT, theme, PRIMARY } from '../../../shared/css/theme';
import {
  filterDay,
  filterDayResponsive,
  getJobsCount,
} from '../calendar-utils';
import { Job } from '../../../shared/types/generated';

// Set the column view
const numColumn = 10;

/**
 * @param {JSX.Element} props - Calendar View.
 * @param {JSX.Element} props.fieldworkers - Calendar View.
 * @returns {JSX.Element} - Calendar View.
 */
export function TableHours({
  fieldworkers,
  day,
}: {
  fieldworkers: FieldworkerColor[];
  day: string;
}): JSX.Element {
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [fieldworkersList, setFieldworkersList] =
    React.useState<FieldworkerDayList>([]);
  const [formatDataResponsive, setFormatDataResponsive] = React.useState<
    {
      job: Job;
      fieldworker: FieldworkerColor;
    }[]
  >([]);
  const [count, setCount] = React.useState<number>(numColumn);
  const [cardWidth, setCardWidth] = React.useState<number>(0);
  const [jobsCount, setJobsCount] = React.useState<number>(0);
  const currentHour = Number(dayjs().format('H'));
  const valueRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // move scroll to current hour
    if (!matchesSm) {
      valueRef?.current?.scrollTo(0, currentHour * 90);
      setCardWidth((valueRef?.current?.offsetWidth ?? 0) - 50);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const validHours = fieldworkers.filter(
      (item) => item.fieldworkerJobFieldworkerRelation.items.length > 0,
    );
    const formatData = filterDay(validHours);
    if (matchesSm) {
      setFieldworkersList(formatData);
      setFormatDataResponsive(filterDayResponsive(validHours));
    } else {
      setFieldworkersList(formatData);
      setJobsCount(getJobsCount(formatData.slice(count - numColumn, count)));
    }
  }, [count, matchesSm, fieldworkers]);

  return (
    <>
      {!matchesSm && fieldworkersList.length > count && (
        <IconButton
          onClick={() => setCount(count + numColumn)}
          color="primary"
          aria-label="upload picture"
          component="span"
          sx={{
            position: 'absolute',
            right: '20px',
            top: '20px',
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      )}
      {!matchesSm && count > numColumn && (
        <IconButton
          onClick={() => setCount(count - numColumn)}
          color="primary"
          aria-label="upload picture"
          component="span"
          sx={{
            position: 'absolute',
            right: '60px',
            top: '20px',
          }}
        >
          <ArrowBackIosIcon />
        </IconButton>
      )}
      {!matchesSm && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            paddingRight: '0.6rem',
          }}
          mt={[6, 0]}
        >
          <Box
            sx={{
              borderRight: `1px solid ${GRAY_LIGHT}`,
              borderBottom: `1px solid ${GRAY_LIGHT}`,
              borderTop: `1px solid ${GRAY_LIGHT}`,
              minWidth: '47px',
            }}
            display="flex"
            alignItems="center"
          >
            <Typography variant="subtitle2">1h</Typography>
          </Box>

          <Box
            display="flex"
            sx={{
              position: 'relative',
              width: fieldworkersList.length <= 2 ? '100%' : 'auto',
            }}
          >
            {fieldworkersList.length ? (
              fieldworkersList.slice(count - numColumn, count).map((fw) => (
                <Box
                  sx={{
                    borderRight: `1px solid ${GRAY_LIGHT}`,
                    borderBottom: `1px solid ${GRAY_LIGHT}`,
                    borderTop: `1px solid ${GRAY_LIGHT}`,
                    backgroundColor: `${fw.color}`,
                    minWidth: '100px',
                    minHeight: '40px',
                    width:
                      fieldworkersList.length <= 2
                        ? '100%'
                        : `${Math.floor(
                            cardWidth /
                              fieldworkersList.slice(count - numColumn, count)
                                .length,
                          )}px`,
                  }}
                  key={fw.id}
                >
                  {/* Show name of the column */}
                  <Typography
                    variant="h6"
                    color="#5e5e5f"
                    textAlign="center"
                    sx={{
                      fontSize: '12px',
                    }}
                  >
                    {fw.fullName}
                  </Typography>
                </Box>
              ))
            ) : (
              <Box
                sx={{
                  width: '100%',
                  minHeight: '40px',
                  borderTop: `1px solid ${GRAY_LIGHT}`,
                  borderRight: `1px solid ${GRAY_LIGHT}`,
                  borderBottom: `1px solid ${GRAY_LIGHT}`,
                }}
              />
            )}
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          minWidth: '100%',
          padding: 0,
          width: [
            '100%',
            fieldworkersList.length <= 2 ? '100%' : 'max-content',
          ],
          height: ['100%', '63vh'],
          overflowY: 'scroll',
          overflowX: 'hidden',
          '&::-webkit-scrollbar': {
            backgroundColor: '#fafafa',
            width: '0.6rem',
            height: '0.6rem',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#bcbcbd',
            borderRadius: '10px',
          },
        }}
        ref={valueRef}
      >
        <Box
          sx={{
            height: ['auto', '100%'],
            minWidth: ['55px', '47px'],
          }}
        >
          {/* Hours left side */}
          {!matchesSm ? (
            hours.map((hour, index) => (
              <Box
                key={hour.id}
                sx={{
                  borderRight: `1px solid ${GRAY_LIGHT}`,
                  height: '90px',
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    position: 'relative',
                    top: '-10px',
                    display: index === 0 ? 'none' : 'block',
                  }}
                >
                  {hour.id}
                </Typography>
              </Box>
            ))
          ) : (
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              sx={{
                marginRight: ['10px', '0'],
              }}
            >
              <Typography variant="body2" color="primary">
                {dayjs(day).format('ddd')}
              </Typography>
              <Avatar
                sx={{
                  backgroundColor: PRIMARY,
                }}
              >
                <Typography variant="h6">{dayjs(day).format('DD')}</Typography>
              </Avatar>
            </Box>
          )}
        </Box>
        <Box
          display="grid"
          gridTemplateRows={[
            `repeat(${fieldworkersList.length}, 80px)`,
            'repeat(24, 90px)',
          ]}
          gridTemplateColumns={[
            'repeat(1, 100%)',
            `repeat(${
              fieldworkersList.slice(count - numColumn, count).length
            }, minmax(100px,${
              fieldworkersList.length === 1
                ? '100%'
                : `${Math.floor(
                    cardWidth /
                      fieldworkersList.slice(count - numColumn, count).length,
                  )}px`
            }))`,
          ]}
          width="100%"
          sx={{
            gridGap: '0',
          }}
        >
          {!matchesSm
            ? fieldworkersList
                .slice(count - numColumn, count)
                .map((fieldworker, index) => (
                  <React.Fragment key={fieldworker.id}>
                    {fieldworker.jobs.map((job) => (
                      <React.Fragment key={job.id}>
                        {job.jobsDay.length > 1 ? (
                          <InfoCards
                            type={CalendarType.DAY}
                            fieldworker={fieldworker}
                            gridRowStart={
                              matchesSm ? 'auto' : Number(job.id.slice(0, 2))
                            }
                            gridColumnStart={matchesSm ? 'auto' : index + 1}
                            jobs={job.jobsDay}
                          />
                        ) : (
                          <InfoCard
                            type={CalendarType.DAY}
                            count={fieldworkersList.length}
                            backgroundColor={fieldworker.color}
                            job={job.jobsDay[0]}
                            fieldworker={fieldworker}
                            gridRowStart={
                              matchesSm ? 'auto' : Number(job.id.slice(0, 2))
                            }
                            gridColumnStart={matchesSm ? 'auto' : index + 1}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))
            : formatDataResponsive.map((hour) => (
                <InfoCard
                  type={CalendarType.DAY}
                  count={fieldworkersList.length}
                  backgroundColor={hour.fieldworker.color}
                  job={hour.job}
                  fieldworker={hour.fieldworker}
                  gridRowStart="auto"
                  gridColumnStart="auto"
                />
              ))}
          {matchesSm && fieldworkersList.length === 0 && (
            <Typography variant="body2" textAlign="center" mt="30px">
              No jobs assigned today
            </Typography>
          )}
          {!matchesSm && (
            <WhiteBoards
              rows={
                hours.length *
                  fieldworkersList.slice(count - numColumn, count).length -
                jobsCount
              }
            />
          )}
          {!matchesSm && fieldworkersList.length === 0 && (
            <WhiteBoards rows={hours.length} />
          )}
        </Box>
      </Box>
    </>
  );
}
