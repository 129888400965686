import { FC } from 'react';
import { Box, BoxProps, Skeleton, styled } from '@mui/material';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link } from 'react-router-dom';
import { theme as appTheme } from '../../../shared/css/theme';
import { AlertTime, JobNumberTypo } from '../../../shared/components/Typografy';
import { JOB_STATUS } from '../../../shared/constants/common';
import { NotificationTypes, adminNotifTypes, customerNotificationTypes, fieldWorkerNotificationTypes } from '../notification-types';
import { AdminNotification, CustomerNotification, FieldWorkerNotification } from './NotificationTextItem';
import { Notification } from '../../../shared/types/generated';

export const MessageWrapper = styled(Box)<BoxProps & { isRead?: boolean }>(
  ({ isRead }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '5px 10px 5px 5px',
    margin: '2px 0',
    background: 'white',
    borderRadius: '10px',
    minHeight: '91px',
    opacity: isRead ? 0.6 : 1,
    ':hover': {
      background: isRead ? 'white' : 'rgba(0,0,0,.07)',
      cursor: 'pointer',
    },
  }),
);
export const IconWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  minHeight: '70px',
  justifyContent: 'space-between',
  flexDirection: 'column',
  borderRadius: '50%',
});

export const NotifBody = styled(Box)({
  marginLeft: '15px',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  width: 'auto',
});
export const TimeWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
});

interface NotificationItemProps {
  id: string;
  jobNumber: number;
  notifType: NotificationTypes;
  notification: Notification;
  jobStatus: JOB_STATUS;
  date: string;
  isRead: boolean;
  markAsRead: (id?: string) => Promise<void>;
  markedAsReadItem?: string;
}

const getNotifIcons = (status: JOB_STATUS, isRead: boolean, isAdminNotif: boolean): JSX.Element => {
  const onSite = <AccessTimeFilledIcon fontSize='large' sx={{
    color: isRead ? 'lightblue' : '#2BA668',
  }}/>;
  const closed = <CheckCircleIcon fontSize='large' sx={{
    color: isRead ? 'lightblue' : appTheme.palette.text.primary,
  }}/>;
  const pending = <AcUnitIcon fontSize='large' sx={{
    color: isRead ? 'lightblue' : appTheme.palette.variant.main,
  }}/>;
  const defaultIcon = <AcUnitIcon fontSize='large' sx={{
    color: isRead ? 'lightblue' : appTheme.palette.secondary.main,
  }}/>;
  if (!isAdminNotif) {
    return defaultIcon;
  }
  if (status === JOB_STATUS.ON_SITE) {
    return onSite;
  }
  if (status === JOB_STATUS.CLOSED) {
    return closed;
  }
  if (status === JOB_STATUS.PENDING) {
    return pending;
  }
  return defaultIcon;
};

export const NotificationItem: FC<NotificationItemProps> = ({
  id,
  jobNumber,
  notifType,
  notification,
  jobStatus,
  date,
  isRead,
  markAsRead,
  markedAsReadItem,
}): JSX.Element => (
    markedAsReadItem === id ? 
    <Box>
      <MessageWrapper isRead>
        <IconWrapper>
          {getNotifIcons(jobStatus, true, adminNotifTypes.includes(notifType))}
        </IconWrapper>
        <NotifBody>
          <Skeleton animation="wave" width='80%' />
          <Skeleton animation="wave" width='50%' />
        </NotifBody>
      </MessageWrapper>
    </Box> :
    <Link to={`/jobs/${notification?.job?.id}`} onClick={() => markAsRead(id)} style={{
      textDecoration: 'none',
    }}>
      <MessageWrapper isRead={isRead}>
        <IconWrapper>
          {getNotifIcons(jobStatus, isRead, adminNotifTypes.includes(notifType))}
          <JobNumberTypo isRead={isRead}>#{jobNumber}</JobNumberTypo>
        </IconWrapper>
        <NotifBody>
          {
            adminNotifTypes.includes(notifType) && 
              <AdminNotification notifiication={notification} />
          }
          {
            fieldWorkerNotificationTypes.includes(notifType) &&
              <FieldWorkerNotification notifiication={notification} />
          }
          {
            customerNotificationTypes.includes(notifType) && 
              <CustomerNotification notifiication={notification} />
          }
          <TimeWrapper>
            <AlertTime>{date}</AlertTime>
          </TimeWrapper>
        </NotifBody>
      </MessageWrapper>
    </Link>
  );