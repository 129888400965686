import dayjs from 'dayjs';
import { ClockInOut, ClockInOutFilter } from '../../shared/types/generated';
import { FieldworkerColor, FieldworkerDayList } from './map-types';
/**
 * @param {any} day - New Data.
 * @param {} filters - Old Data.
 * @returns {Array<string>} - Variables.
 */
export function getMapFilters(
  day: string,
  filters: {
    id: {
      equals?: string;
      not_equals?: string;
    };
  }[],
): ClockInOutFilter {
  const initialFilters: ClockInOutFilter = {
    fieldworker: {
      AND: filters,
    },
  };
  const gte = dayjs(day)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .toISOString();
  const lte = dayjs(day).hour(23).minute(59).second(59).toISOString();

  initialFilters.AND = [
    {
      createdAt: {
        gte,
      },
    },
    {
      createdAt: {
        lte,
      },
    },
    {
      job: {
        archived: {
          is_empty: true,
        },
      },
    },
  ];

  return initialFilters;
}
/**
 * @param {} fieldworkers - Old Data.
 * @returns {Array<FieldworkerColor>} - Variables.
 */
export function mapData(fieldworkers: FieldworkerColor[]): {
  clocks: ClockInOut[];
  fieldworker: FieldworkerColor;
}[] {
  const result: {
    clocks: ClockInOut[];
    fieldworker: FieldworkerColor;
  }[] = [];
  fieldworkers.forEach((fieldworker) => {
    fieldworker.fieldworkerClockInOutRelation.items.forEach(() => {
      result.push({
        clocks: fieldworker.fieldworkerClockInOutRelation.items,
        fieldworker,
      });
    });
  });

  return result;
}
/**
 * @param {} fieldworkers - Old Data.
 * @returns {Array<FieldworkerColor>} - Variables.
 */
export function getJobsCount(fieldworkers: FieldworkerDayList): number {
  let count = 0;
  fieldworkers.forEach((fieldworker) => {
    fieldworker.jobs.forEach((job) => {
      if (job.jobsDay.length > 0) {
        count += 1;
      }
    });
  });
  return count;
}
