import React, { useState } from 'react';
import { error as errorLogger } from '@cobuildlab/pure-logger';
import { Box, Button, Typography, FormControl, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { snackbar } from '../../shared/components/Snackbar';
import { useFieldworkerCreateMutation } from '../../shared/types/generated';
import { FieldworkerCreateInput } from '../../shared/types/types';
import logo from '../../shared/assets/fieldworker.svg';
import {
  FormViewConatiner,
  FormImageContainer,
} from '../../shared/components/Containers';
import { clearQuerysFromCache } from '../../shared/utils';
import { generateColor } from './fieldworker-utils';
import { EMAIL_REGREX } from '../../shared/constants/common';
import { getPhoneNumberFomartToSaveInDB } from '../../shared/utils/phone';
import { FIELDWORKER_FORM_VALIDATOR_SCHEMA } from './fieldworker-types';


type CreateFieldWorkerType = {
  fullName: string;
  email: string;
  phoneNumber: string;
  color?: string;
}

const defaultValues: CreateFieldWorkerType = {
  fullName: '',
  email: '',
  phoneNumber: '',
};


export const CreateFieldworker: React.FC = () => {
  const history = useHistory();
  const [err, setErr] = useState(false);
  const { control, handleSubmit, formState: { errors } } = useForm<CreateFieldWorkerType>({
    defaultValues,
    resolver: yupResolver(FIELDWORKER_FORM_VALIDATOR_SCHEMA, {})
  });
  const [createFieldworker, { loading }] = useFieldworkerCreateMutation({
    onCompleted: () => {
      snackbar.success('Fieldworker created');
      history.push('/fieldworker');
    },
    onError: (error) => {
      errorLogger(error);
      setErr(true);
    },
    update: clearQuerysFromCache(['fieldworkersList']),
  });
  const hasFormErrors = Boolean(Object.keys(errors)?.length);

  const handlerSubmitForm  = handleSubmit<FieldworkerCreateInput>((data) => {
    const phoneNumberFormatToSaveInDataBD = getPhoneNumberFomartToSaveInDB(data.phoneNumber);
    const color = generateColor(data.fullName?.length ?? 16);
    const dataVariable = {
      ...data,
      email: data.email?.toLowerCase(),
      phoneNumber: phoneNumberFormatToSaveInDataBD,
      color,  

    };

    createFieldworker({
      variables: {
        data: dataVariable,
      },
    });
  });

  return (
    <FormViewConatiner
      title="Create Fieldworker"
      subtitle="Add new fieldworker"
    >
      <Typography
        variant="h5"
        sx={{ display: [null, 'none'], gridColumn: 'span 2' }}
      >
        Information
      </Typography>
      <FormImageContainer
        imagePath={logo}
        onSubmit={handlerSubmitForm}
      >
        <Typography
          variant="h5"
          sx={{ display: ['none', 'block'], gridColumn: 'span 2' }}
        >
          Information
        </Typography>
        <FormControl
          variant="outlined"
          fullWidth
          sx={{ gridColumn: 'span 2', marginTop: '10px' }}
        >
          <Controller 
            control={control}
            name="fullName"
            rules={{
              required: {
                value: true,
                message: 'Full name is required'
              }
            }}
            render={({ field }) => (
              <TextField
              {...field}
              label="Full Name"
              helperText={errors?.fullName?.message}
              error={Boolean(errors?.fullName?.message)}
            />
            )}
          />
         
        </FormControl>
        <FormControl variant="outlined" fullWidth sx={{ gridColumn: 'span 2' }}>
          <Controller
            control={control}
            name="email"
            rules={{
              required: {
                value: true,
                message: 'This field is required',
              },
              maxLength: {
                value: 100,
                message: 'Email must be at most 100 characters',
              },
              pattern: {
                value: EMAIL_REGREX,
                message: 'Invalid email address',
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Email"
                helperText={errors?.email?.message}
                error={Boolean(errors?.email?.message)}
              />
            )}
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth sx={{ gridColumn: 'span 2' }}>
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field }) => (
              <TextField
                {...field}
                label="Phone Number"
                helperText={errors?.phoneNumber?.message}
                error={Boolean(errors?.phoneNumber?.message)}
              />
            )}
          />
        </FormControl>
        {err && (
          <Typography
            variant="body1"
            sx={{
              gridColumn: 'span 2',
              marginLeft: '5px',
            }}
            color="error"
          >
            Phone Number or Email already exists
          </Typography>
        )}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            gridColumn: 'span 2',
            position: ['static', 'absolute'],
            bottom: '40px',
            right: '100px',
            width: ['100%', '220px'],
            marginTop: ['20px', '0'],
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              history.goBack();
            }}
          >
            Cancel
          </Button>
          <LoadingButton disabled={hasFormErrors} variant="contained" loading={loading} type="submit">
            Create
          </LoadingButton>
        </Box>
      </FormImageContainer>
    </FormViewConatiner>
  );
};
