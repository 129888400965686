import React from 'react';
import { error as errorLogger } from '@cobuildlab/pure-logger';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';

// import { suggestedPassword, usePasswordForm } from '../Fieldworker-actions';
import { useResetPasswordMutation } from '../../../shared/types/generated';
import { snackbar } from '../../../shared/components/Snackbar';

/**
 * @param root0 - Props.
 * @param root0.open - Prost text.
 * @param root0.close - Prost text.
 * @param root0.email - Prost text.
 * @returns {} Component.
 */
export function ResetPassword({
  open,
  close,
  email,
}: {
  open: boolean;
  close: () => void;
  email: string;
}): JSX.Element {
  const [resetPassword, { loading }] = useResetPasswordMutation({
    onCompleted: () => {
      snackbar.success('Request password sended');
      close();
    },
    onError: (err) => {
      errorLogger(err);
      snackbar.error(err.message);
    },
  });

  const onConfirm = (): void => {
    resetPassword({
      variables: {
        email,
      },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="responsive-dialog-title"
      fullWidth
    >
      <DialogTitle id="responsive-dialog-title">Reset Password</DialogTitle>
      <DialogContent>
        Are you sure you want to reset the password? We will send a reset link
        to{' '}
        <Typography fontWeight="normal" component="b" color='secondary'>
          {email}.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="default" onClick={close}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={onConfirm}
          color="error"
          autoFocus
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
