import React, { useState } from 'react';
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Skeleton, SpeedDial, SpeedDialAction, Typography, styled, } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArchiveIcon from '@mui/icons-material/Archive';
import CircleIcon from '@mui/icons-material/Circle';
import { LoadingButton } from '@mui/lab';
import { Edit } from '@mui/icons-material';
import { useHistory, useParams } from 'react-router-dom';
import { DisplayPhoneNumber } from '../../shared/components/DisplayPhoneNumber';
import { ViewBackButton } from '../../shared/components/ViewBackButton';
import { useAdminDetailQuery, useAdminUpdateMutation } from '../../shared/types/generated';
import { snackbar } from '../../shared/components/Snackbar';
import { clearQuerysFromCache, humanizeDate } from '../../shared/utils';
import { archiveAdminVariables } from './admin-utils';
import { ResetPassword } from './components/ResetPassword';
import { USER_ROLES } from '../session/session-model';

const MdPaper = styled(Box)(({ theme }) => ({
  position: 'relative',
  minWidth: '75%',
  maxWidth: '75%',
  height: 'auto',
  backgroundColor: '#fff',
  margin: '5% auto 0 auto',
  borderRadius: '10px',
  boxShadow: '0px 3px 0px #EDEDF6',
  padding: '35px 20px 35px 25px',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    margin: '0',
    height: 'auto',
    marginTop: '25px',
  },
}));

export const AdminDetailView: React.FC = () =>  {
  const history = useHistory();
  const [openModalWarning, setOpenModalWarning] = useState<boolean>(false);
  const [openModalStatus, setOpenModalStatus] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const [open, setOpen] = useState(false);
  
  const { data, loading } = useAdminDetailQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: id as string,
    }
  });

  let userStatusColor: 'error' | 'warning' | 'secondary' = 'secondary';

  if (data?.user?.archived) {
    userStatusColor = 'error';
  }

  const [archiveAdmin, { loading: loadingArchive, error: errorArchive }] =
    useAdminUpdateMutation({
      onCompleted: () => {
        if (errorArchive === undefined) {
          snackbar.success('Admin archived');
          setOpenModalWarning(false);
          history.goBack();
        } else {
          setOpenModalStatus(true);
        }
      },
      update: clearQuerysFromCache([
        'usersList'
      ]),
    });

  const isArchive = (): boolean => {
    let retorno = true;
    if (data !== undefined && data?.user?.archived === null) {
      retorno = false;
    }
    return retorno;
  };
  const userName = data?.user?.firstName && (`${data?.user?.firstName} ${data?.user?.lastName}`) || data?.user?.email;
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
      <ResetPassword open={open} close={() => setOpen(false)} email={data?.user?.email || ''} />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="left"
        >
          {!loading ? (
            <Typography variant="h4">{userName}</Typography>
          ) : (
            <Skeleton animation="wave" />
          )}
          <ViewBackButton />
        </Box>
        {!loading && (<Box
          sx={{
            zIndex: 50,
            display: ['flex', 'none'],
            position: 'fixed',
            transform: 'translateZ(0px)',
            flexGrow: 1,
            top: '0',
            right: '20px',
            bottom: '30px',
          }}
        >
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: 'absolute', bottom: 16, right: 16 }}
            icon={<MoreVertIcon />}
          >
            {isArchive() === false && (
              <SpeedDialAction
                icon={<Edit />}
                onClick={() => history.push(`/admin/edit/${id}`)}
                tooltipTitle="Edit"
              />
            )}
            {!data?.user?.roles?.items?.find((role) => role?.name === USER_ROLES.SUPER_ADMIN)?.name && (<SpeedDialAction
              icon={<ArchiveIcon />}
              tooltipTitle={
                data?.user?.archived === null ? 'Archive' : 'Active'
              }
              onClick={() => {
                setOpenModalWarning(true);
              }}
            />)}
          </SpeedDial>
        </Box>)}

        {!loading && (<Box
          justifyContent={
            data?.user?.archived === null ? 'space-between' : 'flex-end'
          }
          alignItems="center"
          sx={{ gap: 5, display: ['none', 'flex'] }}
        >
          {!data?.user?.roles?.items?.find((role) => role?.name === USER_ROLES.SUPER_ADMIN)?.name && (<Button
            variant="contained"
            size="large"
            color={data?.user?.archived === null ? 'error' : 'primary'}
            onClick={() => {
              setOpenModalWarning(true);
            }}
          >
            {data?.user?.archived === null
              ? 'Archive admin'
              : 'Active admin'}
          </Button>)}
          <Button
          disableElevation
          variant="contained"
          sx={{ marginLeft: '15px' }}
          onClick={() => setOpen(true)}
        >
          Reset Password
        </Button>
          {isArchive() === false && (
            <>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Edit />}
                href={`/admin/edit/${id}`}
              >
                Edit admin
              </Button>
            </>
          )}
        </Box>)}
      </Box>
      <MdPaper sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="h5">Information</Typography>
          </Grid>
          <Grid item sm={12} md={6} lg={6}>
            <Typography variant="h5">Full Name</Typography>
            {!loading ? (
              <Typography variant="body1">{userName}</Typography>
            ) : (
              <Skeleton animation="wave" />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography variant="h5">Email</Typography>
            {!loading ? (
              <Typography variant="body1">{data?.user?.email}</Typography>
            ) : (
              <Skeleton animation="wave" />
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography variant="h5">Phone Number</Typography>
            {!loading ? (
              <>
                <DisplayPhoneNumber phoneNumber={data?.user?.phone} />
              </>
            ) : (
              <Skeleton animation="wave" />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography variant="h5">Admin Since</Typography>
            {!loading && data ? (
              <Typography variant="body1">
                {humanizeDate(data?.user?.createdAt)}
              </Typography>
            ) : (
              <Skeleton animation="wave" />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography variant="h5">Admin Role</Typography>
            {!loading ? (
              <Typography variant="body1">
                {data?.user?.roles?.items?.find((role) => role?.name === USER_ROLES.SUPER_ADMIN)?.name || data?.user?.roles?.items[0]?.name || ''}
              </Typography>
            ) : (
              <Skeleton animation="wave" />
            )}
          </Grid>
          <Grid item sm={6} md={6} lg={6}>
            <Typography variant="h5">Status</Typography>
            {!loading ? (
              <Chip
                icon={<CircleIcon />}
                label={data?.user?.archived ? 'Archived' : 'Active'}
                variant="outlined"
                color={userStatusColor}
              />
            ) : (
              <Skeleton animation="wave" />
            )}
          </Grid>
        </Grid>
      </MdPaper>
      <Dialog open={openModalWarning}>
        <DialogTitle id="warningTitle">Are you sure?</DialogTitle>
        <DialogContent>
          {data?.user?.archived === null
            ? 'By archiving this administrator, they will lose access to the platform. Proceed with archiving?'
            : 'This Admin will be activated'}
        </DialogContent>
        <DialogActions>
          <Button
            variant="default"
            onClick={() => {
              setOpenModalWarning(false);
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant={data?.user?.archived === null ? 'delete' : 'contained'}
            loading={loadingArchive}
            onClick={() => {
              const con = !data?.user?.archived;
              archiveAdmin({
                variables: archiveAdminVariables(
                  typeof id === 'string' ? id : '',
                  con,
                ),
              });
            }}
          >
            {data?.user?.archived === null ? 'archive' : 'active'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog open={openModalStatus}>
        {errorArchive !== undefined && (
          <>
            <DialogTitle id="warningTitle">Something went wrong</DialogTitle>
            <DialogActions>
              <Button
                variant="default"
                autoFocus
                onClick={() => {
                  setOpenModalStatus(false);
                }}
              >
                close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};