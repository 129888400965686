/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Skeleton,
  List,
  ListItem,
  IconButton,
  FormHelperText,
} from '@mui/material';
import * as filestack from 'filestack-js';
import ClearIcon from '@mui/icons-material/Clear';
import DateAdapter from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Box } from '@mui/system';
import { useHistory, useParams } from 'react-router-dom';
import { DatePicker, LoadingButton, TimePicker } from '@mui/lab';

import {
  FormImageContainer,
  FormViewConatiner,
} from '../../shared/components/Containers';
import logo from '../../shared/assets/bro.svg';
import { JOB_STATUSES_OPTIONS, PartType } from './jobs-types';
import {
  useAddressesSelectListQuery,
  useFieldworkerSelectListQuery,
  useJobUpdateMutation,
  useJobTypeListForSelectsQuery,
  useJobQuery,
  File,
} from '../../shared/types/types';
import { useJobForm } from './jobs-hooks';
import { useUserHasRole } from '../session/session-hooks';
import { snackbar } from '../../shared/components/Snackbar';
import { clearQuerysFromCache } from '../../shared/utils';
import { PartsInputPortal } from '../../shared/components/PartModal';
import {
  SortOrder,
  useAdminsListQuery,
  useCustomerSelectListQuery,
  useGetApiKeyQuery,
} from '../../shared/types/generated';
import { getClockForJob, mapDocumentsToDelete, mapImageToDelete, updateJobVariables, updateJobVariablesV2, updateJobVariablesV3 } from './jobs-utils';
import { SignatureMenu } from './components/SignatureMenu';
import { CalendarModal } from './components/CalendarModal';
import { UseValidateFeatureFlag } from '../auth/auth-hooks';
import { getPhoneNumberWithOutRegionCode } from '../../shared/utils/phone';
import { JOB_STATUS } from '../../shared/constants/common';
import { FeatureFlags } from '../../shared/constants/featureFlag';
import { USER_ROLES } from '../session/session-model';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Los_Angeles');
/**
 * @returns React component.
 */
export function JobEditView(): JSX.Element {
  const { register, setValue, formState, handleSubmit, getValues, watch } =
    useJobForm();
  const featureFlag = UseValidateFeatureFlag(FeatureFlags.INPUTS);
  const featureFlagJobEditV2 = UseValidateFeatureFlag(FeatureFlags.JOB_EDIT_V2);
  const fieldworkerDocsFeatureFlag = UseValidateFeatureFlag(FeatureFlags.FIELDWORKER_DOCS);
  const { id } = useParams<{ id: string }>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [once, setOnce] = useState<boolean>(false);
  const [date, setDate] = useState<Date | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [calendar, setCalendar] = useState<boolean>(false);
  const [fieldworkerFIles, setFieldworkerFIles] = useState<filestack.PickerFileMetadata[]>([]);
  const [oldFwDocs, setOldFwDocs] = useState<File[]>([]);
  const [currentFwDocs, setCurrentFwDocs] = useState<File[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<string>('');
  const isFieldWorker = useUserHasRole('FIELDWORKER');
  const isAdmin = useUserHasRole('ADMIN');
  const [path, setPath] = useState<string>('');
  const [clientFileStack, setClientFileStack] = useState<filestack.Client>();
  const [oldImages, setOldImages] = useState<File[]>([]);
  const [imagesUri, setImagesUri] = useState<filestack.PickerFileMetadata[]>(
    [],
  );
  const { data: JobsTypesData } = useJobTypeListForSelectsQuery();
  const { data: dataFileStack } = useGetApiKeyQuery();
  const history = useHistory();
  const { data: addressesData } = useAddressesSelectListQuery({
    variables: {
      id: selectedCustomer,
    },
  });
  const { data: fieldworkersData, loading: loadingFieldworkers } =
    useFieldworkerSelectListQuery();

  const { data: dataJob } = useJobQuery({
    variables: {
      id,
    },
  });

  const { data: adminList } = useAdminsListQuery({
    fetchPolicy: 'cache-and-network',
    skip: !featureFlagJobEditV2,
    variables: {
      filter: {
        archived: {
          is_empty: true
        },
        roles: {
          some: {
            name: { equals: USER_ROLES.ADMIN }
          }
        }
      },
      sort: [{
        firstName: SortOrder.Asc,
      }]
    }
  });

  const ADMINOPTIONS = useMemo(() => {
    const returnArr = adminList?.usersList?.items?.map((item) => {
      const name = item?.firstName ? `${item?.firstName} ${item?.lastName || ''}` : item?.email;
      return {
        label: name,
        value: name,
      };
    });
    return featureFlagJobEditV2 ? returnArr : null;
  }, [adminList, featureFlagJobEditV2]);

  const { data: customersData } = useCustomerSelectListQuery();
  const [
    jobUpdateMutation,
    { data: dataMutation, loading: loadingMutation, error: errorMutation },
  ] = useJobUpdateMutation({
    onCompleted: () => {
      snackbar.success('Job edited');
      history.goBack();
    },

    /**
     * @param cache - Apollo chache.
     */
    update: clearQuerysFromCache([
      'jobsList',
      'job',
      'fieldworker',
      'customer',
    ]),
  });

  const addressList = addressesData?.addressesList.items ?? [];
  const fieldworkersList = fieldworkersData?.fieldworkersList.items ?? [];
  const jobFieldworkersList =
    dataJob?.job.jobJobFieldWorkerRelation.items ?? [];
  const customersList = customersData?.customersList.items ?? [];

  useEffect(() => {
    if(formState.errors){
      const errros = formState.errors as Record<any, any>;
      const errorListKey = Object.keys(formState.errors) as Array<any>;
      errorListKey.forEach((key) => {
        snackbar.error(errros[key].message || 'Field error');
      });
    }
  }, [formState.errors]);

  useEffect(() => {
    if (dataJob !== undefined && once === false) {
      setOnce(true);
      setSelectedCustomer(dataJob.job.address.customer.id);
      setValue('address', dataJob.job.address.id);
      setValue('name', dataJob.job.name);
      setValue('priority', dataJob.job.priority);
      if (dataJob?.job?.signature) {
        setValue('signature', dataJob?.job?.signature);
        setValue('signatureName', dataJob?.job?.signatureName);
        setValue('signatureTitle', dataJob?.job?.signatureTitle);
      }
      setValue('callerCell',  getPhoneNumberWithOutRegionCode(dataJob?.job?.jobJobInfoRelation?.callerCell));
      setValue(
        'callerName',
        dataJob?.job?.jobJobInfoRelation?.callerName || '',
      );
      setValue(
        'dispatchedBy',
        dataJob?.job?.jobJobInfoRelation?.dispatchedBy || '',
      );
      setOldImages(dataJob.job.images.items as File[]);
      setDate(new Date(dataJob.job.startDateTime));
      setValue(
        'fieldworker',
        dataJob.job.jobJobFieldWorkerRelation.items.map(
          (item) => item.fieldworker.id,
        ),
      );
      const json = JSON.stringify(dataJob);
      const obj = JSON.parse(json);
      setValue('parts', obj.job.jobJobPartsRelation.items);
      setCurrentFwDocs(dataJob?.job?.fieldworkerDocs?.items ?? []);
    }

  }, [
    setOnce,
    once,
    setValue,
    dataJob,
    dataMutation,
    loadingMutation,
    errorMutation,
    fieldworkersData,
  ]);
  useEffect(() => {
    if (dataFileStack?.fileUploadInfo.apiKey) {
      setClientFileStack(
        filestack.init(dataFileStack?.fileUploadInfo.apiKey, {
          security: {
            signature: dataFileStack?.fileUploadInfo.signature as string,
            policy: dataFileStack?.fileUploadInfo.policy as string,
          },
        }),
      );
      setPath(dataFileStack?.fileUploadInfo.path as string);
    }
  }, [dataFileStack]);
  /**
   * - Handle upload files.
   */
  const handleAttachFile = (): void => {
    if (!clientFileStack) return;
    const options = {
      fromSources: ['local_file_system'],
      accept: ['image/*'],
      maxFiles: 10 - ((oldImages?.length || 0) + (imagesUri?.length || 0)),
      /**
       * @param files - Filestack files client.
       */
      onUploadDone: (files: filestack.PickerResponse): void => {
        if (files.filesUploaded.length > 0) {
          const newSourcesArr = files.filesUploaded.map((newSrc) => newSrc);
          const oldSourcesArr = [...imagesUri];
          newSourcesArr.forEach((source) => oldSourcesArr.push(source));

          setImagesUri(oldSourcesArr);
        }
      },
      storeTo: { path },
    };
    clientFileStack.picker(options).open();
  };

  const handleAttachDocs = (): void => {
    if (!clientFileStack) return;
    const options = {
      fromSources: ['local_file_system'],
      accept: ['.pdf', '.docx'],
      maxFiles: 5 - ((fieldworkerFIles?.length ?? 0)) - ((currentFwDocs?.length ?? 0)),
      /**
       * @param files - Filestack files client.
       */
      onUploadDone: (files: filestack.PickerResponse): void => {
        if (files.filesUploaded.length > 0) {
          const newSourcesArr = files.filesUploaded.map((newSrc) => newSrc);
          setFieldworkerFIles((state) => [
            ...state,
            ...newSourcesArr,
          ]);
        }
      },
      storeTo: { path },
    };
    clientFileStack.picker(options).open();
  };
  const deleteNewFieldworkerDoc = (uploadId: string): void => {
    const filteredItems = fieldworkerFIles.filter((item) => item.uploadId !== uploadId);
    setFieldworkerFIles(filteredItems);
  };

  const deleteOldFieldworkerDoc = (fileItem: File): void => {
    setOldFwDocs((state) => [
      ...state,
      fileItem,
    ]);
    const filteredItems = currentFwDocs.filter((item) => item.id !== fileItem.id);
    setCurrentFwDocs(filteredItems);
  };

  const onSubmitForm = handleSubmit((
    data) => {
      const jobClockInOutRelation = getClockForJob({
        currentStatus: data.status as JOB_STATUS,
        previousStatus: dataJob?.job.status as JOB_STATUS,
        clockList: dataJob?.job?.jobClockInOutRelation.items,
      });
      if (featureFlagJobEditV2) {
        if (fieldworkerDocsFeatureFlag) {
          jobUpdateMutation({
            variables: updateJobVariablesV3({
              id,
              inputData: { 
                ...data,
                startDate: date?.toISOString() 
              },
              oldData: {
                oldFieldworkers: jobFieldworkersList,
                originalParts: dataJob?.job?.jobJobPartsRelation
                  ?.items as PartType[],
                adminNote: dataJob?.job?.adminNote?.note,
                officeId: dataJob?.job?.officeNoteJobRelation?.items[0]?.id,
              },
              isFieldWorker,
              fieldWorkerDocs: {
                newDocs: fieldworkerFIles,
                oldDocs: mapDocumentsToDelete(oldFwDocs),
              },
              images: {
                newImages: imagesUri,
                oldImages: mapImageToDelete(
                  dataJob?.job?.images?.items ?? [],
                  oldImages,
                ),
              },
              jobInfoExist: dataJob?.job?.jobJobInfoRelation !== null,
              jobClockInOutRelation,
            }),
          });
        } else {
          jobUpdateMutation({
            variables: updateJobVariablesV2({
              id,
              inputData: { 
                ...data,
                startDate: date?.toISOString() 
              },
              oldData: {
                oldFieldworkers: jobFieldworkersList,
                originalParts: dataJob?.job?.jobJobPartsRelation
                  ?.items as PartType[],
                adminNote: dataJob?.job?.adminNote?.note,
                officeId: dataJob?.job?.officeNoteJobRelation?.items[0]?.id,
              },
              isFieldWorker,
              images: {
                newImages: imagesUri,
                oldImages: mapImageToDelete(
                  dataJob?.job?.images?.items ?? [],
                  oldImages,
                ),
              },
              jobInfoExist: dataJob?.job?.jobJobInfoRelation !== null,
              jobClockInOutRelation,
            }),
          });
        }
      } else {
        jobUpdateMutation({
          variables: updateJobVariables({
            id,
            inputData: { 
              ...data,
              startDate: date?.toISOString() 
            },
            oldData: {
              oldFieldworkers: jobFieldworkersList,
              originalParts: dataJob?.job?.jobJobPartsRelation
                ?.items as PartType[],
              adminNote: dataJob?.job?.adminNote?.note,
              officeId: dataJob?.job?.officeNoteJobRelation?.items[0]?.id,
            },
            isFieldWorker,
            images: {
              newImages: imagesUri,
              oldImages: mapImageToDelete(
                dataJob?.job?.images?.items ?? [],
                oldImages,
              ),
            },
            jobInfoExist: dataJob?.job?.jobJobInfoRelation !== null,
            jobClockInOutRelation,
          }),
        });

      }
    },
  );

  return (
    <FormViewConatiner title="Edit Job" subtitle={dataJob?.job?.name || ''}>
      <FormImageContainer
        imagePath={logo}
        onSubmit={onSubmitForm}
      >
        <PartsInputPortal
          actionAdd={(obj) => {
            setOpen(!open);
            const newParts: PartType[] = getValues('parts') ?? [];
            newParts.push({
              name: obj.name,
              unit: obj.unit,
              partNumber: obj.partNumber,
            });
            const json = JSON.stringify(newParts);
            const newPartsFormat = JSON.parse(json);
            setValue('parts', newPartsFormat);
          }}
          label="Part Name"
          title="Add Part Information"
          errorMessage="Max. 100 characters"
          onClose={() => {
            setOpen(!open);
          }}
          open={open}
          extra
        />
        <CalendarModal
          open={calendar}
          date={date}
          onClose={() => setCalendar(() => !calendar)}
        />
        <SignatureMenu
          open={openModal}
          close={() => setOpenModal(false)}
          onChange={(signature) => {
            setValue('signature', signature);
          }}
        />
        <Typography variant="h5" sx={{ gridColumn: 'span 2' }}>
          Information
        </Typography>
        {dataJob ? (
          <>
            <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
              <Autocomplete
                disabled={isFieldWorker}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                options={customersList}
                defaultValue={dataJob?.job?.address?.customer}
                filterOptions={(options, { inputValue }) =>
                  options.filter(
                    (item) =>
                      item.name
                        .toLowerCase()
                        .indexOf(inputValue.toLowerCase()) >= 0,
                  )
                }
                onChange={(_event, value) => {
                  setValue('name', value?.name ?? '');
                  setSelectedCustomer(value?.id ?? '');
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Customer"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingFieldworkers ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
            {addressList.length > 0 && selectedCustomer && (
              <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
                <InputLabel>Address</InputLabel>
                <Select
                  disabled={isFieldWorker}
                  label="Address"
                  defaultValue={getValues('address')}
                  {...register('address')}
                  error={Boolean(formState.errors?.address?.message)}
                >
                  {addressList.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.city}, {item.state}, {item.streetAddress}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>
                  {formState.errors?.address?.message}
                </FormHelperText>
              </FormControl>
            )}
          </>
        ) : (
          <Skeleton animation="wave" />
        )}

        <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
          {dataJob !== undefined ? (
            <TextField
              disabled={isFieldWorker}
              label="Description"
              defaultValue={dataJob.job.description}
              placeholder="Type the job description"
              helperText={formState.errors?.description?.message}
              error={Boolean(formState.errors?.description?.message)}
              multiline
              minRows={3}
              {...register('description')}
            />
          ) : (
            <Skeleton animation="wave" />
          )}
        </FormControl>
        {featureFlag && (
          <>
            <FormControl
              variant="outlined"
              fullWidth
              sx={{ gridColumn: 'span 2' }}
            >
              {dataJob !== undefined ? (
                <TextField
                  disabled={isFieldWorker}
                  label="Caller Name"
                  {...register('callerName')}
                  defaultValue={dataJob?.job.jobJobInfoRelation?.callerName}
                  helperText={formState.errors?.callerName?.message}
                  error={Boolean(formState.errors?.callerName?.message)}
                />
              ) : (
                <Skeleton animation="wave" />
              )}
            </FormControl>
            <FormControl
              variant="outlined"
              fullWidth
              sx={{ gridColumn: 'span 2' }}
            >
              {dataJob !== undefined ? (
                <TextField
                  disabled={isFieldWorker}
                  label="Caller Cell"
                  helperText={formState?.errors?.callerCell?.message}
                  error={Boolean(formState?.errors?.callerCell?.message)}
                  {...register('callerCell')}
                />
              ): (
                <Skeleton animation="wave" />
              )}
            </FormControl>
          </>
        )}
        {isAdmin  && (
          <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
          {dataJob !== undefined ? (
            <TextField
              disabled={isFieldWorker}
              label="Admin Notes"
              defaultValue={dataJob.job.adminNote?.note ?? ''}
              helperText={formState.errors?.adminNote?.message}
              error={Boolean(formState.errors?.adminNote?.message)}
              multiline
              minRows={3}
              {...register('adminNote')}
            />
          ) : (
            <Skeleton animation="wave" />
          )}
        </FormControl>
        )}
        <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
          {dataJob !== undefined ? (
            <>
              <InputLabel>Type</InputLabel>
              <Select
                disabled={isFieldWorker}
                label="Type"
                {...register('type')}
                defaultValue={dataJob.job?.type?.id}
              >
                {JobsTypesData?.jobTypesList.items.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          ) : (
            <Skeleton animation="wave" />
          )}
        </FormControl>
        <FormControl fullWidth>
          {dataJob !== undefined ? (
            <>
              <InputLabel>Priority</InputLabel>
              <Select
                label="Priority"
                disabled={isFieldWorker}
                defaultValue={dataJob.job.priority}
                {...register('priority')}
              >
                <MenuItem value="LOW">Low</MenuItem>
                <MenuItem value="NORMAL">Normal</MenuItem>
                <MenuItem value="HIGH">High</MenuItem>
              </Select>
            </>
          ) : (
            <Skeleton animation="wave" />
          )}
        </FormControl>
        <FormControl fullWidth>
          {dataJob !== undefined ? (
            <>
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                {...register('status')}
                defaultValue={dataJob.job.status}
              >
                {JOB_STATUSES_OPTIONS.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item.replaceAll('_', ' ')}
                  </MenuItem>
                ))}
              </Select>
            </>
          ) : (
            <Skeleton animation="wave" />
          )}
        </FormControl>
        <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
          {dataJob !== undefined ? (
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                disabled={isFieldWorker}
                onChange={(fecha) => {
                  setDate(fecha);
                }}
                value={date}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          ) : (
            <Skeleton animation="wave" />
          )}
        </FormControl>
        <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
          {dataJob !== undefined ? (
            <LocalizationProvider dateAdapter={DateAdapter}>
              <TimePicker
                disabled={isFieldWorker}
                value={date}
                onChange={(fecha) => {
                  setDate(fecha);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          ) : (
            <Skeleton animation="wave" />
          )}
        </FormControl>
        {!featureFlagJobEditV2 ? (featureFlag && (
          <FormControl
            variant="outlined"
            fullWidth
            sx={{ gridColumn: 'span 2' }}
          >
            {dataJob !== undefined ? (
              <TextField
                disabled={isFieldWorker}
                label="Dispatched by"
                {...register('dispatchedBy')}
                defaultValue={dataJob?.job.jobJobInfoRelation?.dispatchedBy}
                helperText={formState.errors?.dispatchedBy?.message}
                error={Boolean(formState.errors?.dispatchedBy?.message)}
              />
            ) : (
              <Skeleton animation="wave" />
            )}
          </FormControl>
        )) : <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
            {ADMINOPTIONS && dataJob !== undefined && <InputLabel>Dispatched by</InputLabel>}
            {ADMINOPTIONS && dataJob !== undefined && dataJob !== undefined ? (
              <Select
                {...register('dispatchedBy')}
                label="Dispatched by"
                disabled={isFieldWorker}
                placeholder='Dispatched by'
                defaultValue={dataJob?.job.jobJobInfoRelation?.dispatchedBy}
                error={Boolean(formState.errors?.dispatchedBy?.message)}
              >
                {ADMINOPTIONS?.map((adminItem) => (
                  <MenuItem value={adminItem?.value} key={adminItem?.value} >
                  {adminItem?.label}
                </MenuItem>
                ))}
              </Select>) : (
              <Skeleton animation="wave" />
            )}
          </FormControl>
        }

        <Typography variant="h5" sx={{ gridColumn: 'span 2' }}>
          Fieldworkers
        </Typography>

        <FormControl
          fullWidth
          sx={{
            gridColumn: 'span 2',
            display: 'grid',
            gridTemplateColumns: '90% 10%',
          }}
        >
          {dataJob !== undefined ? (
            <>
              <Autocomplete
                disabled={isFieldWorker}
                multiple
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.fullName}
                defaultValue={dataJob.job.jobJobFieldWorkerRelation.items.map(
                  (item) => item.fieldworker,
                )}
                filterOptions={(options, { inputValue }) =>
                  options.filter(
                    (item) =>
                      item.fullName
                        .toLowerCase()
                        .indexOf(inputValue.toLowerCase()) >= 0,
                  )
                }
                options={fieldworkersList}
                onChange={(_event, newValue) => {
                  setValue(
                    'fieldworker',
                    newValue.map((item) => item.id),
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Fieldworker"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingFieldworkers ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
              <IconButton
                color="primary"
                onClick={() => setCalendar(() => !calendar)}
              >
                <CalendarTodayIcon
                  sx={{
                    height: 'fit-content',
                  }}
                />
              </IconButton>
            </>
          ) : (
            <Skeleton animation="wave" />
          )}
        </FormControl>
        {isFieldWorker && (
          <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
            {dataJob !== undefined ? (
              <TextField
                label="Fieldworker Notes"
                placeholder="Type the job fieldworker notes"
                defaultValue={dataJob.job.fieldworkerNotes}
                helperText={formState.errors?.fieldworkerNotes?.message}
                error={Boolean(formState.errors?.fieldworkerNotes?.message)}
                multiline
                minRows={3}
                {...register('fieldworkerNotes')}
              />
            ) : (
              <Skeleton animation="wave" />
            )}
          </FormControl>
        )}
        {isFieldWorker && (
          <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
            {dataJob !== undefined ? (
              <TextField
                label="Office Notes"
                placeholder="Type the office notes"
                defaultValue={
                  dataJob.job?.officeNoteJobRelation?.items[0]?.officeNotes
                }
                helperText={formState.errors?.officeNotes?.message}
                error={Boolean(formState.errors?.officeNotes?.message)}
                multiline
                minRows={3}
                {...register('officeNotes')}
              />
            ) : (
              <Skeleton animation="wave" />
            )}
          </FormControl>
        )}
        <FormControl variant="outlined" fullWidth sx={{ gridColumn: 'span 2' }}>
          {dataJob !== undefined ? (
            <TextField
              label="Unit Serviced"
              defaultValue={dataJob.job.unitServiced}
              {...register('unitServiced')}
              helperText={formState.errors?.unitServiced?.message}
              error={Boolean(formState.errors?.unitServiced?.message)}
            />
          ) : (
            <Skeleton animation="wave" />
          )}
        </FormControl>
        <FormControl variant="outlined" fullWidth sx={{ gridColumn: 'span 2' }}>
          {dataJob !== undefined ? (
            <TextField
              label="Model"
              {...register('model')}
              helperText={formState.errors?.model?.message}
              error={Boolean(formState.errors?.model?.message)}
              defaultValue={dataJob.job.model}
            />
          ) : (
            <Skeleton animation="wave" />
          )}
        </FormControl>
        <FormControl variant="outlined" fullWidth sx={{ gridColumn: 'span 2' }}>
          {dataJob !== undefined ? (
            <TextField
              label="Serial"
              {...register('serial')}
              helperText={formState.errors?.serial?.message}
              error={Boolean(formState.errors?.serial?.message)}
              defaultValue={dataJob.job.serial}
            />
          ) : (
            <Skeleton animation="wave" />
          )}
        </FormControl>
        <FormControl variant="outlined" fullWidth sx={{ gridColumn: 'span 2' }}>
          {dataJob !== undefined ? (
            <TextField
              label="Work Performed"
              {...register('workPerformed')}
              multiline
              minRows={3}
              helperText={formState.errors?.workPerformed?.message}
              error={Boolean(formState.errors?.workPerformed?.message)}
              defaultValue={dataJob.job.workPerformed}
            />
          ) : (
            <Skeleton animation="wave" />
          )}
        </FormControl>
        <FormControl variant="outlined" fullWidth sx={{ gridColumn: 'span 2' }}>
          {dataJob !== undefined ? (
            <TextField
              label="Purchase Order"
              {...register('purchaseOrder')}
              helperText={formState.errors?.purchaseOrder?.message}
              error={Boolean(formState.errors?.purchaseOrder?.message)}
              defaultValue={dataJob.job.purchaseOrder}
            />
          ) : (
            <Skeleton animation="wave" />
          )}
        </FormControl>
        <FormControl variant="outlined" fullWidth sx={{ gridColumn: 'span 2' }}>
          {dataJob !== undefined ? (
            <TextField
              label="Work Order"
              {...register('workOrder')}
              helperText={formState.errors?.workOrder?.message}
              error={Boolean(formState.errors?.workOrder?.message)}
              defaultValue={dataJob.job.workOrder}
            />
          ) : (
            <Skeleton animation="wave" />
          )}
        </FormControl>

        <FormControl variant="outlined" fullWidth sx={{ gridColumn: 'span 2' }}>
          {dataJob !== undefined ? (
            <TextField
              label="Call ID"
              {...register('callID')}
              helperText={formState.errors?.callID?.message}
              error={Boolean(formState.errors?.callID?.message)}
              defaultValue={dataJob.job.callID}
            />
          ) : (
            <Skeleton animation="wave" />
          )}
        </FormControl>
        {isFieldWorker && (
          <>
            <Box sx={{ gridColumn: 'span 2' }}>
              {watch('signature') ? (
                <Box display="flex" flexWrap="wrap" justifyContent="center">
                  <Box
                    sx={{
                      position: 'relative',
                      height: '150px',
                    }}
                  >
                    <IconButton
                      edge="end"
                      onClick={() => {
                        setValue('signature', '');
                      }}
                      sx={{
                        position: 'absolute',
                        top: '0',
                        right: '10px',
                      }}
                    >
                      <ClearIcon color="error" />
                    </IconButton>
                    <img
                      src={getValues('signature') ?? ''}
                      alt="signature"
                      style={{
                        height: '150px',
                      }}
                    />
                  </Box>
                  <Button
                    type="button"
                    variant="outlined"
                    sx={{
                      width: '100%',
                      mt: 2,
                    }}
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  >
                    Edit Signature
                  </Button>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    sx={{ gridColumn: 'span 2', mt: 2 }}
                  >
                    {dataJob ? (
                      <TextField
                        label="Signature Name"
                        {...register('signatureName')}
                        helperText={formState.errors?.signatureName?.message}
                        error={Boolean(
                          formState.errors?.signatureName?.message,
                        )}
                        defaultValue={dataJob.job.signatureName}
                      />
                    ) : (
                      <Skeleton animation="wave" />
                    )}
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    sx={{ gridColumn: 'span 2', mt: 2 }}
                  >
                    {dataJob ? (
                      <TextField
                        label="Signature Title"
                        {...register('signatureTitle')}
                        helperText={formState.errors?.signatureTitle?.message}
                        error={Boolean(
                          formState.errors?.signatureTitle?.message,
                        )}
                        defaultValue={dataJob.job.signatureTitle}
                      />
                    ) : (
                      <Skeleton animation="wave" />
                    )}
                  </FormControl>
                </Box>
              ) : (
                <Button
                  type="button"
                  variant="outlined"
                  sx={{
                    width: '100%',
                  }}
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  Add Signature
                </Button>
              )}
            </Box>
            <Typography variant="h5" sx={{ gridColumn: 'span 2' }}>
              Images
            </Typography>
            <Box sx={{ gridColumn: 'span 2' }}>
              <Button
                type="button"
                variant="outlined"
                disabled={oldImages.length + imagesUri.length >= 10}
                sx={{
                  width: '100%',
                }}
                onClick={handleAttachFile}
              >
                Add Image
              </Button>

              <Box
                display="flex"
                justifyContent="flex-start"
                flexWrap="wrap"
                sx={{
                  width: '100%',
                  mt: 2,
                }}
              >
                {oldImages.length > 0 &&
                  oldImages.map((image, index) => (
                    <Box
                      key={image?.filename || ''}
                      sx={{ width: '30%', position: 'relative', mr: 2 }}
                    >
                      <IconButton
                        edge="end"
                        onClick={() => {
                          const auxOldImages = [...oldImages];
                          auxOldImages.splice(index, 1);
                          setOldImages(auxOldImages);
                        }}
                        sx={{
                          position: 'absolute',
                          top: '0',
                          right: '10px',
                        }}
                      >
                        <ClearIcon color="error" />
                      </IconButton>
                      <img
                        src={image.downloadUrl}
                        alt={image?.filename || ''}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    </Box>
                  ))}
                {imagesUri.length > 0 &&
                  imagesUri.map((image, index) => (
                    <Box
                      key={image?.filename || ''}
                      sx={{ width: '30%', position: 'relative' }}
                    >
                      <IconButton
                        edge="end"
                        onClick={() => {
                          const auxImages = [...imagesUri];
                          auxImages.splice(index, 1);
                          setImagesUri(auxImages);
                        }}
                        sx={{
                          position: 'absolute',
                          top: '0',
                          right: '10px',
                        }}
                      >
                        <ClearIcon color="error" />
                      </IconButton>
                      <img
                        src={`${image.url}?signature=${dataFileStack?.fileUploadInfo.signature}&policy=${dataFileStack?.fileUploadInfo.policy}`}
                        alt={image?.filename || ''}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    </Box>
                  ))}
              </Box>
            </Box>
          </>
        )}
        <Typography variant="h5" sx={{ gridColumn: 'span 2' }}>
          Parts Used
        </Typography>
        <FormControl variant="outlined" fullWidth sx={{ gridColumn: 'span 2' }}>
          {dataJob !== undefined ? (
            <Button
              variant="outlined"
              onClick={() => {
                setOpen(!open);
              }}
            >
              Add Part
            </Button>
          ) : (
            <Skeleton animation="wave" />
          )}
        </FormControl>
        <List sx={{ gridColumn: 'span 2' }}>
          {dataJob &&
            watch('parts')?.map((item, index) => (
              <ListItem
                key={item.id}
                sx={{ 
                  border: '1px solid #e0e0e0', 
                  borderRadius: '10px', 
                  marginBottom: '5px', 
                  '&:nth-last-child(1)': {
                    marginBottom: '0'
                  } 
                }}
                secondaryAction={
                  <IconButton
                    edge="end"
                    onClick={() => {
                      const newParts = getValues('parts');
                      if (newParts) {
                        newParts.splice(index, 1);
                        setValue('parts', newParts);
                      }
                    }}
                  >
                    <ClearIcon color="error" />
                  </IconButton>
                }
                disablePadding
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  padding='8px 40px 8px 8px'
                  textOverflow='ellipsis'
                  overflow='hidden'
                >
                  <Typography variant="body1" noWrap>
                    Part Name: {item.name}
                  </Typography>
                  {item.partNumber !== '' && item.unit !== null && (
                    <Typography variant="body1" noWrap>
                      Part Number: {item.partNumber}
                    </Typography>
                  )}
                  {item.unit !== '' && item.unit !== null && (
                    <Typography variant="body1" noWrap>Unit: {item.unit}</Typography>
                  )}
                </Box>
              </ListItem>
            ))}
        </List>
        {fieldworkerDocsFeatureFlag && isAdmin && <FormControl variant="outlined" fullWidth sx={{ gridColumn: 'span 2' }}>
          <Button
            variant="outlined"
            onClick={() => handleAttachDocs()}
            disabled={fieldworkerFIles?.length + currentFwDocs?.length >= 5}
          >
            Add Fieldworker Docs
          </Button>
        </FormControl>}
        {fieldworkerDocsFeatureFlag && isAdmin && fieldworkerFIles && 
        <List sx={{ gridColumn: 'span 2' }}>
          {fieldworkerFIles?.length > 0 &&
            fieldworkerFIles?.map((item, index) => (
              <ListItem
                key={item.uploadId}
                sx={{ 
                  border: '1px solid #e0e0e0', 
                  borderRadius: '10px', 
                  marginBottom: '5px', 
                  '&:nth-last-child(1)': {
                    marginBottom: '0'
                  }
                }}
                secondaryAction={
                  <IconButton
                    edge="end"
                    onClick={() => deleteNewFieldworkerDoc(item.uploadId)}
                  >
                    <ClearIcon color="error" />
                  </IconButton>
                }
                disablePadding
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  padding='8px 40px 8px 8px'
                  textOverflow='ellipsis'
                  overflow='hidden'
                >
                  <Typography variant="body1" noWrap>
                    {index + 1}.- {item.filename}
                  </Typography>
                </Box>
              </ListItem>
            ))}
          {currentFwDocs.length > 0 &&
            currentFwDocs?.map((item, index) => (
              <ListItem
                key={item.id}
                sx={{ 
                  border: '1px solid #e0e0e0', 
                  borderRadius: '10px', 
                  marginBottom: '5px', 
                  '&:nth-last-child(1)': {
                    marginBottom: '0'
                  } 
                }}
                secondaryAction={
                  <IconButton
                    edge="end"
                    onClick={() => deleteOldFieldworkerDoc(item)}
                  >
                    <ClearIcon color="error" />
                  </IconButton>
                }
                disablePadding
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  padding='8px 40px 8px 8px'
                  textOverflow='ellipsis'
                  overflow='hidden'
                >
                  <Typography variant="body1" noWrap>
                    {index + (fieldworkerFIles?.length + 1)}.- {item.filename}
                  </Typography>
                </Box>
              </ListItem>
            ))}
        </List>}
        <FormControl fullWidth sx={{ gridColumn: 'span 2' }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked
                {...register('notification')}
                disabled={isFieldWorker}
              />
            }
            label="Send Notification"
          />
        </FormControl>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          sx={{
            gridColumn: 'span 2',
            marginBottom: '20px',
            '&>*': {
              /**
               * @param theme - Theme.
               * @returns Margin.
               */
              marginLeft: (theme) => `${theme.spacing(2)} !important`,
            },
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              history.goBack();
            }}
          >
            Cancel
          </Button>
          <LoadingButton variant="contained" type="submit" loading={loadingMutation}>
            Save
          </LoadingButton>
        </Box>
      </FormImageContainer>
    </FormViewConatiner>
  );
}
