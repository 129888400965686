import dayjs from 'dayjs';
import { Job, JobFieldworkerFilter } from '../../shared/types/generated';
import {
  CalendarType,
  FieldworkerColor,
  FieldworkerDayList,
  JobColor,
} from './calendar-types';
/**
 * @param {any} day - New Data.
 * @param {} filters - Old Data.
 * @param {CalendarType} type - Old Data.
 * @returns {Array<string>} - Variables.
 */
export function getCalendarFilters(
  day: string,
  filters: {
    id: {
      equals?: string;
      not_equals?: string;
    };
  }[],
  type: CalendarType,
): JobFieldworkerFilter {
  const initialFilters: JobFieldworkerFilter = {
    fieldworker: {
      AND: filters,
    },
  };
  if (type === CalendarType.WEEK) {
    const gte = dayjs(day)
      .date(Number(dayjs(day).format('DD')) - Number(dayjs(day).format('d')))
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      .toISOString();
    const lte = dayjs(day)
      .date(
        Number(dayjs(day).format('DD')) - Number(dayjs(day).format('d')) + 6,
      )
      .hour(23)
      .minute(59)
      .second(59)
      .toISOString();

    initialFilters.job = {
      AND: [
        {
          startDateTime: {
            // start
            gte,
          },
        },
        {
          archived: {
            is_empty: true,
          },
        },
      ],
      startDateTime: {
        // end
        lte,
      },
    };
  }
  if (type === CalendarType.DAY || type === CalendarType.AGENDA) {
    const gte = dayjs(day)
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      .toISOString();
    const lte = dayjs(day).hour(23).minute(59).second(59).toISOString();

    initialFilters.job = {
      AND: [
        {
          startDateTime: {
            // start
            gte,
          },
        },
        {
          archived: {
            is_empty: true,
          },
        },
      ],
      startDateTime: {
        // end
        lte,
      },
    };
  }
  return initialFilters;
}
/**
 * @param {} fieldworkers - Old Data.
 * @returns {Array<FieldworkerColor>} - Variables.
 */
export function filterWeek(fieldworkers: FieldworkerColor[]): {
  id: string;
  fullName: string;
  jobs: JobColor[];
}[] {
  const result: {
    id: string;
    fullName: string;
    jobs: JobColor[];
  }[] = [];
  fieldworkers.forEach((fieldworker) => {
    fieldworker.fieldworkerJobFieldworkerRelation.items.forEach(({ job }) => {
      const start = Number(dayjs(job.startDateTime).format('H')) + 1;
      const end = Number(dayjs(job.startDateTime).format('d')) + 1;
      const find = result.findIndex((item) => item.id === `${start}-${end}`);
      if (find === -1) {
        result.push({
          id: `${start}-${end}`,
          fullName: fieldworker.fullName,
          jobs: [{ ...job, color: fieldworker.color, fieldWorker: fieldworker }],
        });
      } else {
        result[find].jobs.push({ ...job, color: fieldworker.color, fieldWorker: fieldworker });
      }
    });
  });
  return result;
}
/**
 * @param {} fieldworkers - Old Data.
 * @returns {Array<FieldworkerColor>} - Variables.
 */
export function filterDay(
  fieldworkers: FieldworkerColor[],
): FieldworkerDayList {
  const result: FieldworkerDayList = [];
  fieldworkers.forEach((fieldworker, index) => {
    const jobs: { id: string; jobsDay: JobColor[] }[] = [];
    fieldworker.fieldworkerJobFieldworkerRelation.items.forEach(({ job }) => {
      const start = Number(dayjs(job.startDateTime).format('HH')) + 1;
      const find = jobs.findIndex(
        (item) =>
          item.id === `${start < 10 ? `0${start}` : start}-${index + 1}`,
      );
      if (find === -1) {
        jobs.push({
          id: `${start < 10 ? `0${start}` : start}-${index + 1}`,
          jobsDay: [{ ...job, color: fieldworker.color }],
        });
      } else {
        jobs[find].jobsDay.push({ ...job, color: fieldworker.color });
      }
    });
    result.push({
      id: fieldworker.id,
      fullName: fieldworker.fullName,
      color: fieldworker.color,
      jobs,
    });
  });
  return result;
}
/**
 * @param {} fieldworkers - Old Data.
 * @returns {Array<FieldworkerColor>} - Variables.
 */
export function filterDayResponsive(fieldworkers: FieldworkerColor[]): {
  job: Job;
  fieldworker: FieldworkerColor;
}[] {
  const result: {
    job: Job;
    fieldworker: FieldworkerColor;
  }[] = [];
  fieldworkers.forEach((fieldworker) => {
    fieldworker.fieldworkerJobFieldworkerRelation.items.forEach(({ job }) => {
      result.push({
        job,
        fieldworker,
      });
    });
  });

  return result.sort(
    (a, b) => +new Date(a.job.startDateTime) - +new Date(b.job.startDateTime),
  );
}
/**
 * @param {} fieldworkers - Old Data.
 * @returns {Array<FieldworkerColor>} - Variables.
 */
export function getJobsCount(fieldworkers: FieldworkerDayList): number {
  let count = 0;
  fieldworkers.forEach((fieldworker) => {
    fieldworker.jobs.forEach((job) => {
      if (job.jobsDay.length > 0) {
        count += 1;
      }
    });
  });
  return count;
}
