import { useEffect } from 'react';
import { MainLoader } from '../../shared/components/MainLoader';

/**
 * @returns {JSX.Element} - Session component.
 */
export function CustomerSession(): JSX.Element {
  useEffect(() => {
    if (
      navigator.userAgent.toLowerCase().indexOf('iphone') > -1 ||
      navigator.userAgent.toLowerCase().indexOf('ipad') > -1 ||
      navigator.userAgent.toLowerCase().indexOf('Mac') > -1
    ) {
      window.location.href =
        'https://apps.apple.com/ve/app/coolrite/id1597012509';
    } else {
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.coolrite';
    }
  }, []);

  return <MainLoader />;
}
