/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsdoc/require-jsdoc */
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import MuiTable from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';

import { useMemo } from 'react';
import { TableSkeleton } from './TableSkeleton';
import { Pagination } from '../Pagination';

const TABLE_CELL_ALIGN: {
  left: TableCellProps['align'];
  right: TableCellProps['align'];
  default: TableCellProps['align'];
} = {
  left: 'left',
  right: 'right',
  default: 'left',
};

export type Column<T> = {
  name: string;
  key: string;
  width?: number;
  render?: (data: T) => React.ReactNode;
  action?: (data: T) => React.ReactNode;
};

type TableProps<T> = {
  loading?: boolean;
  columns: Column<T>[];
  data?: T[];
  page?: number;
  pageSize?: number;
  total?: number;
  onChangePage?: (value: number) => void;
};

export const Table = <T extends Record<any, any>>({
  loading,
  columns,
  data,
  page,
  pageSize,
  total,
  onChangePage,
}: TableProps<T>): React.ReactElement<T> => {
  const tablePageSize = pageSize || 10;
  const tableFakeRowsAndColumns = useMemo(
    () => ({
      rows: Array.from(
        {
          length: tablePageSize - (data?.length || 0),
        },
        (v, i) => i,
      ),
      columns: Array.from({ length: columns.length }, (v, i) => i),
    }),
    [tablePageSize, data, columns],
  );

  const getColumnAlign = (
    index: number,
    isActionsEnable?: boolean,
  ): TableCellProps['align'] => {
    if (index === 0) {
      return TABLE_CELL_ALIGN.left;
    }

    if (index === columns.length - 1 && isActionsEnable) {
      return TABLE_CELL_ALIGN.right;
    }

    return TABLE_CELL_ALIGN.default;
  };

  return (
    <Paper
      variant="card"
      sx={{
        display: 'grid',
        gridTemplateRows: '1fr auto',
      }}
    >
      <TableContainer>
        <MuiTable aria-label="simple dense table" stickyHeader>
          <TableHead> 
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  sx={{ minWidth: column.width, height: '50px !important' }}
                  key={column.key}
                  align={getColumnAlign(index, Boolean(column.action))}
                >
                  {column.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? <TableSkeleton rows={10} columns={columns.length} /> : data?.map((row, rowIndex) => (
              <TableRow key={`row-key-${rowIndex}`} sx={{ maxHeight: '50px' }}>
                {columns.map((column, columnIndex) => (
                  <TableCell
                    sx={{ 
                      minWidth: column.width,
                      height: '50px'
                    }}
                    key={`cell-${rowIndex}-${columnIndex}`}
                    align={getColumnAlign(columnIndex, Boolean(column.action))}
                  >
                    {column.render?.(row) ||
                      column.action?.(row) ||
                      row[column.key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {!loading && tableFakeRowsAndColumns.rows.map((_fakeRow) => (
              <TableRow key={_fakeRow} sx={{ height: '50px' }}>
                {tableFakeRowsAndColumns.columns.map((fakeCell, index) => (
                  <TableCell align={getColumnAlign(index)} key={fakeCell} />
                ))}
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
      <Pagination
        page={page || 0}
        pageSize={pageSize || 0}
        totalCount={total || 0}
        onChange={(_page) => onChangePage?.(_page)}
      />
    </Paper>
  );
};
