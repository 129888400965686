import React, { PropsWithChildren, useEffect } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  Polyline,
  InfoWindow,
} from '@react-google-maps/api';
import { ClockInOut, MapListQuery } from '../../../shared/types/generated';
import { mapData } from '../map-utils';
import { FieldworkerColor } from '../map-types';
import { API_KEY_GOOGLE } from '../../../shared/constants';
import { InfoTooltipComponent } from './InfoTooltipComponent';

const styles = {
  containerStyle: {
    width: 'inherit',
    height: 'inherit',
  },
  responsiveMap: {
    with: '100%',
    height: '1900%',
  },
};

const clockIcon = {
  ON_ROUTE:
    'M25.4997 28.3859C26.6802 28.3859 27.6837 27.9719 28.5101 27.144C29.3365 26.3161 29.7497 25.3108 29.7497 24.128H32.583V8.51583C32.583 7.73522 32.3056 7.06697 31.7507 6.51108C31.1959 5.95519 30.5288 5.67725 29.7497 5.67725H9.91634V11.3544H5.66634L1.41634 17.0316V24.128H4.24967C4.24967 25.3108 4.66287 26.3161 5.48926 27.144C6.31565 27.9719 7.31912 28.3859 8.49967 28.3859C9.68023 28.3859 10.6837 27.9719 11.5101 27.144C12.3365 26.3161 12.7497 25.3108 12.7497 24.128H21.2497C21.2497 25.3108 21.6629 26.3161 22.4893 27.144C23.3156 27.9719 24.3191 28.3859 25.4997 28.3859ZM25.4997 25.5473C25.0983 25.5473 24.7618 25.4113 24.4903 25.1393C24.2188 24.8672 24.083 24.5302 24.083 24.128C24.083 23.7259 24.2188 23.3888 24.4903 23.1168C24.7618 22.8447 25.0983 22.7087 25.4997 22.7087C25.9011 22.7087 26.2375 22.8447 26.509 23.1168C26.7806 23.3888 26.9163 23.7259 26.9163 24.128C26.9163 24.5302 26.7806 24.8672 26.509 25.1393C26.2375 25.4113 25.9011 25.5473 25.4997 25.5473ZM8.49967 25.5473C8.09828 25.5473 7.76183 25.4113 7.4903 25.1393C7.21877 24.8672 7.08301 24.5302 7.08301 24.128C7.08301 23.7259 7.21877 23.3888 7.4903 23.1168C7.76183 22.8447 8.09828 22.7087 8.49967 22.7087C8.90106 22.7087 9.23752 22.8447 9.50905 23.1168C9.78057 23.3888 9.91634 23.7259 9.91634 24.128C9.91634 24.5302 9.78057 24.8672 9.50905 25.1393C9.23752 25.4113 8.90106 25.5473 8.49967 25.5473ZM9.91634 18.4509H3.89551L7.08301 14.193H9.91634V18.4509Z',
  CLOCK_IN:
    'M19.5741 21.7022L21.5574 19.7152L16.3158 14.4638V7.93507H13.4824V15.5992L19.5741 21.7022ZM14.8991 29.2244C12.9394 29.2244 11.0977 28.8519 9.37409 28.1067C7.65048 27.3616 6.15117 26.3504 4.87617 25.073C3.60117 23.7956 2.5918 22.2936 1.84805 20.5668C1.1043 18.84 0.732422 16.9949 0.732422 15.0315C0.732422 13.0682 1.1043 11.2231 1.84805 9.49629C2.5918 7.76949 3.60117 6.26741 4.87617 4.99005C6.15117 3.71269 7.65048 2.70144 9.37409 1.95631C11.0977 1.21119 12.9394 0.838623 14.8991 0.838623C16.8588 0.838623 18.7005 1.21119 20.4241 1.95631C22.1477 2.70144 23.647 3.71269 24.922 4.99005C26.197 6.26741 27.2064 7.76949 27.9501 9.49629C28.6939 11.2231 29.0658 13.0682 29.0658 15.0315C29.0658 16.9949 28.6939 18.84 27.9501 20.5668C27.2064 22.2936 26.197 23.7956 24.922 25.073C23.647 26.3504 22.1477 27.3616 20.4241 28.1067C18.7005 28.8519 16.8588 29.2244 14.8991 29.2244Z',
  CLOCK_OUT:
    'M12.9939 21.5603L22.9814 11.5543L20.998 9.56726L12.9939 17.5862L8.95638 13.5413L6.97305 15.5283L12.9939 21.5603ZM14.9772 29.2244C13.0175 29.2244 11.1758 28.8519 9.45221 28.1067C7.7286 27.3616 6.2293 26.3504 4.9543 25.073C3.6793 23.7956 2.66992 22.2936 1.92617 20.5668C1.18242 18.84 0.810547 16.9949 0.810547 15.0315C0.810547 13.0682 1.18242 11.2231 1.92617 9.49629C2.66992 7.76949 3.6793 6.26741 4.9543 4.99005C6.2293 3.71269 7.7286 2.70144 9.45221 1.95631C11.1758 1.21119 13.0175 0.838623 14.9772 0.838623C16.9369 0.838623 18.7786 1.21119 20.5022 1.95631C22.2258 2.70144 23.7251 3.71269 25.0001 4.99005C26.2751 6.26741 27.2845 7.76949 28.0283 9.49629C28.772 11.2231 29.1439 13.0682 29.1439 15.0315C29.1439 16.9949 28.772 18.84 28.0283 20.5668C27.2845 22.2936 26.2751 23.7956 25.0001 25.073C23.7251 26.3504 22.2258 27.3616 20.5022 28.1067C18.7786 28.8519 16.9369 29.2244 14.9772 29.2244Z',
};

const initialCenter = {
  lat: 39.922878,
  lng: -101.694092,
};

export type MapComponentProps = {
  locations: MapListQuery | undefined;
};

export type LatLngType = { lat: number; lng: number };

/**
 * @param { location } LatLng - Markers.
 * @returns { React.FC } - Map.
 */
export const MapComponent: React.FC<PropsWithChildren<MapComponentProps>> = ({
  locations,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY_GOOGLE,
  });
  const [activeMarker, setActiveMarker] = React.useState<string | null>();
  const [centerMap, setCenterMap] = React.useState(initialCenter);
  const [zoom, setZoom] = React.useState(5);
  const [, setMapState] = React.useState(null);

  const onUnmount = React.useCallback(() => {
    setMapState(null);
  }, []);

  /**
   * @param id - Id clock.
   */
  const handleClickMarker = (id: string): void => {
    if (id === activeMarker) {
      return;
    }
    setActiveMarker(id);
  };

  const markers = React.useMemo(() => {
    const formatData = locations?.fieldworkersList?.items.map(
      (fieldworker) => ({
        ...fieldworker,
        color: fieldworker.color,
      }),
    );
    const validMarkers = formatData?.filter(
      (item) => item.fieldworkerClockInOutRelation.items.length > 0,
    ) as FieldworkerColor[];
    return mapData(validMarkers);
  }, [locations]);
  const polylines = React.useMemo(() => {
    if (markers === undefined) return [];
    const pathData = markers.map((item) => {
      const paths: LatLngType[] = [];
      item.clocks.forEach((clock) => {
        if (clock.location === null) return null;
        return paths.push({
          lat: clock?.location.coordinates[0],
          lng: clock?.location.coordinates[1],
        });
      });
      return { paths, fieldworker: item.fieldworker };
    });
    return pathData;
  }, [markers]);
  useEffect(() => {
    // Center the map on the last coords that exist.
    if (markers.length > 0) {
      let coords: ClockInOut | undefined;
      markers.forEach((item) => {
        coords = item.clocks.find((clock) => clock.location !== null);
      });
      if (coords === undefined) return setCenterMap(initialCenter);
      const positions = {
        lat: coords?.location.coordinates[0],
        lng: coords?.location.coordinates[1],
      };
      setZoom(5);
      return setCenterMap(positions);
    }
    setZoom(5);
    return setCenterMap(initialCenter);
  }, [markers]);
  const onLoad = React.useCallback((map) => {
    if (map === null) return;
    const bounds = new window.google.maps.LatLngBounds(initialCenter);
    // map.fitBounds(bounds);
    console.log(bounds);

    setMapState(map);
  }, []);
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={styles.containerStyle}
      center={centerMap}
      zoom={zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {markers &&
        markers.length > 0 &&
        markers.map((marker) => {
          if (marker.clocks === null) return null;
          return marker.clocks.map((clock) => {
            if (clock.location === null) return null;
            const positions = {
              lat: clock.location.coordinates[0],
              lng: clock.location.coordinates[1],
            };
            return (
              <MarkerF
                icon={{
                  path: clockIcon[clock.type as keyof typeof clockIcon],
                  fillColor: marker.fieldworker.color || 'black',
                  fillOpacity: 1,
                  scale: 0.8,
                  anchor: new google.maps.Point(10, 25),
                }}
                key={clock.id}
                position={positions}
                onClick={() => handleClickMarker(clock.id)}
              >
                {activeMarker === clock.id ? (
                  <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                    <InfoTooltipComponent job={clock.job} />
                  </InfoWindow>
                ) : null}
              </MarkerF>
            );
          });
        })}
      {polylines &&
        polylines.length > 0 &&
        polylines.map((line) => {
          if (line.paths.length <= 1) return null;
          return (
            <Polyline
              key={`${line.fieldworker.id} ${line.fieldworker.color}`}
              path={line.paths}
              options={{
                strokeColor: line.fieldworker.color,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                clickable: false,
                draggable: false,
                editable: false,
                visible: true,
                zIndex: 1,
              }}
            />
          );
        })}
    </GoogleMap>
  ) : (
    <></>
  );
};
