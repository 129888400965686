import { Typography, TypographyProps, styled } from '@mui/material';
import { theme as appTheme } from '../css/theme';

export const JobNumberTypo = styled(Typography)<
TypographyProps & { isRead: boolean }
>(() => ({
  fontFamily: 'Poppins',
  fontSize: '12px',
  fontWeight: 'normal',
}));
export const NotifTextWrapper = styled(Typography)<
  TypographyProps & { isRead: boolean }
>(() => ({
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 'normal',
}));

export const NotifBold = styled('b')<{ isRead: boolean }>(({ isRead }) => ({ 
  color: isRead ? 'lightblue' : appTheme.palette.secondary.main, 
  textDecoration: 'none' 
}));

export const AlertTime = styled(Typography)({
  fontSize: '12px',
  fontWeight: 'normal',
});