/* eslint-disable jsdoc/require-jsdoc */
import { useState, useMemo } from 'react';
import dayjs from 'dayjs';
import { Grid, Paper, Box, Typography, useMediaQuery } from '@mui/material';
import { useMapListQuery } from '../../shared/types/types';
import { MainLoader } from '../../shared/components/MainLoader';
import {
  MapFilters,
  MapFiltersModal,
} from './components/MapFilters';
import { FieldworkerColor } from './map-types';
import { getMapFilters } from './map-utils';
import { theme } from '../../shared/css/theme';
import { MapComponent } from './components/Map';
import { MapResponsive } from './MapResponsive';
import { useQuery } from '../../shared/utils/route';
import { UseValidateFeatureFlag } from '../auth/auth-hooks';
import { FeatureFlags } from '../../shared/constants/featureFlag';
import { MapFiltersModalV2, MapFiltersV2 } from './components/MapFiltersV2';

const FETCH_POLICY = 'network-only';

/**
 * @returns {JSX.Element} - Calendar View.
 */
export function Map(): JSX.Element {
  const { query, searchQuery } = useQuery<{ date: string }>();
  const featureFlagMapFieldWorker = UseValidateFeatureFlag(FeatureFlags.MAP_ACTIVE_FIELDWORKER);
  const date = useMemo<Date>(
    () => (query?.date ? dayjs(query.date).toDate() : dayjs().toDate()),
    [query],
  );
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [filters, setFilters] = useState<
    {
      id: {
        not_equals: string;
      };
    }[]
  >([]);
  const { data, loading } = useMapListQuery({
    fetchPolicy: FETCH_POLICY,
    variables: {
      filter: {
        archived: {
          is_empty: true,
        },
      },
      jobFilter: getMapFilters(date.toISOString(), filters),
    },
  });
  const formatData = data?.fieldworkersList?.items.map((fieldworker) => ({
    ...fieldworker,
    color: fieldworker.color,
  }));
  const filteredFieldWorker = useMemo(() => {
    const retArrayOne: FieldworkerColor[] = [];
    const retArraytwo: FieldworkerColor[] = [];
    formatData?.forEach((item) => {
      if (filters.find((f) => f.id.not_equals === item.id) !==
      undefined ||
    item.fieldworkerClockInOutRelation.items.length > 0) {
        retArrayOne.push(item as FieldworkerColor);
      } else {
        retArraytwo.push(item as FieldworkerColor);
      }
    });
    
    return {
      activeFieldworkers: retArrayOne,
      inactiveFieldworkers: retArraytwo,
    };
  }, [formatData, filters]);

  const onChangeDate = (value: Date | null): void => {
    const search: { date: string } = {
      date: dayjs(value).format('MM/DD/YYYY'),
    };
    setFilters([]);
    searchQuery(search);
  };

  const onChangeFilter = (id: string, checked: boolean): void => {
    if (checked) {
      setFilters((state) => [...state, { id: { not_equals: id } }]);
    } else {
      setFilters((state) => state.filter((item) => item.id.not_equals !== id));
    }
  };

  return (
    <Grid container spacing={[0, 1, 2]}>
      <Grid
        item
        sx={{
          display: ['none', 'block', 'block'],
          minWidth: '230px',
          maxWidth: '250px',
          width: '30%',
        }}
      >
        <Paper
          variant="card"
          sx={{
            height: '82vh',
            '&::-webkit-scrollbar': {
              width: '0.3rem',
              backgroundColor: '#fafafa',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#bcbcbd',
              borderRadius: '10px',
            },
          }}
        >
          <Typography
            variant="h6"
            color="primary"
            sx={{
              fontSize: '15px',
            }}
          >
            Resources
          </Typography>

          <Box
            sx={{
              width: '100%',
            }}
          >
            {featureFlagMapFieldWorker ? <MapFiltersV2
              loading={loading}
              onChangeDate={onChangeDate}
              date={date}
              fieldworkers={filteredFieldWorker}
              filters={filters}
              onChange={onChangeFilter}
            /> : <MapFilters
            onChangeDate={onChangeDate}
            date={date}
            fieldworkers={(formatData as FieldworkerColor[]) ?? []}
            filters={filters}
            onChange={onChangeFilter}
          />}
          </Box>
        </Paper>
      </Grid>
      {!loading ? (
        <>
          {matchesSm ? (
            <MapResponsive formatData={data}>
              {featureFlagMapFieldWorker ? <MapFiltersModalV2
                loading={loading}
                onChangeDate={onChangeDate}
                date={date}
                fieldworkers={filteredFieldWorker}
                filters={filters}
                onChange={onChangeFilter}
              /> :
              <MapFiltersModal
                onChangeDate={onChangeDate}
                date={date}
                fieldworkers={(formatData as FieldworkerColor[]) ?? []}
                filters={filters}
                onChange={onChangeFilter}
              />}
            </MapResponsive>
          ) : (
            <Grid item flexGrow={1}>
              <Paper
                variant="card"
                sx={{
                  padding: '0',
                  height: '82vh',
                  position: 'relative',
                  overflowY: 'hidden',
                  backgroundColor: ['#00000000', '#ffff'],
                  '&::-webkit-scrollbar': {
                    backgroundColor: '#fafafa',
                    width: '0.6rem',
                    height: '0.6rem',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#bcbcbd',
                    borderRadius: '10px',
                  },
                }}
              >
                <MapComponent locations={data} />
              </Paper>
            </Grid>
          )}
        </>
      ) : (
        <Grid item xs={matchesSm ? 12 : 8}>
          <MainLoader />
        </Grid>
      )}
    </Grid>
  );
}
