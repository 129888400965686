/* eslint-disable jsdoc/require-jsdoc */
import React, { useState, MouseEvent } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import { GRAY_LIGHT } from '../../../shared/css/theme';

import { JobColor } from '../../map/map-types';
import { InfoCardPopover } from './InfoCardPopover';

type InfoCardMinPreviewProps = {
  job: JobColor;
  fieldWorkerName: string | undefined;
  index: number;
};

export const InfoCardMinPreview: React.FC<InfoCardMinPreviewProps> = ({
  job,
  index,
  fieldWorkerName,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLDivElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        onClick={handleClick}
        sx={{
          width: '50%',
          borderRight: index % 2 === 0 ? `1px solid ${GRAY_LIGHT}` : 'none',
          borderBottom: `1px solid ${GRAY_LIGHT}`,
          backgroundColor: job.color,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          paddingLeft: '5px',
          textOverflow: 'ellipsis',
        }}
      >
        <Link
          noWrap
          href={`/jobs/${job.id}`}
          color="grayDark"
          underline="none"
          sx={{
            fontWeight: 'bold',
            fontSize: '11px',
            maxWidth: '70%',
          }}
        >
          #{job?.code}
        </Link>
      </Box>
      <InfoCardPopover
        job={job}
        fieldWorkerName={fieldWorkerName}
        popoverProps={{
          id: `calendar-card-popover-${job.id}`,
          open,
          anchorEl,
          onClose: handleClose,
        }}
      />
    </>
  );
};
