import React, { useEffect, useMemo, useState } from 'react';
import { Add, MoreHoriz, Refresh } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControl, IconButton, InputLabel, Link, Menu, MenuItem, Select, Stack, Typography, useMediaQuery } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useHistory } from 'react-router-dom';
import { theme } from '../../shared/css/theme';
import { ROUTES } from '../../routes/routes-model';
import { ListViewConatiner } from '../../shared/components/Containers';
import { Pagination } from '../../shared/components/Pagination';
import { SearchInput } from '../../shared/components/SearchInput';
import { Column, Table } from '../../shared/components/table/Table';
import { AdminsListQuery, useAdminsListQuery, useAdminUpdateMutation } from '../../shared/types/generated';
import { AdminListFilters } from './admin-types';
import { useQuery } from '../../shared/utils/route';
import { DEFAULT_FILTERS, archiveAdminVariables, getAdminFilters } from './admin-utils';
import { TableTooltip } from '../../shared/components/TableTooltip';
import { AdminFilters } from './components/AdminFilters';
import { RowResponsive } from '../../shared/components/RowResponsive';
import { snackbar } from '../../shared/components/Snackbar';
import { Pill } from '../../shared/components/Pills';
import { ResetPassword } from './components/ResetPassword';
import { USER_ROLES } from '../session/session-model';

export const AdminListView: React.FC = () => {
  const history = useHistory();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [ancla, setAncla] = useState<null | HTMLElement>(null);
  const [archived, setArchived] = useState<boolean>(false);
  const [filters, setFilters] = useState<AdminListFilters>(DEFAULT_FILTERS);
  const [selected, setSelected] = useState<string>('');
  const [selectedEmail, setSelectedEmail] = useState<string>('');
  const [open, setOpen] = useState<{
    menu: boolean;
    modal: boolean;
    reset: boolean;
  }>({ menu: false, modal: false, reset: false });
  const { query, searchQuery } = useQuery<AdminListFilters>();

  const { data, loading, refetch } = useAdminsListQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      first: getAdminFilters(filters).first,
      skip: getAdminFilters(filters).skip,
      filter: getAdminFilters(filters).AdminFilter,
      sort: getAdminFilters(filters).sort,
    },
  });

  const superAdminList = useMemo(() =>{ 
    const users = data?.usersList.items.filter((user) => user?.roles?.items.find((role) => role.name === USER_ROLES.SUPER_ADMIN)?.name);
    return users?.map((item) => item.id);
  }, [data]);

  const [adminUpdateMutation, { loading: loadigMutation }] = useAdminUpdateMutation(
    {
      onCompleted: () => {
        setOpen((state) => ({ ...state, modal: false, menu: false }));
        snackbar.success(archived ? 'Admin Activated' : 'Admin Archived');
        refetch();
      },
    },
  );
  const relocate = (id: string): void => {
    history.push(`/admin/edit/${id}`);
  };
  const search = (value?: string): void => {
    searchQuery({
      page: 1,
      search: value,
    });
  };
  const onChangePage = (value: number): void => {
    searchQuery({
      page: value,
    });
  };

  const onChangePageSize = (value: number): void => {
    searchQuery({
      page: 1,
      rows: value,
    });
  };
  const handleMoreClose = (): void => {
    setOpen((state) => ({ ...state, modal: false, menu: false }));
  };
  const handleMore = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
    email: string,
    con: boolean,
  ): void => {
    setSelected(id);
    setSelectedEmail(email);
    setAncla(event.currentTarget);
    setArchived(con);
    setOpen((state) => ({ ...state, modal: false, menu: true }));
  };
  const COLUMNS = useMemo<Column<AdminsListQuery['usersList']['items'][0]>[]>(
    () => [
      {
        name: 'Admin',
        key: 'admin',
        render: (item) => (
          <Link href={`admin/${item.id}`} color="inherit" underline="none" fontWeight='normal'>
            {item.firstName ? item.firstName : item.email} {item.firstName &&item.lastName && item.lastName}
          </Link>
        ),
      },
      {
        name: 'Email',
        key: 'email',
        render: (item) => (
          <TableTooltip
            title={item.email}
          >
            <Link
              href={`admin/${item.id}`}
              color="inherit"
              underline="none"
            >
              {item.email}
            </Link>
          </TableTooltip>
        ),
      },
      {
        name: 'Admin Role',
        key: 'adminRole',
        render: (item) => (
          <Link href={`admin/${item.id}`} color="inherit" underline="none" fontWeight='normal'>
            {item?.roles?.items?.find((role) => role?.name === USER_ROLES.SUPER_ADMIN)?.name || item?.roles?.items[0]?.name || ''}
          </Link>
        ),
      },
      {
        name: 'Status',
        key: 'status',
        render: (item) => (
          <Pill
            text={item.archived ? 'Archived' : 'Active'}
            type={item.archived ? 2 : 0}
          />
        ),
      },
      {
        name: 'Phone Number',
        key: 'phone',
        render: (item) => item.phone || '-',
      },
      {
        name: 'Action',
        key: 'action',
        action: (item) => (
            <IconButton
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                let con = false;
                if (item.archived !== null) {
                  con = true;
                }
                handleMore(
                  event,
                  typeof item.id === 'string' ? item.id : '',
                  typeof item.email === 'string' ? item.email : '',
                  con,
                );
              }}
            >
              <MoreHoriz />
            </IconButton>
          ),
        },
    ],
    [],
  );

  useEffect(() => {
    if (query) {
      setFilters((state) => ({
        ...query,
        dbStatus: query.dbStatus ? query.dbStatus : state.dbStatus,
        rows: query.rows ? Number(query.rows) : state.rows,
        page: query.page ? Number(query.page) : state.page,
      }));
    } else {
      setFilters(DEFAULT_FILTERS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <>
      {matches ? (
        <>
          {matchesSm && (
            <Fab
              color="primary"
              aria-label="Create Job"
              href={ROUTES.ADMINS.internalPaths.ADMIN_CREATE.path}
              sx={{
                position: 'fixed',
                right: '12px',
                bottom: '70px',
                zIndex: '3',
              }}
            >
              <Add />
            </Fab>
          )}

          <ListViewConatiner>
            <Box
              sx={{ display: ['none', 'flex'] }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4">Admins Management</Typography>
                <Button variant="contained" size="large">
                  <Add /> Create Admin
                </Button>
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              sx={{ alignItems: 'center' }}
            >
              <Typography variant="body2">
                {data?.usersList && data?.usersList?.items.length < filters.rows
                  ? Math.min(
                      filters.page * filters.rows -
                        (filters.rows - data?.usersList?.items.length),
                    )
                  : Math.min(filters.page * filters.rows)}{' '}
                of {data?.usersList.count ?? 0}
              </Typography>
              <Stack direction="row" spacing={2}>
                <IconButton
                  aria-label="filters"
                  component="button"
                  onClick={async () => {
                    await refetch();
                  }}
                >
                  <Refresh />
                </IconButton>
                <AdminFilters filters={filters} />
              </Stack>
            </Box>
            {data?.usersList?.items.map((item) => (
              <RowResponsive
                key={item.id}
                id={item.id}
                name={`${item.firstName ? item.firstName : item.email} ${item.firstName &&item.lastName && item.lastName}`}
                email={item?.email ?? ''}
                number={item.phone || '-'}
                status={item.archived ? 'Archived' : 'Active'}

                action={(event: React.MouseEvent<HTMLButtonElement>) => {
                  let con = false;
                  if (item.archived !== null) {
                    con = true;
                  }
                  handleMore(
                    event,
                    typeof item.id === 'string' ? item.id : '',
                    typeof item.email === 'string' ? item.email : '',
                    con,
                  );
                }}
              />
            ))}
            <Pagination
              page={filters.page}
              pageSize={filters.rows}
              totalCount={data?.usersList?.count ?? 0}
              onChange={onChangePage}
            />
            <Menu open={open.menu} onClose={handleMoreClose} anchorEl={ancla}>
              <MenuItem
                onClick={() => {
                  relocate(selected);
                }}
              >
                Edit
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOpen((state) => ({ ...state, modal: true, menu: false }));
                }}
              >
                {archived === false ? 'Archive' : 'Active'}
              </MenuItem>
              <MenuItem
              onClick={() => setOpen({ ...open, menu: false, reset: true })}
            >
              Reset
            </MenuItem>
            </Menu>
          </ListViewConatiner>
        </>
      ) : (
        <ListViewConatiner>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">Admins Management</Typography>
              <Button
                variant="contained"
                size="large"
                href={ROUTES.ADMINS.internalPaths.ADMIN_CREATE.path}
              >
                <Add /> Create Admin
              </Button>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Stack direction="row" spacing={2}>
              <SearchInput onChangeText={search} />
              <FormControl
                sx={{
                  minWidth: 150,
                }}
              >
                <InputLabel>Rows</InputLabel>
                <Select
                  label="Rows"
                  value={filters.rows}
                  onChange={(e) => {
                    onChangePageSize(Number(e.target.value));
                  }}
                >
                  <MenuItem value={10}>10 Rows(s)</MenuItem>
                  <MenuItem value={20}>20 Rows(s)</MenuItem>
                  <MenuItem value={30}>30 Rows(s)</MenuItem>
                </Select>
              </FormControl>
              <IconButton
                aria-label="filters"
                component="button"
                onClick={async () => {
                  await refetch();
                }}
              >
                <Refresh />
              </IconButton>                
              <AdminFilters filters={filters} />
            </Stack>
          </Box>
          <Table
            loading={loading}
            columns={COLUMNS}
            data={data?.usersList.items}
            page={filters.page}
            pageSize={filters.rows}
            total={data?.usersList?.count ?? 0}
            onChangePage={onChangePage}
          />
          <Menu open={open.menu} onClose={handleMoreClose} anchorEl={ancla}>
            {archived === false && (
              <MenuItem
                onClick={() => {
                  relocate(selected);
                }}
              >
                Edit
              </MenuItem>
            )}
              {!superAdminList?.includes(selected) && (<MenuItem
                onClick={() => {
                  setOpen((state) => ({ ...state, modal: true, menu: false }));
                }}
              >
                {archived ? 'Active' : 'Archive'}
              </MenuItem>)}
              <MenuItem
              onClick={() => setOpen({ ...open, menu: false, reset: true })}
            >
              Reset
            </MenuItem>
          </Menu>
        </ListViewConatiner>
      )}
      <ResetPassword
        open={open.reset}
        close={() => setOpen({ ...open, reset: false })}
        email={selectedEmail}
      />
      <Dialog open={open.modal}>
        <DialogTitle id="warningTitle">Are you sure?</DialogTitle>
        <DialogContent>
          {archived
            ? 'This Admin will be activated.'
            : 'By archiving this administrator, they will lose access to the platform. Proceed with archiving?'}
        </DialogContent>
        <DialogActions>
          <Button
            variant="default"
            onClick={() => {
              setOpen((state) => ({ ...state, modal: false, menu: false }));
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant={archived ? 'contained' : 'delete'}
            loading={loadigMutation}
            onClick={() => {
              adminUpdateMutation({
                variables:  archiveAdminVariables(selected, !archived),
              });
            }}
          >
            {archived ? 'Active' : 'Archive'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
