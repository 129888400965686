/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { USER_ROLES } from '../modules/session/session-model';
import { IconNames } from '../shared/components/icons/Icon';

type InternalRoutes<T> = {
  [P in keyof T]: Route<T[P]>;
};
export interface Route<T> {
  icon?: IconNames;
  path: `/${string}`;
  text: string;
  internalPaths: InternalRoutes<T>;
  rolePermission?: (keyof typeof USER_ROLES)[];
}

export type Routes<T> = {
  [P in keyof T]: Route<T[P]>;
};

/**
 * @param input - Input objets.
 * @returns Keys.
 */
export function getKeys<T extends Record<any, any>>(input: T): (keyof T)[] {
  const keys = Object.keys(input) as (keyof T)[];
  return keys;
}
/**
 * @param parentPath - Path.
 * @param internalPaths - Paths.
 * @returns Paths.
 */
export function mapInternalPaths<T>(
  parentPath: `/${string}`,
  internalPaths: InternalRoutes<T>,
): InternalRoutes<T> {
  const mainKeys = getKeys(internalPaths);

  const result = mainKeys.reduce((prev, current) => {
    const route = { ...internalPaths[current] };

    const newPath = (parentPath + route.path) as `/${string}`;

    route.path = newPath;

    route.internalPaths = mapInternalPaths(newPath, route.internalPaths);

    return {
      ...prev,
      [current]: route,
    };
  }, internalPaths);

  return result;
}

/**
 * @param routes - Input routes.
 * @returns Output routes.
 */
export function createRoutes<T>(routes: Routes<T>): Routes<T> {
  return mapInternalPaths('' as `/${string}`, routes);
}

export const ROUTES = createRoutes({
  JOBS: {
    path: '/jobs',
    text: 'Jobs',
    icon: 'house',
    internalPaths: {
      JOB_CREATE: {
        icon: 'exit',
        path: '/create',
        text: 'Create Job',
        internalPaths: {},
      },
      JOB_DETAIL: {
        path: '/:id',
        text: 'Job Detail',
        internalPaths: {
          JOB_EDIT: {
            path: '/edit/:id',
            text: 'Edit Job',
            internalPaths: {},
          },
        },
      },
    },
  },
  CUSTOMERS: {
    icon: 'groups',
    path: '/customers',
    text: 'Customers',
    internalPaths: {},
    rolePermission: ['ADMIN'],
  },
  FIELDWORKERS: {
    icon: 'group',
    path: '/fieldworker',
    text: 'Fieldworkers',
    internalPaths: {},
    rolePermission: ['ADMIN'],
  },
  ADMINS: {
    icon: 'admin',
    path: '/admin',
    text: 'Admin',
    internalPaths: {
      ADMIN_CREATE: {
        icon: 'exit',
        path: '/create',
        text: 'Create Admin',
        internalPaths: {},
      },
      ADMIN_DETAIL: {
        path: '/:id',
        text: 'Admin detail',
        internalPaths: {
          ADMIN_EDIT: {
            path: '/edit/:id',
            text: 'Edit Admin',
            internalPaths: {},
          },
        },
      },
    },
    rolePermission: ['SUPER_ADMIN'],
  },
  CALENDAR: {
    icon: 'calendar',
    path: '/calendar',
    text: 'Calendar',
    internalPaths: {},
    rolePermission: ['ADMIN'],
  },
  MAP: {
    icon: 'map',
    path: '/map',
    text: 'Map',
    internalPaths: {},
    rolePermission: ['ADMIN'],
  },
});
