import { styled } from '@mui/material/styles';
import { theme as appTheme } from '../css/theme';

const StyledPill = styled('span')<{
  color: string;
  minWidth: number;
}>(({ color, theme, minWidth }) => ({
  background: color,
  color: '#fff',
  borderRadius: '10px',
  fontWeight: 'bold',
  padding: theme.spacing(1),
  fontsize: '12px',
  minWidth,
  display: 'inline-block',
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    minWidth,
  },
}));

const SmallStyledPill = styled('span')<{
  color: string;
  minWidth: number;
}>(({ color, theme, minWidth }) => ({
  background: color,
  color: '#fff',
  borderRadius: '10px',
  fontWeight: 'bold',
  padding: theme.spacing(1),
  fontSize: '10px',
  width: minWidth,
  display: 'inline-block',
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    minWidth,
  },
}));

const {
  text: textColor,
  secondary,
  variant,
  delete: deleteColor,
} = appTheme.palette;

export const colors = [
  secondary.main,
  '#9558E2',
  '#DD5476',
  variant.main,
  deleteColor.main,
  '#3A543B',
  '#FF4D00',
  '#2BA668',
  textColor.primary,
  '#3e67b0',
] as const;

type Indices<T extends readonly unknown[]> = Exclude<
  Partial<T>['length'],
  T['length']
>;

export type PillsTypeProp =
  | Indices<typeof colors>
  | Exclude<keyof typeof colors, keyof []>;

/**
 * @param root0 - Props.
 * @param root0.text - Children text.
 * @param root0.type - Type.
 * @param root0.minWidth - Min width.
 * @returns Component.
 */
export function Pill({
  text,
  type,
  minWidth = 110,
}: {
  text: string;
  type: PillsTypeProp;
  minWidth?: number;
}): JSX.Element {
  const color = colors[type];
  if (text === 'READY FOR RETURN') {
    return (
      <SmallStyledPill color={color} minWidth={minWidth}>
        {text}
      </SmallStyledPill>
    );
  }
  return (
    <StyledPill color={color} minWidth={minWidth}>
      {text}
    </StyledPill>
  );
}
