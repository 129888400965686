/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloClient, ApolloQueryResult } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { JOB_FORM_VALIDATOR_SCHEMA, JobFormType } from './jobs-types';
import {
  JobsListQueryVariables,
  JobsListQuery,
  useJobsListQuery,
  JobListSubscriptionDocument,
} from '../../shared/types/types';


/**
 * @returns Form hook state.
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useJobForm() {
  const data = useForm<JobFormType>({
    resolver: yupResolver(JOB_FORM_VALIDATOR_SCHEMA, {}),
  });
  return data;
}
/**
 * @returns {JobsListQuery} - Hook query customer.
 * @param {JobsListQueryVariables} variables - Variables.
 */
export function useJobsListSuscription(variables: JobsListQueryVariables): {
  loading: boolean;
  data: JobsListQuery | undefined;
  refetch: () => Promise<ApolloQueryResult<JobsListQuery>>;
  client: ApolloClient<unknown>;
} {
  const { loading, data, refetch, subscribeToMore, client } = useJobsListQuery({
    variables,
  });

  useEffect(() => {
    subscribeToMore({
      document: JobListSubscriptionDocument,
      variables: {
        filter: variables.filter,
      },
      /**
       * @param listData - Data.
       * @returns Data.
       */
      updateQuery: (listData) => {
        refetch();

        return listData;
      },
    });
  }, [refetch, subscribeToMore, variables.filter]);
  return { data, loading, refetch, client };
}
