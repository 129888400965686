import * as yup from 'yup';
import { phoneNumberSchemaValidation } from '../../shared/utils/phone';

export const JobsStatuses = {
  NEW: 'NEW',
  QUOTE: 'QUOTE',
  Q_SUBMITTED: 'Q_SUBMITTED',
  PENDING: 'PENDING',
  DISPATCHED: 'DISPATCHED',
  READY_FOR_RETURN: 'READY_FOR_RETURN',
  ON_ROUTE: 'ON_ROUTE',
  ON_SITE: 'ON_SITE',
  CLOSED: 'CLOSED',
  PAUSED: 'PAUSED'
} as const;

export const PAUSED_STATE = { PAUSED: 'PAUSED' } as const;
export type JobsStatusesType = typeof JobsStatuses & typeof PAUSED_STATE;

export type JobsListFilters = {
  search?: string;
  from?: string;
  to?: string;
  type?: string;
  fieldWorker?: string;
  status?: string;
  fromStart?: string;
  toStart?: string;
  page: number;
  dbStatus?: string;
  rows: number;
};

export type PartType = {
  name: string;
  id?: string;
  measure?: string | null | undefined;
  quantity?: string | undefined | number | null;
  unit?: string | null | undefined;
  partNumber?: string | null | undefined;
};
export type PartsType = PartType[];

export type PartUsedType = {
  name: string | undefined;
  id: string | undefined;
  measure: string | undefined;
  quantity: string | undefined;
  relationId: string | null | undefined;
};

export const JOB_STATUSES_OPTIONS = Object.keys(JobsStatuses);

export const JOB_FORM_VALIDATOR_SCHEMA = yup
  .object({
    name: yup.string(),
    address: yup.string().required('Address is required.'),
    endDate: yup.string(),
    startDate: yup.string(),
    description: yup
      .string()
      .test(
        'len',
        'Max. 5000 characters',
        (val) => val === undefined || val.length < 5001,
      ),
    adminNote: yup
      .string()
      .test(
        'len',
        'Max. 5000 characters',
        (val) => val === undefined || val.length < 5001,
      ),
    type: yup.string(),
    status: yup.string(),
    fieldworker: yup
      .array(yup.string().required('fieldworker must be an string'))
      .required()
      .default([]),
    notification: yup.boolean().default(true),
    sendWorkOrder: yup.boolean().default(true),
    fieldworkerNotes: yup
      .string()
      .test(
        'len',
        'Max. 4000 characters',
        (val) => val === undefined || val.length < 4001,
      ),
    officeNotes: yup
      .string()
      .test(
        'len',
        'Max. 5000 characters',
        (val) => val === undefined || val.length < 5001,
      ),
    unitServiced: yup
      .string()
      .test(
        'len',
        'Max. 100 characters',
        (val) => val === undefined || val.length < 101,
      ),
    model: yup
      .string()
      .test(
        'len',
        'Max. 100 characters',
        (val) => val === undefined || val.length < 101,
      ),
    serial: yup
      .string()
      .test(
        'len',
        'Max. 100 characters',
        (val) => val === undefined || val.length < 101,
      ),
    priority: yup.string(),
    workPerformed: yup
      .string()
      .test(
        'len',
        'Max. 4000 characters',
        (val) => val === undefined || val.length < 4001,
      ),
    purchaseOrder: yup
      .string()
      .test(
        'len',
        'Max. 100 characters',
        (val) => val === undefined || val.length < 101,
      ),
    workOrder: yup
      .string()
      .test(
        'len',
        'Max. 100 characters',
        (val) => val === undefined || val.length < 101,
      ),
    callID: yup
      .string()
      .test(
        'len',
        'Max. 100 characters',
        (val) => val === undefined || val.length < 101,
      ),
    parts: yup.array().of(
      yup.object().shape({
        name: yup.string().required('Part name is required'),
        measure: yup.string().optional().nullable(),
        quantity: yup.string().optional().nullable(),
        unit: yup.string().optional().nullable(),
        partNumber: yup.string().optional().nullable(),
      }),
    ),
    partsUsed: yup.array().of(
      yup.object({
        name: yup.string(),
        quantity: yup.string(),
        id: yup.string().optional(),
        relationId: yup.string().nullable(),
        measure: yup.string(),
      }),
    ),
    fieldworkerDocs: yup.array().of(
      yup.object({
        filename: yup.string(),
        handle: yup.string(),
        uploadId: yup.string(),
        url: yup.string(),
      }),
    ),
    signature: yup.string().nullable(),
    signatureName: yup.string().when('signature', {
      /**
       * @param value - SignatureName value.
       * @returns {boolean} - True if signature is defined.
       */
      is: (value: string) => value && value.length > 0,
      then: yup
        .string()
        .required('Signature name is required.')
        .test(
          'len',
          'Max. 100 characters',
          (val) => val === null || val === undefined || val.length < 101,
        ),
      otherwise: yup
        .string()
        .test(
          'len',
          'Max. 100 characters',
          (val) => val === null || val === undefined || val.length < 101,
        ),
    }),
    signatureTitle: yup.string().when('signature', {
      /**
       * @param value - SignatureTitle value.
       * @returns {boolean} - True if signature is defined.
       */
      is: (value: string) => value && value.length > 0,
      then: yup
        .string()
        .required('Signature title is required.')
        .test(
          'len',
          'Max. 100 characters',
          (val) => val === null || val === undefined || val.length < 101,
        ),
      otherwise: yup
        .string()
        .test(
          'len',
          'Max. 100 characters',
          (val) => val === null || val === undefined || val.length < 101,
        ),
    }),
    callerCell: yup
      .string()
      .test(
        'phoneNumberValidation',
        'Invalid phone number',
        (value) => phoneNumberSchemaValidation.isValid(value),
      ),
    callerName: yup
      .string()
      .test(
        'len',
        'Max. 30 characters',
        (val) => val === null || val === undefined || val.length < 31,
      ),
    dispatchedBy: yup
      .string()
      .test(
        'len',
        'Max. 30 characters',
        (val) => val === null || val === undefined || val.length < 31,
      ),
  })
  .required();

export type JobFormType = typeof JOB_FORM_VALIDATOR_SCHEMA['__outputType'];
