import { Add, Refresh, MoreHoriz } from '@mui/icons-material';
import {
  InputLabel,
  Stack,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Button,
  Box,
  Typography,
  Menu,
  Link,
  useMediaQuery,
  Fab,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  styled,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

import { JobsListFilters } from './jobs-types';
import { createJobListfilter, archiveJobVariables } from './jobs-utils';
import { ROUTES } from '../../routes/routes-model';
import { useUserHasRole } from '../session/session-hooks';
import { ListViewConatiner } from '../../shared/components/Containers';
import { Pagination } from '../../shared/components/Pagination';
import { JobStatusPill } from './components/StatusPills';
import { RowResponsive } from '../../shared/components/RowResponsive';
import { snackbar } from '../../shared/components/Snackbar';
import { humanizeDate, clearQuerysFromCache } from '../../shared/utils';
import { useJobsListSuscription } from './jobs-hooks';
import { SortOrder, useJobUpdateMutation } from '../../shared/types/types';
import { Table, Column } from '../../shared/components/table/Table';
import { useQuery } from '../../shared/utils/route';
import { JobsListQuery } from '../../shared/types/generated';
import { DB_STATUS } from '../../shared/constants/common';
import { SearchInput } from '../../shared/components/SearchInput';
import { JobFilter } from './components/JobFilters';
import { TableTooltip } from '../../shared/components/TableTooltip';
import { JobFieldWorkerTableCellList } from './components/JobFieldWorkerTableCellList';

const BREAK_POINTS = 'xl';
const LG_BREAK_POINT = 'lg';

const CustomerLink = styled(Link)(({ theme }) => ({
  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 'normal',
  width: 250,
  [theme.breakpoints.down(BREAK_POINTS)]: {
    width: 150,
  },
  [theme.breakpoints.down(LG_BREAK_POINT)]: {
    width: 100,
  },
}));

const StartDateText = styled('span')(({ theme }) => ({
  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: 160,
  [theme.breakpoints.down(BREAK_POINTS)]: {
    width: 160,
  },
}));

const TypeText = styled('span')(({ theme }) => ({
  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [theme.breakpoints.down(BREAK_POINTS)]: {
    width: 150,
  },
  [theme.breakpoints.down(LG_BREAK_POINT)]: {
    width: 100,
  },
}));

const DEFAULT_FILTERS: JobsListFilters = {
  search: '',
  from: '',
  to: '',
  status: '',
  type: '',
  fromStart: '',
  fieldWorker: '',
  dbStatus: DB_STATUS.Active,
  toStart: '',
  page: 1,
  rows: 10,
};

export function JobsListView(): JSX.Element {
  const [ancla, setAncla] = useState<null | HTMLElement>(null);
  const [openModalWarning, setOpenModalWarning] = useState<boolean>(false);
  const [archived, setArchived] = useState<boolean>(false);
  const theme = useTheme();
  const { query, searchQuery } = useQuery<JobsListFilters>();
  const IS_CUSTOMER = useUserHasRole('CUSTOMER');
  const IS_FIELDWORKER = useUserHasRole('FIELDWORKER');
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>('');
  const history = useHistory();
  const [filters, setFilters] = useState<JobsListFilters>(DEFAULT_FILTERS);
  const { data, loading, refetch, client } = useJobsListSuscription({
    first: filters.rows,
    skip: (filters.page - 1) * filters.rows,
    filter: createJobListfilter(filters, IS_FIELDWORKER),
    sort: [
      {
        createdAt: SortOrder.Desc,
      },
    ],
  });
  const [jobUpdateMutation, { loading: loadigMutation }] = useJobUpdateMutation(
    {
      onCompleted: () => {
        setOpenModalWarning(false);
        snackbar.success(archived ? 'Job Activated' : 'Job Archived');
      },
      update: clearQuerysFromCache([
        'jobsList',
        'job',
        'fieldworker',
        'fieldworkersList',
      ]),
    },
  );
  const handleMore = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
    con: boolean,
  ): void => {
    setSelected(id);
    setAncla(event.currentTarget);
    setArchived(con);
    setOpen(true);
  };
  const COLUMNS = useMemo<Column<JobsListQuery['jobsList']['items'][0]>[]>(
    () => [
      {
        name: 'Code',
        key: 'code',
        render: (item) => (
          <Link href={`jobs/${item.id}`} color="inherit" underline="none">
            {item.code}
          </Link>
        ),
      },
      {
        name: 'Customer Name',
        key: 'address',
        render: (item) => (
          <TableTooltip
            title={item.address.customer.name}
          >
            <CustomerLink
              // href={`/customers/details?id=${item.address.customer.id}`}
              href={`jobs/${item.id}`}
              color="inherit"
              underline="none"
            >
              {item.address.customer.name}
            </CustomerLink>
          </TableTooltip>
        ),
      },
      {
        name: 'Start Date',
        key: 'archived',
        render: (item) => (
          <TableTooltip
            title={humanizeDate(item?.startDateTime)}
          >
            <StartDateText>{humanizeDate(item?.startDateTime)}</StartDateText>
          </TableTooltip>
        ),
      },
      {
        name: 'Status',
        key: 'status',
        render: (item) => <JobStatusPill status={item.status} />,
      },
      {
        name: 'Fieldworker',
        key: 'fieldworker',
        render: (item) => <JobFieldWorkerTableCellList data={item} />,
      },
      {
        name: 'Job Type',
        key: 'type',
        render: (item) => (
          <TableTooltip
            title={item?.type?.name}
          >
            <TypeText>{item?.type?.name}</TypeText>
          </TableTooltip>
        ),
      },
      {
        name: 'Action',
        key: 'action',
        action: (item) => {
          if (!IS_FIELDWORKER) {
            return (
              <IconButton
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  let con = false;
                  if (item.archived !== null) {
                    con = true;
                  }
                  handleMore(
                    event,
                    typeof item.id === 'string' ? item.id : '',
                    con,
                  );
                }}
              >
                <MoreHoriz />
              </IconButton>
            );
          }
          if (!item.archived) {
            return (
              <IconButton
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  let con = false;
                  if (item.archived !== null) {
                    con = true;
                  }
                  handleMore(
                    event,
                    typeof item.id === 'string' ? item.id : '',
                    con,
                  );
                }}
              >
                <MoreHoriz />
              </IconButton>
            );
          }

          return null;
        },
      },
    ],
    [IS_FIELDWORKER],
  );

  const relocate = (id: string): void => {
    history.push(`/jobs/edit/${id}`);
  };

  const handleMoreClose = (): void => {
    setOpen(false);
  };

  const search = (value: string): void => {
    searchQuery({
      page: 1,
      search: value,
    });
  };

  const fieldworkersString = (arr: Array<string>): string => {
    let fieldworkers = '';
    arr.forEach((element) => {
      fieldworkers =
        fieldworkers !== '' ? `${fieldworkers}, ${element}` : element;
    });
    return fieldworkers;
  };

  const onChangePage = (value: number): void => {
    searchQuery({
      page: value,
    });
  };

  const onChangePageSize = (value: number): void => {
    searchQuery({
      page: 1,
      rows: value,
    });
  };

  useEffect(() => {
    if (query) {
      setFilters((state) => ({
        ...query,
        dbStatus: query.dbStatus ? query.dbStatus : state.dbStatus,
        rows: query.rows ? Number(query.rows) : state.rows,
        page: query.page ? Number(query.page) : state.page,
      }));
    } else {
      setFilters(DEFAULT_FILTERS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (IS_CUSTOMER) {
      history.push('/logout');
    }
  }, [history, IS_CUSTOMER]);

  return (
    <>
      {matches ? (
        <>
          {matchesSm && !IS_FIELDWORKER && (
            <Fab
              color="primary"
              aria-label="Create Job"
              href={ROUTES.JOBS.internalPaths.JOB_CREATE.path}
              sx={{
                position: 'fixed',
                right: '12px',
                bottom: '70px',
                zIndex: '3',
              }}
            >
              <Add />
            </Fab>
          )}

          <ListViewConatiner>
            <Box
              sx={{ display: ['none', 'flex'] }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4">Jobs Management</Typography>
              {!IS_FIELDWORKER && (
                <Button variant="contained" size="large">
                  <Add /> Create Job
                </Button>
              )}
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              sx={{ alignItems: 'center' }}
            >
              <Typography variant="body2">
                {data?.jobsList && data?.jobsList?.items.length < filters.rows
                  ? Math.min(
                      filters.page * filters.rows -
                        (filters.rows - data?.jobsList?.items.length),
                    )
                  : Math.min(filters.page * filters.rows)}{' '}
                of {data?.jobsList.count ?? 0}
              </Typography>
              <Stack direction="row" spacing={2}>
                <IconButton
                  aria-label="filters"
                  component="button"
                  onClick={async () => {
                    clearQuerysFromCache([
                      'job',
                      'clockInOutsList',
                      'jobPartsList',
                    ])(client.cache);
                    await refetch();
                  }}
                >
                  <Refresh />
                </IconButton>
                <JobFilter filters={filters} />
              </Stack>
            </Box>

            {data?.jobsList?.items.map((item) => (
              <RowResponsive
                type="job"
                key={item.id}
                id={item.id}
                code={item.code}
                name={item.name || item?.address?.customer?.name}
                fieldworker={fieldworkersString(
                  item.jobJobFieldWorkerRelation.items.map(
                    (a) => a?.fieldworker?.fullName ?? '',
                  ),
                )}
                date={humanizeDate(item?.startDateTime)}
                email={item?.address?.customer?.name ?? ''}
                street={item?.address?.name || item?.address?.streetAddress}
                status={item.status}
                action={(event: React.MouseEvent<HTMLButtonElement>) => {
                  let con = false;
                  if (item.archived !== null) {
                    con = true;
                  }
                  handleMore(
                    event,
                    typeof item.id === 'string' ? item.id : '',
                    con,
                  );
                }}
              />
            ))}
            <Pagination
              page={filters.page}
              pageSize={filters.rows}
              totalCount={data?.jobsList.count ?? 0}
              onChange={onChangePage}
            />
            <Menu open={open} onClose={handleMoreClose} anchorEl={ancla}>
              <MenuItem
                onClick={() => {
                  relocate(selected);
                }}
              >
                Edit
              </MenuItem>
              {!IS_FIELDWORKER && (
                <MenuItem
                  onClick={() => {
                    setOpen(false);
                    setOpenModalWarning(true);
                  }}
                >
                  {archived === false ? 'Archive' : 'Active'}
                </MenuItem>
              )}
            </Menu>
          </ListViewConatiner>
        </>
      ) : (
        <ListViewConatiner>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">Jobs Management</Typography>
            {!IS_FIELDWORKER && (
              <Button
                variant="contained"
                size="large"
                href={ROUTES.JOBS.internalPaths.JOB_CREATE.path}
              >
                <Add /> Create Job
              </Button>
            )}
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Stack direction="row" spacing={2}>
              <SearchInput onChangeText={search} />
              <FormControl
                sx={{
                  minWidth: 150,
                }}
              >
                <InputLabel>Rows</InputLabel>
                <Select
                  label="Rows"
                  value={filters.rows}
                  onChange={(e) => {
                    onChangePageSize(Number(e.target.value));
                  }}
                >
                  <MenuItem value={10}>10 Rows(s)</MenuItem>
                  <MenuItem value={20}>20 Rows(s)</MenuItem>
                  <MenuItem value={30}>30 Rows(s)</MenuItem>
                </Select>
              </FormControl>
              <IconButton
                aria-label="filters"
                component="button"
                onClick={async () => {
                  clearQuerysFromCache([
                    'job',
                    'clockInOutsList',
                    'jobPartsList',
                  ])(client.cache);
                  await refetch();
                }}
              >
                <Refresh />
              </IconButton>
              <JobFilter filters={filters} />
            </Stack>
          </Box>
          <Table
            loading={loading}
            columns={COLUMNS}
            data={data?.jobsList.items}
            page={filters.page}
            pageSize={filters.rows}
            total={data?.jobsList.count ?? 0}
            onChangePage={onChangePage}
          />
          <Menu open={open} onClose={handleMoreClose} anchorEl={ancla}>
            {archived === false && (
              <MenuItem
                onClick={() => {
                  relocate(selected);
                }}
              >
                Edit
              </MenuItem>
            )}
            {!IS_FIELDWORKER && (
              <MenuItem
                onClick={() => {
                  setOpen(false);
                  setOpenModalWarning(true);
                }}
              >
                {archived ? 'Active' : 'Archive'}
              </MenuItem>
            )}
          </Menu>
        </ListViewConatiner>
      )}
      <Dialog open={openModalWarning}>
        <DialogTitle id="warningTitle">Are you sure?</DialogTitle>
        <DialogContent>
          {archived
            ? 'This Job will be activated.'
            : 'This job will be moved to the archived job list. Proceed with archiving?'}
        </DialogContent>
        <DialogActions>
          <Button
            variant="default"
            onClick={() => {
              setOpenModalWarning(false);
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant={archived ? 'contained' : 'delete'}
            loading={loadigMutation}
            onClick={() => {
              jobUpdateMutation({
                variables: archiveJobVariables(selected, !archived),
              });
            }}
          >
            {archived ? 'Active' : 'Archive'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
