/* eslint-disable jsdoc/require-jsdoc */
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { Calendar } from '../../calendar/CalendarView';

type CalendarModalProps = {
  open: boolean;
  onClose: () => void;
  date: Date | null;
};

export const CalendarModal: React.FC<CalendarModalProps> = ({
  open,
  onClose,
  date,
}) => {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth={false}
    >
      <Box
        sx={{
          width: '90vw',
          maxWidth: theme.spacing(180),
          [theme.breakpoints.down('sm')]: {
            width: '80vw',
            padding: theme.spacing(2),
          },
        }}
      >
        <Calendar initialDate={date} />
      </Box>
    </Dialog>
  );
};
