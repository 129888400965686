/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
import * as yup from 'yup';
import { phoneNumberSchemaValidation } from '../../shared/utils/phone';

export const CUSTOMER_FORM_VALIDATOR_SCHEMA = yup
  .object({
    name: yup.string().required('Customer Name is required.'),
    email: yup.string().email('Invalid email address.'),
    phoneNumber: yup
      .string()
      .test(
        'phoneNumberValidation',
        'Invalid phone number',
        (value) => phoneNumberSchemaValidation.isValid(value),
      ),
    willLogin: yup.bool(),
    customerCustomerEmailRelation: yup.array().of(
      yup.object().shape({
        email: yup.string().email(),
      }),
    ),
    customerPhoneNumberRelation: yup.array().of(
      yup.object().shape({
        phoneNumber: yup
          .string()
          .test(
            'phoneNumberValidation',
            'Invalid phone number',
            (value) => phoneNumberSchemaValidation.isValid(value),
          ),
      }),
    ),
    customerAddressRelation: yup.array().of(
      yup.object({
        contactName: yup.string(),
        contactPhone: yup
          .string()
          .test(
            'phoneNumberValidation',
            'Invalid phone number',
            (value) => phoneNumberSchemaValidation.isValid(value),
          ),
        city: yup.string(),
        zipCode: yup.string(),
        state: yup.string(),
        name: yup.string(),
        streetAddress: yup.string().required('Street Address is required'),
      }),
      /*        .test('unique-address-name', function ({ name }) {
          const { parent, createError, path } = this;
          const addressNamesMatches = parent.filter(
            (address: Address) => address.name === name,
          ).length;

          return (
            addressNamesMatches === 1 ||
            createError({
              path: `${path}.name`,
              message: 'Name must be unique for same customer',
            })
          );
        }), */
    ),
  })
  .required();
export type CustomerFormType =
  typeof CUSTOMER_FORM_VALIDATOR_SCHEMA['__outputType'];

export type CreateCustomerFormType = {
  name: string;
  email: string;
  phoneNumber: string;
  customerAddressRelation: Array<Address>;
};

export type EditCustomerFormType = {
  name: string;
  email: string;
  phoneNumber: string;
  willLogin: boolean;
  customerCustomerEmailRelation: EmailType[];
  customerPhoneNumberRelation: PhoneType[];
  customerAddressRelation: Array<AddressEdit>;
};

export type Address = {
  city: string;
  zipCode: string;
  state: string;
  name: string;
  streetAddress: string;
  contactName: string;
  contactPhone: string;
};

export type AddressEdit = {
  contactName: string;
  contactPhone: string;
  id: string;
  city: string;
  zipCode: string;
  state: string;
  name: string;
  streetAddress: string;
};

export type CustomerItemType = {
  __typename?: 'Customer' | undefined;
  name: string;
  email: string;
  id: string;
  archived: string;
  createdAt: string;
};

export type InvalidFieldType = {
  name: boolean;
  phoneNumber: boolean;
  addresses: { name: boolean; street: boolean; nameReplicated: boolean }[];
};

export type EmailType = {
  email: string;
  id?: string;
};

export type PhoneType = {
  phoneNumber: string;
  id?: string;
};

export type CustomerFiltersType = {
  from?: string;
  to?: string;
  status: string;
  search?: string;
  rows: number;
  page: number;
};
