/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { yupResolver } from '@hookform/resolvers/yup';
import { ApolloError } from '@apollo/client/errors';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import {
  useCustomersListQuery,
  CustomersListQuery,
  CustomersListQueryVariables,
  CustomerListSubscriptionDocument,
} from '../../shared/types/generated';

import {
  CUSTOMER_FORM_VALIDATOR_SCHEMA,
  // CustomerFormType,
} from './customer-types';

export type  UseCustomerFormType = {
  name: string;
  email: string;
  phoneNumber: string;
  willLogin: boolean;
  customerCustomerEmailRelation: {
    email: string;
  }[];
  customerPhoneNumberRelation: {
    phoneNumber: string;
  }[];
  customerAddressRelation: {
    contactName: string;
    contactPhone: string;
    city: string;
    zipCode: string;
    state: string;
    name: string;
    streetAddress: string;
  }[];
}

/**
 * @returns Form hook state.
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useCustomerForm() {
  // const data = useForm<CustomerFormType>({
  const data = useForm<UseCustomerFormType>({
    resolver: yupResolver(CUSTOMER_FORM_VALIDATOR_SCHEMA, {}),
    defaultValues: {
      name: '',
      email: '',
      phoneNumber: '',
      willLogin: false,
      customerPhoneNumberRelation: [],
      customerAddressRelation: [
        {
          contactName: '',
          contactPhone: '',
          city: '',
          zipCode: '',
          state: '',
          name: '',
          streetAddress: '',
        },
      ],
    },
  });
  // useSubcribeError(data, () => {
  //   snackbar.error('Validation error');
  // });
  return data;
}

/**
 * @returns {CustomersListQuery} - Hook query customer.
 * @param {CustomersListQueryVariables} variables - Variables.
 */
export function useCustomerListHook(variables: CustomersListQueryVariables): {
  loading: boolean;
  data: CustomersListQuery | undefined;
  error: ApolloError | undefined;
} {
  const { loading, data, error, refetch, subscribeToMore } =
    useCustomersListQuery({
      fetchPolicy: 'cache-and-network',
      variables,
    });

  useEffect(() => {
    subscribeToMore({
      document: CustomerListSubscriptionDocument,
      variables: {
        filter: variables.filter,
      },
      /**
       * @param listData - Data.
       * @returns Data.
       */
      updateQuery: (listData) => {
        refetch();

        return listData;
      },
    });
  }, [refetch, subscribeToMore, variables.filter]);

  return { loading, data, error };
}
