import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { createAction } from '@cobuildlab/react-simple-state/lib/actions';
import { useHistory, useLocation } from 'react-router-dom';
import { useSubscription } from '@cobuildlab/react-simple-state';
import {
  OnTokenErrorEvent,
  OnTokenEvent,
} from '../../shared/events/token-event';
// import { useFeatureFlagsListQuery } from '../../shared/types/generated';
import { OnFeatureFlagEvent } from './auth-events';

/**
 * @returns {void}
 */
export function useSetupAuth0Token(): {
  loading: boolean;
  isTokenReady: boolean;
} {
  const auth = useAuth0();
  const [loading, setLoading] = useState(false);
  const [isTokenReady, setIsTokenReady] = useState(false);

  useSubscription(OnTokenEvent, () => {
    setIsTokenReady(true);
    setLoading(false);
  });
  useEffect(() => {
    const tokenAction = createAction(
      OnTokenEvent,
      OnTokenErrorEvent,
      async () => {
        const token = await auth.getIdTokenClaims();
        // eslint-disable-next-line no-underscore-dangle
        return { token: token?.__raw as string };
      },
    );

    if (auth.isAuthenticated) {
      setLoading(true);
      tokenAction();
    }
  }, [auth, auth.getIdTokenClaims, auth.isAuthenticated]);

  // TODO: hanlde the error case when fetching the token
  return { loading, isTokenReady };
}

/**
 * @param {string} route - Default path to redirect.
 * @returns {void}
 */
export function useDefaultRedirect(route: string): void {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/' || location.pathname === '') {
      history.push(route);
    }
  }, [location, history, route]);

  // TODO: hanlde the error case when fetching the token
}

/**
 * @param featureFlagName - Name of the featureFlag - Not use this hook above session component.
 * @returns { void } - Return options or void.
 */
export function UseValidateFeatureFlag(featureFlagName: string): boolean {
  const featureFlag = OnFeatureFlagEvent.get();

  if (!featureFlag) {
    return false;
  }
  const objectFeatureFlag = featureFlag.find(
    (items) => items.flag === featureFlagName,
  );
  return Boolean(objectFeatureFlag?.active);
}
