import React from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ErrorIcon from '@mui/icons-material/Error';
import Tooltip from '@mui/material/Tooltip';


import { getNationalPhoneNumber } from '../utils/phone';

type DisplayPhoneInputProps = {
  phoneNumber: string | undefined;
  variant?:  TypographyProps['variant'];
  leftIcon?: React.ReactNode;
}

export const DisplayPhoneNumber: React.FC<DisplayPhoneInputProps> = ({ phoneNumber, variant, leftIcon }) => {
  const nationalPhoneNumer =  getNationalPhoneNumber(phoneNumber);
  return (
    <Stack py={1} direction="row" alignItems="center" spacing={1}>
      {!nationalPhoneNumer.isValid && phoneNumber && (
        <Tooltip title="Invalid phone number">
          <ErrorIcon fontSize="small" color="error" />
        </Tooltip>
      )}
      {nationalPhoneNumer.isValid && leftIcon && phoneNumber && (<>{leftIcon}</>)}
      <Typography color="textSecondary" variant={variant ?? 'body1'}>
        {nationalPhoneNumer.value}
      </Typography>
    </Stack>
  );
};