import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Divider,
  IconButton,
  Tooltip,
  useMediaQuery,
  Stack,
  useTheme,
  Box,
  Typography
} from '@mui/material';

// import AccountCircle from '@mui/icons-material/AccountCircle';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import { useHistory, useLocation } from 'react-router-dom';
import HelpOutlineSharpIcon from '@mui/icons-material/HelpOutlineSharp';
import { ROUTES } from '../../routes/routes-model';
import { useCacheBuster } from '../../cache-buster';
import { COMMINT_HASH } from '../constants';
import { MobileNavMenu } from './NavMenu/MobileNavMenu';
import { useQuery } from '../utils/route';
import { SearchInput, SearchInputRef } from './SearchInput';
import { User, useUserSessionQuery } from '../types/types';
import { NotificationPannel } from '../../modules/notifications/NotificationPannel';
import { FeatureFlagWrapper } from './FeatureFlagWrapper';
import { FeatureFlags } from '../constants/featureFlag';
import { USER_ROLES } from '../../modules/session/session-model';
import { UseValidateFeatureFlag } from '../../modules/auth/auth-hooks';

const SEARCH_TITLE: { [key: string]: string } = {
  Customers: 'Customer',
  Jobs: 'Job',
  Fieldworkers: 'Fieldworker',
};

/**
 * @returns Component.
 */
export const Topbar: React.FC = () => {
  const featureFlagUserInfo = UseValidateFeatureFlag(FeatureFlags.USER_DISPLAYED_INFO);
  const currentSession = useUserSessionQuery();
  const searchRef = useRef<SearchInputRef>(null);
  const { searchQuery, query } = useQuery<{ search?: string; page: number }>();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState<boolean>(false);
  const { loading, reload, newVersionAvailable } = useCacheBuster(COMMINT_HASH);
  const searchAllowRender = useMemo(() => {
    const path = location?.pathname?.split('/')?.filter((item) => item.length);
    if(!path || path?.length > 1){
      return false;
    }
    return path[0]?.toLowerCase()?.includes('jobs', 0) || path[0]?.toLowerCase()?.includes('customers', 0) || path[0]?.toLowerCase()?.includes('fieldworker', 0);
  }, [location]);
  const sectionTitle = useMemo(() => {
    const title = Object.values(ROUTES).find(
      (i) => i.path.slice(0, 3) === location?.pathname?.slice(0, 3),
    );
    if (title) {
      return title?.text;
    }
    return '404';
  }, [location]);
  

  const search = (value: string): void => {
    searchQuery({
      page: 1,
      search: value,
    });
  };

  useEffect(() => {
    if(!query?.search?.length){
      searchRef?.current?.clearInput();
    }
  }, [query]);

  const userInfo = useMemo(() => {
    if (!featureFlagUserInfo) return null;
    const user = currentSession?.data?.user;
    const userRoles = currentSession.data?.user?.roles?.items;
    let name: string;
    let role: string | undefined;
    if (user?.fielworker) {
      name = user.fielworker.fullName;
    } else if (user?.firstName) {
      name = `${user?.firstName} ${user?.lastName}`;
    } else {
      name = user?.email || '';
    }

    if (userRoles?.length === 1) {
      role = userRoles[0].name;
    } else if (userRoles?.find((item) => item.name === USER_ROLES.SUPER_ADMIN)){
      role = userRoles?.find((item) => item.name === USER_ROLES.SUPER_ADMIN)?.name;
    } else {
      role = userRoles?.find((item) => item.name === USER_ROLES.ADMIN)?.name; 
    }
    

    return {
      userName: name,
      userRole: role,
    };
  }, [currentSession, featureFlagUserInfo]);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          gridArea: 'topbar',
          position: 'initial',
          background: '#fff',
          boxShadow: 'none',
          display: 'flex',
          justifyContent: ['space-between', 'flex-end'],
          flexDirection: 'row',
          padding: '10px 0',
          color: 'icons.main',
        }}
      >
        <Toolbar
          sx={{
            display: ['none', 'flex'],
            maxHeight: '100%',
            background: '#fff',
            minHeight: {
              xs: 'auto', // theme.breakpoints.up('xs')
            },
            '&>*': {
              margin: '0 5px',
            },
          }}
        >
          {!matchesSm && newVersionAvailable && (
            <>
              <Tooltip
                title="New Version available, click to update"
                arrow
                open={newVersionAvailable}
              >
                <IconButton
                  onClick={reload}
                  disabled={!newVersionAvailable}
                  color={
                    loading || !newVersionAvailable ? 'default' : 'primary'
                  }
                >
                  <HelpOutlineSharpIcon />
                </IconButton>
              </Tooltip>
              <Divider orientation="vertical" />
            </>
          )}
          {featureFlagUserInfo && !matchesSm && currentSession?.data?.user && <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'end'
          }}>
            <Typography variant='subtitle1' color='rgba(0,0,0,1)'>
              {userInfo?.userName}
            </Typography>
            {userInfo?.userRole && <Typography variant='subtitle2' >
              {userInfo.userRole}
            </Typography>}
          </Box>}
          <FeatureFlagWrapper flag={FeatureFlags.NOTIFICATIONS}>
            {!matchesSm && <NotificationPannel />}
          </FeatureFlagWrapper>
          <Divider orientation="vertical" />
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => history.push('/logout')}
            color="inherit"
          >
            <ExitToAppOutlinedIcon />
          </IconButton>
        </Toolbar>
        <Toolbar
          sx={{
            maxHeight: '100%',
            width: '100%',
            background: '#fff',
            display: ['flex', 'none'],
            minHeight: {
              xs: 'auto', // theme.breakpoints.up('xs')
            },
          }}
        >
          <Stack flex="1" direction="row" justifyContent="space-between" alignItems="center">
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={() => {
                setOpen(true);
              }}
            >
              <MenuIcon />
            </IconButton>
            {searchAllowRender && (
              <SearchInput 
                ref={searchRef}
                onChangeText={search} 
                label={`Search ${SEARCH_TITLE[sectionTitle]}`}
                sx={{ 
                  maxWidth: '70%', 
                  [theme.breakpoints.up('sm')]: { 
                    display: 'none' 
                  }
                }}
              />
            )}
            {matchesSm && newVersionAvailable && (
              <Tooltip
                title="New Version available, click to update"
                arrow
                open={newVersionAvailable}
              >
                <IconButton
                  onClick={reload}
                  disabled={!newVersionAvailable}
                  color={
                    loading || !newVersionAvailable ? 'default' : 'primary'
                  }
                >
                  <HelpOutlineSharpIcon />
                </IconButton>
              </Tooltip>
            )}
          <FeatureFlagWrapper flag={FeatureFlags.NOTIFICATIONS}>
            {matchesSm && <NotificationPannel />}
          </FeatureFlagWrapper>            
          </Stack>
        </Toolbar>
      </AppBar>
      {featureFlagUserInfo ? <MobileNavMenu open={open} close={() => setOpen(false)} currentUser={currentSession?.data?.user as User} /> : <MobileNavMenu open={open} close={() => setOpen(false)} />}
    </>
  );
};
