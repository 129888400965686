import React, { useCallback, useEffect } from 'react';
import {
  FormControl,
  Button,
  DialogContent,
  Box,
  DialogTitle,
  Dialog,
  TextField,
  Modal,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Grid,
  CardActionArea,
  CardContent,
  Card,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  DialogActions,
  Paper,
  Link,
  OutlinedInput,
  InputAdornment,
  ListItem,
  IconButton,
  ListItemText,
  ListItemButton,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ClearIcon from '@mui/icons-material/Clear';
import { Search } from '@mui/icons-material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PartType } from '../../modules/jobs/jobs-types';
import {
  PartUsedFilter,
  PartUsedListQuery,
  usePartUsedListQuery,
  useTopPartsUsedListQuery,
} from '../types/generated';
import { Pill } from './Pills';
import { theme } from '../css/theme';

const MEASURING = [
  'lb',
  'oz',
  'gal',
  'qt',
  'in',
  'ft',
  'yd',
  'set',
  'pcs',
  'unit',
  'can',
] as const;
const QUANTITY = Array.from(Array(50).keys()).map((item) => item + 1);

/**
 * @param root0 - Props.
 * @param root0.open - Close or Open Modal.
 * @param root0.onClose - Close Modal.
 * @param root0.actionAdd - Sudmite action.
 * @param root0.title - Sudmite action.
 * @param root0.label - Sudmite action.
 * @param root0.errorMessage - Sudmite action.
 * @param root0.extra - Boolean validator.
 * @returns {} Component.
 */
export function PartsInputPortal({
  actionAdd,
  onClose,
  open,
  title,
  label,
  errorMessage,
  extra,
}: {
  actionAdd: (item: PartType) => void;
  onClose: () => void;
  open: boolean;
  title: string;
  label: string;
  errorMessage: string;
  extra?: boolean;
}): JSX.Element {
  const [partsFormValues, setPartFormValues] = React.useState<PartType>({
    name: '',
    unit: '',
    partNumber: '',
  });
  const validateDisabled = useCallback(() => {
    const nameValidate = Boolean(
      partsFormValues.name.length > 100 || partsFormValues.name.length === 0,
    );
    if (nameValidate) {
      return true;
    }
    return false;
  }, [partsFormValues]);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <FormControl variant="outlined" fullWidth sx={{ marginTop: '20px' }}>
          <TextField
            label={label}
            onChange={(input) => {
              setPartFormValues({
                ...partsFormValues,
                name: input.target.value,
              });
            }}
            error={Boolean(
              partsFormValues.name.length > 100 &&
                partsFormValues.name.length > 0,
            )}
            helperText={
              Boolean(
                partsFormValues.name.length > 100 &&
                  partsFormValues.name.length > 0,
              ) && errorMessage
            }
          />
        </FormControl>
        {extra ? (
          <>
            <FormControl
              variant="outlined"
              fullWidth
              sx={{ marginTop: '20px' }}
            >
              <TextField
                label="Part Number"
                onChange={(input) => {
                  setPartFormValues({
                    ...partsFormValues,
                    partNumber: input.target.value,
                  });
                }}
              />
            </FormControl>
            <FormControl
              variant="outlined"
              fullWidth
              sx={{ marginTop: '20px' }}
            >
              <TextField
                label="Unit"
                onChange={(input) => {
                  setPartFormValues({
                    ...partsFormValues,
                    unit: input.target.value,
                  });
                }}
              />
            </FormControl>
          </>
        ) : null}
        <Box
          display="flex"
          justifyContent="flex-end"
          sx={{ width: '100%', marginTop: '1rem' }}
        >
          <Button
            variant="default"
            onClick={() => {
              setPartFormValues({
                name: '',
                unit: '',
                partNumber: '',
              });
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={validateDisabled()}
            onClick={() => {
              actionAdd(partsFormValues);
              setPartFormValues({
                name: '',
                unit: '',
                partNumber: '',
              });
            }}
            type="submit"
            color="primary"
          >
            Add
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

/**
 * @name PartEditModal
 * @description Part of the application that shows the parts.
 * @returns {JSX.Element} Componente React.
 * @param root0 - Props.
 * @param root0.part - Part.
 * @param root0.onAccepted - Add part.
 * @param root0.onCancel - Add part.
 * @param root0.onClose - Add part.
 * @param root0.open - Add part.
 * @param root0.handleChange - Add part.
 */
const PartEditModal = ({
  part,
  open,
  onClose,
  onAccepted,
  onCancel,
  handleChange,
}: {
  part: PartType;
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
  onAccepted: () => void;
  handleChange: (event: SelectChangeEvent) => void;
}): JSX.Element => (
  <Modal
    open={open}
    onClose={onClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: '10px',
        boxShadow: 24,
        p: 3,
      }}
    >
      <Typography variant="h6" component="h2" textAlign="center" mb={2}>
        {part.name}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel>Quantity</InputLabel>
            <Select
              value={String(part.quantity) ?? ''}
              label="Quantity"
              name="quantity"
              onChange={handleChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              {QUANTITY.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth>
            <InputLabel>Measuring</InputLabel>
            <Select
              value={
                part.measure?.length === 0 ? 'Select Value' : part.measure || ''
              }
              label="Measuring"
              name="measure"
              onChange={handleChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              <MenuItem disabled value="Select Value">
                Select Value
              </MenuItem>
              {MEASURING.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box display="flex" flexDirection="column" rowGap={1} mt={2}>
        <Button
          fullWidth
          disabled={Boolean(part?.measure?.length === 0 || !part.measure)}
          onClick={onAccepted}
        >
          Accept
        </Button>
        <Button fullWidth variant="default" onClick={onCancel}>
          Cancel
        </Button>
      </Box>
    </Box>
  </Modal>
);

/**
 * @name PartItem
 * @description Part of the application that shows the parts.
 * @returns {JSX.Element} Componente React.
 * @param root0 - Props.
 * @param root0.part - Part.
 * @param root0.addPart - Add part.
 * @param root0.matches - Add part.
 */
export const PartItem = ({
  part,
  addPart,
  matches,
}: {
  part: PartType;
  addPart: (item: PartType) => void;
  matches?: boolean;
}): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState(part);
  // write jsdoc of this function
  /**
   * @param event - Event.
   */
  const handleChange = (event: SelectChangeEvent): void => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };
  useEffect(() => {
    setData(part);
  }, [part]);
  return (
    <>
      {matches ? (
        <Card>
          <CardActionArea
            onClick={() => {
              if (data?.measure?.length === 0 || data.measure == null) {
                setOpen(true);
              } else {
                setData({ ...data, quantity: '1', measure: '' });
                addPart({ ...data, quantity: '1', measure: '' });
                setOpen(false);
              }
            }}
          >
            <CardContent
              sx={{
                padding: '10px',
              }}
            >
              <Grid
                container
                spacing={1}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid
                  item
                  xs={data.measure && data?.measure?.length > 1 ? 8 : 10}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(
                          data.measure && data?.measure?.length > 0,
                        )}
                        name={part.name}
                      />
                    }
                    label={part.name}
                  />
                </Grid>
                {data.measure && data.measure.length > 1 && (
                  <Grid item xs={4}>
                    <Box display="flex" justifyContent="center">
                      <Pill
                        minWidth={80}
                        text={`${data.quantity} ${data.measure}`}
                        type="0"
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      ) : (
        <TableRow
          hover
          onClick={() => {
            if (data?.measure?.length === 0 || data.measure == null) {
              setOpen(true);
            } else {
              setData({ ...data, quantity: '1', measure: '' });
              addPart({ ...data, quantity: '1', measure: '' });
              setOpen(false);
            }
          }}
        >
          <TableCell padding="checkbox">
            <Checkbox
              checked={Boolean(data.measure && data?.measure?.length > 0)}
            />
          </TableCell>
          <TableCell>
            <Typography>{part.name}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography>
              {data.measure && data.measure.length > 1 ? data.quantity : ''}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography>{data.measure}</Typography>
          </TableCell>
        </TableRow>
      )}
      <PartEditModal
        handleChange={handleChange}
        onAccepted={() => {
          addPart(data);
          setOpen(false);
        }}
        onCancel={() => {
          setData({ ...data, quantity: '1', measure: '' });
          setOpen(false);
        }}
        onClose={() => setOpen(false)}
        open={open}
        part={data}
      />
    </>
  );
};

/**
 * @name PartUsedItem
 * @description Part of the application that shows the parts.
 * @returns {JSX.Element} Componente React.
 * @param root0 - Props.
 * @param root0.part - Part.
 * @param root0.addPart - Add part.
 * @param root0.removePart - Add part.
 */
export const PartUsedItem = ({
  part,
  addPart,
  removePart,
}: {
  part: PartType;
  addPart: (item: PartType) => void;
  removePart: () => void;
}): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState(part);
  // write jsdoc of this function
  /**
   * @param event - Event.
   */
  const handleChange = (event: SelectChangeEvent): void => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <>
      <ListItem
        sx={{ border: '1px solid #e0e0e0', borderRadius: '5px' }}
        onClick={() => setOpen(true)}
        secondaryAction={
          <IconButton edge="end" onClick={removePart}>
            <ClearIcon color="error" />
          </IconButton>
        }
      >
        <ListItemButton
          sx={{
            flexDirection: ['column', 'row'],
            alignItems: ['flex-start', 'center'],
            padding: 0,
            paddingRight: '0 !important',
          }}
        >
          <ListItemText
            sx={{
              margin: '7px 0 7px 15px',
            }}
            primary={part.name}
          />
          <Pill
            text={`${part.quantity ?? ''} ${part.measure ?? ''}`}
            type="0"
          />
        </ListItemButton>
      </ListItem>
      <PartEditModal
        handleChange={handleChange}
        onAccepted={() => {
          addPart(data);
          setOpen(false);
        }}
        onCancel={() => {
          setData({ ...data, quantity: '1', measure: '' });
          setOpen(false);
        }}
        onClose={() => setOpen(false)}
        open={open}
        part={data}
      />
    </>
  );
};

/**
 * @param root0 - Props.
 * @param root0.open - Close or Open Modal.
 * @param root0.onClose - Close Modal.
 * @param root0.actionAdd - Sudmite action.
 * @param root0.title - Sudmite action.
 * @param root0.parts - Sudmite action.
 * @returns {} Component.
 */
export function PartsUsedInputPortal({
  actionAdd,
  onClose,
  parts,
  open,
  title,
}: {
  actionAdd: (items: PartType[]) => void;
  onClose: () => void;
  parts: PartType[];
  open: boolean;
  title: string;
}): JSX.Element {
  const [partsFormValues, setPartFormValues] = React.useState<{
    name: string;
    data: PartType[];
  }>({
    name: '',
    data: [],
  });
  const { data: topParts, loading } = useTopPartsUsedListQuery();
  const { data, fetchMore } = usePartUsedListQuery({
    variables: {
      first: 20,
      filter: {
        name: {
          contains: partsFormValues.name,
        },
        AND:
          !loading && partsFormValues.name.length === 0
            ? (topParts?.partUsedsList.items.map((item) => ({
                name: {
                  not_contains: item.name,
                },
              })) as PartUsedFilter[])
            : [],
      },
    },
  });
  useEffect(() => {
    setPartFormValues({
      ...partsFormValues,
      data: parts,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parts]);
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  /**
   * @param newPart - Part.
   * @returns {void} Add part.
   * @name addPart
   */
  const addPart = (newPart: PartType): void => {
    const index = partsFormValues.data.findIndex(
      (item) => item.name === newPart.name,
    );
    if (index !== -1) {
      if (newPart.measure === '') {
        const newData = [...partsFormValues.data];
        newData.splice(index, 1);
        setPartFormValues({
          ...partsFormValues,
          data: newData,
        });
        return;
      }

      const newData = [...partsFormValues.data];
      newData[index] = newPart;
      setPartFormValues({
        ...partsFormValues,
        name: '',
        data: newData,
      });
    } else {
      setPartFormValues({
        ...partsFormValues,
        name: '',
        data: [...partsFormValues.data, newPart],
      });
    }
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth
      fullScreen
      scroll="paper"
      PaperProps={{
        sx: {
          backgroundColor: '#f8f8fb',
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h4">{title}</Typography>
        <Link
          onClick={onClose}
          color="gray"
          underline="hover"
          variant="subtitle2"
        >
          <ArrowBackIosNewIcon />
          Back
        </Link>
      </DialogTitle>
      <Box px={3}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Search</InputLabel>
          <OutlinedInput
            endAdornment={
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            }
            label="Search"
            value={partsFormValues.name}
            onChange={(input) => {
              setPartFormValues({
                ...partsFormValues,
                name: input.target.value,
              });
            }}
            error={Boolean(
              partsFormValues.name.length > 100 &&
                partsFormValues.name.length > 0,
            )}
          />
        </FormControl>
      </Box>
      <DialogContent
        sx={{
          paddingBottom: '0 !important',
        }}
      >
        <Paper
          variant="card"
          sx={{
            height: '85%',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'hidden',
          }}
        >
          <InfiniteScroll
            dataLength={data?.partUsedsList?.items.length ?? 20}
            height="65vh"
            next={() =>
              fetchMore({
                variables: {
                  first: (data?.partUsedsList?.items?.length ?? 20) + 20,
                },
                // eslint-disable-next-line jsdoc/require-jsdoc
                updateQuery: (oldData, { fetchMoreResult }) => {
                  if (fetchMoreResult) {
                    const newData = {
                      partUsedsList: {
                        __typename: 'PartUsedListResponse',
                        count: fetchMoreResult.partUsedsList.count,
                        items: fetchMoreResult.partUsedsList.items,
                      },
                    };
                    return {
                      ...newData,
                      __typename: 'Query',
                    } as PartUsedListQuery;
                  }

                  return oldData;
                },
              })
            }
            hasMore
            loader={<></>}
          >
            {matches ? (
              <>
                {partsFormValues.name.length === 0 &&
                  topParts?.partUsedsList?.items &&
                  [
                    ...partsFormValues.data.filter((item) => item.id === ''),
                    ...topParts?.partUsedsList?.items,
                  ].map((part) => (
                    <PartItem
                      matches={matches}
                      part={
                        partsFormValues.data.find(
                          (item) => item.name === part.name,
                        ) || {
                          name: part.name,
                          quantity: '1',
                          measure: '',
                        }
                      }
                      key={part.id}
                      addPart={addPart}
                    />
                  ))}

                {data?.partUsedsList?.items.map((part) => (
                  <PartItem
                    matches={matches}
                    part={
                      partsFormValues.data.find(
                        (item) => item.name === part.name,
                      ) || {
                        name: part.name,
                        quantity: '1',
                        measure: '',
                      }
                    }
                    key={part.id}
                    addPart={addPart}
                  />
                ))}
                {partsFormValues.name && partsFormValues.name.length > 0 && (
                  <PartItem
                    matches={matches}
                    part={{
                      name: partsFormValues.name,
                      quantity: '1',
                      measure: '',
                      id: '',
                    }}
                    addPart={(newPart) => {
                      addPart(newPart);
                      /* setPartFormValues({
                        ...partsFormValues,
                        name: '',
                      }); */
                    }}
                  />
                )}
              </>
            ) : (
              <Table
                aria-label="simple table"
                sx={{
                  minHeight: 'auto',
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">#</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell align="center">Quantity</TableCell>
                    <TableCell align="center">Measuring</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ height: '80%' }}>
                  {partsFormValues.name.length === 0 &&
                    topParts?.partUsedsList?.items &&
                    [
                      ...partsFormValues.data.filter((item) => item.id === ''),
                      ...topParts?.partUsedsList?.items,
                    ].map((topPart) => (
                      <PartItem
                        matches={matches}
                        part={
                          partsFormValues.data.find(
                            (item) => item.name === topPart.name,
                          ) || {
                            name: topPart.name,
                            quantity: '1',
                            measure: '',
                          }
                        }
                        key={topPart.id}
                        addPart={addPart}
                      />
                    ))}
                  {data?.partUsedsList?.items &&
                    data?.partUsedsList?.items.map((part) => (
                      <PartItem
                        matches={matches}
                        part={
                          partsFormValues.data.find(
                            (item) => item.name === part.name,
                          ) || {
                            name: part.name,
                            quantity: '1',
                            measure: '',
                          }
                        }
                        key={part.id}
                        addPart={addPart}
                      />
                    ))}
                  {partsFormValues.name &&
                    partsFormValues.name.length > 0 &&
                    !partsFormValues.data.find(
                      (item) => item.name === partsFormValues.name,
                    ) && (
                      <PartItem
                        matches={matches}
                        part={
                          partsFormValues.data.find(
                            (item) => item.name === partsFormValues.name,
                          ) || {
                            name: partsFormValues.name,
                            quantity: '1',
                            measure: '',
                            id: '',
                          }
                        }
                        addPart={(newPart) => {
                          addPart(newPart);
                          /* setPartFormValues({
                          ...partsFormValues,
                          name: '',
                        }); */
                        }}
                      />
                    )}
                </TableBody>
              </Table>
            )}
          </InfiniteScroll>
        </Paper>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setPartFormValues({
                name: '',
                data: parts,
              });
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              actionAdd(partsFormValues.data);
              setPartFormValues({
                ...partsFormValues,
                name: '',
              });
            }}
            type="submit"
            color="primary"
          >
            Add
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
