export enum NotificationTypes {
  ADMIN_JOB_STATUS = 'ADMIN_JOB_STATUS',
  ADMIN_JOB_CREATE_BY_CUSTOMER = 'ADMIN_JOB_CREATE_BY_CUSTOMER',
  FIELDWORKER_JOB_ASSIGNED = 'FIELDWORKER_JOB_ASSIGNED',
  FIELDWORKER_JOB_UNASSIGNED = 'FIELDWORKER_JOB_UNASSIGNED',
  FIELDWORKER_JOB_START_DATE = 'FIELDWORKER_JOB_START_DATE',
  CUSTOMER_JOB_STATUS = 'CUSTOMER_JOB_STATUS',
  CUSTOMER_JOB_FW_DATE = 'CUSTOMER_JOB_FW_DATE',
  CUSTOMER_JOB_NEW_START_DATE = 'CUSTOMER_JOB_NEW_START_DATE',
  CUSTOMER_JOB_START_DATE_CHANGE = 'CUSTOMER_JOB_START_DATE_CHANGE',
}

export const adminNotifTypes = [
  NotificationTypes.ADMIN_JOB_STATUS,
  NotificationTypes.ADMIN_JOB_CREATE_BY_CUSTOMER,
];

export const fieldWorkerNotificationTypes = [
  NotificationTypes.FIELDWORKER_JOB_ASSIGNED,
  NotificationTypes.FIELDWORKER_JOB_UNASSIGNED,
  NotificationTypes.FIELDWORKER_JOB_START_DATE,
];

export const customerNotificationTypes = [
  NotificationTypes.CUSTOMER_JOB_STATUS,
  NotificationTypes.CUSTOMER_JOB_FW_DATE,
  NotificationTypes.CUSTOMER_JOB_NEW_START_DATE,
  NotificationTypes.CUSTOMER_JOB_START_DATE_CHANGE,
];