import React from 'react';
import { Grid, Box } from '@mui/material';
import { CalendarType, FieldworkerColor } from '../calendar-types';
import { TableHours } from './TableHours';
import { TableWeek } from './TableWeek';
import { TableAgenda } from './TableAgenda';

/**
 * @returns {JSX.Element} - Calendar View.
 * @param {JSX.Element} props - Calendar View.
 * @param {JSX.Element} props.fieldworkers - Calendar View.
 * @param {JSX.Element} props.type - Calendar View.
 * @param {JSX.Element} props.today - Calendar View.
 */
export function CalendarResponsive({
  type,
  formatData,
  date,
  children,
}: {
  type: CalendarType;
  formatData: FieldworkerColor[];
  date: string;
  children: JSX.Element;
}): JSX.Element {
  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        sx={{
          // width: type === CalendarType.DAY ? '100%' : '90%',
          // position: type === CalendarType.DAY ? 'static' : 'fixed',
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      >
        {children}
      </Box>
      {type === CalendarType.DAY && (
        <TableHours
          fieldworkers={(formatData as FieldworkerColor[]) ?? []}
          day={date}
        />
      )}
      {type === CalendarType.WEEK && (
        <TableWeek
          fieldworkers={(formatData as FieldworkerColor[]) ?? []}
          day={date}
        />
      )}
      {type === CalendarType.AGENDA && (
        <TableAgenda
          fieldworkers={(formatData as FieldworkerColor[]) ?? []}
          day={date}
        />
      )}
    </Grid>
  );
}
