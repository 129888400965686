import { useState, useEffect, useMemo, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Typography,
  Box,
  Menu,
  Link,
  Dialog,
  DialogTitle,
  Button,
  DialogActions,
  Stack,
  DialogContent,
  useMediaQuery,
  Fab,
  FormControl,
  InputLabel,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useHistory } from 'react-router-dom';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { Add } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { snackbar } from '../../shared/components/Snackbar';
import { Pill } from '../../shared/components/Pills';
import { Pagination } from '../../shared/components/Pagination';
import { RowResponsive } from '../../shared/components/RowResponsive';
import {
  useUpdateCustomerMutation,
  CustomersListQuery,
  useUpdateCustomer2Mutation,
} from '../../shared/types/generated';
import { useCustomerListHook } from './customer-hooks';
import {
  createVariablesCustomerList,
  archiveCustomerVariables,
  archiveCustomerVariablesV2,
} from './customer-utils';
import { humanizeDate, clearQuerysFromCache } from '../../shared/utils';
import { CustomerFiltersType, CustomerItemType } from './customer-types';
import { SearchInput } from '../../shared/components/SearchInput';
import { CustomerFilters } from './components/CustomerFilters';
import { useQuery } from '../../shared/utils/route';
import { DB_STATUS } from '../../shared/constants/common';
import { Column, Table } from '../../shared/components/table/Table';
import { FeatureFlags } from '../../shared/constants/featureFlag';
import { UseValidateFeatureFlag } from '../auth/auth-hooks';

const DEFAULT_FILTERS: CustomerFiltersType = {
  status: DB_STATUS.Active,
  rows: 10,
  page: 1,
};

export function CustomerTable(): JSX.Element {
  const history = useHistory();
  const theme = useTheme();
  const featureFlagBlockUser = UseValidateFeatureFlag(FeatureFlags.BLOCK_CUSTOMERS);
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { query, searchQuery } = useQuery<CustomerFiltersType>();
  const [selected, setSelected] = useState<string>('');
  const [ancla, setAncla] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [archived, setArchived] = useState<boolean>(false);
  const [openModalWarning, setOpenModalWarning] = useState<boolean>(false);
  const [openModalStatus, setOpenModalStatus] = useState<boolean>(false);
  const [filters, setFilters] = useState<CustomerFiltersType>(DEFAULT_FILTERS);

  const { loading, data } = useCustomerListHook(
    createVariablesCustomerList(
      filters.search || '',
      (filters.page - 1) * filters.rows,
      filters.rows,
      filters.from ? new Date(filters.from).toISOString() : undefined,
      filters.to ? new Date(filters.to).toISOString() : undefined,
      filters.status,
    ),
  );

  const [archiveCustomer, { loading: loadingArchive, error: errorArchive }] =
    useUpdateCustomerMutation({
      variables: archiveCustomerVariables(
        typeof selected === 'string' ? selected : '',
        !archived,
      ),
      onCompleted: () => {
        if (errorArchive === undefined) {
          snackbar.success(
            archived ? 'Customer Activated' : 'Customer Archived',
          );
          setOpenModalWarning(false);
        } else {
          setOpenModalStatus(true);
        }
      },
      update: clearQuerysFromCache(['customersList', 'customer']),
    });

  const [archiveCustomerV2, { loading: loadingArchiveV2, error: errorArchiveV2}] =
    useUpdateCustomer2Mutation({
      variables: archiveCustomerVariablesV2(
        typeof selected === 'string' ? selected : '',
        !archived,
      ),
      onCompleted: () => {
        if (errorArchiveV2 === undefined) {
          snackbar.success(
            archived ? 'Customer Activated' : 'Customer Archived',
          );
          setOpenModalWarning(false);
        } else {
          setOpenModalStatus(true);
        }
      },
      update: clearQuerysFromCache(['customersList', 'customer']),
    });

  const handleMore = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement>,
      id: string,
      con: boolean,
    ): void => {
      setSelected(id);
      setAncla(event.currentTarget);
      setArchived(con);
      setOpen(true);
    },
    [],
  );

  const COLUMNS = useMemo<
    Column<CustomersListQuery['customersList']['items'][0]>[]
  >(
    () => [
      {
        name: 'Full Name',
        key: 'fullName',
        render: (item) => (
          <Link
            color="inherit"
            underline="none"
            href={`/customers/details?id=${item.id}`}
            fontWeight='normal'
          >
            {item.name}
          </Link>
        ),
      },
      {
        name: 'Email Address',
        key: 'email',
      },
      {
        name: 'Status',
        key: 'archived',
        render: (item) => (
          <Pill
            text={item.archived ? 'Archived' : 'Active'}
            type={item.archived ? 2 : 0}
          />
        ),
      },
      {
        name: 'Customer Since',
        key: 'createdAt',
        render: (item) => humanizeDate(item?.createdAt),
      },
      {
        name: 'Action',
        key: 'action',
        action: (item) => (
          <IconButton
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              let con = false;
              if (item.archived !== null) {
                con = true;
              }

              handleMore(
                event,
                typeof item.id === 'string' ? item.id : '',
                con,
              );
            }}
          >
            <MoreHoriz />
          </IconButton>
        ),
      },
    ],
    [handleMore],
  );

  const search = (value: string): void => {
    searchQuery({
      page: 1,
      search: value,
    });
  };

  const relocate = (id: string): void => {
    history.push(`/customers/edit?id=${id}`);
  };

  const handleMoreClose = (): void => {
    setOpen(false);
  };

  const onChangePage = (value: number): void => {
    searchQuery({
      page: value,
    });
  };

  const onChangePageSize = (value: number): void => {
    searchQuery({
      page: 1,
      rows: value,
    });
  };

  useEffect(() => {
    if (query) {
      setFilters((state) => ({
        ...query,
        status: query.status ? query.status : state.status,
        rows: query.rows ? Number(query.rows) : state.rows,
        page: query.page ? Number(query.page) : state.page,
      }));
    } else {
      setFilters(DEFAULT_FILTERS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <>
      {matchesSm && (
        <Fab
          color="primary"
          aria-label="Create Job"
          href="/customers/create"
          sx={{
            position: 'fixed',
            right: '32px',
            bottom: '70px',
            zIndex: '3',
          }}
        >
          <Add />
        </Fab>
      )}
      {matches ? (
        <Box
          sx={{
            display: 'grid',
            rowGap: '16px',
            gridTemplateColumns: '100%',
            gridTemplateRows: 'auto auto 1fr',
            height: '100%',
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ alignItems: 'center' }}
          >
            <Typography variant="body2">
              {data?.customersList &&
              data?.customersList?.items.length < filters.rows
                ? Math.min(
                    filters.page * filters.rows -
                      (filters.rows - data?.customersList?.items.length),
                  )
                : Math.min(filters.page * filters.rows)}{' '}
              of {data?.customersList.count ?? 0}
            </Typography>
            <Stack direction="row" spacing={2}>
              <CustomerFilters filters={filters} />
            </Stack>
          </Box>
          {data &&
            data.customersList.items.map((value: CustomerItemType) => (
              <RowResponsive
                type="customer"
                key={value.id}
                id={value.id}
                name={value.name}
                email={value.email}
                number={humanizeDate(value?.createdAt)}
                status={value.archived ? 'Archived' : 'Active'}
                action={(event: React.MouseEvent<HTMLButtonElement>) => {
                  let con = false;
                  if (value.archived !== null) {
                    con = true;
                  }
                  handleMore(
                    event,
                    typeof value.id === 'string' ? value.id : '',
                    con,
                  );
                }}
              />
            ))}
          <Pagination
            page={filters.page}
            pageSize={filters.rows}
            totalCount={data?.customersList.count ?? 0}
            onChange={onChangePage}
          />
        </Box>
      ) : (
        <>
          <Box display="flex" justifyContent="flex-end">
            <Stack direction="row" spacing={2}>
              <SearchInput onChangeText={search} />
              <FormControl
                sx={{
                  minWidth: 150,
                }}
              >
                <InputLabel>Rows</InputLabel>
                <Select
                  label="Rows"
                  value={filters.rows}
                  onChange={(e) => {
                    onChangePageSize(Number(e.target.value));
                  }}
                >
                  <MenuItem value={10}>10 Rows(s)</MenuItem>
                  <MenuItem value={20}>20 Rows(s)</MenuItem>
                  <MenuItem value={30}>30 Rows(s)</MenuItem>
                </Select>
              </FormControl>
              <CustomerFilters filters={filters} />
            </Stack>
          </Box>
          <Table
            loading={loading}
            columns={COLUMNS}
            data={data?.customersList.items}
            pageSize={filters.rows}
            page={filters.page}
            total={data?.customersList.count}
            onChangePage={onChangePage}
          />
        </>
      )}

      <Menu open={open} onClose={handleMoreClose} anchorEl={ancla}>
        {archived === false && (
          <MenuItem
            onClick={() => {
              relocate(selected);
            }}
          >
            Edit
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            setOpenModalWarning(true);
            setOpen(false);
          }}
        >
          {archived === false ? 'Archive' : 'Active'}
        </MenuItem>
      </Menu>

      <Dialog open={openModalWarning}>
        <DialogTitle id="warningTitle">Are you sure?</DialogTitle>
        <DialogContent>
          {archived
            ? 'This Customer will be activated.'
            : 'By archiving this customer, they will lose access to the platform. Proceed with archiving?'}
        </DialogContent>
        <DialogActions>
          <Button
            variant="default"
            onClick={() => {
              setOpenModalWarning(false);
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant={archived ? 'contained' : 'delete'}
            loading={loadingArchive || loadingArchiveV2}
            onClick={() => {
              if (featureFlagBlockUser) {
                archiveCustomerV2();
              }else { 
                archiveCustomer(); 
              }
            }}
          >
            {archived === true ? 'active' : 'archive'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog open={openModalStatus}>
        {(errorArchive !== undefined || errorArchiveV2 !== undefined)&& (
          <>
            <DialogTitle id="warningTitle">Something went wrong</DialogTitle>
            <DialogActions>
              <Button
                color="primary"
                autoFocus
                onClick={() => {
                  setOpenModalStatus(false);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}
