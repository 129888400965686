import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import Logo from '../../../shared/assets/Coolritelogo_page.png';

import {
  JobDetailQuery,
  ClockListQuery,
} from '../../../shared/types/generated';
import { humanizeDate } from '../../../shared/utils';
import { getNationalPhoneNumber } from '../../../shared/utils/phone';

// Create styles
const styles = StyleSheet.create({
  Row: {
    display: 'flex',
    flexDirection: 'row',
  },
  RowReverse: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },

  Wrap: {
    flexWrap: 'wrap',
  },

  Column: {
    display: 'flex',
    flexDirection: 'column',
  },
  ColumnReverse: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },

  PerfectCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  VerticalCenter: {
    display: 'flex',

    alignItems: 'center',
  },
  HorizontalCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  spaceArount: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  title: {
    fontSize: 15,
  },
  subTitle: {
    fontSize: 12,
    color: 'white',
    backgroundColor: 'grey',
  },
  content: {
    fontSize: 10,
    marginTop: 5,
  },
  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

interface MyProps {
  data: JobDetailQuery | undefined;
  clockData: ClockListQuery | undefined;
  featureFlag: boolean;
}

/**
 * @returns Any Component.
 * @param {MyProps} props - Props.
 */ // eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const PdfComponent = ({ data, clockData, featureFlag }: MyProps): any => {
  const imagesPerRow = 4;
  const numberOfRows = Math.ceil((data?.job?.images?.items?.length || 0) / imagesPerRow);
  const imagesArray = [];
  for (let i = 0; i < numberOfRows; i+=1) {
    imagesArray.push(data?.job?.images?.items.filter(
      (_item, index) => 
        index >= i*imagesPerRow && index < imagesPerRow*(i + 1)));
  }
  return (
  <Document>
    <Page size="A4" style={styles.page}>
      <View
        style={[
          { backgroundColor: '#5EB2CF', width: '100%', height: 200 },
          styles.PerfectCenter,
          styles.Column,
        ]}
      >
        <Image style={{ width: 200, height: 50 }} source={Logo} />
        <Text style={{ fontSize: 20, color: 'white' }}>Coolrite App</Text>
      </View>
      <View style={[{ padding: 5 }, styles.Row, styles.Wrap]}>
        <View style={[{ width: '50%', padding: 10 }]}>
          <Text style={styles.title}>Job Code: #{data?.job?.code}</Text>
        </View>
        <View style={[{ width: '50%', padding: 10, paddingLeft: 0 }]}>
          <Text style={styles.title}>
            Status: {data?.job.status.replaceAll('_', ' ')}
          </Text>
        </View>
        <View
          style={[
            styles.Row,
            styles.VerticalCenter,
            {
              backgroundColor: 'grey',
              width: '100%',
              height: 20,
              marginLeft: 10,
              marginBottom: 2,
              marginRight: 10,
              padding: 2,
            },
          ]}
        >
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.subTitle}>Customer Name</Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.subTitle}>Address</Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.subTitle}>Contact Email</Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.subTitle}>Phone Number</Text>
          </View>
        </View>
        <View style={[styles.Row, { width: '100%', padding: 10 }]}>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.content}>
              {data?.job.address.customer.name || '-'}
            </Text>
          </View>
          <View style={[styles.Column, { width: '25%', paddingRight: '15px' }]}>
            <Text style={styles.content}>
              {`${data?.job.address.streetAddress || '-'}, ${
                data?.job.address.city || '-'
              }, ${data?.job.address.state || '-'}`}
            </Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.content}>{`${
              data?.job.address.customer.email || '-'
            }`}</Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.content}>
              {`${getNationalPhoneNumber(data?.job.address.customer.phoneNumber).value || '-'}`}
            </Text>
          </View>
        </View>
        <View
          style={[
            styles.Row,
            styles.VerticalCenter,
            {
              backgroundColor: 'grey',
              width: '100%',
              height: 20,
              marginLeft: 10,
              marginBottom: 2,
              marginRight: 10,
              marginTop: 10,
              padding: 2,
            },
          ]}
        >
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.subTitle}>Date</Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.subTitle}>Type</Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.subTitle}>Priority</Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.subTitle}>Fieldworker</Text>
          </View>
        </View>
        <View
          style={[
            styles.Row,
            styles.VerticalCenter,
            { width: '100%', padding: 10 },
          ]}
        >
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.content}>
              {humanizeDate(data?.job.createdAt ?? '')}
            </Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.content}>{data?.job.type?.name || '-'}</Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.content}>{data?.job.priority || '-'}</Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.content}>
              {data?.job.jobJobFieldWorkerRelation.items
                .map((item) => item?.fieldworker?.fullName || '-')
                .join(', ')}
            </Text>
          </View>
        </View>

        <View
          style={[
            styles.Row,
            styles.VerticalCenter,
            {
              backgroundColor: 'grey',
              width: '100%',
              height: 20,
              marginLeft: 10,
              marginRight: 10,
              marginTop: 10,
              padding: 2,
            },
          ]}
        >
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.subTitle}>Start Date</Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.subTitle}>Unit Serviced</Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.subTitle}>Model</Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.subTitle}>Serial</Text>
          </View>
        </View>
        <View
          style={[
            styles.Row,
            styles.VerticalCenter,
            { width: '100%', padding: 10 },
          ]}
        >
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.content}>
              {humanizeDate(data?.job.startDateTime ?? '') === '-'
                ? 'Unassigned'
                : humanizeDate(data?.job.startDateTime ?? '') || '-'}
            </Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.content}>{data?.job.unitServiced || '-'}</Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.content}> {`${data?.job.model || '-'}`}</Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.content}>{`${data?.job.serial || '-'}`}</Text>
          </View>
        </View>
        <View
          style={[
            styles.Row,
            styles.VerticalCenter,
            {
              backgroundColor: 'grey',
              width: '100%',
              height: 20,
              marginLeft: 10,
              marginRight: 10,
              marginTop: 10,
              padding: 2,
            },
          ]}
        >
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.subTitle}>Dispatched By</Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.subTitle}>Caller Name</Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.subTitle}>Caller Cell</Text>
          </View>
        </View>
        <View
          style={[
            styles.Row,
            styles.VerticalCenter,
            { width: '100%', padding: 10 },
          ]}
        >
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.content}>
              {data?.job.jobJobInfoRelation?.dispatchedBy || '-'}
            </Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.content}>
              {data?.job.jobJobInfoRelation?.callerName || '-'}
            </Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.content}>
              {' '}
              {`${getNationalPhoneNumber(data?.job.jobJobInfoRelation?.callerCell).value || '-'}`}
            </Text>
          </View>
        </View>
        <View
          style={[
            styles.Row,
            styles.VerticalCenter,
            {
              backgroundColor: 'grey',
              width: '100%',
              height: 20,
              marginLeft: 10,
              marginRight: 10,
              marginBottom: 2,
              marginTop: 10,
              padding: 2,
            },
          ]}
        >
          <View style={[styles.Column, { width: '100%' }]}>
            <Text style={styles.subTitle}>Description</Text>
          </View>
        </View>
        <View
          style={[
            styles.Row,
            styles.VerticalCenter,
            styles.Wrap,
            { width: '100%', padding: 10 },
          ]}
        >
          <View style={[styles.Column, { width: '100%' }]}>
            <Text style={[styles.content, { textAlign: 'justify' }]}>
              {data?.job.description || '-'}
            </Text>
          </View>
        </View>
        <View
          style={[
            styles.Row,
            styles.VerticalCenter,
            {
              backgroundColor: 'grey',
              width: '100%',
              height: 20,
              marginLeft: 10,
              marginBottom: 2,
              marginRight: 10,
              marginTop: 10,
              padding: 2,
            },
          ]}
        >
          <View style={[styles.Column, { width: '100%' }]}>
            <Text style={styles.subTitle}>Fieldworker Notes</Text>
          </View>
        </View>
        <View
          style={[
            styles.Row,
            styles.VerticalCenter,
            { width: '100%', padding: 10 },
          ]}
        >
          <View style={[styles.Column, { width: '100%', padding: 2 }]}>
            <Text style={[styles.content, { textAlign: 'justify' }]}>
              {data?.job.fieldworkerNotes || '-'}
            </Text>
          </View>
        </View>
        <View
          style={[
            styles.Row,
            styles.VerticalCenter,
            {
              backgroundColor: 'grey',
              width: '100%',
              height: 20,
              marginLeft: 10,
              marginBottom: 2,
              marginRight: 10,
              marginTop: 10,
              padding: 2,
            },
          ]}
        >
          <View style={[styles.Column, { width: '100%' }]}>
            <Text style={styles.subTitle}>Work Performed</Text>
          </View>
        </View>
        <View
          style={[
            styles.Row,
            styles.VerticalCenter,
            { width: '100%', padding: 10 },
          ]}
        >
          <View style={[styles.Column, { width: '100%', padding: 2 }]}>
            <Text style={[styles.content, { textAlign: 'justify' }]}>
              {data?.job.workPerformed || '-'}
            </Text>
          </View>
        </View>

        <View
          style={[
            styles.Row,
            styles.VerticalCenter,
            {
              backgroundColor: 'grey',
              width: '100%',
              height: 20,
              marginLeft: 10,
              marginRight: 10,
              marginTop: 10,
              padding: 2,
            },
          ]}
        >
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.subTitle}>Purchase Order</Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.subTitle}>Work Order</Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.subTitle}>Call ID</Text>
          </View>
        </View>
        <View style={[styles.Row, { width: '100%', padding: 10 }]}>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.content}>{`${
              data?.job.purchaseOrder || '-'
            }`}</Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.content}>{`${
              data?.job.workOrder || '-'
            }`}</Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.content}>{`${data?.job.callID || '-'}`}</Text>
          </View>
        </View>

        <View
          style={[
            styles.Row,
            styles.VerticalCenter,
            {
              backgroundColor: 'grey',
              width: '100%',
              height: 20,
              marginLeft: 10,
              marginBottom: 2,
              marginRight: 10,
              marginTop: 10,
              padding: 2,
            },
          ]}
        >
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.subTitle}>Signature</Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.subTitle}>Signature Name</Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.subTitle}>Signature Title</Text>
          </View>
        </View>
        <View
          style={[
            styles.Column,
            styles.VerticalCenter,
            { width: '100%', padding: 10, paddingBottom: 0 },
          ]}
        >
          {data?.job.signature !== null && data?.job.signature !== '' && (
            <View style={[styles.Row, { width: '100%', padding: 10 }]}>
              <View style={[styles.Column, { width: '25%' }]}>
                <Image
                  style={{ width: 60, height: 60 }}
                  source={data?.job.signature ?? data?.job.signature ?? ' '}
                />
              </View>
              <View style={[styles.Column, { width: '25%' }]}>
                <Text style={{ fontSize: 8 }}>
                  {data?.job.signatureName || '-'}
                </Text>
              </View>
              <View style={[styles.Column, { width: '25%' }]}>
                <Text style={{ fontSize: 8 }}>
                  {data?.job.signatureTitle || '-'}
                </Text>
              </View>
            </View>
          )}
        </View>
      </View>

      <View style={[{ padding: 5, paddingTop: 0 }, styles.Row, styles.Wrap]}>
        <View style={{ width: '100%', padding: 10 }}>
          <Text style={styles.title}>Clock In / Clock Out</Text>
        </View>
        <View
          style={[
            styles.Row,
            styles.VerticalCenter,
            {
              backgroundColor: 'grey',
              width: '100%',
              height: 20,
              marginLeft: 10,
              marginRight: 10,
              marginTop: 10,
              padding: 2,
            },
          ]}
        >
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.subTitle}>#</Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.subTitle}>Type Clock</Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.subTitle}>Fieldworker</Text>
          </View>
          <View style={[styles.Column, { width: '25%' }]}>
            <Text style={styles.subTitle}>Time</Text>
          </View>
        </View>

        {clockData !== undefined &&
          clockData.clockInOutsList.items.map((item, index) => {
            if (item.type === 'ON_ROUTE') return null;
            return (
              <View
                style={[
                  styles.Row,
                  {
                    width: '100%',
                    paddingLeft: 2,
                    height: 20,
                    marginLeft: 10,
                    marginRight: 10,
                    marginTop: 10,
                    backgroundColor: '#21212108',
                  },
                ]}
              >
                <View style={[styles.Column, { width: '25%', height: '100%' }]}>
                  <Text style={styles.content}>{index + 1}</Text>
                </View>
                <View style={[styles.Column, { width: '25%', height: '100%' }]}>
                  <Text style={styles.content}>
                    {item.type === 'CLOCK_IN' ? 'Clock In' : 'Clock Out'}
                  </Text>
                </View>
                <View style={[styles.Column, { width: '25%', height: '100%' }]}>
                  <Text style={styles.content}>
                    {item?.fieldworker?.fullName || '-'}
                  </Text>
                </View>
                <View style={[styles.Column, { width: '25%', height: '100%' }]}>
                  <Text style={styles.content}>
                    {humanizeDate(item.createdAt) || '-'}
                  </Text>
                </View>
              </View>
            );
          })}
      </View>
      <View style={[{ padding: 5, paddingBottom: 0 }, styles.Row, styles.Wrap]}>
        <View style={{ width: '100%', padding: 10 }}>
          <Text style={styles.title}>Parts Used</Text>
        </View>
        <View
          style={[
            styles.Row,
            styles.VerticalCenter,
            {
              backgroundColor: 'grey',
              width: '100%',
              height: 20,
              marginLeft: 10,
              marginRight: 10,
              marginTop: 10,
              padding: 2,
            },
          ]}
        >
          <View style={[styles.Column, { width: '10%' }]}>
            <Text style={styles.subTitle}>#</Text>
          </View>
          <View style={[styles.Column, { width: '20%', height: '100%' }]}>
            <Text style={styles.subTitle}>Name</Text>
          </View>
          <View style={[styles.Column, { width: '25%', height: '100%' }]}>
            <Text style={styles.subTitle}>Part Number</Text>
          </View>
          <View style={[styles.Column, { width: '25%', height: '100%' }]}>
            <Text style={styles.subTitle}>Unit</Text>
          </View>
          <View style={[styles.Column, { width: '20%' }]}>
            <Text style={styles.subTitle}>Created At</Text>
          </View>
        </View>

        {data !== undefined &&
          data.job.jobJobPartsRelation.items.map((item, index) => (
            <View
              style={[
                styles.Row,
                {
                  width: '100%',
                  paddingLeft: 2,
                  paddingBottom: 2,
                  minHeight: 20,
                  marginLeft: 10,
                  marginRight: 10,
                  marginTop: 10,
                  backgroundColor: '#21212108',
                },
              ]}
            >
              <View style={[styles.Column, { width: '10%', height: '100%' }]}>
                <Text style={styles.content}>{index + 1}</Text>
              </View>
              <View
                style={[
                  styles.Row,
                  styles.VerticalCenter,
                  { width: '20%', paddingRight: 8, paddingBottom: 8 },
                ]}
              >
                <Text style={[styles.content, { textAlign: 'justify' }]}>
                  {item.name || '-'}
                </Text>
              </View>
              <View
                style={[
                  styles.Row,
                  styles.VerticalCenter,
                  { width: '25%', paddingRight: 8, paddingBottom: 8 },
                ]}
              >
                <Text style={[styles.content, { textAlign: 'center' }]}>
                  {item.partNumber || '-'}
                </Text>
              </View>
              <View
                style={[
                  styles.Row,
                  styles.VerticalCenter,
                  { width: '25%', paddingRight: 8, paddingBottom: 8 },
                ]}
              >
                <Text style={[styles.content, { textAlign: 'center' }]}>
                  {item.unit || '-'}
                </Text>
              </View>

              <View style={[styles.Column, { width: '20%', height: '100%' }]}>
                <Text style={styles.content}>
                  {humanizeDate(item.createdAt) || '-'}
                </Text>
              </View>
            </View>
          ))}
      </View>
      <View style={[{ padding: 5, paddingBottom: 0 }, styles.Row, styles.Wrap]}>
        <View style={{ width: '100%', padding: 10 }}>
          <Text style={styles.title}>Parts Order</Text>
        </View>
        <View
          style={[
            styles.Row,
            styles.VerticalCenter,
            {
              backgroundColor: 'grey',
              width: '100%',
              height: 20,
              marginLeft: 10,
              marginRight: 10,
              marginTop: 10,
              padding: 2,
            },
          ]}
        >
          <View style={[styles.Column, { width: '33%' }]}>
            <Text style={styles.subTitle}>#</Text>
          </View>
          <View style={[styles.Column, { width: '33%', height: '100%' }]}>
            <Text style={styles.subTitle}>Name</Text>
          </View>
          <View style={[styles.Column, { width: '33%' }]}>
            <Text style={styles.subTitle}>Price</Text>
          </View>
        </View>

        {data !== undefined &&
          data.job.jobJobPartOrderRelation.items.map((item, index) => (
            <View
              style={[
                styles.Row,
                {
                  width: '100%',
                  paddingLeft: 2,
                  paddingBottom: 2,
                  minHeight: 20,
                  marginLeft: 10,
                  marginRight: 10,
                  marginTop: 10,
                  backgroundColor: '#21212108',
                },
              ]}
            >
              <View style={[styles.Column, { width: '33%', height: '100%' }]}>
                <Text style={styles.content}>{index + 1}</Text>
              </View>
              <View
                style={[
                  styles.Row,
                  styles.VerticalCenter,
                  { width: '33%', paddingRight: 8, paddingBottom: 8 },
                ]}
              >
                <Text style={[styles.content, { textAlign: 'justify' }]}>
                  {item.name || '-'}
                </Text>
              </View>

              <View style={[styles.Column, { width: '33%', height: '100%' }]}>
                <Text style={styles.content}>${item.price || '-'}</Text>
              </View>
            </View>
          ))}
      </View>
      <View style={[{ padding: 5 }, styles.Row, styles.Wrap]}>
        <View
          style={[
            styles.Row,
            styles.VerticalCenter,
            {
              backgroundColor: 'grey',
              width: '100%',
              height: 20,
              marginLeft: 10,
              marginBottom: 2,
              marginTop: 10,
              marginRight: 10,
              padding: 2,
            },
          ]}
        >
          <View style={[styles.Column, { width: '100%' }]}>
            <Text style={styles.subTitle}>Images</Text>
          </View>
        </View>
        {featureFlag && imagesArray && imagesArray?.length > 0 && imagesArray?.map((imageArrItem) => 
          <View
            style={[
              styles.Row,
              styles.VerticalCenter,
              { width: '100%', padding: 10, paddingBottom: 0 },
            ]}
          >
            <View style={[styles.Row, { width: '100%', padding: 2 }]}>
              {imageArrItem && imageArrItem?.length > 0 &&
                imageArrItem?.map((item) => (
                  <Image
                    style={{
                      width: 150,
                      margin: 5,
                      height: 'auto',
                      objectFit: 'contain',
                    }}
                    source={
                      item.downloadUrl !== null
                        ? `https://cdn.filestackcontent.com/rotate=deg:exif/resize=width:1080${
                            item.downloadUrl.split('.com')[1]
                          }`
                        : ' '
                    }
                  />
                ))}
            </View>
          </View>
        )}
        {!featureFlag && <View
            style={[
              styles.Row,
              styles.VerticalCenter,
              { width: '100%', padding: 10, paddingBottom: 0 },
            ]}
          >
            <View style={[styles.Row, { width: '100%', padding: 2 }]}>
              {data?.job?.images?.items && data?.job?.images?.items?.length > 0 &&
                data?.job?.images?.items?.map((item) => (
                  <Image
                    style={{
                      width: 150,
                      margin: 5,
                      height: 'auto',
                      objectFit: 'contain',
                    }}
                    source={
                      item.downloadUrl !== null
                        ? `https://cdn.filestackcontent.com/rotate=deg:exif/resize=width:1080${
                            item.downloadUrl.split('.com')[1]
                          }`
                        : ' '
                    }
                  />
                ))}
            </View>
          </View>}
      </View>
    </Page>
  </Document>
);
};
