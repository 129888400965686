
import { FC } from 'react';
import { styled } from '@mui/styles';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { NotificationItem } from './NotificationItem';
import { JOB_STATUS } from '../../../shared/constants/common';
import { notificationDateFormat } from '../notification-utils';
import { Notification } from '../../../shared/types/generated';
import { NotificationTypes } from '../notification-types';
import { NotificationSkeleton } from './NotificationSkeleton';

const Container = styled(Box)({
  background: 'transparent',
  overflowY: 'auto',
  maxHeight: '70vh',
  scrollbarWidth: 'thin',
  '&&::-webkit-scrollbar-thumb': {
    maxWidth: '5px',
  },
});

interface NotificationsListProps {
  items?: Notification[];
  isLoading: boolean;
  markAsRead: (id?: string) => Promise<void>;
  markedAsReadItem?: string;
  totalNotif: number;
}
export const NotificationsList: FC<NotificationsListProps> = ({
  items,
  isLoading,
  markAsRead,
  markedAsReadItem,
  totalNotif,
}): JSX.Element => {
  const content =
    items && totalNotif > 0 ? (
      <Box>
        {items?.map((notif) => {
          const relativeDate = notificationDateFormat(notif.createdAt);
          const jobNumber = notif?.job?.code;
          const notifType = notif?.type as NotificationTypes;
          const jobStatus = Object(notif?.snapshot)?.job?.status as JOB_STATUS;
          const isRead = notif?.read;
          return (
            <>
              <NotificationItem
                id={notif.id}
                jobNumber={jobNumber}
                notifType={notifType}
                notification={notif}
                jobStatus={jobStatus}
                isRead={isRead}
                date={relativeDate}
                markAsRead={markAsRead}
                markedAsReadItem={markedAsReadItem}
              />
              <Divider />
            </>
          );
        })}
      </Box>
    ) : (
      <Stack alignItems='center' p='15px 0' width='100%'>
        <Typography variant='subtitle2'>No new notifications</Typography>
      </Stack>
    );
  return <Container>{isLoading ?  <NotificationSkeleton /> : content}</Container>;
};
