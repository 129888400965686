/* eslint-disable no-plusplus */
import dayjs from 'dayjs';
import * as filestack from 'filestack-js';
import {
  JobJobJobPartsRelationUpdateRelationInput,
  JobPartKeyFilter,
  Job_CustomerDocs_FileCreateInput,
  Job_JobPartCreateInput,
  Job_JobPartUpdateInput,
} from '../../shared/types/generated';
// import { }, AdminNote } from '../../shared/types/generated';
import {
  JobQuery,
  JobCreateInput,
  JobFilter,
  JobUpdateMutationVariables,
  JobUpdateInput,
  JobJobJobFieldWorkerRelationUpdateRelationInput,
  JobsListQueryVariables,
  Job_Images_FileCreateInput,
  File,
} from '../../shared/types/types';
import { JobFormType, JobsListFilters, PartType } from './jobs-types';
import { CLOCK, DB_STATUS, JOB_STATUS } from '../../shared/constants/common';
import { getPhoneNumberFomartToSaveInDB } from '../../shared/utils/phone';

/**
 * @returns {Array<Job_JobPartCreateInput>} - Create the create part object.
 * @param {JobFormType} jobForm - Job form.s.
 */
const createPart = (jobForm: JobFormType): Array<Job_JobPartCreateInput> => {
  const create: Array<Job_JobPartCreateInput> = [];
  jobForm.parts
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ?.filter((item) => item.id === undefined || item.id?.length === 0)
    .forEach((item) =>
      create.push({
        name: item.name,
        unit: item.unit || '',
        partNumber: item.partNumber || '',
      }),
    );

  return create;
};

/**
 * @param filters - Input filter object.
 * @param fieldworker - If user is fieldworker.
 * @returns Filters mapped to 8base vars.
 */
export function createJobListfilter(
  filters: JobsListFilters,
  fieldworker: boolean,
): JobFilter {
  const resultFilter: JobFilter[] = [];

  if (filters.dbStatus === DB_STATUS.Active) {
    resultFilter.push({
      archived: { is_empty: true },
    });
  }
  if (filters.dbStatus === DB_STATUS.Archived) {
    resultFilter.push({
      archived: { is_empty: false },
    });
  }

  if (filters.search && filters.search !== '') {
    resultFilter.push({
      OR: [
        {
          code: {
            equals: !Number.isNaN(Number(filters.search))
              ? Number(filters.search)
              : undefined,
          },
        },
        {
          type: {
            name: {
              contains: filters.search,
            },
          },
        },
        {
          address: {
            customer: {
              name: {
                contains: filters.search,
              },
            },
          },
        },
        {
          status: {
            contains: filters.search,
          },
        },
      ],
    });
  }

  if (filters.from && filters.from !== '') {
    resultFilter.push({
      createdAt: {
        gte: dayjs(filters.from).startOf('day').set('hour', 1).format(),
      },
    });
  }

  if (filters.to && filters.to !== '') {
    resultFilter.push({
      createdAt: {
        lte: dayjs(filters.to).endOf('day').set('hour', 23).format(),
      },
    });
  }

  if (filters.fromStart && filters.fromStart !== '') {
    resultFilter.push({
      startDateTime: {
        gte: dayjs(filters.fromStart).startOf('day').set('hour', 1).format(),
      },
    });
  }
  if (filters.toStart && filters.toStart !== '') {
    resultFilter.push({
      startDateTime: {
        lte: dayjs(filters.toStart).startOf('day').set('hour', 23).format(),
      },
    });
  }

  if (filters.status && filters.status !== '') {
    resultFilter.push({
      status: {
        contains: filters.status,
      },
    });
  }

  if (filters.type && filters.type !== '') {
    resultFilter.push({
      type: {
        id: {
          equals: filters.type,
        },
      },
    });
  }

  if (filters.fieldWorker) {
    resultFilter.push({
      jobJobFieldWorkerRelation: {
        some: {
          fieldworker: {
            id: { equals: filters.fieldWorker }
          }
        }
      }
    });
  }

  if (fieldworker) {
    resultFilter.push(
      {
        address: {
          customer: {
            customerAddressRelation: {
              some: {
                addressJobRelation: {
                  some: {
                    jobJobFieldWorkerRelation: {
                      some: {
                        fieldworker: {
                          user: {
                            is_self: true,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
      {
        jobJobFieldWorkerRelation: {
          some: {
            fieldworker: {
              user: {
                is_self: true,
              },
            },
          },
        },
      },
    );
  }
  return { AND: resultFilter };
}

/**
 * @param inputData - Input form data.
 * @returns Mapped data to input.
 */
export function mapJobCreateFormToInput(
  inputData: JobFormType,
): JobCreateInput {
  const data: JobCreateInput = {
    name: inputData.name ?? '',
    priority: inputData.priority ?? '',
    sendNotification: inputData.notification,
    sendWorkOrder: inputData.sendWorkOrder,
    status: inputData.status,
    endDateTime: inputData.endDate
      ? dayjs(inputData.endDate).toISOString()
      : undefined,
    startDateTime: inputData.startDate
      ? dayjs(inputData.startDate).toISOString()
      : undefined,
    description: inputData.description,
    fieldworkerNotes: inputData.fieldworkerNotes,
    unitServiced: inputData.unitServiced,
    model: inputData.model,
    serial: inputData.serial,
    workPerformed: inputData.workPerformed,
    purchaseOrder: inputData.purchaseOrder,
    workOrder: inputData.workOrder,
    callID: inputData.callID,
    adminNote: {
      create: {
        note: inputData.adminNote,
      },
    },
    address: {
      connect: {
        id: inputData.address,
      },
    },
    jobJobFieldWorkerRelation: {},
    jobJobPartsRelation: {
      create: createPart(inputData),
    },
    jobJobInfoRelation: {
      create: {
        callerCell: getPhoneNumberFomartToSaveInDB(inputData.callerCell),
        callerName: inputData.callerName || '',
        dispatchedBy: inputData.dispatchedBy || '',
      },
    },
  };

  if (inputData.fieldworker && inputData.fieldworker.length > 0) {
    data.jobJobFieldWorkerRelation = {
      create: inputData.fieldworker.map((id: string) => ({
        fieldworker: {
          connect: {
            id,
          },
        },
      })),
    };
  }

  if (inputData.type) {
    data.type = {
      connect: {
        id: inputData.type,
      },
    };
  }
  return data;
}
/**
 * @param inputData - Input form data.
 * @returns Mapped data to input.
 */
export function mapJobCreateFormToInputV2(
  inputData: JobFormType,
): JobCreateInput {
  const data: JobCreateInput = {
    name: inputData.name ?? '',
    priority: inputData.priority ?? '',
    sendNotification: inputData.notification,
    sendWorkOrder: inputData.sendWorkOrder,
    status: inputData.status,
    endDateTime: inputData.endDate
      ? dayjs(inputData.endDate).toISOString()
      : undefined,
    startDateTime: inputData.startDate
      ? dayjs(inputData.startDate).toISOString()
      : undefined,
    description: inputData.description,
    fieldworkerNotes: inputData.fieldworkerNotes,
    unitServiced: inputData.unitServiced,
    model: inputData.model,
    serial: inputData.serial,
    workPerformed: inputData.workPerformed,
    purchaseOrder: inputData.purchaseOrder,
    workOrder: inputData.workOrder,
    callID: inputData.callID,
    fieldworkerDocs: {
      create: [...inputData.fieldworkerDocs?.map((item) => ({
        fileId: item.handle,
        filename: item.filename,
        public: true,
      })) as Job_CustomerDocs_FileCreateInput[],
    ]
    },
    adminNote: {
      create: {
        note: inputData.adminNote,
      },
    },
    address: {
      connect: {
        id: inputData.address,
      },
    },
    jobJobFieldWorkerRelation: {},
    jobJobPartsRelation: {
      create: createPart(inputData),
    },
    jobJobInfoRelation: {
      create: {
        callerCell: getPhoneNumberFomartToSaveInDB(inputData.callerCell),
        callerName: inputData.callerName || '',
        dispatchedBy: inputData.dispatchedBy || '',
      },
    },
  };

  if (inputData.fieldworker && inputData.fieldworker.length > 0) {
    data.jobJobFieldWorkerRelation = {
      create: inputData.fieldworker.map((id: string) => ({
        fieldworker: {
          connect: {
            id,
          },
        },
      })),
    };
  }

  if (inputData.type) {
    data.type = {
      connect: {
        id: inputData.type,
      },
    };
  }
  return data;
}

/**
 * @param list - List with all information.
 * @returns Mapped data to input.
 */
export function formatList(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  list: Array<any>,
): Array<{
  name: string;
  id: string;
  items: {
    id: string;
    name: string;
  }[];
}> {
  const newArr = [];
  let items = [];

  for (let i = 0; i < list.length - 1; i++) {
    if (list[i].name === list[i + 1].name) {
      items.push(list[i].customer);
    } else if (items.length < 1) {
      newArr.push({
        id: list[i].id,
        name: list[i].name,
        items: [list[i].customer],
      });
    } else {
      newArr.push({ id: list[i].id, name: list[i].name, items });
      items = [];
    }
  }
  return newArr;
}

/**
 * @returns {Array<JobPartKeyFilter>} - Create disconnect part object.
 * @param {JobFormType} jobForm - Job form.
 * @param {PartType[]} originalParts - Input data.
 */
const disconnectPart = (
  jobForm: JobFormType,
  originalParts: PartType[],
): Array<JobPartKeyFilter> => {
  const disconnect: Array<JobPartKeyFilter> = [];
  originalParts
    .filter((item) => !jobForm.parts?.find((item2) => item2.id === item.id))
    .map((item) => ({ id: item.id }))
    .forEach((item) => disconnect.push(item));
  return disconnect;
};

/**
 * @returns {Array<Job_JobPartUpdateInput>} -Create Update Part object.
 * @param {JobFormType} jobForm - Job form.
 */
const updatePart = (jobForm: JobFormType): Array<Job_JobPartUpdateInput> => {
  const update: Array<Job_JobPartUpdateInput> = [];

  jobForm.parts
    ?.filter((item) => item.id !== undefined && item.id !== '')
    .map((item) => ({
      filter: {
        id: item.id,
      },
      data: {
        name: item.partName,
        unit: item?.unit || '',
        partNumber: item.partNumber || '',
      },
    }))
    .forEach((item) => update.push(item));
  return update;
};

/**
 * @returns {JobJobJobPartsRelationUpdateRelationInput} - Create Parts Object.
 * @param {JobFormType} jobForm - Job form.
 * @param {PartType[]} originalParts - Input data.
 */
export const createPartsObject = (
  jobForm: JobFormType,
  originalParts: PartType[],
): JobJobJobPartsRelationUpdateRelationInput => {
  const parts: JobJobJobPartsRelationUpdateRelationInput = {
    create: createPart(jobForm),
    update: updatePart(jobForm),
    disconnect: disconnectPart(jobForm, originalParts),
  };
  return parts;
};
/**
 * @param {filestack.PickerFileMetadata[]} Sources - Source.
 * @returns {Job_Images_FileCreateInput[]} - Source mapped.
 */
export const mapSources = (
  Sources: filestack.PickerFileMetadata[],
): Job_Images_FileCreateInput[] => {
  const mappedSource = Sources.map((source) => ({
    fileId: source.handle,
    filename: source.filename,
  }));
  return mappedSource;
};
/**
 * @param {filestack.PickerFileMetadata[]} Sources - Source.
 * @returns {Job_Images_FileCreateInput[]} - Source mapped.
 */
export const mapSourcesV2 = (
  Sources: filestack.PickerFileMetadata[],
): Job_Images_FileCreateInput[] => {
  const mappedSource = Sources.map((source) => ({
    fileId: source.handle,
    filename: source.filename,
    public: true,
  }));
  return mappedSource;
};
/**
 * @param {File[]} oldDataFiles - Old files.
 * @param {File[]} docs - Documents.
 * @returns {File[]} - Source mapped.
 */
export const mapImageToDelete = (
  oldDataFiles: File[],
  docs: File[],
): File[] => {
  const FilesToDelete = oldDataFiles
    ?.filter((oldD) => {
      const currentOldFiles = docs.find((doc) => doc.id === oldD.id);
      if (currentOldFiles) return false;
      return true;
    })
    .map((r) => ({ id: r.id }));
  return FilesToDelete;
};
/**
 * @param {File[]} docs - Documents.
 * @returns {File[]} - Source mapped.
 */
export const mapDocumentsToDelete = (
  docs: File[],
): File[] => {
  const FilesToDelete = docs.map((r) => ({ id: r.id }));
  return FilesToDelete;
};

/**
 * @param {string} params - Job id.
 * @param {string} params.id - Job id.
 * @param {JobFormType} params.inputData - Input data.
 * @param {Array} params.oldFieldworkers - Input data.
 * @param {Array} params.originalParts - Input data.
 * @param {boolean} params.isFieldWorker - Is it is fieldworker.
 * @param {filestack.PickerFileMetadata[]} params.images - Is it is fieldworker.
 * @param {boolean} params.jobInfoExist - If exist jobInfoRelation.
 * @returns {JobUpdateMutationVariables} - Variables.
 */
export function updateJobVariables({
  id,
  inputData,
  oldData,
  isFieldWorker,
  images,
  jobInfoExist,
  jobClockInOutRelation,
}: {
  id: string;
  inputData: JobFormType;
  oldData: {
    oldFieldworkers: JobQuery['job']['jobJobFieldWorkerRelation']['items'];
    originalParts: PartType[];
    adminNote?: string;
    officeId?: string;
  };
  isFieldWorker?: boolean;
  images: {
    oldImages: File[];
    newImages: filestack.PickerFileMetadata[];
  };
  jobInfoExist?: boolean;
  jobClockInOutRelation?: JobUpdateInput['jobClockInOutRelation'],
}): JobUpdateMutationVariables {
  const objectJobInfo = jobInfoExist
    ? {
        update: {
          callerCell: getPhoneNumberFomartToSaveInDB(inputData.callerCell),
          callerName: inputData.callerName || '',
          dispatchedBy: inputData.dispatchedBy || '',
        },
      }
    : {
        create: {
          callerCell: getPhoneNumberFomartToSaveInDB(inputData.callerCell),
          callerName: inputData.callerName || '',
          dispatchedBy: inputData.dispatchedBy || '',
        },
      };

  const data: JobUpdateInput = isFieldWorker
    ? {
        fieldworkerNotes: inputData.fieldworkerNotes,
        status: inputData.status,
        unitServiced: inputData.unitServiced,
        model: inputData.model,
        serial: inputData.serial,
        workPerformed: inputData.workPerformed,
        purchaseOrder: inputData.purchaseOrder,
        workOrder: inputData.workOrder,
        callID: inputData.callID,
        signature: inputData.signature ?? '',
        signatureTitle: inputData.signatureTitle,
        signatureName: inputData.signatureName,
        images: {
          create: mapSources(images.newImages),
          disconnect: images.oldImages,
        },
        officeNoteJobRelation: oldData.officeId
          ? {
              update: [
                {
                  data: {
                    id: oldData.officeId,
                    officeNotes: inputData?.officeNotes ?? '',
                  },
                },
              ],
            }
          : {
              create: [
                {
                  officeNotes: inputData.officeNotes,
                },
              ],
            },
        jobJobInfoRelation: objectJobInfo,
      }
    : {
        name: inputData.name,
        sendNotification: inputData.notification,
        sendWorkOrder: inputData.sendWorkOrder,
        status: inputData.status,
        fieldworkerNotes: inputData.fieldworkerNotes,
        unitServiced: inputData.unitServiced,
        model: inputData.model,
        serial: inputData.serial,
        workPerformed: inputData.workPerformed,
        purchaseOrder: inputData.purchaseOrder,
        workOrder: inputData.workOrder,
        callID: inputData.callID,
        priority: inputData.priority,
        adminNote: oldData.adminNote
          ? {
              update: {
                note: inputData.adminNote,
              },
            }
          : {
              create: {
                note: inputData.adminNote,
              },
            },
        endDateTime: inputData.endDate
          ? dayjs(inputData.endDate).toISOString()
          : undefined,
        startDateTime: inputData.startDate
          ? dayjs(inputData.startDate).toISOString()
          : undefined,
        description: inputData.description,
        address: {
          reconnect: {
            id: inputData.address,
          },
        },
        autoClock: true,
        jobJobFieldWorkerRelation: {},
        jobJobPartsRelation: createPartsObject(
          inputData as JobFormType,
          oldData.originalParts,
        ),
        jobJobInfoRelation: objectJobInfo,
      };
  if (!isFieldWorker) {
    data.jobJobFieldWorkerRelation = inputData.fieldworker.reduce(
      (prev, current) => {
        const isOldRecord = oldData.oldFieldworkers.find(
          (item) => item.fieldworker.id === current,
        );

        if (isOldRecord) {
          // If is a old record
          // We push the JobFieldworker id to  the reconnect
          prev.reconnect.push({ id: isOldRecord.id });
          return prev;
        }
        // If is a new record
        // We save the Fieldworker id to make the create relation.
        prev.create.push({
          fieldworker: {
            connect: {
              id: current,
            },
          },
        });

        return prev;
      },
      {
        reconnect: [],
        create: [],
      } as Required<
        Pick<
          JobJobJobFieldWorkerRelationUpdateRelationInput,
          'create' | 'reconnect'
        >
      >,
    );
  }

  if(jobClockInOutRelation){
    Object.assign(data, { jobClockInOutRelation });
  }

  if (inputData.type) {
    data.type = {
      reconnect: {
        id: inputData.type,
      },
    };
  }

  const variables: JobUpdateMutationVariables = {
    data,
    id,
  };

  return variables;
}
/**
 * @param {string} params - Job id.
 * @param {string} params.id - Job id.
 * @param {JobFormType} params.inputData - Input data.
 * @param {Array} params.oldFieldworkers - Input data.
 * @param {Array} params.originalParts - Input data.
 * @param {boolean} params.isFieldWorker - Is it is fieldworker.
 * @param {filestack.PickerFileMetadata[]} params.images - Is it is fieldworker.
 * @param {boolean} params.jobInfoExist - If exist jobInfoRelation.
 * @returns {JobUpdateMutationVariables} - Variables.
 */
export function updateJobVariablesV3({
  id,
  inputData,
  oldData,
  isFieldWorker,
  images,
  fieldWorkerDocs,
  jobInfoExist,
  jobClockInOutRelation,
}: {
  id: string;
  inputData: JobFormType;
  oldData: {
    oldFieldworkers: JobQuery['job']['jobJobFieldWorkerRelation']['items'];
    originalParts: PartType[];
    adminNote?: string;
    officeId?: string;
  };
  isFieldWorker?: boolean;
  images: {
    oldImages: File[];
    newImages: filestack.PickerFileMetadata[];
  };
  fieldWorkerDocs: {
    oldDocs: File[];
    newDocs: filestack.PickerFileMetadata[];
  };
  jobInfoExist?: boolean;
  jobClockInOutRelation?: JobUpdateInput['jobClockInOutRelation'],
}): JobUpdateMutationVariables {
  const objectJobInfo = jobInfoExist
    ? {
        update: {
          callerCell: getPhoneNumberFomartToSaveInDB(inputData.callerCell),
          callerName: inputData.callerName || '',
          dispatchedBy: inputData.dispatchedBy || '',
        },
      }
    : {
        create: {
          callerCell: getPhoneNumberFomartToSaveInDB(inputData.callerCell),
          callerName: inputData.callerName || '',
          dispatchedBy: inputData.dispatchedBy || '',
        },
      };

  const data: JobUpdateInput = isFieldWorker
    ? {
        fieldworkerNotes: inputData.fieldworkerNotes,
        status: inputData.status,
        unitServiced: inputData.unitServiced,
        model: inputData.model,
        serial: inputData.serial,
        workPerformed: inputData.workPerformed,
        purchaseOrder: inputData.purchaseOrder,
        workOrder: inputData.workOrder,
        callID: inputData.callID,
        signature: inputData.signature ?? '',
        signatureTitle: inputData.signatureTitle,
        signatureName: inputData.signatureName,
        jobJobPartsRelation: createPartsObject(
          inputData as JobFormType,
          oldData.originalParts,
        ),
        images: {
          create: mapSourcesV2(images.newImages),
          disconnect: images.oldImages,
        },
        officeNoteJobRelation: oldData.officeId
          ? {
              update: [
                {
                  data: {
                    id: oldData.officeId,
                    officeNotes: inputData?.officeNotes ?? '',
                  },
                },
              ],
            }
          : {
              create: [
                {
                  officeNotes: inputData.officeNotes,
                },
              ],
            },
        jobJobInfoRelation: objectJobInfo,
      }
    : {
        name: inputData.name,
        sendNotification: inputData.notification,
        sendWorkOrder: inputData.sendWorkOrder,
        status: inputData.status,
        fieldworkerNotes: inputData.fieldworkerNotes,
        unitServiced: inputData.unitServiced,
        model: inputData.model,
        serial: inputData.serial,
        workPerformed: inputData.workPerformed,
        purchaseOrder: inputData.purchaseOrder,
        workOrder: inputData.workOrder,
        callID: inputData.callID,
        priority: inputData.priority,
        fieldworkerDocs: {
          create: mapSourcesV2(fieldWorkerDocs.newDocs),
          disconnect: fieldWorkerDocs.oldDocs,
        },
        adminNote: oldData.adminNote
          ? {
              update: {
                note: inputData.adminNote,
              },
            }
          : {
              create: {
                note: inputData.adminNote,
              },
            },
        endDateTime: inputData.endDate
          ? dayjs(inputData.endDate).toISOString()
          : undefined,
        startDateTime: inputData.startDate
          ? dayjs(inputData.startDate).toISOString()
          : undefined,
        description: inputData.description,
        address: {
          reconnect: {
            id: inputData.address,
          },
        },
        autoClock: true,
        jobJobFieldWorkerRelation: {},
        jobJobPartsRelation: createPartsObject(
          inputData as JobFormType,
          oldData.originalParts,
        ),
        jobJobInfoRelation: objectJobInfo,
      };
  if (!isFieldWorker) {
    data.jobJobFieldWorkerRelation = inputData.fieldworker.reduce(
      (prev, current) => {
        const isOldRecord = oldData.oldFieldworkers.find(
          (item) => item.fieldworker.id === current,
        );

        if (isOldRecord) {
          // If is a old record
          // We push the JobFieldworker id to  the reconnect
          prev.reconnect.push({ id: isOldRecord.id });
          return prev;
        }
        // If is a new record
        // We save the Fieldworker id to make the create relation.
        prev.create.push({
          fieldworker: {
            connect: {
              id: current,
            },
          },
        });

        return prev;
      },
      {
        reconnect: [],
        create: [],
      } as Required<
        Pick<
          JobJobJobFieldWorkerRelationUpdateRelationInput,
          'create' | 'reconnect'
        >
      >,
    );
  }

  if(jobClockInOutRelation){
    Object.assign(data, { jobClockInOutRelation });
  }

  if (inputData.type) {
    data.type = {
      reconnect: {
        id: inputData.type,
      },
    };
  }

  const variables: JobUpdateMutationVariables = {
    data,
    id,
  };

  return variables;
}
export function updateJobVariablesV2({
  id,
  inputData,
  oldData,
  isFieldWorker,
  images,
  jobInfoExist,
  jobClockInOutRelation,
}: {
  id: string;
  inputData: JobFormType;
  oldData: {
    oldFieldworkers: JobQuery['job']['jobJobFieldWorkerRelation']['items'];
    originalParts: PartType[];
    adminNote?: string;
    officeId?: string;
  };
  isFieldWorker?: boolean;
  images: {
    oldImages: File[];
    newImages: filestack.PickerFileMetadata[];
  };
  jobInfoExist?: boolean;
  jobClockInOutRelation?: JobUpdateInput['jobClockInOutRelation'],
}): JobUpdateMutationVariables {
  const objectJobInfo = jobInfoExist
    ? {
        update: {
          callerCell: getPhoneNumberFomartToSaveInDB(inputData.callerCell),
          callerName: inputData.callerName || '',
          dispatchedBy: inputData.dispatchedBy || '',
        },
      }
    : {
        create: {
          callerCell: getPhoneNumberFomartToSaveInDB(inputData.callerCell),
          callerName: inputData.callerName || '',
          dispatchedBy: inputData.dispatchedBy || '',
        },
      };

  const data: JobUpdateInput = isFieldWorker
    ? {
        fieldworkerNotes: inputData.fieldworkerNotes,
        status: inputData.status,
        unitServiced: inputData.unitServiced,
        model: inputData.model,
        serial: inputData.serial,
        workPerformed: inputData.workPerformed,
        purchaseOrder: inputData.purchaseOrder,
        workOrder: inputData.workOrder,
        callID: inputData.callID,
        signature: inputData.signature ?? '',
        signatureTitle: inputData.signatureTitle,
        signatureName: inputData.signatureName,
        jobJobPartsRelation: createPartsObject(
          inputData as JobFormType,
          oldData.originalParts,
        ),
        images: {
          create: mapSourcesV2(images.newImages),
          disconnect: images.oldImages,
        },
        officeNoteJobRelation: oldData.officeId
          ? {
              update: [
                {
                  data: {
                    id: oldData.officeId,
                    officeNotes: inputData?.officeNotes ?? '',
                  },
                },
              ],
            }
          : {
              create: [
                {
                  officeNotes: inputData.officeNotes,
                },
              ],
            },
        jobJobInfoRelation: objectJobInfo,
      }
    : {
        name: inputData.name,
        sendNotification: inputData.notification,
        sendWorkOrder: inputData.sendWorkOrder,
        status: inputData.status,
        fieldworkerNotes: inputData.fieldworkerNotes,
        unitServiced: inputData.unitServiced,
        model: inputData.model,
        serial: inputData.serial,
        workPerformed: inputData.workPerformed,
        purchaseOrder: inputData.purchaseOrder,
        workOrder: inputData.workOrder,
        callID: inputData.callID,
        priority: inputData.priority,
        adminNote: oldData.adminNote
          ? {
              update: {
                note: inputData.adminNote,
              },
            }
          : {
              create: {
                note: inputData.adminNote,
              },
            },
        endDateTime: inputData.endDate
          ? dayjs(inputData.endDate).toISOString()
          : undefined,
        startDateTime: inputData.startDate
          ? dayjs(inputData.startDate).toISOString()
          : undefined,
        description: inputData.description,
        address: {
          reconnect: {
            id: inputData.address,
          },
        },
        autoClock: true,
        jobJobFieldWorkerRelation: {},
        jobJobPartsRelation: createPartsObject(
          inputData as JobFormType,
          oldData.originalParts,
        ),
        jobJobInfoRelation: objectJobInfo,
      };
  if (!isFieldWorker) {
    data.jobJobFieldWorkerRelation = inputData.fieldworker.reduce(
      (prev, current) => {
        const isOldRecord = oldData.oldFieldworkers.find(
          (item) => item.fieldworker.id === current,
        );

        if (isOldRecord) {
          // If is a old record
          // We push the JobFieldworker id to  the reconnect
          prev.reconnect.push({ id: isOldRecord.id });
          return prev;
        }
        // If is a new record
        // We save the Fieldworker id to make the create relation.
        prev.create.push({
          fieldworker: {
            connect: {
              id: current,
            },
          },
        });

        return prev;
      },
      {
        reconnect: [],
        create: [],
      } as Required<
        Pick<
          JobJobJobFieldWorkerRelationUpdateRelationInput,
          'create' | 'reconnect'
        >
      >,
    );
  }

  if(jobClockInOutRelation){
    Object.assign(data, { jobClockInOutRelation });
  }

  if (inputData.type) {
    data.type = {
      reconnect: {
        id: inputData.type,
      },
    };
  }

  const variables: JobUpdateMutationVariables = {
    data,
    id,
  };

  return variables;
}

/**
 * @returns {JobsListQueryVariables} - Variables.
 * @param {string} id - Id to archive.
 * @param {boolean} archive - Id to archive.
 */
export const archiveJobVariables = (
  id: string,
  archive: boolean,
): JobUpdateMutationVariables => {
  const date = new Date().toISOString();
  const data: JobUpdateInput = {
    archived: archive ? date : (null as unknown as string),
  };
  const obj = {
    data,

    id,
  };

  return obj;
};

/**
 * @param idAddress - Input filter object.
 * @param idJob - Input filter object.
 * @returns Filters mapped to 8base vars.
 */
export const jobHistoryFilter = (
  idAddress: string,
  idJob: string,
): JobFilter => ({
  AND: [
    { id: { not_equals: idJob } },
    { address: { id: { equals: idAddress } } },
  ],
});


export const getClockForJob = (params: {
  currentStatus: JOB_STATUS | undefined;
  previousStatus: JOB_STATUS | undefined;
  clockList: JobQuery['job']['jobClockInOutRelation']['items'] | undefined;
}): JobUpdateInput['jobClockInOutRelation'] => {
  let jobClockInOutRelation: JobUpdateInput['jobClockInOutRelation'];
  const lastClock = params.clockList?.[0];
  if (params.currentStatus !== params?.previousStatus) {

    // on close or pause status
    if (
      lastClock?.type === CLOCK.CLOCK_IN &&
      (params.currentStatus === JOB_STATUS.CLOSED ||
        params.currentStatus === JOB_STATUS.PAUSED)
    ) {
      // create a clock cloused
      jobClockInOutRelation = {
        create: [
          {
            type: CLOCK.CLOCK_OUT,
            fieldworker: {
              connect: {
                id: lastClock.fieldworker.id,
              },
            },
          },
        ],
      };
    }

    if (
      lastClock?.type === CLOCK.CLOCK_IN &&
      !(
        (params.currentStatus as JOB_STATUS) === JOB_STATUS.CLOSED &&
        (params.currentStatus as JOB_STATUS) === JOB_STATUS.PAUSED &&
        (params.currentStatus as JOB_STATUS) === JOB_STATUS.ON_SITE
      )
    ) {
      // create a clock cloused
      jobClockInOutRelation = {
        create: [
          {
            type: CLOCK.CLOCK_OUT,
            fieldworker: {
              connect: {
                id: lastClock.fieldworker.id,
              },
            },
          },
        ],
      };
    }


    if (
      params.currentStatus === JOB_STATUS.ON_ROUTE &&
      lastClock?.type === CLOCK.CLOCK_IN
    ) {
      // CREATE A CLOCK-OUT AND A CLOCK-ON-ROUTE
      jobClockInOutRelation = {
        create: [
          {
            type: CLOCK.CLOCK_OUT,
            fieldworker: {
              connect: {
                id: lastClock.fieldworker.id,
              },
            },
          },
        ],
      };
    }
  }
  return jobClockInOutRelation;
};

export const allFilter = {
  label: 'All',
  value: '',
};