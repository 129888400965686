/* eslint-disable jsdoc/require-jsdoc */
import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import {
  Grid,
  Paper,
  Box,
  Typography,
  useMediaQuery,
  SelectChangeEvent,
} from '@mui/material';

import { useCalendarListQuery } from '../../shared/types/generated';
import { MainLoader } from '../../shared/components/MainLoader';
import { TableHours } from './components/TableHours';
import {
  CalendarFilters,
  CalendarFiltersModal,
} from './components/CalendarFilters';
import { CalendarType, FieldworkerColor } from './calendar-types';
import { TableWeek } from './components/TableWeek';
import { getCalendarFilters } from './calendar-utils';
import { theme, GRAY_LIGHT } from '../../shared/css/theme';
import { CalendarResponsive } from './components/CalendarResponsive';
import { TableAgenda } from './components/TableAgenda';
import { generateColor } from '../fieldworker/fieldworker-utils';
import { useQuery } from '../../shared/utils/route';
import { UseValidateFeatureFlag } from '../auth/auth-hooks';
import { FeatureFlags } from '../../shared/constants/featureFlag';
import { CalendarFiltersModalV2, CalendarFiltersV2 } from './components/CalendarFiltersV2';

type CalendarProps = {
  initialDate?: Date | null;
};

export const Calendar: React.FC<CalendarProps> = ({ initialDate }) => {
  const { query, searchQuery } = useQuery<{ date: string; type: string }>();
  const featureFlagCalendarFieldWorker = UseValidateFeatureFlag(FeatureFlags.CALENDAR_ACTIVE_FIELDWORKER);
  const calendar = useMemo<{ date: Date; type: CalendarType }>(
    () => ({
      date: query?.date ? dayjs(query.date).toDate() : dayjs().toDate(),
      type: query?.type ? (query.type as CalendarType) : CalendarType.DAY,
    }),
    [query],
  );
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [filters, setFilters] = useState<
    {
      id: {
        not_equals: string;
      };
    }[]
  >([]);
  const { data, loading } = useCalendarListQuery({
    variables: {
      filter: {
        archived: {
          is_empty: true,
        },
      },
      jobFilter: getCalendarFilters(
        calendar.date.toISOString(),
        filters,
        calendar.type,
      ),
    },
  });

  const auxFilteredData = data?.fieldworkersList?.items.map((fieldworker) => ({
    ...fieldworker,
    color:
      fieldworker.color || generateColor(fieldworker.fullName?.length || 16),
  }));

  // const showAll = Boolean(auxFilteredData?.length);

  const formatData = auxFilteredData;

  const filteredFieldWorker = useMemo(() => {
    const retArrayOne: FieldworkerColor[] = [];
    const retArraytwo: FieldworkerColor[] = [];
    formatData?.forEach((item) => {
      if (filters.find((f) => f.id.not_equals === item.id) !==
      undefined ||
      item.fieldworkerJobFieldworkerRelation.items.length > 0) {
        retArrayOne.push(item as FieldworkerColor);
      } else {
        retArraytwo.push(item as FieldworkerColor);
      }
    });
    
    return {
      activeFieldworkers: retArrayOne,
      inactiveFieldworkers: retArraytwo,
    };
  }, [formatData, filters]);

  const onChangeDate = (value: Date | null): void => {
    let search: Partial<{ date: string; type: string }> = {
      date: dayjs(value).format('MM/DD/YYYY'),
    };
    if (query?.type) {
      search = {
        ...search,
        type: query.type,
      };
    }
    setFilters([]);
    searchQuery(search);
  };

  const onChangeType = (event: SelectChangeEvent): void => {
    let search: Partial<{ date: string; type: string }> = {
      type: event.target.value,
    };
    if (query?.date) {
      search = {
        date: query.date,
        ...search,
      };
    }
    setFilters([]);
    searchQuery(search);
  };

  const filtersOnChange = (id: string, checked: boolean): void => {
    if (checked) {
      setFilters([...filters, { id: { not_equals: id } }]);
    } else {
      setFilters(filters.filter((item) => item.id.not_equals !== id));
    }
  };

  useEffect(() => {
    if (matchesSm) {
      onChangeType({
        target: {
          value: CalendarType.AGENDA,
          name: CalendarType.AGENDA,
        },
      } as SelectChangeEvent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchesSm]);

  useEffect(() => {
    if (initialDate) {
      onChangeDate(initialDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDate]);

  return (
    <Grid
      container
      spacing={[0, 1, 2]}
      flexWrap='nowrap'
    >
      <Grid
        item
        sx={{
          display: ['none', 'block', 'block'],
          minWidth: '230px',
          maxWidth: '250px',
          width: '30%',
        }}
      >
        <Paper
          variant="card"
          sx={{
            height: '82vh',
            '&::-webkit-scrollbar': {
              width: '0.3rem',
              backgroundColor: '#fafafa',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#bcbcbd',
              borderRadius: '10px',
            },
          }}
        >
          <Typography
            variant="h6"
            color="primary"
            sx={{
              fontSize: '15px',
            }}
          >
            Resources
          </Typography>

          <Box
            sx={{
              width: '100%',
            }}
          >
            {featureFlagCalendarFieldWorker ? <CalendarFiltersV2
              loading={loading}
              onChangeDate={onChangeDate}
              date={calendar.date}
              fieldworkers={filteredFieldWorker}
              filters={filters}
              onChange={filtersOnChange}
              type={calendar.type}
              onTypeChange={onChangeType}
            /> : <CalendarFilters
            onChangeDate={onChangeDate}
            date={calendar.date}
            fieldworkers={(formatData as FieldworkerColor[]) ?? []}
            filters={filters}
            onChange={filtersOnChange}
            type={calendar.type}
            onTypeChange={onChangeType}
          />}
          </Box>
        </Paper>
      </Grid>
      {!loading ? (
        <>
          {matchesSm ? (
            <CalendarResponsive
              date={calendar.date.toISOString()}
              type={calendar.type}
              formatData={formatData as FieldworkerColor[]}
            >
              {featureFlagCalendarFieldWorker ? <CalendarFiltersModalV2
                onChangeDate={onChangeDate}
                date={calendar.date}
                fieldworkers={filteredFieldWorker}
                loading={loading}
                filters={filters}
                onChange={filtersOnChange}
                type={calendar.type}
                onTypeChange={onChangeType}
              /> : <CalendarFiltersModal
                onChangeDate={onChangeDate}
                date={calendar.date}
                fieldworkers={(formatData as FieldworkerColor[]) ?? []}
                filters={filters}
                onChange={filtersOnChange}
                type={calendar.type}
                onTypeChange={onChangeType}
              />}
            </CalendarResponsive>
          ) : (
            <Grid item flexGrow={1}>
              <Paper
                variant="card"
                sx={{
                  height: '82vh',
                  position: 'relative',
                  overflowY: 'auto',
                  backgroundColor: ['#00000000', '#ffff'],
                  '&::-webkit-scrollbar': {
                    backgroundColor: '#fafafa',
                    width: '0.6rem',
                    height: '0.6rem',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#bcbcbd',
                    borderRadius: '10px',
                  },
                }}
              >
                <Box
                  display="flex"
                  justifyContent={['flex-end', 'space-between']}
                  sx={{
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      height: '40px',
                      width: '47px',
                      borderRight: `1px solid ${GRAY_LIGHT}`,
                    }}
                    display="flex"
                    alignItems="center"
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: '10px',
                      }}
                    >
                      GMT{dayjs(calendar.date).format('ZZ').slice(0, -2)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: '100%',
                      width: 'calc(100% - 50px)',
                      paddingLeft: '10px',
                    }}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6" color="primary">
                      {dayjs(calendar.date).format('dddd, DD MMMM')}
                    </Typography>
                  </Box>
                </Box>
                {calendar.type === CalendarType.DAY && (
                  <TableHours
                    fieldworkers={(formatData as FieldworkerColor[]) ?? []}
                    day={calendar.date.toISOString()}
                  />
                )}
                {calendar.type === CalendarType.WEEK && (
                  <TableWeek
                    fieldworkers={(formatData as FieldworkerColor[]) ?? []}
                    day={calendar.date.toISOString()}
                  />
                )}
                {calendar.type === CalendarType.AGENDA && (
                  <TableAgenda
                    fieldworkers={(formatData as FieldworkerColor[]) ?? []}
                    day={calendar.date.toISOString()}
                  />
                )}
              </Paper>
            </Grid>
          )}
        </>
      ) : (
        <Grid item flexGrow={1}>
          <MainLoader />
        </Grid>
      )}
    </Grid>
  );
};
