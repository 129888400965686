import { Pill, PillsTypeProp } from '../../../shared/components/Pills';
import { JobsStatuses } from '../jobs-types';

/**
 * @param props - Props.
 * @param props.status - Status.
 * @returns Jobs Status pill.
 */
export function JobStatusPill(props: { status: string }): JSX.Element {
  const keys = Object.keys({ ...JobsStatuses, PAUSED: 'PAUSED' });
  const index = keys.indexOf(props.status);

  return (
    <Pill
      text={props.status.replaceAll('_', ' ')}
      type={index as PillsTypeProp}
    />
  );
}
