import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { error as errorLogger } from '@cobuildlab/pure-logger';
import {
  Box,
  Button,
  Typography,
  FormControl,
  TextField,
  CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useFieldworkerUpdateMutation } from '../../shared/types/generated';
import { useFieldworkerDetailQuery } from '../../shared/types/types';
import logo from '../../shared/assets/fieldworker.svg';
import { clearQuerysFromCache } from '../../shared/utils';
import { snackbar } from '../../shared/components/Snackbar';

import {
  FormViewConatiner,
  FormImageContainer,
} from '../../shared/components/Containers';
import { generateColor } from './fieldworker-utils';
import { getPhoneNumberFomartToSaveInDB, getPhoneNumberWithOutRegionCode } from '../../shared/utils/phone';
import { FIELDWORKER_FORM_VALIDATOR_SCHEMA } from './fieldworker-types';

type EditFieldWorkerType = {
  fullName: string;
  email: string;
  phoneNumber: string;
}

const defaultValues: EditFieldWorkerType = {
  fullName: '',
  email: '',
  phoneNumber: '',
};


export const EditFieldworker: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { control, handleSubmit, formState: { errors }, setValue } = useForm<EditFieldWorkerType>({
    defaultValues,
    resolver: yupResolver(FIELDWORKER_FORM_VALIDATOR_SCHEMA, {})
  });
  const { data: dataFieldworker, loading: isFetchingData } = useFieldworkerDetailQuery({
    variables: {
      id,
    },
  });
  const hasFormErrors = Boolean(Object.keys(errors)?.length);

  const [editFieldworker, { loading }] = useFieldworkerUpdateMutation({
    onCompleted: () => {
      snackbar.success('Fieldworker edited');
      history.push('/fieldworker');
    },
    onError: (err) => {
      console.log(JSON.stringify(err));
      errorLogger(err);
    },
    update: clearQuerysFromCache(['fieldworkersList']),
  });

    useEffect(() => {
      if(dataFieldworker){
        setValue('fullName', dataFieldworker?.fieldworker?.fullName);
        setValue('email', dataFieldworker?.fieldworker?.email);
        setValue('phoneNumber', getPhoneNumberWithOutRegionCode(dataFieldworker?.fieldworker?.phoneNumber));
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataFieldworker]);

    const handleSubmitForm = handleSubmit((data) : void => {
      const phoneNumberFormatToSaveInDataBD = getPhoneNumberFomartToSaveInDB(data.phoneNumber);
      const dataVariable = { 
        ...data, 
        id, 
        phoneNumber: phoneNumberFormatToSaveInDataBD 
      };
      const color = generateColor(data.fullName?.length ?? 16);

      if(dataFieldworker?.fieldworker.color !== color){
        Object.assign(dataVariable, {
          color,  
        });
      }

      editFieldworker({
        variables: { 
          data:  dataVariable,
        }
      });
    });

  return (
    <FormViewConatiner
      title="Edit Fieldworker"
      subtitle={dataFieldworker?.fieldworker?.fullName || ''}
    >
      <Typography
        variant="h5"
        sx={{ display: [null, 'none'], gridColumn: 'span 2' }}
      >
        Information
      </Typography>
      <FormImageContainer imagePath={logo} onSubmit={handleSubmitForm}>
        <Typography
          variant="h5"
          sx={{ display: ['none', 'block'], gridColumn: 'span 2' }}
        >
          Information
        </Typography>
        <FormControl
          variant="outlined"
          fullWidth
          sx={{ gridColumn: 'span 2', marginTop: '10px', position: 'relative' }}
        >
          <Controller 
            control={control}
            name="fullName"
            rules={{
              required: {
                value: true,
                message: 'Full name is required'
              }
            }}
            render={({ field }) => (
            <TextField
              {...field}
              InputProps={{ endAdornment: isFetchingData ? <CircularProgress size={20} /> : undefined }}
              label="Full Name"
              helperText={errors?.fullName?.message}
              error={Boolean(errors?.fullName?.message)}
            />
            )}
          />
        </FormControl>
        <FormControl
          variant="outlined"
          fullWidth
          sx={{ gridColumn: 'span 2', marginTop: '10px', position: 'relative' }}
        >
          <Controller
            control={control}
            name="email"
            rules={{
              required: {
                value: true,
                message: 'This field is required',
              },
              maxLength: {
                value: 100,
                message: 'Email must be at most 100 characters',
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                disabled
                InputProps={{ endAdornment: isFetchingData ? <CircularProgress size={20} /> : undefined }}
                label="Email"
                helperText={errors?.email?.message}
                error={Boolean(errors?.email?.message)}
              />
            )}
          />
        </FormControl>
        <FormControl
          variant="outlined"
          fullWidth
          sx={{ gridColumn: 'span 2', marginTop: '10px', position: 'relative' }}
        >
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field }) => (
              <TextField
                {...field}
                InputProps={{ endAdornment: isFetchingData ? <CircularProgress size={20} /> : undefined }}
                label="Phone Number"
                helperText={errors?.phoneNumber?.message}
                error={Boolean(errors?.phoneNumber?.message)}
              />
            )}
          />
        </FormControl>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            gridColumn: 'span 2',
            position: ['static', 'absolute'],
            bottom: '40px',
            right: '100px',
            width: ['100%', '210px'],
            marginTop: ['20px', '0'],
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              history.goBack();
            }}
          >
            Cancel
          </Button>
          <LoadingButton disabled={hasFormErrors} variant="contained" loading={loading} type="submit">
            Save
          </LoadingButton>
        </Box>
      </FormImageContainer>
    </FormViewConatiner>
  );
};
