import { CustomerHeader } from './CustomerHeader';
import { CustomerTable } from './CustomerTable';
import { ListViewConatiner } from '../../shared/components/Containers';

/**
 * @returns {JSX.Element} - Customer view.
 */
export function CustomerView(): JSX.Element {
  return (
    <ListViewConatiner>
      <CustomerHeader />
      <CustomerTable />
    </ListViewConatiner>
  );
}
