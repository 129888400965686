import React from 'react';
import { FilterList } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CalendarType, FieldworkerColor } from '../calendar-types';
import { Dropdown } from '../../../shared/components/Dropdown';
import { theme } from '../../../shared/css/theme';

/**
 * @param {JSX.Element} props - Calendar View.
 * @param {JSX.Element} props.fieldworkers - Calendar View.
 * @returns {JSX.Element} - Calendar View.
 */
export function CalendarFiltersV2({
  fieldworkers,
  loading,
  type,
  onTypeChange,
  onChange = () => {},
  filters,
  onChangeDate,
  date,
  expanded,
}: {
  fieldworkers: {
    activeFieldworkers: FieldworkerColor[];
    inactiveFieldworkers: FieldworkerColor[];
  };
  loading: boolean,
  type: CalendarType;
  onTypeChange: (event: SelectChangeEvent) => void;
  onChange?: (id: string, checked: boolean) => void;
  filters: {
    id: {
      not_equals?: string;
      equals?: string;
    };
  }[];
  onChangeDate: (date: Date | null) => void;
  date: Date | null;
  expanded?: boolean;
}): JSX.Element {
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          label="Date"
          onChange={onChangeDate}
          value={date}
          renderInput={(params) => (
            <TextField
              fullWidth
              sx={{
                marginTop: '10px',
              }}
              {...params}
            />
          )}
        />
      </LocalizationProvider>
      <FormControl
        fullWidth
        sx={{
          marginTop: '1rem',
        }}
      >
        <InputLabel>Type</InputLabel>
        <Select label="Type" value={type} onChange={onTypeChange}>
          {!matchesSm && (
            <MenuItem value={CalendarType.DAY}>{CalendarType.DAY}</MenuItem>
          )}
          <MenuItem value={CalendarType.WEEK}>{CalendarType.WEEK}</MenuItem>
          <MenuItem value={CalendarType.AGENDA}>{CalendarType.AGENDA}</MenuItem>
        </Select>
      </FormControl>
      <Accordion
        sx={{
          boxShadow: 'none',
          padding: '0',
        }}
        defaultExpanded={expanded !== undefined ? expanded : true}
      >
        <AccordionSummary
          sx={{
            padding: '0',
          }}
          expandIcon={<ExpandMoreIcon color="primary" />}
        >
          <Typography color="primary" variant="subtitle2">
            FW assigned
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: '0',
          }}
        >
          {!loading ? (
            <FormGroup>
              {fieldworkers?.activeFieldworkers?.map((item) => (
                <FormControlLabel
                  key={item.id}
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    width: '100%',
                  }}
                  label={
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: '12px',
                      }}
                      noWrap
                    >
                      {item.fullName}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      checked={Boolean(
                        item.fieldworkerJobFieldworkerRelation.items.length > 0,
                      )}
                      onChange={(event) => {
                        onChange(item.id, !event.target.checked);
                      }}
                      disabled={
                        filters.find((f) => f.id.not_equals === item.id) ===
                          undefined &&
                        item.fieldworkerJobFieldworkerRelation.items.length ===
                          0
                      }
                      sx={{
                        color: item.color,
                        '&.Mui-checked': {
                          color: item.color,
                        },
                      }}
                    />
                  }
                />
              ))}
            </FormGroup>
          ) : (
            <>
              <Skeleton animation="wave" width="inherit" height="inherit" />
              <Skeleton animation="wave" width="inherit" height="inherit" />
              <Skeleton animation="wave" width="inherit" height="inherit" />
            </>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{
          boxShadow: 'none',
          padding: '0',
        }}
        defaultExpanded={expanded !== undefined ? expanded : true}
      >
        <AccordionSummary
          sx={{
            padding: '0',
          }}
          expandIcon={<ExpandMoreIcon color="primary" />}
        >
          <Typography color="primary" variant="subtitle2">
          FW Available
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: '0',
          }}
        >
          {!loading ? (
            <FormGroup>
              {fieldworkers?.inactiveFieldworkers?.map((item) => (
                <FormControlLabel
                  key={item.id}
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    width: '100%',
                  }}
                  label={
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: '12px',
                      }}
                      noWrap
                    >
                      {item.fullName}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      checked={Boolean(
                        item.fieldworkerJobFieldworkerRelation.items.length > 0,
                      )}
                      onChange={(event) => {
                        onChange(item.id, !event.target.checked);
                      }}
                      disabled={
                        filters.find((f) => f.id.not_equals === item.id) ===
                          undefined &&
                        item.fieldworkerJobFieldworkerRelation.items.length ===
                          0
                      }
                      sx={{
                        color: item.color,
                        '&.Mui-checked': {
                          color: item.color,
                        },
                      }}
                    />
                  }
                />
              ))}
            </FormGroup>
          ) : (
            <>
              <Skeleton animation="wave" width="inherit" height="inherit" />
              <Skeleton animation="wave" width="inherit" height="inherit" />
              <Skeleton animation="wave" width="inherit" height="inherit" />
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
}
/**
 * @param {JSX.Element} props - Calendar View.
 * @param {JSX.Element} props.fieldworkers - Calendar View.
 * @returns {JSX.Element} - Calendar View.
 */
export function CalendarFiltersModalV2({
  fieldworkers,
  loading,
  type,
  onTypeChange,
  onChange,
  filters,
  onChangeDate = () => {},
  date,
}: {
  fieldworkers: {
    activeFieldworkers: FieldworkerColor[];
    inactiveFieldworkers: FieldworkerColor[];
  };
  loading: boolean,
  type: CalendarType;
  onTypeChange: (event: SelectChangeEvent) => void;
  onChange?: (id: string, checked: boolean) => void;
  filters: {
    id: {
      equals?: string;
      not_equals?: string;
    };
  }[];
  onChangeDate: (date: Date | null) => void;
  date: Date | null;
}): JSX.Element {
  return (
    <>
      <Dropdown
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        button={({ toggle }) => (
          <IconButton
            aria-label="filters"
            component="button"
            onClick={({ currentTarget }: React.MouseEvent<HTMLElement>) => {
              toggle(currentTarget);
            }}
          >
            <FilterList />
          </IconButton>
        )}
        sx={{
          minWidth: '200px',
          maxWidth: '60%',
          '&::-webkit-scrollbar': {
            width: '0.3rem',
            backgroundColor: '#fafafa',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#bcbcbd',
            borderRadius: '10px',
          },
        }}
      >
        <CalendarFiltersV2
          onChangeDate={onChangeDate}
          date={date}
          fieldworkers={fieldworkers}
          loading={loading}
          filters={filters}
          type={type}
          onTypeChange={onTypeChange}
          onChange={onChange}
        />
      </Dropdown>
    </>
  );
}
