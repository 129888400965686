import { Grid, Typography, Link, Box, useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { GRAY_DARK, theme } from '../../../shared/css/theme';
import { Job } from '../../../shared/types/generated';

type InfoTooltipComponentType = {
  job: Job;
};
/**
 * @param {string} props - Today date.
 * @param {string} props.job - Today date.
 * @returns Component.
 */
export const InfoTooltipComponent: React.FC<InfoTooltipComponentType> = ({
  job,
}) => {
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      sx={{
        display: ['grid', 'block'],
        gridTemplateColumns: [matchesSm ? 'none' : 'repeat(3, 33%)'],
        padding: ['10px', '0 5px 5px 5px'],
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      <Box
        sx={{
          display: matchesSm ? null : ['grid', 'block'],
          gridTemplateColumns: [matchesSm ? 'none' : 'repeat(3, 33%)'],
          padding: ['10px', '0 5px 5px 5px'],
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <Link
          noWrap
          href={`jobs/${job.id}`}
          color="grayDark"
          underline="none"
          sx={{
            fontWeight: 'bold',
            fontSize: '13px',
            width: '100%',
          }}
        >
          #{job.code}
        </Link>
        {!matchesSm ? (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid md={12}>
              <Typography
                noWrap
                color={GRAY_DARK}
                variant="caption"
                component="div"
              >
                {dayjs(job.startDateTime).format('HH:mm A')}
              </Typography>
              <Box display="flex" flexDirection="column" alignContent="center">
                <Typography
                  noWrap
                  color={GRAY_DARK}
                  variant="caption"
                  component="div"
                  fontWeight="bold"
                  sx={{
                    gridColumnStart: '3',
                  }}
                >
                  Address:{' '}
                </Typography>
                <Typography
                  noWrap
                  color={GRAY_DARK}
                  variant="caption"
                  component="div"
                >
                  <FmdGoodIcon
                    sx={{
                      color: GRAY_DARK,
                      fontSize: '10px',
                    }}
                  />
                  {job?.address?.state}, {job?.address?.city},{' '}
                  {job?.address?.streetAddress}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <>
            <Typography
              noWrap
              color={GRAY_DARK}
              variant="caption"
              component="div"
            >
              {dayjs(job?.startDateTime).format('HH:mm A')}
            </Typography>
            <Typography
              noWrap
              color={GRAY_DARK}
              variant="caption"
              component="div"
              sx={{
                gridColumnStart: '3',
              }}
            >
              <FmdGoodIcon
                sx={{
                  color: GRAY_DARK,
                  fontSize: '10px',
                }}
              />
              {job?.address?.state}, {job?.address?.city},{' '}
              {job?.address?.streetAddress}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};
