import dayjs from 'dayjs';
import { Fieldworker } from '../../shared/types/generated';
import { Job } from '../../shared/types/types';

export const hours = [
  {
    id: '12 AM',
    value: '0',
  },
  {
    id: '1 AM',
    value: '1',
  },
  {
    id: '2 AM',
    value: '2',
  },
  {
    id: '3 AM',
    value: '3',
  },
  {
    id: '4 AM',
    value: '4',
  },
  {
    id: '5 AM',
    value: '5',
  },
  {
    id: '6 AM',
    value: '6',
  },
  {
    id: '7 AM',
    value: '7',
  },
  {
    id: '8 AM',
    value: '8',
  },
  {
    id: '9 AM',
    value: '9',
  },
  {
    id: '10 AM',
    value: '10',
  },
  {
    id: '11 AM',
    value: '11',
  },
  {
    id: '12 PM',
    value: '12',
  },
  {
    id: '1 PM',
    value: '13',
  },
  {
    id: '2 PM',
    value: '14',
  },
  {
    id: '3 PM',
    value: '15',
  },
  {
    id: '4 PM',
    value: '16',
  },
  {
    id: '5 PM',
    value: '17',
  },
  {
    id: '6 PM',
    value: '18',
  },
  {
    id: '7 PM',
    value: '19',
  },
  {
    id: '8 PM',
    value: '20',
  },
  {
    id: '9 PM',
    value: '21',
  },
  {
    id: '10 PM',
    value: '22',
  },
  {
    id: '11 PM',
    value: '23',
  },
];
export const days = [
  {
    id: 'Sun',
  },
  {
    id: 'Mon',
  },
  {
    id: 'Tues',
  },
  {
    id: 'Wed',
  },
  {
    id: 'Thur',
  },
  {
    id: 'Fri',
  },
  {
    id: 'Sat',
  },
];
export const colors: Array<string> = [
  '#47B2E499',
  '#3DC28277',
  '#FFB80099',
  '#EF535099',
  '#B0002099',
  '#9558E299',
  '#1c387e7d',
  '#53375085',
  '#01673499',
  '#6D4C4199',
  '#89A7F499',
  '#75D2A6ff',
  '#fbc845ff',
  '#F4986Dff',
  '#F76A8399',
  '#BB98E899',
  '#646F8D99',
  '#DAAFD699',
  '#9CF4C899',
  '#E3998199',
  '#2E65F299',
  '#07D97499',
  '#DC9E0099',
  '#FF510099',
  '#FF002E99',
  '#5F02D699',
  '#909C8099',
  '#D331C399',
  '#0D8C4D99',
  '#C9552E99',
];

export type FieldworkerColor = Fieldworker & {
  color: string;
};
export type JobColor = Job & {
  color: string;
  fieldWorker?: Fieldworker;
};

export enum CalendarType {
  DAY = 'Day',
  WEEK = 'Week',
  AGENDA = 'Scheduled',
}

export type FieldworkerDayList = {
  id: string;
  fullName: string;
  color: string;
  jobs: {
    id: string;
    jobsDay: JobColor[];
  }[];
}[];
/**
 * @param {} day - Old Data.
 * @returns {Array<FieldworkerColor>} - Variables.
 */
export function getDays(day: string): Array<string> {
  const result: Array<string> = [];
  const currentDay = Number(dayjs(day).format('DD'));
  const dayOfWeek = Number(dayjs(day).format('d'));
  for (let i = 0; i < 8; i += 1) {
    if (currentDay - dayOfWeek + i < 1 || currentDay - dayOfWeek + i > 30) {
      result.push(
        dayjs(day)
          .date(currentDay - dayOfWeek + i)
          .format('D'),
      );
    } else {
      result.push(String(currentDay - dayOfWeek + i));
    }
  }

  return result;
}
