/**
 * @param {number} number - Length of the fieldworker name.
 * @returns {string} - Color for the fieldworker.
 */
export const generateColor = (number: number): string => {
  const finalNumber = number > 16 ? 16 : number;
  const letters = '0123456789ABCDEF';
  let color = '#';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 6; i++)
    color += letters[Math.floor(Math.random() * finalNumber)];
  return `${color}55`;
};
