import { Box, Typography, Button } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import CoolriteLogo from '../../shared/assets/Coolritelogo_page.png';

/**
 * @returns {JSX.Element} - Customer's view table.
 */
export function Welcome(): JSX.Element {
  const { loginWithRedirect } = useAuth0();

  return (
    <Box
      sx={{ height: '100%', width: '100%' }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        sx={{ width: '100%' }}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        m={2}
      >
        <img src={CoolriteLogo} alt="logo" style={{ width: '70%' }} />
      </Box>
      <Box
        sx={{ width: '100%' }}
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={2}
      >
        <Typography variant="h2">Welcome</Typography>
        <Typography variant="body1">Web App Coolrite </Typography>
      </Box>
      <Box
        sx={{ width: '100%' }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        m={1}
      >
        <Box m={5}>
          <Button
            variant="outlined"
            color="primary"
            onClick={loginWithRedirect}
          >
            Log in
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
