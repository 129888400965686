/* eslint-disable jsdoc/require-jsdoc */
import React from 'react';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';

const Container = styled('span')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
  padding: theme.spacing(1, 0, 1, 0),
}));

const Text = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.subtitle2.fontFamily,
  fontWeight: theme.typography.subtitle2.fontWeight,
  fontSize: theme.typography.subtitle2.fontSize,
  lineHeight: theme.typography.subtitle2.lineHeight,
  letterSpacing: theme.typography.subtitle2.letterSpacing,
  color: theme.palette.grey[600],
  '&: hover': {
    textDecoration: 'underline',
  },
}));

const Icon = styled(ArrowBackIos)(({ theme }) => ({
  fontSize: theme.typography.subtitle2.fontSize,
  color: theme.palette.grey[600],
  marginRight: theme.spacing(1),
}));

export const ViewBackButton: React.FC = () => {
  const history = useHistory();
  return (
    <Container onClick={history.goBack}>
      <Icon />
      <Text>Back</Text>
    </Container>
  );
};
