/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import dayjs from 'dayjs';
import generator from 'generate-password';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FieldworkerFilter } from '../../shared/types/generated';
import {
  PASSWORD_FORM_VALIDATOR_SCHEMA,
  FIELDWORKER_FORM_VALIDATOR_SCHEMA,
} from './fieldworker-types';
import { DB_STATUS } from '../../shared/constants/common';
/**
 * @param  filters - Input filter object.
 * @param {string} filters.search -Name of fieldworker.
 * @param {string} filters.from -Date start.
 * @param {string} filters.to -Date end.
 * @param {string} filters.status -Fieldworke status.
 * @returns {Object}.
 */
export function searchFieldworker(filters: {
  search?: string;
  from?: string;
  to?: string;
  status?: string;
}): FieldworkerFilter {
  const resultFilter: FieldworkerFilter[] = [
    {
      archived: {
        is_empty: true,
      },
    },
  ];

  if (filters.search && filters.search !== '') {
    resultFilter.push({
      OR: [
        {
          fullName: {
            contains: filters.search,
          },
        },
        {
          email: {
            contains: filters.search,
          },
        },
      ],
    });
  }

  if (filters.from && filters.from !== '') {
    resultFilter.push({
      createdAt: {
        gte: dayjs(filters.from).toISOString(),
      },
    });
  }

  if (filters.to && filters.to !== '') {
    resultFilter.push({
      createdAt: {
        lte: dayjs(filters.to).toISOString(),
      },
    });
  }

  if (filters?.status === DB_STATUS.All) {
    resultFilter[0] = {};
  }

  if (filters.status && filters.status === DB_STATUS.Active) {
    resultFilter[0] = {
      archived: {
        is_empty: true,
      },
    };
  }
  if (filters.status && filters.status === DB_STATUS.Archived) {
    resultFilter[0] = {
      archived: {
        is_empty: false,
      },
    };
  }
  return { AND: resultFilter };
}

/**
 * @returns {string} New password.
 */
export const suggestedPassword = (): string => {
  const password = generator.generate({
    numbers: true,
    length: 10,
  });
  return password;
};

/**
 * @returns Form hook state.
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function usePasswordForm() {
  const data = useForm({
    resolver: yupResolver(PASSWORD_FORM_VALIDATOR_SCHEMA, {}),
  });
  return data;
}
/**
 * @returns Form hook state.
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useFieldworkerForm() {
  const data = useForm({
    resolver: yupResolver(FIELDWORKER_FORM_VALIDATOR_SCHEMA, {}),
  });
  return data;
}
